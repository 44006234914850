import React, { useState,useEffect } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { data_not_found } from '../../../helpers/Constants'
import { Pagination,Input,Select } from 'antd'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';
import { Toaster, toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { base_url } from '../../../App'
import { MdOutlineRefresh } from 'react-icons/md'
import {BsSearch} from 'react-icons/bs';


function ClientInvoice() {

 const navigate = useNavigate()   
 const [data,setdata] = useState([])   
 const [clients,setclients] = useState([])   
 const [page,setpage] = useState(1)   
 const [pagination,setpagination] = useState({})

 const [search,setsearch] = useState({client:'',month_year:'',attachment_type:''})
 const data_type = [{label:'Invoice',value:'Invoice'},{label:'Statutories',value:'Statutories'},{label:'Other Docs',value:'Other Docs'}]


 const roles = useSelector(state=>state.Auth.roles)

 
 useEffect(()=>{
    getData()
 },[page])

 async function searchData(v){ 
    await axios.get(`fms/fmsclients/search?search_text=${v}`,authHeader)
     .then((res)=>{
         let arr = []
         res?.data?.datas?.forEach(d => {
             arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
         });
       
         setclients(arr)
         return arr
     }).catch((err)=>{
     })
   }

   function handleChange(v,type){
    if(type === 'client'){
       setsearch({...search,client:clients?.find((c)=>c?.value === v)})
    }
    if(type === 'attachment_type'){
        setsearch({...search,attachment_type:data_type?.find((c)=>c?.value === v)})
    }
  }
 
 function getData(){
    axios.get(`fms/client_invoice/get?page=${page}&month_year=${search?.month_year}&client=${search?.client?.value !== undefined ? search?.client?.value : ''}&attachment_type=${search?.attachment_type?.value !== undefined ? search?.attachment_type?.value : ''}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 function applyfilter(){
    setpage(1)
    axios.get(`fms/client_invoice/get?page=${1}&month_year=${search?.month_year}&client=${search?.client?.value !== undefined ? search?.client?.value : ''}&attachment_type=${search?.attachment_type?.value !== undefined ? search?.attachment_type?.value : ''}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 function resetfilter(){
    setsearch({client:'',month_year:'',attachment_type:''})
    setpage(1)
    axios.get(`fms/client_invoice/get?page=${1}&month_year=${''}&client=${''}&attachment_type=${''}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 function setpagefuun(v){
    setpage(v)
 }

 function deleteData(id){
    axios.delete(`fms/client_invoice/delete/${id}`,authHeader)
    .then((res)=>{
        toast.success('Deleted Successfully')
        getData()
    })
 }

 function openfile(v){
    let url = `${base_url}` + v
    window.open(url,'_blank')
 }


 console.log("search here",search)

  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div style={{width:'98%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eee'}}>
                <BoldText1 val={`Client Based Attachments (${pagination?.total})`} />
                
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Select value={search?.attachment_type} style={{width:'150px',marginRight:'10px'}}  options={data_type}  onChange={(v)=>handleChange(v,'attachment_type')}  />
               
                <Select showSearch filterOption={false} value={search?.client} style={{width:'150px'}}  options={clients} onSearch={searchData} onChange={(v)=>handleChange(v,'client')}  />
                <h6 style={{width:'10px'}}></h6>
                <Input style={{width:'150px'}}  type='month'value={search?.month_year} onChange={(e)=>{setsearch({...search,month_year:e.target.value})}} />  
                <h6 style={{width:'10px'}}></h6>
                <BsSearch onClick={()=>{applyfilter();}} size={27} style={{marginRight:'5px',background:'#222f3e',color:'#fff',padding:'5px'}}/>
                <MdOutlineRefresh onClick={()=>{resetfilter();}} size={30} style={{marginRight:'5px',background:'#eee',padding:'4px'}}/>

                {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr') || roles?.includes('fms_finance')) &&
                <PrimaryButton btn_name={'Add Client Invoice'} onClick={()=>navigate('/fms/client_invoice/create')} />}
                </div>
            </div>

            {data?.length > 0 ? 
            <>
            <div style={{ border:'1px solid #eaeded',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                <div className='mateial_table_header'>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'11px',minWidth: '4%',maxWidth: '4%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>SL No</p> 
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'11px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>Client</p>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd',  }}>Month Year</p>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #ddd',  }}>Assigned user Type</p>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #ddd',  }}>Assigned Officer</p>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'12px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #ddd',  }}>Message</p>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #ddd',  }}>Status</p>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #ddd',  }}>Remarks</p>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'11px',minWidth: '13%',maxWidth: '13%',borderRight:'1px solid #ddd',  }}>Created / Updated</p>
                    <p style={{padding:'5px !important',paddingLeft:'5px',fontSize:'12px',minWidth: '7%',maxWidth: '7%' }}>Actions</p>
                </div>


                {data.map((d, i) => (
                    <div className='mateial_table_body' key={i}   style={{borderTop:'1px solid #ddd',padding:'0px !important',display:'flex',flexDirection:'row',}}>
                   
                    <p style={{fontSize:'11px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '4%',maxWidth: '4%',borderRight:'1px solid #ddd', color: 'black',paddingBottom:'0px',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                    <p style={{fontSize:'12px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '9.6%',maxWidth: '9.6%',borderRight:'1px solid #ddd', color: 'black',opacity:'1', }} >{d?.client?.client_name}</p>
                    <p style={{fontSize:'12px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '9.6%',maxWidth: '9.6%',borderRight:'1px solid #ddd', }}>{d?.month_year} / {d?.attachment_type}</p>
                    <p style={{fontSize:'12px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '11.5%',maxWidth: '11.5%',borderRight:'1px solid #ddd', }}>{d?.assigned_user_type}</p>
                    <p style={{fontSize:'12px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '11.5%',maxWidth: '11.5%',borderRight:'1px solid #ddd', }}>{d?.assigned_user_type === 'InHouse-Employee' ? d?.field_officer?.name : d?.deployed_officer?.name}</p>
                    <p style={{fontSize:'12px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '14.6%',maxWidth: '14.6%',borderRight:'1px solid #ddd', }}>{d?.message} <br></br> {d?.attachment !== '' && <span onClick={()=>openfile(d?.attachment)} style={{textDecoration:'underline',fontWeight:'bold',color:'#4fb3ff'}}>View Attachment</span>}</p>
                    <p style={{fontSize:'12px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '6.7%',maxWidth: '6.7%',borderRight:'1px solid #ddd', }}>{d?.status}</p>
                    <p style={{fontSize:'12px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '11.6%',maxWidth: '11.6%',borderRight:'1px solid #ddd', }}>{d?.remarks} <br></br> {d?.remark_attachment !== '' && <span onClick={()=>openfile(d?.remark_attachment)} style={{textDecoration:'underline',fontWeight:'bold',color:'#4fb3ff'}}>View Attachment</span>}</p>
                    <p style={{fontSize:'8px !important',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '12.5%',maxWidth: '12.5%',borderRight:'1px solid #ddd', }}>{moment(d?.createdAt).format('ll')} / {moment(d?.updatedAt).format('ll')}</p>
                    <p style={{fontSize:'12px',wordBreak:'break-all',paddingBottom:'0px',marginBottom:'0px',minWidth: '7%',maxWidth: '7%' }}>
                        <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('edit',{state:d})} />
                        {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
                        <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                    </p>

                    </div>
                ))}

            </div> 

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > 15 &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                }
            </div>
            </>
            :
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
            </div>}
        </div>
    </FmsDashboardMainRoot>
  )
}

export default ClientInvoice