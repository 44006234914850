import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { Select, Button, Input } from 'antd'
import { SmallText } from '../../../helpers/constants/ConstantsText'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import makeAnimated from 'react-select/animated';
import { authHeader } from '../../../helpers/usertoken/UserToken'
import TextInput from '../../../components/ItComponents/TextInput'
import TextArea from 'antd/lib/input/TextArea'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import { Upload } from 'antd';
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';

const animatedComponents = makeAnimated();

function MonthlyCalendartTaskCE() {

  const [clients,setclients] = useState([])  
  const [old_data_client,setold_data_client] = useState([])  
  const [users,setusers] = useState([])  
  const navigation = useNavigate()
  const {state} = useLocation()

  const assigned_to_arr = [{label:'InHouse-Employee',value:'InHouse-Employee'},{label:'Deployed Employee',value:'Deployed Employee'}]

  const statusArr = [{label:'Pending',value:'Pending'},{label:'Progress',value:'Progress'},{label:'Completed',value:'Completed'},{label:'Not Completed',value:'Not Completed'}]


  const [data,setdata] = useState({client:'',fms_in_house_employee:'',assigned_user_type:'',fms_deployed_employee:'',task:'',status:'Pending',file:'',summary:'',date:''})
  const [error,seterror] = useState({client:'',fms_in_house_employee:'',assigned_user_type:'',fms_deployed_employee:'',task:'',status:'',file:'',summary:'',date:''})



  async function uploadFile(name,v){
    authHeader['headers']['Content-Type'] = 'multipart/form-data'
    var fd = new FormData()
    fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
   await axios.post(`fms/work_assigned/upload_file`,fd,authHeader)
    .then((res)=>{
        setdata({...data,file:res?.data?.data})
    })
}


  useEffect(()=>{
     if(state?._id !== undefined){
      

        let passData = {
            ...state,
            client:{label:`${state?.client?.site_name}[client name : ${state?.client?.client_name}]`,value:state?.client?._id},
            assigned_user_type:{label:state?.assigned_user_type,value:state?.assigned_user_type}
        }

        // console.log("state",state)

        if(state?.assigned_user_type == 'InHouse-Employee'){
            passData['fms_in_house_employee'] = {label:`${state?.fms_in_house_employee?.name}[Employee Id : ${state?.fms_in_house_employee?.employee_id}]`,value:state?.fms_in_house_employee?._id}
        }else if(state?.assigned_user_type == 'Deployed Employee'){
            passData['fms_deployed_employee'] = {label:`${state?.fms_deployed_employee?.name}[Employee Id : ${state?.fms_deployed_employee?.employee_id}]`,value:state?.fms_deployed_employee?._id}
        }
        setdata(passData)
     }else{
        setdata({client:'',fms_in_house_employee:'',assigned_user_type:'',fms_deployed_employee:'',task:'',status:'Pending',file:'',summary:''})
        seterror({client:'',fms_in_house_employee:'',assigned_user_type:'',fms_deployed_employee:'',task:'',status:'',file:'',summary:''})
     }
  },[])






async function searchuser(v){ 
    if(data?.assigned_user_type !== undefined){
        if(data?.assigned_user_type?.label === 'InHouse-Employee'){
            await axios.get(`fms/fms_in_house_employee/search?search=${v}`,authHeader)
            .then((res)=>{
                let arr = []
                res?.data?.datas?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
                });
                setusers(arr)
                return arr
            }).catch((err)=>{
            })
        }else if(data?.assigned_user_type?.label === 'Deployed Employee'){{
            await axios.post(`fms/basicemployee/search?search_text=${v}`,{},authHeader)
            .then((res)=>{
                // console.log("res?.data",res?.data)
                let arr = []
                res?.data?.data?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?.id})
                });
                setusers(arr)
                return arr
            }).catch((err)=>{
            })
        }
   
    }
   }
   }

  async function searchData(v){ 
   await axios.get(`fms/fmsclients/search?search_text=${v}`,authHeader)
    .then((res)=>{
        let arr = []
        res?.data?.datas?.forEach(d => {
            arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
        });
        if(data?.client?.length > 0){
            setold_data_client(data?.client)
        }
        setclients(arr)
        return arr
    }).catch((err)=>{
    })
  }


  console.log("data",data)


  async function submitData(){
       
            const send_data = {
                ...data,
                client:data?.client?.value,
                assigned_user_type:data?.assigned_user_type?.label,
                
            }

            if(send_data?.assigned_user_type === 'InHouse-Employee'){
                send_data['fms_in_house_employee'] = data?.fms_in_house_employee?.value
                delete send_data.fms_deployed_employee
            }else if(send_data?.assigned_user_type === 'Deployed Employee'){
                send_data['fms_deployed_employee'] = data?.fms_deployed_employee?.value
                delete send_data.fms_in_house_employee

            }
            if(state?._id !== undefined){
                axios.put(`fms/monthly_calendar_task/update/${state?._id}`,send_data,authHeader)
                .then((res)=>{
                    toast.success("Monthly Calendar Task Updated")
                    setdata({client:'',fms_in_house_employee:'',assigned_user_type:'',fms_deployed_employee:'',task:'',status:'',file:'',summary:''})
                    seterror({client:'',fms_in_house_employee:'',assigned_user_type:'',fms_deployed_employee:'',task:'',status:'',file:'',summary:''})
                    setTimeout(() => {
                     navigation(-1)
                    }, 1500);
                }).catch((err)=>{
                })
            }else{
                axios.post(`fms/monthly_calendar_task/create`,send_data,authHeader)
                .then((res)=>{
                    toast.success("Monthly Calendar Task Created")
                    setdata({client:'',fms_in_house_employee:'',assigned_user_type:'',fms_deployed_employee:'',task:'',status:'',file:'',summary:''})
                    seterror({client:'',fms_in_house_employee:'',assigned_user_type:'',fms_deployed_employee:'',task:'',status:'',file:'',summary:''})
                }).catch((err)=>{
                })
            }
  }
//   console.log("datas",clients)


function handleChange(v){

    // let arr = []
    // v?.forEach((d)=>{
        let data_found = clients?.find((f)=>f.value === v)

    //     if(data_found !== null && data_found !== undefined){
    //         arr?.push(data_found)
    //     }else{
    //         let old_data_found = old_data_client?.find((f)=>f.value === d)
    //         arr?.push(old_data_found)
    //     }
    // })
    setdata({...data,client:data_found})
}

function handleChange1(v,name){   
    if(name == 'assigned_user_type'){
        setdata({...data,[name]:assigned_to_arr?.find((v1)=>v1?.label === v)})
        seterror({...error,[name]:''})
    }
}   

 console.log("data",data)

  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div>
            
        <div style={{maxWidth:'400px',}}>

            <h6 style={{fontWeight:'800',fontSize:14,marginBottom:'4px'}}>Monthly Calendar Task </h6>
            <h6 style={{fontWeight:'400',fontSize:12.5,marginBottom:'4px',width:'100%',opacity:1}}>Use the below form to create or update the monthly calendar task </h6>
             
            <div style={{margin:'10px 0px',width:'320px',}}>
                    <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Assigned To'}  />
                    <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
                    <Select 
                    cacheOptions
                        value={data?.assigned_user_type?.label}
                        placeholder="" 
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        onChange={(v)=>handleChange1(v,'assigned_user_type')}
                        options={assigned_to_arr}
                        style={{ width: '100%', }}
                    />
                    </div>
                </div>

             
             {data?.assigned_user_type?.label == 'InHouse-Employee' && 
                <div style={{width:'400px',marginTop:0}}>
                <SmallText fontWeight={'500'} val={'Field Officer'}  />
                <Select showSearch filterOption={false} value={data?.fms_in_house_employee === '' ? null : data?.fms_in_house_employee} options={users} onSearch={searchuser} onChange={(e)=>setdata({...data,fms_in_house_employee:users?.find(f=>f.value === e)})} style={{width:'80%',marginBottom:10}} />
                </div>}

                {data?.assigned_user_type?.label == 'Deployed Employee' && 
                <div style={{width:'400px',marginTop:0}}>
                <SmallText fontWeight={'500'} val={'Deployed Officer'}  />
                <Select showSearch filterOption={false} value={data?.fms_deployed_employee === '' ? null : data?.fms_deployed_employee} options={users} onSearch={searchuser} onChange={(e)=>setdata({...data,fms_deployed_employee:users?.find(f=>f.value === e)})} style={{width:'80%',marginBottom:10}} />
                </div>}


                <div style={{width:'400px'}}>
                <SmallText fontWeight={'500'} val={'Clients'}  />
                <Select showSearch filterOption={false} value={data?.client} options={clients} onSearch={searchData} onChange={handleChange} style={{width:'80%',marginBottom:10}} />
                </div>

                <div style={{width:'320px'}}>
                <SmallText fontWeight={'500'} val={'Task'}  />
                <Input type="date" value={data?.date} onChange={(e)=>{setdata({...data,date:e.target.value});seterror({...error,date:''})}}  style={{width:'100%',marginBottom:10}} />
               

                <SmallText fontWeight={'500'} val={'Task'}  />
                <TextArea value={data?.task} onChange={(e)=>{setdata({...data,task:e.target.value});seterror({...error,task:''})}}  style={{width:'100%',marginBottom:10}} />


              

                {state?._id !== undefined && <>
                <div style={{marginTop:'10px'}}>


                <SmallText fontWeight={'500'} val={'Status'}  />

                <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961',}}>
                <Select 
                    showSearch
                    filterOption={false}
                    style={{ width: '100%',boxShadow:'none' }}
                    value={data?.status === '' ? null : data?.status}
                    options={statusArr}
                    onChange={(v)=>{setdata({...data,status:v});seterror({...error,status:''})}}
                />
                </div>
                </div>

                <SmallText fontWeight={'600'} val={'Image'}  />
                {(data.file === '' || data.file === null || data.file == undefined || data.file == '') ?
                <Upload showUploadList={false} onChange={(v)=>uploadFile('photo',v)} style={{borderLeft:`4px solid ${theme_color}`,width:'250px'}} >
                    <Button style={{width:'320px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                </Upload>
                :
                <Button  onClick={()=>setdata({...data,file:''})} style={{borderLeft:`4px solid ${theme_color}`,width:'320px',display:'flex',alignItems:'left',fontSize:'10px',marginBottom:'0px'}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left',marginBottom:'0px'}}>{data?.file?.split('/')[data?.file?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                }

                <div style={{width:'400px',marginTop:'10px'}}>
                <SmallText fontWeight={'500'} val={'Summary'}  />
                <TextArea value={data?.summary} onChange={(e)=>{setdata({...data,summary:e.target.value});seterror({...error,summary:''})}}  style={{width:'80%',marginBottom:10}} />
                </div>
                </>}
                </div>




            <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                <SecondaryButton btn_name={'Cancel'} onClick={()=>{navigation(-1)}} />
                <PrimaryButton btn_name={'Save'} onClick={submitData} />
            </div>
        </div>

        </div>
    </FmsDashboardMainRoot>
  )
}

export default MonthlyCalendartTaskCE