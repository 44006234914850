import React, { useState,useEffect } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { data_not_found } from '../../../helpers/Constants'
import { Pagination,Tag,Tabs } from 'antd'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';
import { Toaster, toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'

const { TabPane } = Tabs;


function Tickets() {

 const navigate = useNavigate()   
 const [data,setdata] = useState([])   
 const [page,setpage] = useState(1)   
 const [pagination,setpagination] = useState({})
 const [activetab,setactivetab] = useState('1')


 const roles = useSelector(state=>state.Auth.roles)

//  console.log("roles kp",roles)
 
 useEffect(()=>{
  
    getData()
 },[page,activetab])
 
 function getData(){
    
    axios.get(`fms/ticket/get?page=${page}&step=${activetab}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 function setpagefuun(v){
    setpage(v)
 }

 function deleteData(id){
    axios.delete(`fms/ticket/delete/${id}`,authHeader)
    .then((res)=>{
        toast.success('Deleted Successfully')
        getData()
    })
 }


 function setactivetabfunc(v){
    setactivetab(v)
    setpage(1)
    axios.get(`fms/ticket/get?page=${page}&step=${v}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }



  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div style={{width:'98%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eaeded'}}>
                <BoldText1 val={`Tickets (${pagination?.total})`} />
                {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_user') || roles?.includes('fms_hr') || roles?.includes('fms_ticket')) &&
                <PrimaryButton btn_name={'Add Ticket'} onClick={()=>navigate('/fms/ticket/create')} />}
            </div>

              <Tabs
                size="small"
                activeKey={activetab}
                activetab={activetab}
                defaultValue={activetab}
                style={{ width: '100%', margin: '0px 0px', marginBottom: '0px', position: 'sticky', top: '40px' }}
                onChange={(v) =>{setactivetab(v);setactivetabfunc(v)}}>
                
                 <TabPane tab="Not Approved" key="1">
                    {data?.length > 0 ? 
                    <div style={{ marginTop:'10px', width:'100%',overflow:'hidden' }}>
                         <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                            <p className='checked_here' style={{opacity:0}} ></p> 
                            <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-10px',marginRight:'10px',opacity:'1' }}>Ticket No</p>
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Report Date</p>
                            <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Client Name</p>
                            <p style={{fontSize:'12px',minWidth: '9.7%',maxWidth: '10%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Field Officer</p>
                            <p style={{fontSize:'12px',minWidth: '12.4%',maxWidth: '12.4%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Client Complaint</p>
                            <p style={{fontSize:'12px',minWidth: '6.8%',maxWidth: '6.8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Raised By</p>
                            <p style={{fontSize:'12px',minWidth: '7.15%',maxWidth: '7.15%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Escalated By</p>
                            <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Remarks</p>
                            <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Resolution</p>
                            <p style={{fontSize:'12px',minWidth: '4.8%',maxWidth: '4.8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Status</p>
                            <p style={{fontSize:'11px',minWidth: '7.1%',maxWidth: '7.1%',borderRight:'1px solid #eaeded', marginRight: '0.2%' }}>Resolved Date</p>
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%',paddingLeft:'-5px' }}>Actions</p>
                        </div>


                        {data.map((d, i) => (
                            <div key={i}   style={{borderLeft:'1px solid #eaeded',padding:'0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                            {/* <p style={{opacity:0}}></p> */}
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'0px',marginRight:'10px',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{moment(d?.report_date).format('ll')}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7.8%',maxWidth: '5.5%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.client?.client_name}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.6%',maxWidth: '10%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.field_officer?.name} {d?.deployed_officer?.name} <br></br> <b style={{fontSize:'10px'}}> Handled By : {d?.assigned_user_type}</b> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12.2%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.message}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '6.6%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.raised_by}</span></p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.escalted_by}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '11.9%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.remarks}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '11.6%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.resolution}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '4.9%',maxWidth: '5%',borderRight:'1px solid #eaeded', marginRight: '0.5%',paddingTop:'10px' }}><span style={{fontSize:'9px',marginTop:'10px !important',marginRight:'4px'}}><Tag style={{fontSize:'7px'}}>{d?.status}</Tag></span> </p>

                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'11px',minWidth: '6.9%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.resolve_date !== null &&  moment(d?.resolve_date).format('ll')}</p>

                        {['fms_hr','fms_manager','fms_crm_head'].filter((f)=>roles?.includes(f))?.length > 0 &&
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%', marginRight: '0.5%' }}>
                                <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('edit',{state:d})} />
                                {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
                                <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                            </p>}

                            </div>
                        ))}

                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                        {pagination.total > 15 &&
                            <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                        }
                    </div>

                    </div> 
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                        <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                        <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                    </div>}
                </TabPane>

                <TabPane tab="Pending" key="2">
                    {data?.length > 0 ? 
                    <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                        <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                            <p className='checked_here' style={{opacity:0}} ></p> 
                            <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-10px',marginRight:'10px',opacity:'1' }}>Ticket No</p>
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Report Date</p>
                            <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Client Name</p>
                            <p style={{fontSize:'12px',minWidth: '9.7%',maxWidth: '10%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Field Officer</p>
                            <p style={{fontSize:'12px',minWidth: '12.4%',maxWidth: '12.4%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Client Complaint</p>
                            <p style={{fontSize:'12px',minWidth: '6.8%',maxWidth: '6.8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Raised By</p>
                            <p style={{fontSize:'12px',minWidth: '7.15%',maxWidth: '7.15%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Escalated By</p>
                            <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Remarks</p>
                            <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Resolution</p>
                            <p style={{fontSize:'12px',minWidth: '4.8%',maxWidth: '4.8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Status</p>
                            <p style={{fontSize:'11px',minWidth: '7.1%',maxWidth: '7.1%',borderRight:'1px solid #eaeded', marginRight: '0.2%' }}>Resolved Date</p>
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%',paddingLeft:'-5px' }}>Actions</p>
                        </div>


                        {data.map((d, i) => (
                            <div key={i}   style={{borderLeft:'1px solid #eaeded',padding:'0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                            {/* <p style={{opacity:0}}></p> */}
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'0px',marginRight:'10px',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{moment(d?.report_date).format('ll')}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7.8%',maxWidth: '5.5%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.client?.client_name}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.6%',maxWidth: '10%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.field_officer?.name} {d?.deployed_officer?.name} <br></br> <b style={{fontSize:'10px'}}> Handled By : {d?.assigned_user_type}</b> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12.2%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.message}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '6.6%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.raised_by}</span></p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.escalted_by}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '11.9%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.remarks}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '11.6%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.resolution}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '4.9%',maxWidth: '5%',borderRight:'1px solid #eaeded', marginRight: '0.5%',paddingTop:'10px' }}><span style={{fontSize:'9px',marginTop:'10px !important',marginRight:'4px'}}><Tag style={{fontSize:'7px'}}>{d?.status}</Tag></span> </p>

                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'11px',minWidth: '6.9%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.resolve_date !== null &&  moment(d?.resolve_date).format('ll')}</p>

                        {!roles?.includes('fms_data_view') &&

                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%', marginRight: '0.5%' }}>
                                <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('edit',{state:d})} />
                                {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
                                <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                            </p>}

                            </div>
                        ))}

                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                        {pagination.total > 15 &&
                            <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                        }
                    </div>

                    </div> 
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                        <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                        <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                    </div>}
                </TabPane>

                <TabPane tab="In Progress" key="3">
                    {data?.length > 0 ? 
                    <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                        <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                            <p className='checked_here' style={{opacity:0}} ></p> 
                            <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-10px',marginRight:'10px',opacity:'1' }}>Ticket No</p>
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Report Date</p>
                            <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Client Name</p>
                            <p style={{fontSize:'12px',minWidth: '9.7%',maxWidth: '10%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Field Officer</p>
                            <p style={{fontSize:'12px',minWidth: '12.4%',maxWidth: '12.4%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Client Complaint</p>
                            <p style={{fontSize:'12px',minWidth: '6.8%',maxWidth: '6.8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Raised By</p>
                            <p style={{fontSize:'12px',minWidth: '7.15%',maxWidth: '7.15%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Escalated By</p>
                            <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Remarks</p>
                            <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Resolution</p>
                            <p style={{fontSize:'12px',minWidth: '4.8%',maxWidth: '4.8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Status</p>
                            <p style={{fontSize:'11px',minWidth: '7.1%',maxWidth: '7.1%',borderRight:'1px solid #eaeded', marginRight: '0.2%' }}>Resolved Date</p>
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%',paddingLeft:'-5px' }}>Actions</p>
                        </div>


                        {data.map((d, i) => (
                            <div key={i}   style={{borderLeft:'1px solid #eaeded',padding:'0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                            {/* <p style={{opacity:0}}></p> */}
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'0px',marginRight:'10px',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{moment(d?.report_date).format('ll')}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7.8%',maxWidth: '5.5%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.client?.client_name}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.6%',maxWidth: '10%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.field_officer?.name} {d?.deployed_officer?.name} <br></br> <b style={{fontSize:'10px'}}> Handled By : {d?.assigned_user_type}</b> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12.2%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.message}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '6.6%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.raised_by}</span></p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.escalted_by}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '11.9%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.remarks}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '11.6%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.resolution}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '4.9%',maxWidth: '5%',borderRight:'1px solid #eaeded', marginRight: '0.5%',paddingTop:'10px' }}><span style={{fontSize:'9px',marginTop:'10px !important',marginRight:'4px'}}><Tag style={{fontSize:'7px'}}>{d?.status}</Tag></span> </p>

                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'11px',minWidth: '6.9%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.resolve_date !== null &&  moment(d?.resolve_date).format('ll')}</p>

                        {!roles?.includes('fms_data_view') &&

                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%', marginRight: '0.5%' }}>
                                <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('edit',{state:d})} />
                                {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
                                <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                            </p>}

                            </div>
                        ))}

                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                        {pagination.total > 15 &&
                            <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                        }
                    </div>

                    </div> 
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                        <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                        <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                    </div>}
                </TabPane>

                <TabPane tab="Resolved" key="4">
                    {data?.length > 0 ? 
                    <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                         <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                            <p className='checked_here' style={{opacity:0}} ></p> 
                            <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-10px',marginRight:'10px',opacity:'1' }}>Ticket No</p>
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Report Date</p>
                            <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Client Name</p>
                            <p style={{fontSize:'12px',minWidth: '9.7%',maxWidth: '10%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Field Officer</p>
                            <p style={{fontSize:'12px',minWidth: '12.4%',maxWidth: '12.4%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Client Complaint</p>
                            <p style={{fontSize:'12px',minWidth: '6.8%',maxWidth: '6.8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Raised By</p>
                            <p style={{fontSize:'12px',minWidth: '7.15%',maxWidth: '7.15%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Escalated By</p>
                            <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Remarks</p>
                            <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Resolution</p>
                            <p style={{fontSize:'12px',minWidth: '4.8%',maxWidth: '4.8%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>Status</p>
                            <p style={{fontSize:'11px',minWidth: '7.1%',maxWidth: '7.1%',borderRight:'1px solid #eaeded', marginRight: '0.2%' }}>Resolved Date</p>
                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%',paddingLeft:'-5px' }}>Actions</p>
                        </div>


                        {data.map((d, i) => (
                            <div key={i}   style={{borderLeft:'1px solid #eaeded',padding:'0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                            {/* <p style={{opacity:0}}></p> */}
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'0px',marginRight:'10px',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{moment(d?.report_date).format('ll')}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7.8%',maxWidth: '5.5%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.client?.client_name}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.6%',maxWidth: '10%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.field_officer?.name} {d?.deployed_officer?.name} <br></br> <b style={{fontSize:'10px'}}> Handled By : {d?.assigned_user_type}</b> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12.2%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.message}</p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '6.6%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.raised_by}</span></p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '7%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.escalted_by}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '11.9%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.remarks}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '11.6%',maxWidth: '12%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{d?.resolution}</span> </p>
                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '4.9%',maxWidth: '5%',borderRight:'1px solid #eaeded', marginRight: '0.5%',paddingTop:'10px' }}><span style={{fontSize:'9px',marginTop:'10px !important',marginRight:'4px'}}><Tag style={{fontSize:'7px'}}>{d?.status}</Tag></span> </p>

                            <p style={{paddingBottom:'0px',marginBottom:'0px',fontSize:'11px',minWidth: '6.9%',maxWidth: '7%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.resolve_date !== null &&  moment(d?.resolve_date).format('ll')}</p>

                        {!roles?.includes('fms_data_view') &&

                            <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '7%', marginRight: '0.5%' }}>
                                <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('edit',{state:d})} />
                                {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
                                <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                            </p>}

                            </div>
                        ))}

                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                        {pagination.total > 15 &&
                            <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                        }
                    </div>

                    </div> 
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                        <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                        <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                    </div>}
                </TabPane>

            </Tabs>

        </div>
    </FmsDashboardMainRoot>
  )
}

export default Tickets