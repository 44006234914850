import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import {BiEditAlt} from 'react-icons/bi'
import Tooltip from '@mui/material/Tooltip';
import { data_not_found } from '../../../helpers/Constants';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { Pagination, Tabs, Tag } from 'antd'
import Avatar from '@mui/material/Avatar';
import { base_url, local_base_url } from '../../../App';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {FaWpforms} from 'react-icons/fa';
import {RiNewspaperLine} from 'react-icons/ri';
import {MdDeleteOutline,MdOutlineRefresh} from 'react-icons/md'
import toast,{ Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { MdExitToApp } from 'react-icons/md';
import {BsSearch} from 'react-icons/bs';
import fileDownload from "js-file-download";

const { TabPane } = Tabs;


function FmsEmployee(){


    const navigate = useNavigate()
    const roles = useSelector(state => state.Auth.roles)

    const fcpl_id = useSelector(state => state.Auth.fcpl_id)

    const [search,setsearch] = useState('')
    const [filterApplied,setfilterApplied] = useState(false)

    const [data,setdata] = useState([])
    const [pagination,setpagination] = useState({})

    const [activetab,setactivetab] = useState('1')
    const [activetab1,setactivetab1] = useState('1')

    const [type,settype] = useState('Compliance')
    const [page,setpage] = useState(1)
    const [verified,setverified] = useState(true)

    useEffect(()=>{
        setactivetab('1')
        setactivetab1('1')
        getdata('1')
    },[])


    useEffect(()=>{
        if(filterApplied){
            if(activetab == '1'){
                if(activetab1 == '1'){
                    getfilterdata('1')
                }else if(activetab1 == '2'){
                    getfilterdata('2')
                }
            }else if(activetab == '2'){
                if(activetab1 == '1'){
                    getfilterdata1('1')
                }else if(activetab1 == '2'){
                    getfilterdata1('2')
                }
            }else if(activetab == '3'){
                if(activetab1 == '1'){
                    getfilterdata2('1')
                }else if(activetab1 == '2'){
                    getfilterdata2('2')
                }
            }else if(activetab == '4'){
                if(activetab1 == '1'){
                    getfilterdata3('1')
                }else if(activetab1 == '2'){
                    getfilterdata3('2')
                }
            }
        }else{
            if(activetab == '1'){
                if(activetab1 == '1'){
                    getdata('1')
                }else if(activetab1 == '2'){
                    getdata('2')
                }
            }else if(activetab == '2'){
                if(activetab1 == '1'){
                    getdata1('1')
                }else if(activetab1 == '2'){
                    getdata1('2')
                }
            }else if(activetab == '3'){
                if(activetab1 == '1'){
                    getdata2('1')
                }else if(activetab1 == '2'){
                    getdata2('2')
                }
            }else if(activetab == '4'){
                if(activetab1 == '1'){
                    getdata3('1')
                }else if(activetab1 == '2'){
                    getdata3('2')
                }
            }
        }
    },[page,filterApplied,activetab])

    function getdata(v){
        if(v == '1'){
            setverified(false)
        }else if( v == '2'){
            setverified(true)
        }


      axios.get(`fms/basicemployee/get/${page}/${'Compliance'}/${v}`,authHeader)
      .then((res)=>{
        setdata(res.data.datas)
        setpagination(res.data.pagination)
      })
    }

    function getdata1(v){
        if(v == '1'){
            setverified(false)
        }else{
            setverified(true)
        }
        axios.get(`fms/basicemployee/get/${page}/${'Non-Compliance'}/${v}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

    function getdata2(v){
        if(v == '1'){
            setverified(false)
        }else{
            setverified(true)
        }
        axios.get(`fms/basicemployee/get/${page}/${'Contract Based'}/${v}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }


    function getdata3(v){
        if(v == '1'){
            setverified(false)
        }else{
            setverified(true)
        }
        axios.get(`fms/basicemployee/get/${page}/${'Old Employee Data'}/${v}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

    function getfilterdata(v){
        if(v == '1'){
            setverified(false)
        }else{
            setverified(true)
        }
      axios.get(`fms/basicemployee/filter/${page}/${'Compliance'}/${v}?search=${search}`,authHeader)
      .then((res)=>{
        setdata(res.data.datas)
        setpagination(res.data.pagination)
      })
    }

    function getfilterdata1(v){
        if(v == '1'){
            setverified(false)
        }else{
            setverified(true)
        }
        axios.get(`fms/basicemployee/filter/${page}/${'Non-Compliance'}/${v}?search=${search}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

    function getfilterdata2(v){
        if(v == '1'){
            setverified(false)
        }else{
            setverified(true)
        }
        axios.get(`fms/basicemployee/filter/${page}/${'Contract Based'}/${v}?search=${search}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

    function getfilterdata3(v){
        if(v == '1'){
            setverified(false)
        }else{
            setverified(true)
        }
        axios.get(`fms/basicemployee/filter/${page}/${'Old Employee Data'}/${v}?search=${search}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

    function setactivetabfunc(v){
        setactivetab1('1')
        setactivetab(v)
        setfilterApplied(false)
        setpage(1)
        if(v == '1'){
          getdata('1')
        }else if(v === '2'){
          getdata1('1')
        }else if(v == '3'){
          getdata2('1')
        }
    }

    function setactivetabfunc1(v){
        setpage(1)
        setactivetab1(v)
        // setactivetab(1)
        if(activetab == '1'){
            if(v == '1'){
                getdata('1')
            }else if(v == '2'){
                getdata('2')
            }else if(v == '3'){
                getdata('3')
            }else if(v == '4'){
                getdata('4')
            }else if(v == '5'){
                getdata('5')
            }
        }else if(activetab === '2'){
            if(v == '1'){
                getdata1('1')
            }else if(v == '2'){
                getdata1('2')
            }else if(v == '3'){
                getdata1('3')
            }else if(v == '4'){
                getdata1('4')
            }else if(v == '5'){
                getdata1('5')
            }
          }else if(activetab === '3'){
            if(v == '1'){
                getdata2('1')
            }else if(v == '2'){
                getdata2('2')
            }else if(v == '3'){
                getdata2('3')
            }else if(v == '4'){
                getdata2('4')
            }else if(v == '5'){
                getdata2('5')
            }
          }
          else if(activetab === '4'){
            if(v == '1'){
                getdata3('1')
            }else if(v == '2'){
                getdata3('2')
            }else if(v == '3'){
                getdata3('3')
            }else if(v == '4'){
                getdata3('4')
            }else if(v == '5'){
                getdata3('5')
            }
          }
    }

    function deleterecord(v){
        axios.delete(`fms/basicemployee/delete/${v}`,authHeader)
        .then((res)=>{
            toast.success(res.data.message);
            getdata('1')
        })
    }

    function setpagefuun(v){
        setpage(v)
    }

    function applyFilter(){
        setpage(1)
        if(search?.length > 2){
            setfilterApplied(true)
            if(activetab == '1'){
                if(activetab1 == '1'){
                    getfilterdata('1')
                }else if(activetab1 == '2'){
                    getfilterdata('2')
                }
            }else if(activetab == '2'){
                if(activetab1 == '1'){
                    getfilterdata1('1')
                }else if(activetab1 == '2'){
                    getfilterdata1('2')
                }
            }else if(activetab == '3'){
                if(activetab1 == '1'){
                    getfilterdata2('1')
                }else if(activetab1 == '2'){
                    getfilterdata2('2')
                }
            }else if(activetab == '4'){
                if(activetab1 == '1'){
                    getfilterdata3('1')
                }else if(activetab1 == '2'){
                    getfilterdata3('2')
                }
            }
        }
    }

    function resetFilter(){
        setfilterApplied(false)
        setsearch('')
    }

    const handleDownload = (url, filename) => {
        // console.log('url',url,'filename',filename)

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Employee Excel Downloaded") 
            })
    }

    async function downloadExcel(){   
        await axios.get(`fms/basicemployee/downloadExcel`,authHeader)
        .then((res)=>{
            console.log("response",res?.data)
            let path = res.data.path.replace('public/','')
            handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
            // console.log("res?.data",res?.data)


            // getdata('1')
        }).catch((err)=>{
            console.log("error",err?.reponse)

        })
    //    console.log("download Excel Function")
    }



    return(
        <FmsDashboardMainRoot> 

            <Toaster />
            <div style={{width:'96%'}}>

             <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
              <BoldText1 val={`FMS Employees`} />
              <div style={{display:'flex'}}>
              {(roles.includes('admin') || fcpl_id == 'fcpl-240' || roles.includes('fms_hr'))  && 
              <PrimaryButton btn_name={'Download Excel'} onClick={downloadExcel} />}
              <h6 style={{width:'5px'}}></h6>
              <PrimaryButton btn_name={'Add Employee '} onClick={()=>navigate('/fms/epi_form')} />
              </div>
              </div>

              <Tabs
                    size="small"
                    activetab={activetab}
                    current={activetab}
                    style={{ width: '100%', margin: '0px 0px', marginBottom: '0px', position: 'sticky', top: '50px' }}
                    onChange={(v) => setactivetabfunc(v)}>
                        <TabPane tab="Compliance Employee" key="1">
                        <Tabs
                            size="small"
                            activetab={activetab1} 
                            current={activetab1}
                            onChange={(v) => setactivetabfunc1(v)}
                            style={{ width: '100%', margin: '0px 0px', marginBottom: '0px',marginTop:'-10px', position: 'sticky',}}>

                            <TabPane tab="Not Verified" key="1">

                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Not Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                                <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                    <div  className='mateial_table_header'>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                        <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                    </div>


                                    {data.map((d, i) => (
                                        <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                           
                                            <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                            <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                            <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                            <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                            <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                            <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                            <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                            <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                        <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                        {!roles.includes('fms_data_view') &&

                                            <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                                <Tooltip title='Edit'>
                                                <span>
                                                <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Form (F)'>
                                                <span>
                                                <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Statement Form'>
                                                <span>
                                                <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                                </span>
                                                </Tooltip>
                                                {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                                <Tooltip title='Delete Record'>
                                                <span>
                                                <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                                </span>
                                                </Tooltip>}
                                            </p>}
                                        </div>
                                    ))}
                                </div> 

                                <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                    {pagination.total > 15 &&
                                     <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                    }
                                </div>
                                </>
                            
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Verified" key="2">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                            <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                               
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Exited" key="5">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {(roles.includes('fms_hr') || roles.includes('fms_manager')) &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Docs Changed" key="3">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Letter Request" key="4">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                        </Tabs>  
                        </TabPane>

                        <TabPane tab="Non Compliance Employee" key="2">
                        <Tabs
                            size="small"
                            activetab={activetab1}
                            current={activetab1}
                            onChange={(v) => setactivetabfunc1(v)}
                            style={{ width: '100%', margin: '0px 0px', marginBottom: '0px',marginTop:'-10px', position: 'sticky',}}>

                            <TabPane tab="Not Verified" key="1">
                               <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                <BoldText1 val={`Not Verified Employee(${pagination.total})`} fontSize={'12px'}  /> 
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                                <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                                <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                                </div>
                               </div>      
                               {data.length > 0 ? 
                                <>
                                <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                    <div  className='mateial_table_header'>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                        <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                    </div>


                                    {data.map((d, i) => (
                                        <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                           
                                            <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                            <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                            <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                            <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                            <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                            <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                            <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                            <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                        <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                        {!roles.includes('fms_data_view') &&

                                            <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                                <Tooltip title='Edit'>
                                                <span>
                                                <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Form (F)'>
                                                <span>
                                                <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Statement Form'>
                                                <span>
                                                <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                                </span>
                                                </Tooltip>
                                                {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                                <Tooltip title='Delete Record'>
                                                <span>
                                                <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                                </span>
                                                </Tooltip>}
                                            </p>}
                                        </div>
                                    ))}
                                </div> 

                                <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                    {pagination.total > 15 &&
                                     <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                    }
                                </div>
                                </>
                             
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}

                                <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                    {pagination.total > 15 &&
                                     <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                    }
                                </div>
                            </TabPane>  

                            <TabPane tab="Verified" key="2">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
                                     <div className='mateial_table_header'>
                                        <p className='checked_here' style={{opacity:0}} ></p> 
                                        <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
                                        <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                        <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                        <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                        <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                        <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                        <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                        <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                    </div>


                                    {data.map((d, i) => (
                                        <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
                                            <p   className='checked_here' style={{opacity:0}}>
                                            </p>
                                            <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                            <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
                                            <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                            <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                            <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                            <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
                                            <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                            {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
                                            <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
                                            {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
                                        <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
                                        <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>

                                        {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
                                        {!roles.includes('fms_data_view') &&
                                          
                                            <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                            <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Edit'>
                                                <span>
                                                <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'10px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Form (F)'>
                                                <span>
                                                <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'10px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Statement Form'>
                                                <span>
                                                <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                                </span>
                                                </Tooltip>
                                                {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                                <Tooltip title='Delete Record'>
                                                <span>
                                                <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={14}   style={{marginLeft:'8px'}} />
                                                </span>
                                                </Tooltip>}
                                            </p>}
                                        </div>
                                    ))}
                                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                        {pagination.total > 15 &&
                                        <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                        }
                                    </div> 

                                </div> 
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane> 
                            
                            <TabPane tab="Exited" key="5">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {(roles.includes('fms_hr') || roles.includes('fms_manager')) &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Docs Changed" key="3">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
                                     <div className='mateial_table_header'>
                                        <p className='checked_here' style={{opacity:0}} ></p> 
                                        <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
                                        <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                        <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                        <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                        <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                        <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                        <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                        <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                    </div>


                                    {data.map((d, i) => (
                                        <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
                                            <p   className='checked_here' style={{opacity:0}}>
                                            </p>
                                            <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                            <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
                                            <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                            <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                            <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                            <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
                                            <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                            {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
                                            <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
                                            {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
                                        <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
                                        <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>

                                        {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
                                        {!roles.includes('fms_data_view') &&
                                          
                                            <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                            <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Edit'>
                                                <span>
                                                <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'10px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Form (F)'>
                                                <span>
                                                <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'10px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Statement Form'>
                                                <span>
                                                <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                                </span>
                                                </Tooltip>
                                                {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                                <Tooltip title='Delete Record'>
                                                <span>
                                                <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={14}   style={{marginLeft:'8px'}} />
                                                </span>
                                                </Tooltip>}
                                            </p>}
                                        </div>
                                    ))}
                                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                        {pagination.total > 15 &&
                                        <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                        }
                                    </div> 

                                </div> 
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Letter Request" key="4">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
                                     <div className='mateial_table_header'>
                                        <p className='checked_here' style={{opacity:0}} ></p> 
                                        <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
                                        <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                        <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                        <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                        <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                        <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                        <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                        <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                        <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                    </div>


                                    {data.map((d, i) => (
                                        <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
                                            <p   className='checked_here' style={{opacity:0}}>
                                            </p>
                                            <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                            <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
                                            <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                            <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                            <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                            <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
                                            <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                            {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
                                            <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
                                            {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
                                        <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
                                        <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>

                                        {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
                                        {!roles.includes('fms_data_view') &&
                                          
                                            <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                            <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Edit'>
                                                <span>
                                                <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'10px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Form (F)'>
                                                <span>
                                                <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'10px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Statement Form'>
                                                <span>
                                                <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                                </span>
                                                </Tooltip>
                                                {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                                <Tooltip title='Delete Record'>
                                                <span>
                                                <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={14}   style={{marginLeft:'8px'}} />
                                                </span>
                                                </Tooltip>}
                                            </p>}
                                        </div>
                                    ))}
                                    <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                        {pagination.total > 15 &&
                                        <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                        }
                                    </div> 

                                </div> 
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                        </Tabs>  
                        </TabPane>

                        <TabPane tab="Contract Based" key="3">
                        <Tabs
                            size="small"
                            activetab={activetab1} 
                            current={activetab1}
                            onChange={(v) => setactivetabfunc1(v)}
                            style={{ width: '100%', margin: '0px 0px', marginBottom: '0px',marginTop:'-10px', position: 'sticky',}}>

                            <TabPane tab="Not Verified" key="1">

                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Not Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                                <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                    <div  className='mateial_table_header'>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                        <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                    </div>


                                    {data.map((d, i) => (
                                        <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                           
                                            <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                            <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                            <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                            <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                            <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                            <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                            <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                            <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                        <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                        {!roles.includes('fms_data_view') &&

                                            <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                                <Tooltip title='Edit'>
                                                <span>
                                                <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Form (F)'>
                                                <span>
                                                <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                                <Tooltip title='Statement Form'>
                                                <span>
                                                <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                                </span>
                                                </Tooltip>
                                                {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                                <Tooltip title='Delete Record'>
                                                <span>
                                                <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                                </span>
                                                </Tooltip>}
                                            </p>}
                                        </div>
                                    ))}
                                </div> 

                                <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                    {pagination.total > 15 &&
                                     <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                    }
                                </div>
                                </>
                            
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Verified" key="2">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Exited" key="5">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {(roles.includes('fms_hr') || roles.includes('fms_manager')) &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Docs Changed" key="3">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Letter Request" key="4">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                        </Tabs>  
                        </TabPane>

                        <TabPane tab="Old Employee Data" key="4">
                        <Tabs
                            size="small"
                            activetab={activetab1} 
                            current={activetab1}
                            onChange={(v) => setactivetabfunc1(v)}
                            style={{ width: '100%', margin: '0px 0px', marginBottom: '0px',marginTop:'-10px', position: 'sticky',}}>

                            <TabPane tab="Not Verified" key="1">

                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Not Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                               <>
                               <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                   <div  className='mateial_table_header'>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                       <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                   </div>


                                   {data.map((d, i) => (
                                       <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                          
                                           <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                           <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                           <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                           <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                           <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                           <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                           <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                           <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                       <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                       {!roles.includes('fms_data_view') &&

                                           <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                               <Tooltip title='Edit'>
                                               <span>
                                               <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                               </span>
                                               </Tooltip>
                                               <Tooltip title='Form (F)'>
                                               <span>
                                               <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                               </span>
                                               </Tooltip>
                                               <Tooltip title='Statement Form'>
                                               <span>
                                               <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                               </span>
                                               </Tooltip>
                                               {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                               <Tooltip title='Delete Record'>
                                               <span>
                                               <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                               </span>
                                               </Tooltip>}
                                           </p>}
                                       </div>
                                   ))}
                               </div> 

                               <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                   {pagination.total > 15 &&
                                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                   }
                               </div>
                               </>
                           
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Verified" key="2">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Exited" key="5">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {(roles.includes('fms_hr') || roles.includes('fms_manager')) &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                            <TabPane tab="Docs Changed" key="3">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>

                            <TabPane tab="Letter Request" key="4">
                            <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                            </div>    
                            {data.length > 0 ? 
                                <>
                            <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                <div  className='mateial_table_header'>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                    <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                </div>


                                {data.map((d, i) => (
                                    <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                       
                                        <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                        <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                        <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                        <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                        <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                        <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                        <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                        <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                    <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                    <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                    {!roles.includes('fms_data_view') &&

                                        <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                              <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                            <Tooltip title='Edit'>
                                            <span>
                                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Form (F)'>
                                            <span>
                                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                            </span>
                                            </Tooltip>
                                            <Tooltip title='Statement Form'>
                                            <span>
                                            <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                            </span>
                                            </Tooltip>
                                            {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                            <Tooltip title='Delete Record'>
                                            <span>
                                            <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                            </span>
                                            </Tooltip>}
                                        </p>}
                                    </div>
                                ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                 <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                            </div>
                            </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
                            </TabPane>  

                        </Tabs>  

                        </TabPane>

                      

              </Tabs>

            
            </div>


        </FmsDashboardMainRoot>
    )
}


export default FmsEmployee





///////old 
// import React,{useState,useEffect} from 'react'
// import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
// import { BoldText1 } from '../../../helpers/constants/ConstantsText';
// import PrimaryButton from '../../../components/buttons/PrimaryButton';
// import axios from 'axios';
// import {BiEditAlt} from 'react-icons/bi'
// import Tooltip from '@mui/material/Tooltip';
// import { data_not_found } from '../../../helpers/Constants';
// import { authHeader } from '../../../helpers/usertoken/UserToken';
// import { Pagination, Tabs, Tag } from 'antd'
// import Avatar from '@mui/material/Avatar';
// import { base_url, local_base_url } from '../../../App';
// import moment from 'moment';
// import { useNavigate } from 'react-router-dom';
// import {FaWpforms} from 'react-icons/fa';
// import {RiNewspaperLine} from 'react-icons/ri';
// import {MdDeleteOutline,MdOutlineRefresh} from 'react-icons/md'
// import toast,{ Toaster } from 'react-hot-toast';
// import { useSelector } from 'react-redux';
// import { MdExitToApp } from 'react-icons/md';
// import {BsSearch} from 'react-icons/bs';
// import fileDownload from "js-file-download";

// const { TabPane } = Tabs;


// function FmsEmployee(){


//     const navigate = useNavigate()
//     const roles = useSelector(state => state.Auth.roles)

//     const fcpl_id = useSelector(state => state.Auth.fcpl_id)

//     const [search,setsearch] = useState('')
//     const [filterApplied,setfilterApplied] = useState(false)

//     const [data,setdata] = useState([])
//     const [pagination,setpagination] = useState({})

//     const [activetab,setactivetab] = useState('1')
//     const [activetab1,setactivetab1] = useState('1')

//     const [type,settype] = useState('Compliance')
//     const [page,setpage] = useState(1)
//     const [verified,setverified] = useState(true)

//     useEffect(()=>{
//         setactivetab('1')
//         setactivetab1('1')
//         getdata('1')
//     },[])


//     useEffect(()=>{
//         if(filterApplied){
//             if(activetab == '1'){
//                 if(activetab1 == '1'){
//                     getfilterdata('1')
//                 }else if(activetab1 == '2'){
//                     getfilterdata('2')
//                 }
//             }else if(activetab == '2'){
//                 if(activetab1 == '1'){
//                     getfilterdata1('1')
//                 }else if(activetab1 == '2'){
//                     getfilterdata1('2')
//                 }
//             }else if(activetab == '3'){
//                 if(activetab1 == '1'){
//                     getfilterdata2('1')
//                 }else if(activetab1 == '2'){
//                     getfilterdata2('2')
//                 }
//             }else if(activetab == '4'){
//                 if(activetab1 == '1'){
//                     getfilterdata3('1')
//                 }else if(activetab1 == '2'){
//                     getfilterdata3('2')
//                 }
//             }
//         }else{
//             if(activetab == '1'){
//                 if(activetab1 == '1'){
//                     getdata('1')
//                 }else if(activetab1 == '2'){
//                     getdata('2')
//                 }
//             }else if(activetab == '2'){
//                 if(activetab1 == '1'){
//                     getdata1('1')
//                 }else if(activetab1 == '2'){
//                     getdata1('2')
//                 }
//             }else if(activetab == '3'){
//                 if(activetab1 == '1'){
//                     getdata2('1')
//                 }else if(activetab1 == '2'){
//                     getdata2('2')
//                 }
//             }else if(activetab == '4'){
//                 if(activetab1 == '1'){
//                     getdata3('1')
//                 }else if(activetab1 == '2'){
//                     getdata3('2')
//                 }
//             }
//         }
//     },[page,filterApplied,activetab])

//     function getdata(v){
//         if(v == '1'){
//             setverified(false)
//         }else if( v == '2'){
//             setverified(true)
//         }
//       axios.get(`fms/basicemployee/get/${page}/${'Compliance'}/${v}`,authHeader)
//       .then((res)=>{
//         setdata(res.data.datas)
//         setpagination(res.data.pagination)
//       })
//     }

//     function getdata1(v){
//         if(v == '1'){
//             setverified(false)
//         }else{
//             setverified(true)
//         }
//         axios.get(`fms/basicemployee/get/${page}/${'Non-Compliance'}/${v}`,authHeader)
//         .then((res)=>{
//           setdata(res.data.datas)
//           setpagination(res.data.pagination)
//         })
//     }

//     function getdata2(v){
//         if(v == '1'){
//             setverified(false)
//         }else{
//             setverified(true)
//         }
//         axios.get(`fms/basicemployee/get/${page}/${'Contract Based'}/${v}`,authHeader)
//         .then((res)=>{
//           setdata(res.data.datas)
//           setpagination(res.data.pagination)
//         })
//     }


//     function getdata3(v){
//         if(v == '1'){
//             setverified(false)
//         }else{
//             setverified(true)
//         }
//         axios.get(`fms/basicemployee/get/${page}/${'Old Employee Data'}/${v}`,authHeader)
//         .then((res)=>{
//           setdata(res.data.datas)
//           setpagination(res.data.pagination)
//         })
//     }

//     function getfilterdata(v){
//         if(v == '1'){
//             setverified(false)
//         }else{
//             setverified(true)
//         }
//       axios.get(`fms/basicemployee/filter/${page}/${'Compliance'}/${v}?search=${search}`,authHeader)
//       .then((res)=>{
//         setdata(res.data.datas)
//         setpagination(res.data.pagination)
//       })
//     }

//     function getfilterdata1(v){
//         if(v == '1'){
//             setverified(false)
//         }else{
//             setverified(true)
//         }
//         axios.get(`fms/basicemployee/filter/${page}/${'Non-Compliance'}/${v}?search=${search}`,authHeader)
//         .then((res)=>{
//           setdata(res.data.datas)
//           setpagination(res.data.pagination)
//         })
//     }

//     function getfilterdata2(v){
//         if(v == '1'){
//             setverified(false)
//         }else{
//             setverified(true)
//         }
//         axios.get(`fms/basicemployee/filter/${page}/${'Contract Based'}/${v}?search=${search}`,authHeader)
//         .then((res)=>{
//           setdata(res.data.datas)
//           setpagination(res.data.pagination)
//         })
//     }

//     function getfilterdata3(v){
//         if(v == '1'){
//             setverified(false)
//         }else{
//             setverified(true)
//         }
//         axios.get(`fms/basicemployee/filter/${page}/${'Old Employee Data'}/${v}?search=${search}`,authHeader)
//         .then((res)=>{
//           setdata(res.data.datas)
//           setpagination(res.data.pagination)
//         })
//     }

//     function setactivetabfunc(v){
//         setactivetab1('1')
//         setactivetab(v)
//         setfilterApplied(false)
//         setpage(1)
//         if(v == '1'){
//           getdata('1')
//         }else if(v === '2'){
//           getdata1('1')
//         }else if(v == '3'){
//           getdata2('1')
//         }
//     }

//     function setactivetabfunc1(v){
//         setpage(1)
//         setactivetab1(v)
//         // setactivetab(1)
//         if(activetab == '1'){
//             if(v == '1'){
//                 getdata('1')
//             }else if(v == '2'){
//                 getdata('2')
//             }else if(v == '3'){
//                 getdata('3')
//             }else if(v == '4'){
//                 getdata('4')
//             }else if(v == '5'){
//                 getdata('5')
//             }
//         }else if(activetab === '2'){
//             if(v == '1'){
//                 getdata1('1')
//             }else if(v == '2'){
//                 getdata1('2')
//             }else if(v == '3'){
//                 getdata1('3')
//             }else if(v == '4'){
//                 getdata1('4')
//             }else if(v == '5'){
//                 getdata1('5')
//             }
//           }else if(activetab === '3'){
//             if(v == '1'){
//                 getdata2('1')
//             }else if(v == '2'){
//                 getdata2('2')
//             }else if(v == '3'){
//                 getdata2('3')
//             }else if(v == '4'){
//                 getdata2('4')
//             }else if(v == '5'){
//                 getdata2('5')
//             }
//           }
//           else if(activetab === '4'){
//             if(v == '1'){
//                 getdata3('1')
//             }else if(v == '2'){
//                 getdata3('2')
//             }else if(v == '3'){
//                 getdata3('3')
//             }else if(v == '4'){
//                 getdata3('4')
//             }else if(v == '5'){
//                 getdata3('5')
//             }
//           }
//     }

//     function deleterecord(v){
//         axios.delete(`fms/basicemployee/delete/${v}`,authHeader)
//         .then((res)=>{
//             toast.success(res.data.message);
//             getdata('1')
//         })
//     }

//     function setpagefuun(v){
//         setpage(v)
//     }

//     function applyFilter(){
//         setpage(1)
//         if(search?.length > 2){
//             setfilterApplied(true)
//             if(activetab == '1'){
//                 if(activetab1 == '1'){
//                     getfilterdata('1')
//                 }else if(activetab1 == '2'){
//                     getfilterdata('2')
//                 }
//             }else if(activetab == '2'){
//                 if(activetab1 == '1'){
//                     getfilterdata1('1')
//                 }else if(activetab1 == '2'){
//                     getfilterdata1('2')
//                 }
//             }else if(activetab == '3'){
//                 if(activetab1 == '1'){
//                     getfilterdata2('1')
//                 }else if(activetab1 == '2'){
//                     getfilterdata2('2')
//                 }
//             }else if(activetab == '4'){
//                 if(activetab1 == '1'){
//                     getfilterdata3('1')
//                 }else if(activetab1 == '2'){
//                     getfilterdata3('2')
//                 }
//             }
//         }
//     }

//     function resetFilter(){
//         setfilterApplied(false)
//         setsearch('')
//     }

//     const handleDownload = (url, filename) => {
//         // console.log('url',url,'filename',filename)

//         axios.get(url, {
//             responseType: 'blob',
//         })
//             .then((res) => {
//                 fileDownload(res.data, filename)
//                 toast.success("Employee Excel Downloaded") 
//             })
//     }

//     async function downloadExcel(){   
//         await axios.get(`fms/basicemployee/downloadExcel`,authHeader)
//         .then((res)=>{
//             console.log("response",res?.data)
//             let path = res.data.path.replace('public/','')
//             handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
//             // console.log("res?.data",res?.data)


//             // getdata('1')
//         }).catch((err)=>{
//             console.log("error",err?.reponse)

//         })
//     //    console.log("download Excel Function")
//     }



//     return(
//         <FmsDashboardMainRoot> 

//             <Toaster />
//             <div style={{width:'96%'}}>

//              <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
//               <BoldText1 val={`FMS Employees123`} />
//               <div style={{display:'flex'}}>
//               {(roles.includes('admin') || fcpl_id == 'fcpl-240' || roles.includes('fms_hr'))  && 
//               <PrimaryButton btn_name={'Download Excel'} onClick={downloadExcel} />}
//               <h6 style={{width:'5px'}}></h6>
//               <PrimaryButton btn_name={'Add Employee '} onClick={()=>navigate('/fms/epi_form')} />
//               </div>
//               </div>

//               <Tabs
//                     size="small"
//                     activetab={activetab}
//                     current={activetab}
//                     style={{ width: '100%', margin: '0px 0px', marginBottom: '0px', position: 'sticky', top: '50px' }}
//                     onChange={(v) => setactivetabfunc(v)}>
//                         <TabPane tab="Compliance Employee" key="1">
//                         <Tabs
//                             size="small"
//                             activetab={activetab1} 
//                             current={activetab1}
//                             onChange={(v) => setactivetabfunc1(v)}
//                             style={{ width: '100%', margin: '0px 0px', marginBottom: '0px',marginTop:'-10px', position: 'sticky',}}>

//                             <TabPane tab="Not Verified" key="1">

//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Not Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {!roles.includes('fms_data_view') &&

//                                             <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Verified" key="2">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Exited" key="5">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Docs Changed" key="3">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Letter Request" key="4">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                         </Tabs>  
//                         </TabPane>

//                         <TabPane tab="Non Compliance Employee" key="2">
//                         <Tabs
//                             size="small"
//                             activetab={activetab1}
//                             current={activetab1}
//                             onChange={(v) => setactivetabfunc1(v)}
//                             style={{ width: '100%', margin: '0px 0px', marginBottom: '0px',marginTop:'-10px', position: 'sticky',}}>

//                             <TabPane tab="Not Verified" key="1">
//                                <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                                 <BoldText1 val={`Not Verified Employee(${pagination.total})`} fontSize={'12px'}  /> 
//                                 <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                                 <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                                 <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                                 <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                                 </div>
//                                </div>      
//                                {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                      <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ? 'Rejected' : 'Approved'}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                             <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                             {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                             {!roles.includes('fms_data_view') &&
//                                             <p style={{fontSize:'12px',width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'10px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'10px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={14}   style={{marginLeft:'8px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>
//                             </TabPane>  

//                             <TabPane tab="Verified" key="2">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                      <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>

//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                          
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
//                                             <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'10px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'10px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={14}   style={{marginLeft:'8px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}
//                                     <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                         {pagination.total > 15 &&
//                                         <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                         }
//                                     </div> 

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane> 
                            
//                             <TabPane tab="Exited" key="5">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Docs Changed" key="3">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                      <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>

//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                          
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
//                                             <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'10px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'10px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={14}   style={{marginLeft:'8px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}
//                                     <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                         {pagination.total > 15 &&
//                                         <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                         }
//                                     </div> 

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Letter Request" key="4">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                      <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>

//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                          
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
//                                             <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'10px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'10px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={14}   style={{marginLeft:'8px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}
//                                     <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                         {pagination.total > 15 &&
//                                         <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                         }
//                                     </div> 

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                         </Tabs>  
//                         </TabPane>

//                         <TabPane tab="Contract Based" key="3">
//                         <Tabs
//                             size="small"
//                             activetab={activetab1} 
//                             current={activetab1}
//                             onChange={(v) => setactivetabfunc1(v)}
//                             style={{ width: '100%', margin: '0px 0px', marginBottom: '0px',marginTop:'-10px', position: 'sticky',}}>

//                             <TabPane tab="Not Verified" key="1">

//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Not Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {!roles.includes('fms_data_view') &&

//                                             <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Verified" key="2">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Exited" key="5">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Docs Changed" key="3">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Letter Request" key="4">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                         </Tabs>  
//                         </TabPane>

//                         <TabPane tab="Old Employee Data" key="4">
//                         <Tabs
//                             size="small"
//                             activetab={activetab1} 
//                             current={activetab1}
//                             onChange={(v) => setactivetabfunc1(v)}
//                             style={{ width: '100%', margin: '0px 0px', marginBottom: '0px',marginTop:'-10px', position: 'sticky',}}>

//                             <TabPane tab="Not Verified" key="1">

//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Not Verified Employee(${pagination.total})`} fontSize={'12px'}  />  
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {!roles.includes('fms_data_view') &&

//                                             <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Verified" key="2">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Exited" key="5">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                             <TabPane tab="Docs Changed" key="3">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>

//                             <TabPane tab="Letter Request" key="4">
//                             <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
//                             <BoldText1 val={`Verified Employee(${pagination.total})`} fontSize={'12px'}  />    
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <input value={search} onChange={(e)=>{setsearch(e.target.value);setfilterApplied(false)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
//                             <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
//                             <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
//                             </div>
//                             </div>    
//                             {data.length > 0 ? 
//                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
//                                     <div className='mateial_table_header'>
//                                         <p className='checked_here' style={{opacity:0}} ></p> 
//                                         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
//                                         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
//                                         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
//                                         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
//                                         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
//                                         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
//                                     </div>


//                                     {data.map((d, i) => (
//                                         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
//                                             <p   className='checked_here' style={{opacity:0}}>
//                                             </p>
//                                             <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
//                                             <p style={{fontSize:'10px !important', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',textTransform:'uppercase', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px' }}><Avatar  sx={{  width: 18, height: 18,fontSize:11,textTransform:'uppercase' }} alt={d?.name} src={`${base_url}${d?.photo}`} /></p>
//                                             <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
//                                             <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
//                                             <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
//                                             <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
//                                             <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
//                                             {/* <Tooltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
//                                             {/* <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color="green">Approved</Tag>}</p></Tooltip> */}
//                                             <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}</p></Tooltip>
//                                         <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
//                                         <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
//                                         {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('lll')}</span> </p> */}
//                                         {!roles.includes('fms_data_view') &&
                                         
//                                             <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px',marginLeft:'0%'}}>
//                                                 <Tooltip title='Exit Form'>
//                                                 <span>
//                                                 <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Edit'>
//                                                 <span>
//                                                 <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Form (F)'>
//                                                 <span>
//                                                 <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={14} style={{ marginRight:'8px' }}  />
//                                                 </span>
//                                                 </Tooltip>
//                                                 <Tooltip title='Statement Form'>
//                                                 <span>
//                                                 <RiNewspaperLine onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
//                                                 </span>
//                                                 </Tooltip>
//                                                 {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
//                                                 <Tooltip title='Delete Record'>
//                                                 <span>
//                                                 <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={18}   style={{marginLeft:'6px'}} />
//                                                 </span>
//                                                 </Tooltip>}
//                                             </p>}
//                                         </div>
//                                     ))}

//                                 <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
//                                     {pagination.total > 15 &&
//                                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
//                                     }
//                                 </div>

//                                 </div> 
//                                 :
//                                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                                 </div>}
//                             </TabPane>  

//                         </Tabs>  

//                         </TabPane>

                      

//               </Tabs>

            
//             </div>


//         </FmsDashboardMainRoot>
//     )
// }


// export default FmsEmployee