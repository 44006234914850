import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Pagination,DatePicker, Drawer } from 'antd';
import Tooltip from '@mui/material/Tooltip';
import {MdDeleteOutline,MdOutlineRefresh} from 'react-icons/md';
import moment from 'moment'
import { data_not_found } from '../../../helpers/Constants';
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText'
import { Toaster, toast } from 'react-hot-toast'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { base_url, local_base_url } from '../../../App'
import fileDownload from "js-file-download";
import { useSelector } from 'react-redux'
import { Select } from 'antd'
import { BsSearch } from 'react-icons/bs';
import { AiOutlineEdit } from 'react-icons/ai';


function ClientJioTagingAttendance() {
  const roles = useSelector(state => state.Auth.roles)

  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)

  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)

  const [filter,setfilter] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',client:''})
  
  const [clients,setclients] = useState([])


  useEffect(()=>{
    getdata(page)
  },[page])

  async function handlechangeclient(e){
    setfilter({...filter,client:clients?.find(f=>f.value === e)})
  }

  async function getdata(v){
    await axios.get(`fms/attendance_summary/get?page=${v ? v : page}&from_date=${filter?.from_date1}&to_date=${filter?.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}`,authHeader)
    .then((res)=>{
      setdata(res.data.datas)
      setpagination(res.data.pagiantion)
    }).catch((err)=>{
    })
  }

  async function searchData(v){ 
    await axios.get(`fms/fmsclients/search?search_text=${v}&are_we_serving=1`,authHeader)
     .then((res)=>{
         let arr = []
         res?.data?.datas?.forEach(d => {
             arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
         });
         setclients(arr)
         return arr
     }).catch((err)=>{
     })
  }


  function setpagefuun(v){
    setpage(v)
  }

  function deleteData(id){
    axios.delete(`fms/attendance_summary/delete/${id}`,authHeader)
    .then((res)=>{
      if(res?.status == 200){
        getdata()
        toast.success('Deleted Successfully')
      }
    }).catch((err)=>{
    })
  }

  async function applyFilter(){
    await axios.get(`fms/attendance_summary/get?page=${1}&from_date=${filter?.from_date1}&to_date=${filter?.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}`,authHeader)
    .then((res)=>{
      setdata(res.data.datas)
      setpagination(res.data.pagiantion)
    }).catch((err)=>{
    })
  }

  async function resetFilter(){
     setfilter({from_date:'',to_date:'',from_date1:'',to_date1:'',client:'',check_point:''})
     await axios.get(`fms/attendance_summary/get?page=${1}&from_date=${''}&to_date=${''}&client=${''}`,authHeader)
     .then((res)=>{
       setdata(res.data.datas)
       setpagination(res.data.pagiantion)
     }).catch((err)=>{
     })
  }


  function openfile(v){
      let url = `${base_url}` + v
      window.open(url,'_blank')
   }

   async function updatemodalfunc(){
    let sendData = {...selectedData}
    delete sendData.field_officer
    delete sendData.deployed_officer
    await axios.put(`fms/attendance_summary/update/${selectedData?._id}`,sendData,authHeader)
    .then((res)=>{
      getdata()
      toast.success("Attendance Summary Updated")
      setmodal(false)
    }).catch((err)=>{
    })
   }

  return (
    <FmsDashboardMainRoot>
      <Toaster />
      <div style={{width:'98%'}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingBottom:'2px',borderBottom:'1px solid #ddd'}}>
        <BoldText1 val="Client GeoTag Attendance" /> 

        {(roles.includes('admin') || roles.includes('fms_hr') || roles.includes('fms_crm_head')) &&
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:'-20px'}}>
        
          <Select showSearch filterOption={false} placeholder={'Select Client'} value={filter?.client === '' ? null : filter?.client} options={clients}  onSearch={searchData} onChange={(e)=>handlechangeclient(e)} style={{width:'120px',marginBottom:0,marginRight:10}} />

          <DatePicker value={filter?.from_date} style={{width:'130px'}} onChange={(e,e1)=>setfilter({...filter,from_date:e,from_date1:e1})} size='medium' placeholder='From Date' />
          <h6 style={{opacity:0}}>W</h6>
          <DatePicker value={filter?.to_date} style={{width:'130px'}} onChange={(e,e1)=>setfilter({...filter,to_date:e,to_date1:e1})} placeholder='To Date'  />
          <h6 style={{opacity:0}}>W</h6>
          <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'0px',background:'#eee',padding:'7px'}}/>
          <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
          <h6 style={{opacity:0}}>W</h6>
        </div>}
        </div>
      </div>

       <Drawer placement={'right'} visible={modal} height={'100%'} width={'25%'} closable={false} maskClosable={false} bodyStyle={{ background: '#fff', maxHeight: '280px', margin: '5%', padding: '0px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', overflow: 'hidden' }}>
              <div style={{marginTop:15}}>
                <h6 style={{fontWeight:'700'}}>Update the Attendance Summary</h6>
                <h6 style={{fontSize:13,fontWeight:400}}>use the below form to update the attendance sumamry!</h6>

                <h6 style={{fontSize:'12px'}}>Lats/Long : <span style={{fontWeight:'800'}}>{selectedData?.lats} / {selectedData?.long}</span></h6>
                <h6 style={{fontSize:'12px'}}>Location : <span style={{fontWeight:'800'}}>{selectedData?.location}</span></h6>
                <h6 style={{fontSize:'12px'}}>Remarks</h6>
                <h6 tyle={{fontWeight:'800'}}>{selectedData?.remarks}</h6>

                <div style={{marginBottom:'10px',marginTop:'10px',display:'flex'}}>
                  <p>
                    <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{setselectedData({...selectedData,verified:!selectedData.verified});}}>
                    {selectedData.verified &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                  </p>
                  <div style={{width:'400px',marginLeft:'20px'}}>
                  <SmallText fontWeight={'600'} val={"Verify status"}  />
                  </div>
                </div>


                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <SecondaryButton btn_name={'Close'} onClick={()=>setmodal(!modal)} />
                  <PrimaryButton btn_name={'Save'} onClick={()=>updatemodalfunc()} />
                </div>
              </div>        
        </Drawer>

      <div>
      {data.length > 0 ? 
      <div style={{ border: '0px solid lightgray',marginTop:'20px', width:'98%' }}>
      
              {data.length > 0 ? 
              
               <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                    <div className='mateial_table_header' style={{border:'1px solid #eaeded',}}>
                        <p className='checked_here' style={{opacity:0}} ></p> 
                        <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                        <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Client Info</p>
                        <p style={{fontSize:'12px',minWidth: '15%',maxWidth: '15%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Date</p>
                        <p style={{fontSize:'12px',minWidth: '20%',maxWidth: '20%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Remarks</p>
                        <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Lats/Long</p>
                        <p style={{fontSize:'12px',minWidth: '20%',maxWidth: '20%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Location</p>
                        <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Created At </p>
                        <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%' }}>Actions</p>
                    </div>
                    {data?.map((d,i)=>(
                    <div key={d?._id} style={{borderLeft:'1px solid #ddd',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #ddd',borderBottom:'1px solid #ddd',height:'100%'}}>
                        <p className='checked_here' style={{opacity:0}} ></p> 
                        <p style={{marginBottom:'0px',padding:'4px 5px',fontSize:'11px',paddingLeft:'4px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                        <p style={{marginBottom:'0px',padding:'4px 5px',fontSize:'11px',paddingLeft:'4px',minWidth: '10.5%',maxWidth: '10.5%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>{d?.client?.site_name}</p>
                        <p style={{marginBottom:'0px',padding:'4px 5px',fontSize:'11px',paddingLeft:'4px',minWidth: '15.3%',maxWidth: '15.3%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>{moment(d?.date)?.format('LLL')} <br></br> <h6 style={{fontSize:'11px',fontWeight:'800'}}>({d?.verified ? 'Verified' : 'Not Verified'})</h6></p>
                        <p style={{marginBottom:'0px',padding:'4px 5px',fontSize:'11px',paddingLeft:'4px',minWidth: '20%',maxWidth: '20%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>
                          <h6 style={{fontSize:'13px'}}>Added By : {d?.created_user_type} / {d?.created_user_type === 'InHouse-Employee' ? d?.fms_in_house_employee?.name : d?.fms_deployed_employee?.name} </h6>
                          {d?.remarks} <br></br>
                          {(d?.file !== null && d?.file !== undefined && d?.file !== '') &&<span onClick={()=>openfile(d?.file)} style={{fontWeight:'800',color:'#4768b5',textDecorationLine:'underline',cursor:"pointer"}}>View File</span>}
                        </p>
                        <p style={{marginBottom:'0px',padding:'4px 5px',fontSize:'11px',paddingLeft:'4px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd', color: 'black',opacity:'1',wordBreak:'break-all' }}>{d?.lats} / {d?.long}</p>
                        <p style={{marginBottom:'0px',padding:'4px 5px',fontSize:'11px',paddingLeft:'4px',minWidth: '20.2%',maxWidth: '20.2%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>{d?.location}</p>
                        <p style={{marginBottom:'0px',padding:'4px 5px',fontSize:'11px',paddingLeft:'4px',minWidth: '10.5%',maxWidth: '10.5%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>{moment(d?.createdAt)?.format('LLL')}</p>
                        
                        {(roles.includes('admin')) &&
                        <p style={{marginBottom:'0px',padding:'4px 5px',fontSize:'12px',display:'inline',minWidth: '10%',maxWidth: '10%', marginRight: '0.5%' }}>
                            
                                
                        <AiOutlineEdit onClick={()=>{setselectedData(d);setmodal(true)}}  size={16}   style={{marginLeft:'6px'}} />
                        <MdDeleteOutline onClick={()=>deleteData(d?._id)}  size={16}   style={{marginLeft:'6px'}} />

                        </p>}
                    </div>))}
                    <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                        {pagination?.total > pagination?.limit &&
                            <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                        }
                    </div>
               </div> 
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                  <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                  <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                  <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
              </div>}
      <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
          {pagination?.total > pagination?.limit &&
            <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
          }
      </div>

      </div> 
      :
      <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
          <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
          <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients scan report created yet"}</h4>
      </div>}
      </div>

    </FmsDashboardMainRoot>
  )
}

export default ClientJioTagingAttendance