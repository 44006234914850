import React,{useState,useEffect} from 'react';
import { BoldHeadText, BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Input,Modal,Pagination} from 'antd'
import {useSelector} from 'react-redux'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import {BiEditAlt} from 'react-icons/bi'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFilter } from "react-icons/ai";
import toast,{ Toaster } from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import { IoIosClose } from 'react-icons/io';
import { data_not_found } from '../../../helpers/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdDeleteOutline,MdOutlineRefresh } from 'react-icons/md'
import { AiOutlineFileExcel, AiFillFileExcel} from "react-icons/ai";
import { authHeader } from '../../../helpers/usertoken/UserToken';
import {  local_base_url } from '../../../App';
import fileDownload from "js-file-download";
import moment from 'moment';


function FmsRoles(){

    const roles = useSelector(state => state.Auth.roles)
    
    // console.log("roles",roles)

    const AuthHeader = {headers:{
        'Content-Type': 'application/json',
    }}

    const {pathname} = useLocation()

    // console.log("pathname",pathname)

    const [drawer,set_drawer] = useState(false)
    const [data,setdata] = useState({id:'',role_name:'',display_name:''})
    const [modalvisible,setmodalvisible] = useState(false)


    const [search,setsearch] = useState('')



    const [fmsclients,setfmsclients] = useState([])
    const { TextArea } = Input;
    const theme_color = useSelector(state=>state.Theme.theme_color)

    const [pagination,setpagination] = useState({})
    const [page,setpage] = useState(1)

    const navigator = useNavigate()

    function func(){

    }

    function setpagefuun(v){
        setpage(v)
    }


    useEffect(()=>{
        getdata()
    },[page])


    useEffect(()=>{
        getdata()
    },[pathname])

    function getdata(){
        axios.get(`fms/app_roles/get?page=${page}`,AuthHeader)
        .then((res)=>{
            setpagination(res.data.pagination)
            setfmsclients(res.data.datas)
        }).catch((err)=>{
        })
        
        
    }
    
    function submitform(){
        if(data.id === ''){
            axios.post('fms/app_roles/create',data,AuthHeader)
            .then((res)=>{
                getdata()
                toast.success(res.data.message)
                set_drawer(false)
                setdata({role_name:'',display_name:''})
            }).catch((err)=>{
            })
        }else{
            axios.put(`fms/app_roles/update/${data.id}`,data,AuthHeader)
            .then((res)=>{
                getdata()
                set_drawer(false)
                toast.success(res.data.message)
                setdata({id:'',role_name:'',display_name:''})
            }).catch((err)=>{
            })
        }
      
    }


    function deletefunc(){
        axios.delete(`fms/app_roles/delete/${data.id}`,data,AuthHeader)
        .then((res)=>{
            getdata()
            setmodalvisible(false)
            toast.success(res.data.message)
            setdata({id:'',role_name:'',display_name:''})
        }).catch((err)=>{
        // console.log('err data here na',err)
        })
    }


    function searchData(){
        if(search?.length === 0){
            getdata()
        }else{
            axios.get(`fms/app_roles/search?search_text=${search}`,AuthHeader)
            .then((res)=>{
                setpagination(res.data.pagination)
                setfmsclients(res.data.datas)
            }).catch((err)=>{
            })
        }    
    }

    // async function downloadqrCode(d){
    //   if(d?.file === undefined || d?.file === null){
    //     await axios.get(`fms/fmsclients/get_scanner/${d._id}`,AuthHeader)
    //     .then((res)=>{
    //         getdata()
    //         if(res?.status == 200){
    //             let url = `${base_url}fms/scanner/${res?.data?.data?.file}`
    //                 axios.get(url,{ responseType: 'blob',})
    //                 .then((res)=>{
    //                     FileDownload(res.data, 'scanner.png');
    //                 })
    //         }
    //     }).catch((err)=>{
    //     })
    //   }else{
    //     let url = `${base_url}fms/scanner/${d?.file}`
    //     axios.get(url,{ responseType: 'blob',})
    //     .then((res)=>{
    //         FileDownload(res.data, 'scanner.png');
    //     })
    //   }
    // }

    // async function updatealldata(){
    //     axios.put(`fms/fmsclients/updateall`,{},AuthHeader)
    //     .then((res)=>{
    //         getdata()
    //         set_drawer(false)
    //         toast.success(res.data.message)
    //         setdata({client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true,id:''})
    //     }).catch((err)=>{
    //     })
    // }


    async function downloadExcel(){   
        await axios.get(`fms/app_roles/downloadExcel`,authHeader)
        .then((res)=>{
            let path = res.data.path.replace('public/','')
            handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
            // console.log("res?.data",res?.data)


            // getdata('1')
        })
    //    console.log("download Excel Function")
    }

    const handleDownload = (url, filename) => {
        // console.log('url',url,'filename',filename)

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Roles Excel Downloaded") 
            })
    }


    return(
         <FmsDashboardMainRoot>
            <Toaster />

            <SwipeableDrawer
                anchor='right'
                style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
                    open={drawer}
                    onClose={()=>{set_drawer(!drawer);setdata({id:'',role_name:'',display_name:''})}}
                > 
                        <div style={{width:'300px',padding:'30px'}}> 
                           
                        {/* <IoClose  style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={()=>set_drawer(false)} /> */}

                           <div>
                            <BoldHeadText  val="Add/Edit Fms Role" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>
                              

                            <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Role Name'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.role_name} onChange={(e)=>setdata({...data,role_name:e.target.value})} />
                                </div>

                               <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Display Name'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.display_name} onChange={(e)=>setdata({...data,display_name:e.target.value})} />
                                </div>


                               


                               

                            <p style={{marginTop:'1px'}}></p>
                            
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <PrimaryButton btn_name={'Continue'} onClick={submitform} />
                            <SecondaryButton btn_name={'Close'} onClick={()=>set_drawer(!drawer)} />
                            </div>

                          </div>
                        </div>
            </SwipeableDrawer>
              
            <div style={{display:'flex',alignItems:'center',width:'56%',justifyContent:'space-between'}}>
              <BoldText1 val={`${'Fms Roles'} (${pagination?.total !== undefined ? pagination?.total : fmsclients?.length})`} />
              <div style={{display:'flex',flexDirection:'row'}}>

                
              {/* <PrimaryButton btn_name={'Update All'} onClick={updatealldata}/> */}
              <h6 style={{width:'10px'}}></h6>
              {pathname !== '/fms/exit_clients' &&              
              <div style={{display:'flex',alignItems:'center'}}>
              <Input type="text" style={{marginRight:0,width:'40%'}} value={search} onChange={(e)=>setsearch(e.target.value)} onKeyPress={(e)=>e.key === 'Enter' && searchData()} />
               <MdOutlineRefresh onClick={()=>{getdata();setsearch('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
               {(roles.includes('admin') || roles.includes('fms_hr'))  && 
               <AiFillFileExcel onClick={()=>{downloadExcel();}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}} />}
              <div style={{width:'140px'}}>
              <PrimaryButton btn_name={'Add Roles'} onClick={()=>{set_drawer(true);;setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true})}}/>
              </div>  
              </div>}
              </div>
              </div>

              <Modal visible={modalvisible} footer={false} closable={false} onCancel={() => setmodalvisible(false)} style={{ maxWidth: '280px' }} centered>
                    <div style={{ maxWidth: '200px', }}>
                    <IoIosClose  onClick={() => setmodalvisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
                        <h6 style={{ padding: '1%', fontWeight: '700', fontSize: '13px' }}>Delete Client</h6>
                        <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#def0ff', border: '1px solid #236499', borderRadius: '2px' }}><strong>Note:</strong>do you really want to delete the selected contact once deleted cannot be retrieved back</h6>
                        {/* <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
                            <BoldText1 val={'Are You sure ?'} />
                            <SmallText val={'do you really want to delete the selected contact '} />
                        </Row> */}
                        <div style={{ display:'flex',width:'100%',marginLeft:'90px',justifyItems:'flex-end'}} >
                            <SecondaryButton btn_name={' Cancel '} width="100%" onClick={()=>{setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true});setmodalvisible(false)}}/>
                            <PrimaryButton btn_name={' Delete'}  width="100%"  onClick={deletefunc}/>
                        </div>
                    </div>
              </Modal>
            {fmsclients.length > 0 ? 
               <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'50%',overflow:'hidden' }}>
                              <div className='mateial_table_header' style={{border:'1px solid #eaeded'}}>
                                  <p style={{fontSize:'11px',minWidth: '8%',maxWidth: '8%',paddingLeft:'10px',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                  <p style={{fontSize:'12px',minWidth: '30%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Role Name</p>
                                  <p style={{fontSize:'12px',minWidth: '30%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Display Name</p>
                                  <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%' }}>Actions</p>
                              </div>
                              {fmsclients?.map((d,i)=>(
                              <div key={d?._id} style={{borderLeft:'1px solid #eaeded',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',minWidth: '8%',maxWidth: '8%',paddingLeft:'10px',borderRight:'1px solid #eaeded', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '28.5%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.role_name}</p>
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '29.4%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.display_name}</p>
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                        
                                          <AiOutlineEdit style={{marginRight:'10px'}} onClick={() =>{setdata({...data,id:d._id,role_name:d?.role_name,display_name:d?.display_name});set_drawer(true)}}  />
                                   
                                          <AiOutlineDelete onClick={() => {setdata({...data,id:d._id,role_name:d?.role_name,display_name:d?.display_name});setmodalvisible(true)}}  />
                                  </p>
                                  </p>
                              </div>))}
                              <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                {pagination?.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
                             </div>

                </div> 
                :
                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                </div>
                
                }
                       
         </FmsDashboardMainRoot>
    )
}




export default FmsRoles







//////old
// import React,{useState,useEffect} from 'react';
// import { BoldHeadText, BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
// import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import {Input,Modal,Pagination} from 'antd'
// import {useSelector} from 'react-redux'
// import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
// import axios from 'axios';
// import {BiEditAlt} from 'react-icons/bi'
// import toast,{ Toaster } from 'react-hot-toast';
// import Tooltip from '@mui/material/Tooltip';
// import { IoIosClose } from 'react-icons/io';
// import { data_not_found } from '../../../helpers/Constants';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { MdDeleteOutline,MdOutlineRefresh } from 'react-icons/md'
// import { AiOutlineFileExcel, AiFillFileExcel} from "react-icons/ai";
// import { authHeader } from '../../../helpers/usertoken/UserToken';
// import {  local_base_url } from '../../../App';
// import fileDownload from "js-file-download";


// function FmsRoles(){

//     const roles = useSelector(state => state.Auth.roles)
    
//     // console.log("roles",roles)

//     const AuthHeader = {headers:{
//         'Content-Type': 'application/json',
//     }}

//     const {pathname} = useLocation()

//     // console.log("pathname",pathname)

//     const [drawer,set_drawer] = useState(false)
//     const [data,setdata] = useState({id:'',role_name:'',display_name:''})
//     const [modalvisible,setmodalvisible] = useState(false)


//     const [search,setsearch] = useState('')



//     const [fmsclients,setfmsclients] = useState([])
//     const { TextArea } = Input;
//     const theme_color = useSelector(state=>state.Theme.theme_color)

//     const [pagination,setpagination] = useState({})
//     const [page,setpage] = useState(1)

//     const navigator = useNavigate()

//     function func(){

//     }

//     function setpagefuun(v){
//         setpage(v)
//     }


//     useEffect(()=>{
//         getdata()
//     },[page])


//     useEffect(()=>{
//         getdata()
//     },[pathname])

//     function getdata(){
//         axios.get(`fms/app_roles/get?page=${page}`,AuthHeader)
//         .then((res)=>{
//             setpagination(res.data.pagination)
//             setfmsclients(res.data.datas)
//         }).catch((err)=>{
//         })
        
        
//     }
    
//     function submitform(){
//         if(data.id === ''){
//             axios.post('fms/app_roles/create',data,AuthHeader)
//             .then((res)=>{
//                 getdata()
//                 toast.success(res.data.message)
//                 set_drawer(false)
//                 setdata({role_name:'',display_name:''})
//             }).catch((err)=>{
//             })
//         }else{
//             axios.put(`fms/app_roles/update/${data.id}`,data,AuthHeader)
//             .then((res)=>{
//                 getdata()
//                 set_drawer(false)
//                 toast.success(res.data.message)
//                 setdata({id:'',role_name:'',display_name:''})
//             }).catch((err)=>{
//             })
//         }
      
//     }


//     function deletefunc(){
//         axios.delete(`fms/app_roles/delete/${data.id}`,data,AuthHeader)
//         .then((res)=>{
//             getdata()
//             setmodalvisible(false)
//             toast.success(res.data.message)
//             setdata({id:'',role_name:'',display_name:''})
//         }).catch((err)=>{
//         // console.log('err data here na',err)
//         })
//     }


//     function searchData(){
//         if(search?.length === 0){
//             getdata()
//         }else{
//             axios.get(`fms/app_roles/search?search_text=${search}`,AuthHeader)
//             .then((res)=>{
//                 setpagination(res.data.pagination)
//                 setfmsclients(res.data.datas)
//             }).catch((err)=>{
//             })
//         }    
//     }

//     // async function downloadqrCode(d){
//     //   if(d?.file === undefined || d?.file === null){
//     //     await axios.get(`fms/fmsclients/get_scanner/${d._id}`,AuthHeader)
//     //     .then((res)=>{
//     //         getdata()
//     //         if(res?.status == 200){
//     //             let url = `${base_url}fms/scanner/${res?.data?.data?.file}`
//     //                 axios.get(url,{ responseType: 'blob',})
//     //                 .then((res)=>{
//     //                     FileDownload(res.data, 'scanner.png');
//     //                 })
//     //         }
//     //     }).catch((err)=>{
//     //     })
//     //   }else{
//     //     let url = `${base_url}fms/scanner/${d?.file}`
//     //     axios.get(url,{ responseType: 'blob',})
//     //     .then((res)=>{
//     //         FileDownload(res.data, 'scanner.png');
//     //     })
//     //   }
//     // }

//     // async function updatealldata(){
//     //     axios.put(`fms/fmsclients/updateall`,{},AuthHeader)
//     //     .then((res)=>{
//     //         getdata()
//     //         set_drawer(false)
//     //         toast.success(res.data.message)
//     //         setdata({client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true,id:''})
//     //     }).catch((err)=>{
//     //     })
//     // }


//     async function downloadExcel(){   
//         await axios.get(`fms/app_roles/downloadExcel`,authHeader)
//         .then((res)=>{
//             let path = res.data.path.replace('public/','')
//             handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
//             // console.log("res?.data",res?.data)


//             // getdata('1')
//         })
//     //    console.log("download Excel Function")
//     }

//     const handleDownload = (url, filename) => {
//         // console.log('url',url,'filename',filename)

//         axios.get(url, {
//             responseType: 'blob',
//         })
//             .then((res) => {
//                 fileDownload(res.data, filename)
//                 toast.success("Roles Excel Downloaded") 
//             })
//     }


//     return(
//          <FmsDashboardMainRoot>
//             <Toaster />

//             <SwipeableDrawer
//                 anchor='right'
//                 style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
//                     open={drawer}
//                     onClose={()=>{set_drawer(!drawer);setdata({id:'',role_name:'',display_name:''})}}
//                 > 
//                         <div style={{width:'300px',padding:'30px'}}> 
                           
//                         {/* <IoClose  style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={()=>set_drawer(false)} /> */}

//                            <div>
//                             <BoldHeadText  val="Add/Edit Fms Role" fontSize={'15px'} fontWeight='900'/>
//                             <p style={{borderBottom:'1px solid #eee'}}></p>
                              

//                             <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Role Name'}  />
//                                 </div>
//                                 <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.role_name} onChange={(e)=>setdata({...data,role_name:e.target.value})} />
//                                 </div>

//                                <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Display Name'}  />
//                                 </div>
//                                 <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.display_name} onChange={(e)=>setdata({...data,display_name:e.target.value})} />
//                                 </div>


                               


                               

//                             <p style={{marginTop:'1px'}}></p>
                            
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <PrimaryButton btn_name={'Continue'} onClick={submitform} />
//                             <SecondaryButton btn_name={'Close'} onClick={()=>set_drawer(!drawer)} />
//                             </div>

//                           </div>
//                         </div>
//             </SwipeableDrawer>
              
//             <div style={{display:'flex',alignItems:'center',width:'56%',justifyContent:'space-between'}}>
//               <BoldText1 val={`${'Fms Roles'} (${pagination?.total !== undefined ? pagination?.total : fmsclients?.length})`} />
//               <div style={{display:'flex',flexDirection:'row'}}>

                
//               {/* <PrimaryButton btn_name={'Update All'} onClick={updatealldata}/> */}
//               <h6 style={{width:'10px'}}></h6>
//               {pathname !== '/fms/exit_clients' &&              
//               <div style={{display:'flex',alignItems:'center'}}>
//               <Input type="text" style={{marginRight:0,width:'40%'}} value={search} onChange={(e)=>setsearch(e.target.value)} onKeyPress={(e)=>e.key === 'Enter' && searchData()} />
//                <MdOutlineRefresh onClick={()=>{getdata();setsearch('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
//                {(roles.includes('admin') || roles.includes('fms_hr'))  && 
//                <AiFillFileExcel onClick={()=>{downloadExcel();}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}} />}
//               <div style={{width:'140px'}}>
//               <PrimaryButton btn_name={'Add Roles'} onClick={()=>{set_drawer(true);;setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true})}}/>
//               </div>  
//               </div>}
//               </div>
//               </div>

//               <Modal visible={modalvisible} footer={false} closable={false} onCancel={() => setmodalvisible(false)} style={{ maxWidth: '280px' }} centered>
//                     <div style={{ maxWidth: '200px', }}>
//                     <IoIosClose  onClick={() => setmodalvisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
//                         <h6 style={{ padding: '1%', fontWeight: '700', fontSize: '13px' }}>Delete Client</h6>
//                         <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#def0ff', border: '1px solid #236499', borderRadius: '2px' }}><strong>Note:</strong>do you really want to delete the selected contact once deleted cannot be retrieved back</h6>
//                         {/* <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
//                             <BoldText1 val={'Are You sure ?'} />
//                             <SmallText val={'do you really want to delete the selected contact '} />
//                         </Row> */}
//                         <div style={{ display:'flex',width:'100%',marginLeft:'90px',justifyItems:'flex-end'}} >
//                             <SecondaryButton btn_name={' Cancel '} width="100%" onClick={()=>{setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true});setmodalvisible(false)}}/>
//                             <PrimaryButton btn_name={' Delete'}  width="100%"  onClick={deletefunc}/>
//                         </div>
//                     </div>
//               </Modal>
               

//                <div style={{width:'50%',overflowX:'hidden'}}>

//                 {fmsclients.length > 0 ? 
//                 <div style={{ border: '0px solid lightgray',marginTop:'0px', overflowY: 'scroll',overflowX:'hidden',width:'100%' }}>
//                     <div className='mateial_table_header'>
//                         <p style={{ width: '10%', color: 'black', }}>Sl No</p>
//                         <p style={{ width: '40%',  }}>Role Name</p>
//                         <p style={{ width: '40%',  }}>Display Name</p>
//                         <p style={{ width: '10%' }}>Actions</p>
//                     </div>


//                     {fmsclients.map((d, i) => (
//                         <div key={i} className={'mateial_table_body'} >
//                             <p style={{ minWidth: '10%',maxWidth:'10%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }} onClick={() => func(d)}>{page == 1 ?  i+1 : (pagination?.limit * (page-1)) + (i+1)}</p>
//                             <p style={{ minWidth: '40%',width:'40%',maxWidth:'40%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }} >{d?.role_name}</p>
//                             <p style={{ minWidth: '40%',width:'40%',maxWidth:'40%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.display_name}</p>

//                             <p style={{minWidth: '10%',maxWidth:'10%', display: 'flex',alignItems:'center', }}>
//                             <Tooltip title='Edit'>
//                                 <span >
//                                 <BiEditAlt size={12} className='bg-slate-100' onClick={() =>{setdata({...data,id:d._id,role_name:d?.role_name,display_name:d?.display_name});set_drawer(true)}} style={{ color: '#000',marginRight:'15px' }}  />
//                                 </span>
//                             </Tooltip>
//                             {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
//                             <Tooltip title='Delete'>
//                                 <span>
//                                 <MdDeleteOutline size={12} onClick={() => {setdata({...data,id:d._id,role_name:d?.role_name,display_name:d?.display_name});setmodalvisible(true)}} style={{ color: '#000' }} />
//                                 </span>
//                             </Tooltip>}
//                             {/* onClick={() => {downloadqrCode(d)}} */}
                           
//                             </p>
//                         </div>
//                     ))}


//                 <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
//                 {pagination?.total > pagination?.limit &&
//                     <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
//                 }
//                 </div>

//                 </div> 
//                 :
//                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                 </div>}
//                 </div>
              
//          </FmsDashboardMainRoot>
//     )
// }




// export default FmsRoles