import axios from "axios";
import React,{useState,useEffect} from "react";
import { authHeader } from "../../../helpers/usertoken/UserToken";
import FmsDashboardMainRoot from "../FmsDashboardMainRoot";
import {useParams} from 'react-router-dom';
import { base_url } from "../../../App";
import {useSelector} from 'react-redux'
import {Modal, Tag, Select, Input, Upload, Button} from 'antd';
import toast, { Toaster } from "react-hot-toast";
import fileDownload from "js-file-download";
import {BiCheckbox, BiCheckboxSquare, BiEdit} from 'react-icons/bi';
import {AiOutlineCalendar, AiOutlineInfoCircle, AiOutlineEdit, AiOutlineUser, AiOutlineClockCircle} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom'
import {MdFileDownload} from 'react-icons/md'
import {GiExpand} from 'react-icons/gi'
import moment from "moment";
import { IoMdPrint } from "react-icons/io";
import PrimaryButton, { SecondaryButton } from "../../../components/buttons/PrimaryButton";
import { theme_color } from "../../../helpers/constants/ConstantColor";
import { SmallText } from "../../../helpers/constants/ConstantsText";
import { TiWarningOutline } from "react-icons/ti";
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import TextArea from "antd/lib/input/TextArea";
import { Drawer } from "@mui/material";
import {Pagination} from 'antd'
import { RiChatHistoryLine } from "react-icons/ri";
import { PiDress } from "react-icons/pi";

function FmsEmployeeDetail(){

    const { id } = useParams();

    // console.log("id here",id)

    const user = useSelector(state=>state.Auth)
    const token = useSelector(state=>state.Auth.access_token)

    // console.log("user",user)

    const AuthHeader = {headers:{
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }}
    
    const [data,setdata] = useState()
    const [letter_data,setletter_data] = useState({})
    const [selected_user,setselected_user] = useState({})
    const [usersArr,setusersArr] = useState([])
    const [step,setstep] = useState(1)

    const [page,setpage] = useState(1)


    const [regenerate,setregenerate] = useState({id_card:false,hr_policy:false,self_declartion:false,appointment_letter:false,offer_letter:false,deployment_letter:false,visible:false,language:'EN'})
   
    const [warningLetterInfo,setwarningLetterInfo] = useState({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',modal:false})
    const [warningLetterInfoErr,setwarningLetterInfoErr] = useState({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',})

    const [uniformInfo,setuniformInfo] = useState({uniform_required:false,id_required:false,uniform_info:'',remarks:'',raised_user_type:'InHouse-Employee'})
    const [uniformInfoErr,setuniformInfoErr] = useState({uniform_required:'',id_required:'',uniform_info:'',remarks:''})

    // uniform_info


    const [visible,setvisible] = useState()
    const [visible1,setvisible1] = useState(false)
    const [uniformModal,setuniformModal] = useState(false)

    const [timelineData,settimelineData] = useState({datas:[],pagination:{total:0}})

    const [timelineModal,settimelineModal] = useState(false) 

    const roles = useSelector(state=>state.Auth.roles)

    const [serviceInfo,setserviceInfo] = useState([])





    useEffect(()=>{
     axios.get(`fms/basicemployee/get_detail/${id}`,authHeader)
     .then((res)=>{

        setdata(res.data.data[0])
     })
     getletters()
     gettimeline()
     getemployeeservice()
    },[])

    async function gettimeline(){
     await axios.get(`fms/basicemployee/get_fms_timeline_based_employee_form/${id}?page=${page}`,authHeader)
      .then((res)=>{
        settimelineData(res?.data?.data)
      }).catch((err)=>{
      })
    }


    async function getemployeeservice(){
      await axios.get(`fms/basicemployee/fms_employee_service_history/${id}`,authHeader)
       .then((res)=>{
        setserviceInfo(res?.data?.data)
        //  settimelineData(res?.data?.data)
       }).catch((err)=>{
       })
     }

    async function setpagefuun(v){
      setpage(v)
      await axios.get(`fms/basicemployee/get_fms_timeline_based_employee_form/${id}?page=${v}`,authHeader)
      .then((res)=>{
        settimelineData(res?.data?.data)
      }).catch((err)=>{
      })
    }


    async function getletters(){
      axios.get(`fms/basicemployee/get_employee_letter/${id}`,authHeader)
      .then((res)=>{
        setletter_data(res?.data?.data)
      }).catch((err)=>{
      })
    }

    async function createletters(){
      axios.post(`fms/basicemployee/create_employee_letter_provided/${id}`,{
        ...regenerate
      },authHeader)
      .then((res)=>{
        if(res?.status == 200){
          setregenerate({id_card:false,hr_policy:false,self_declartion:false,appointment_letter:false,visible:false,offer_letter:false,deployment_letter:false,language:'EN'})
          toast.success("Letters Created Successfully")
          getletters()
        }else{
          setregenerate({...regenerate,visible:false})
          toast.success("Something Went Wrong")
        }
        // setletter_data(res?.data?.data)
      }).catch((err)=>{
        setregenerate({...regenerate,visible:false})
      })
    }

    // console.log("data",data)


    const handleDownload = async (url, filename,type) => {
      // console.log("url",url)
      // console.log("filename",filename)
      // console.log("type",type)

      // const proxyUrl = `${base_url}/proxy?url=${encodeURIComponent(url)}`;
      // console.log("proxyUrl",proxyUrl)
      // fileDownload(proxyUrl, filename)
      // toast.success("Clients Excel Downloaded")
      try{
        await axios.get(url, {
            responseType: 'blob',
        }).then((res) => {
          // console.log("response.data",res?.data)
          console.log("response.status",res?.status)
          if (res.status === 200) {
            fileDownload(res.data, filename);
            toast.success(`${type} downloaded successfully`)
            // console.log("Download started successfully");
          } else {
            // console.error("Error downloading file: ", response);
          }
          // console.log("res?.data",res?.data)
          // console.log("filename",filename)
          //   toast.success(`${type} downloaded successfully`)
          //   fileDownload(res.data, filename)
        })
      }catch(e){
        console.log("err",e)
      }
  }
  

  async function searchuser(v){ 
    await axios.get(`fms/fms_in_house_employee/search?search=${v}`,AuthHeader)
        .then((res)=>{
            let arr = []
            res?.data?.datas?.forEach(d => {
                arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
            });
            setusersArr(arr)
            return arr
        }).catch((err)=>{
        })
}        


  async function assignuserfunc(){
    if(selected_user === ''){
      toast.error("Selected User Field is required*")
    }else{
      await axios.put(`fms/basicemployee/update_fms_employee/${id}`,{created_by_fms_in_employee:selected_user},AuthHeader)
      .then((res)=>{
         if(res?.status === 200){
          setvisible1(false)
          axios.get(`fms/basicemployee/get_detail/${id}`,authHeader)
          .then((res)=>{
             setdata(res.data.data[0])
          })
          toast.success("User Status Updated Successfully")
         }
      }).catch((err)=>{
        setvisible1(false)
      })
    }

  }

  function uploadFile(v){
    authHeader['headers']['Content-Type'] = 'multipart/form-data'
    var fd = new FormData()
    fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
    axios.post(`fms/warning_letter/upload_file`,fd,authHeader)
    .then((res)=>{
      setwarningLetterInfo({...warningLetterInfo,attachment:res?.data?.data})
    }).catch((err)=>{
        console.log("err data",err)
    })
  }

  async function submitwarningLetter() {
    if(warningLetterInfo?.info == ''){
      setwarningLetterInfoErr({...warningLetterInfoErr,info:'This Field is required!'})
    }else if(warningLetterInfo?.attachment == ''){
      setwarningLetterInfoErr({...warningLetterInfoErr,attachment:'This Field is required!'})
    }else{
      await axios.post(`fms/warning_letter/create`,{...warningLetterInfo,fms_employee:id},AuthHeader)
      .then((res)=>{
        setwarningLetterInfo({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',modal:false})
          toast.success("Warning Letter Request Created Successfully")
      }).catch((err)=>{
        setwarningLetterInfo({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',modal:false})
        setwarningLetterInfoErr({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',modal:''})
      })
    }
  }

  const navigate = useNavigate()


  function openDigitalSignature(v){
      const newTab = window.open();
      newTab.document.write(`<img src="${v}" />`);
  }

  async function uniformmodalfunc(){
      if(!uniformInfo?.uniform_info){
        setuniformInfoErr({...uniformInfoErr,uniform_info:"This Field is required*"})
      }else{

        let send_data = {...uniformInfo}
        send_data['employee'] = id

        await axios.post(`fms/uniform_request/create`,{...send_data},AuthHeader)
        .then((res)=>{
          setuniformInfoErr({uniform_required:'',id_required:'',uniform_info:'',remarks:''})
          setuniformInfo({uniform_required:false,id_required:false,uniform_info:'',remarks:''})
          toast.success("Uniform Request Created Successfully")
        }).catch((err)=>{
          setuniformInfoErr({uniform_required:'',id_required:'',uniform_info:'',remarks:''})
          setuniformInfo({uniform_required:false,id_required:false,uniform_info:'',remarks:''})
        })
        setuniformModal(false)
      }
  }

    return(
          <FmsDashboardMainRoot>

            <Modal open={regenerate?.visible} closable={false} footer={false} width={400} style={{position:'absolute',top:0,left:'37%'}}>
              <div>
                <h6 style={{fontWeight:'800'}}>Regenerate the Docs</h6>
                <p style={{fontSize:'12px'}}>Click on the items below to generate the docs for the selected employee!</p>
                
                <div>

                  <h6 style={{fontSize:'12px'}}>Language</h6>

                  <div style={{display:'flex'}}>
                    <h6 onClick={()=>setregenerate({...regenerate,language:'EN'})} style={{width:'100px',fontWeight:'400',fontSize:'13px',background:regenerate?.language === 'EN' ? theme_color : 'white',textAlign:'center',color:regenerate?.language === 'EN' ? 'white' : 'black',padding:'4px',cursor:'pointer'}}>English</h6>
                    <h6 onClick={()=>setregenerate({...regenerate,language:'KN'})} style={{width:'100px',fontWeight:'400',fontSize:'13px',background:regenerate?.language === 'KN' ? theme_color : 'white',textAlign:'center',color:regenerate?.language === 'KN' ? 'white' : 'black',padding:'4px',cursor:'pointer'}}>Kannada</h6>
                    <h6 onClick={()=>setregenerate({...regenerate,language:'HI'})} style={{width:'100px',fontWeight:'400',fontSize:'13px',background:regenerate?.language === 'HI' ? theme_color : 'white',textAlign:'center',color:regenerate?.language === 'HI' ? 'white' : 'black',padding:'4px',cursor:'pointer'}}>Hindi</h6>
                  </div>

                  <div onClick={()=>setregenerate({...regenerate,hr_policy:!regenerate.hr_policy})} style={{display:'flex',alignItems:'center',fontSize:'11px',cursor:'pointer'}}>

                  {!regenerate?.hr_policy ?
                  <BiCheckbox size={20}  color="#aaa"/> : 
                  <BiCheckboxSquare size={20} />}

                  <h6 style={{fontSize:'11px',marginLeft:'10px',marginBottom:'0px'}}>Hr Policy Docs</h6>
                  </div> 

                  <div onClick={()=>setregenerate({...regenerate,self_declartion:!regenerate.self_declartion})} style={{display:'flex',alignItems:'center',fontSize:'11px',cursor:'pointer'}}>

                  {!regenerate?.self_declartion ?
                  <BiCheckbox size={20} color="#aaa" /> : 
                  <BiCheckboxSquare size={20}  />}

                  <h6 style={{fontSize:'11px',marginLeft:'10px',marginBottom:'0px'}}>Self Declartion Docs</h6>
                  </div> 

                  <div onClick={()=>setregenerate({...regenerate,id_card:!regenerate.id_card})} style={{display:'flex',alignItems:'center',fontSize:'11px',cursor:'pointer'}}>

                  {!regenerate?.id_card ?
                  <BiCheckbox size={20} color="#aaa" /> : 
                  <BiCheckboxSquare size={20} />}

                  <h6 style={{fontSize:'11px',marginLeft:'10px',marginBottom:'0px'}}>Id Card</h6>
                  </div> 

                  <div onClick={()=>setregenerate({...regenerate,appointment_letter:!regenerate.appointment_letter})} style={{display:'flex',alignItems:'center',fontSize:'11px',cursor:'pointer'}}>

                  {!regenerate?.appointment_letter ?
                  <BiCheckbox size={20} color="#aaa" /> : 
                  <BiCheckboxSquare size={20} />}

                  <h6 style={{fontSize:'11px',marginLeft:'10px',marginBottom:'0px'}}>Appointment Letter</h6>
                  </div> 

                  <div onClick={()=>setregenerate({...regenerate,deployment_letter:!regenerate.deployment_letter})} style={{display:'flex',alignItems:'center',fontSize:'11px',cursor:'pointer'}}>

                  {!regenerate?.deployment_letter ?
                  <BiCheckbox size={20} color="#aaa" /> : 
                  <BiCheckboxSquare size={20} />}

                  <h6 style={{fontSize:'11px',marginLeft:'10px',marginBottom:'0px'}}>Deployment / BGV Letter</h6>
                  </div> 

                  <div onClick={()=>setregenerate({...regenerate,offer_letter:!regenerate.offer_letter})} style={{display:'flex',alignItems:'center',fontSize:'11px',cursor:'pointer'}}>

                  {!regenerate?.offer_letter ?
                  <BiCheckbox size={20} color="#aaa" /> : 
                  <BiCheckboxSquare size={20} />}

                  <h6 style={{fontSize:'11px',marginLeft:'10px',marginBottom:'0px'}}>Offer Letter</h6>
                  </div> 


                  <div style={{display:'flex',marginTop:'10px',flexDirection:'row',alignItems:'center',justifyContent:'end'}}>
                    <SecondaryButton btn_name={'Close'} onClick={()=>setregenerate({...regenerate,visible:!regenerate.visible})} />
                    <PrimaryButton btn_name={'Regenerate'} onClick={()=>createletters()} />
                  </div>

                </div>
              </div>

            </Modal>

            <Modal open={warningLetterInfo?.modal} closable={false} footer={false} width={400} style={{position:'absolute',top:0,left:'37%'}}>
              <div>
                <h6 style={{fontWeight:'800'}}>Warning Letter Request</h6>
                <p style={{fontSize:'12px'}}>Use the below form to create the warning letter request!</p>
                
                <div>

                  <div style={{marginBottom:'10px'}}>
                      <div style={{width:'150px'}}>
                      <SmallText fontWeight={'600'} val={'Incident Info'} />
                      </div>
                      <TextArea type="text" style={{borderLeft:`4px solid ${theme_color}`}} value={warningLetterInfo.info} onChange={(e)=>{setwarningLetterInfo({...warningLetterInfo,info:e.target.value});setwarningLetterInfoErr({...warningLetterInfoErr,info:''})}} />
                      <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                      {(warningLetterInfoErr?.info) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                      <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{warningLetterInfoErr?.info  }</p>
                      </div>
                  </div>

                  <div style={{marginBottom:'10px',width:'100%'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={`Incident Attachment`} />
                    </div>
                    {(warningLetterInfo?.attachment === null || warningLetterInfo?.attachment == undefined || warningLetterInfo?.attachment === '') ?
                    <Upload showUploadList={false}  onChange={(v)=>uploadFile(v)} style={{width:'100%'}}>
                        {/* ,borderLeft:`4px solid ${theme_color}` */}
                        <Button style={{width:'350px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                    </Upload>
                    :
                    <Button onClick={()=>{setwarningLetterInfo({...warningLetterInfo,attachment:''});setwarningLetterInfoErr({...warningLetterInfoErr,attachment:''})}}  style={{width:'100%',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',borderLeft:`4px solid ${theme_color}`}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{(warningLetterInfo?.attachment != undefined && warningLetterInfo?.attachment !== '')  ? warningLetterInfo?.attachment : warningLetterInfo?.attachment?.split('/')[warningLetterInfo?.attachment?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
                    }
                    <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                    {(warningLetterInfoErr?.attachment) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{warningLetterInfoErr?.attachment}</p>
                    </div>
                  </div>


                


                  <div style={{display:'flex',marginTop:'10px',flexDirection:'row',alignItems:'center',justifyContent:'end'}}>
                    <SecondaryButton btn_name={'Close'} onClick={()=>setwarningLetterInfo({...warningLetterInfo,modal:false})} />
                    <PrimaryButton btn_name={'Request'} onClick={()=>submitwarningLetter()} />
                  </div>

                </div>
              </div>

            </Modal>

            <Modal open={visible1} closable={false} footer={false} width={400} style={{position:'absolute',top:0,left:'37%'}}>
              <div>
                <h6 style={{fontWeight:'800'}}>Assign Created By Info</h6>
                <p style={{fontSize:'12px'}}>Use the below option to assign onboarded user for the current docs!</p>
                

                <SmallText fontWeight={'600'} val={'Select user'} />
                <Select 
                    showSearch
                    cacheOptions
                    filterOption={false}
                    allowClear={true}
                    onSearch={searchuser}
                    mode="single" 
                    options={usersArr} 
                    style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} 
                    value={selected_user}  
                    onChange={(e)=>{setselected_user(e)}} 
                    />


                <div>
                  <div style={{display:'flex',marginTop:'10px',flexDirection:'row',alignItems:'center',justifyContent:'end'}}>
                    <SecondaryButton btn_name={'Close'} onClick={()=>setvisible1(false)} />
                    <PrimaryButton btn_name={'Assign Created By'} onClick={()=>assignuserfunc()} />
                  </div>
                </div>
              </div>
            </Modal>

            <Modal open={uniformModal} closable={false} footer={false} width={400} style={{position:'absolute',top:0,left:'37%'}}>
              <div>
                <h6 style={{fontWeight:'800'}}>Create Uniform Request</h6>
                <p style={{fontSize:'12px'}}>Use the below option to create the uniform request!</p>
                

                <div style={{marginTop:'14px',marginRight:'10px',display:'flex'}}>
                  <p className={uniformInfo.uniform_required ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setuniformInfo({...uniformInfo,uniform_required:!uniformInfo.uniform_required}) }>
                    {uniformInfo.uniform_required && <p className='checked_here1' style={{background:theme_color}}></p>}
                  </p>
                  <SmallText fontWeight={'600'} val={'Uniform Required'}  />
                </div>
      
                <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
                  <p className={uniformInfo.id_required ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>setuniformInfo({...uniformInfo,id_required:!uniformInfo.id_required}) }>
                    {uniformInfo.id_required && <p className='checked_here1' style={{background:theme_color}}></p>}
                  </p>
                  <SmallText fontWeight={'600'} val={'ID Required'}  />
                </div>

                <div style={{marginBottom:'10px',marginTop:'15px'}}>
                  <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Uniform Info'} />
                  </div>
                    <TextArea type="text" style={{borderLeft:`4px solid ${theme_color}`}} value={uniformInfo?.uniform_info} onChange={(e)=>{setuniformInfo({...uniformInfo,uniform_info:e.target.value});setuniformInfoErr({...uniformInfoErr,uniform_info:''})}} />
                    <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
                    {(uniformInfoErr?.uniform_info) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{uniformInfoErr?.uniform_info}</p>
                  </div>
                </div>

                <div>
                  <div style={{display:'flex',marginTop:'10px',flexDirection:'row',alignItems:'center',justifyContent:'end'}}>
                    <SecondaryButton btn_name={'Close'} onClick={()=>setuniformModal(false)} />
                    <PrimaryButton btn_name={'Save'} onClick={()=>uniformmodalfunc()} />
                  </div>
                </div>
              </div>
            </Modal>

            <Drawer title="Basic Drawer" placement="right" onClose={() => settimelineModal(false)} open={timelineModal} height={'100%'} width={300} closable={false} headerStyle={{ display: 'none' }} >
              <div style={{width:'300px',minWidth:'300px',maxWidth:'300px'}}>
                <h4 style={{ fontSize: '14px', fontWeight: '800',margin:'5px',marginTop:'20px' }}>TimeLine ({timelineData?.pagination?.total}) </h4>

                {timelineData?.datas?.map((t)=>(
                <div style={{margin:'5px',padding:'8px',border:'1px solid #ddd'}}>
                  
                  <h6 style={{fontSize:'12px'}}>Created By :  <span style={{fontWeight:'800'}}>{t?.created_by_fms_in_employee?.name}</span> </h6>
            
                  <h6 style={{fontWeight:'800',fontSize:'13px'}}>History Updation</h6> 
                  <h6 style={{fontSize:'11px'}} dangerouslySetInnerHTML={{__html:t?.history}}></h6>

                  <h6 style={{padding:'4px',backgroundColor:'#ddd',fontSize:'10px'}}>Created At : {moment(t?.createdAt)?.format('LLL')}</h6>
                </div>))}


                <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                    {timelineData?.pagination.total > timelineData?.pagination?.limit &&
                      <Pagination current={page} size="small" total={timelineData?.pagination?.total}   onChange={(v) => setpagefuun(v)}  pageSize={timelineData?.pagination?.limit} />
                    }
                </div>
              </div>
            </Drawer>

            <Toaster />
            <div style={{display:'flex' ,minHeight:'90vh',overflow:'hidden'}}>
                <div style={{flex:1,borderRight:'1px solid #eee',position:'relative'}}>

                    <div>

                       <div>
                        <div style={{display:'flex'}}>
                            <div style={{display:'flex',marginBottom:'10px',minWidth:'30%',maxWidth:'30%'}}>
                            <img src={`${base_url}${data?.photo}`} alt={data?.photo} style={{width:'90px',height:'90px',borderRadius:'10px',objectFit:'cover',boxShadow:'px 3px 1px #9E9E9E',border:'3px solid #eee'}} />
                            <div style={{marginLeft:'10px',marginTop:'5px'}}>
                            <h6 style={{fontSize:'15px',fontWeight:'800',marginBottom:'0px',padding:'0px 0px'}}>{data?.name} <span style={{fontSize:'11px',fontWeight:'600'}}> [Employee Id : {data?.employee_id}] </span></h6>
                            <div>
                            <Tag color="geekblue" style={{fontSize:'10px'}}>{data?.designation?.display_name}</Tag>
                            <span style={{fontWeight:'600',fontSize:'12px',}} >{data?.department_branch_were_deployed?.site_name}</span>
                            <div style={{display:'flex',alignItems:'center'}}>
                            <h6 style={{fontSize:'15px',fontWeight:'800',marginBottom:'0px',padding:'0px 0px'}}><span style={{fontSize:'13px',fontWeight:'500',color:'#000'}}>{data?.mobile_no} </span></h6>
                            <span style={{fontSize:'13px',color:'#2687bf',borderBottom:'1px solid #2687bf',marginLeft:'20px'}}>{data?.personal_email_id}</span>

                            </div>
                            </div>
                            </div>
                            </div>
                            <div style={{marginLeft:'20px',borderLeft:'1px solid #eee',paddingLeft:'20px',minWidth:'30%',maxWidth:'30%'}}>
                              {(data?.created_by?.length > 0 || data?.created_by_fms_in_employee?.length > 0) && <h6 style={{fontSize:'11.5px',marginBottom:'4px'}}>Onboarded by : {data?.created_by_fms_in_employee?.length > 0 ? `${data?.created_by_fms_in_employee[0]?.name} [${data?.created_by_fms_in_employee[0]?.employee_id}]` :  `${data?.created_by[0]?.name} [${data?.created_by[0]?.employee_id}]`}</h6>}
                              {data?.createdAt !== undefined && <h6 style={{fontSize:'11.5px',marginBottom:'2px'}}>Approved on : {moment(data?.createdAt)?.format('LLL')}</h6>}
                              {(data?.updatedAt !== undefined && data?.employee_id !== '') && <h6 style={{fontSize:'11.5px',marginBottom:'2px'}}>updated on : {moment(data?.updatedAt)?.format('LLL')}</h6>}
                              {(data?.is_approved && data?.updatedAt !== undefined) ? <span style={{fontSize:'11.5px',backgroundColor:'#ebebeb',padding:'4px'}}>Approved on : {moment(data?.updatedAt)?.format('LLL')}</span> : <span style={{fontSize:'13px',backgroundColor:'#ebebeb',padding:'4px'}}>Approved Status : Pending Verification</span>}
                            </div>
                       </div>

                       
                       {((!data?.terminated && !data?.inactive_due_to_client && ['Newly Joined','Rejoined']?.includes(data?.employee_status))  || roles?.includes('fms_hr')) &&
                       <div style={{display:'flex',width:'100%',flexWrap:'wrap',alignItems:'center',background:'#fff',marginTop:'10px'}}>
                       {['admin','fms_hr','fms_user','fms_manager','controllers']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                       <div onClick={()=>setuniformModal(!uniformModal)} style={{border:'1px solid #eee',marginBottom:'10px',marginLeft:'10px',display:'flex',maxWidth:'150px',alignItems:'center',justifyItems:'center',justifyContent:'center',padding:'5px',cursor:'pointer'}} >
                          <PiDress size={18} />
                          <h6 style={{fontSize:'13px',marginBottom:'0px',marginLeft:'5px'}}>Uniform Request</h6>
                       </div>}

                       {/* {['admin','fms_hr','controllers']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                       <div onClick={()=>setvisible1(!visible1)} style={{border:'1px solid #eee',marginBottom:'10px',marginLeft:'10px',display:'flex',maxWidth:'110px',alignItems:'center',justifyItems:'center',justifyContent:'center',padding:'5px',cursor:'pointer'}} >
                          <AiOutlineUser size={18} />
                          <h6 style={{fontSize:'13px',marginBottom:'0px',marginLeft:'5px'}}>Created By</h6>
                       </div>} */}

                       {['admin','fms_hr','fms_user','fms_manager','controllers']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                       <div onClick={()=>settimelineModal(!timelineModal)} style={{border:'1px solid #eee',marginBottom:'10px',marginLeft:'10px',display:'flex',maxWidth:'110px',alignItems:'center',justifyItems:'center',justifyContent:'center',padding:'5px',cursor:'pointer'}} >
                          <RiChatHistoryLine size={18} />
                          <h6 style={{fontSize:'13px',marginBottom:'0px',marginLeft:'5px'}}>TimeLine</h6>
                       </div>}

                       <div onClick={()=>setwarningLetterInfo({...warningLetterInfo,modal:true})} style={{border:'1px solid #eee',marginBottom:'10px',marginLeft:'10px',display:'flex',maxWidth:'200px',alignItems:'center',justifyItems:'center',justifyContent:'center',padding:'5px',cursor:'pointer'}}>
                          <TiWarningOutline size={18} />
                          <h6 style={{fontSize:'13px',marginBottom:'0px',marginLeft:'5px'}}>Warning Letter Request</h6>
                       </div>

                     
                       <div style={{border:'1px solid #eee',marginBottom:'10px',marginLeft:'10px',display:'flex',maxWidth:'100px',alignItems:'center',justifyItems:'center',justifyContent:'center',padding:'5px',cursor:'pointer'}} onClick={()=>navigate('/fms/employee_based_on_clients/add_attendance',{state:{_id:data?._id,department_branch_were_deployed:data?.department_branch_were_deployed,employee_id:data?.employee_id,name:data?.name,photo:data?.photo,designation:[{designation_name:data?.designation?.designation_name,_id:data?.designation?._id}],date_of_joining:data?.date_of_joining,createdAt:data?.createdAt,type:'onboarded_employee'}})} >
                        <AiOutlineCalendar size={18} />
                       <h6 style={{fontSize:'13px',marginBottom:'0px',marginLeft:'5px'}}>Attendance</h6>
                       </div>

                       <div style={{border:'1px solid #eee',marginBottom:'10px',marginLeft:'10px',display:'flex',maxWidth:'100px',alignItems:'center',justifyItems:'center',justifyContent:'center',padding:'5px',cursor:'pointer'}} onClick={()=>{navigate(`/fms/epi_form/edit/${data._id}`)}}>
                        <AiOutlineEdit size={10} />
                       <h6 style={{fontSize:'13px',marginBottom:'0px',marginLeft:'10px'}}>Edit</h6>
                       </div>
                       </div>} 


                       {['admin','fms_hr','controllers']?.filter((f)=>roles?.includes(f))?.length > 0 && data?.modified &&
                       <div style={{border:'1px solid #ddd',border:'1px solid #ddd',padding:'5px',maxWidth:'100%'}}>
                              <>
                              {(timelineData?.datas[0]?.created_by_fms_in_employee !== null && timelineData?.datas[0]?.created_by_fms_in_employee !== undefined ) && <h6 style={{marginTop:'10px',fontSize:'11.5px',marginBottom:'0px'}}>Recent Modified by : <span style={{fontWeight:'bolder'}}>{timelineData?.datas[0]?.created_by_fms_in_employee?.name}</span> </h6>}
                              {<span style={{padding:'0px 0px',fontSize:'11px',fontWeight:'bolder'}} >Data Modified</span> }
                              <h6 style={{fontSize:'11px', fontSize: '11px', 
                                whiteSpace: 'normal', 
                                overflowWrap: 'break-word', 
                                wordBreak: 'break-word' }} dangerouslySetInnerHTML={{__html:timelineData?.datas[0]?.history}}></h6>
                              </>
                       </div>}


                       <div style={{display:'flex',marginTop:'30px'}}>
                          <h6 style={{fontWeight:'700',fontSize:'13px',marginRight:'10px',paddingBottom:'5PX',borderBottom:step == 1 ? '2px solid #2687bf' : '2px solid #fff',cursor:'pointer'}} onClick={()=>setstep(1)}>Basic Info</h6>
                          <h6 style={{fontWeight:'700',fontSize:'13px',marginRight:'10px',borderBottom:step == 2 ? '2px solid #2687bf' : '2px solid #fff',cursor:'pointer'}} onClick={()=>setstep(2)}>Other Info</h6>
                          <h6 style={{fontWeight:'700',fontSize:'13px',borderBottom:step == 3 ? '2px solid #2687bf' : '2px solid #fff',cursor:'pointer'}} onClick={()=>setstep(3)}>Service Info</h6>
                       </div>  
                       <p style={{width:'99%',border:'1px solid #f5f5f5',marginTop:'-9px'}}></p>                     

                      <div style={{width:'100%'}}>

                       {step === 1 && 
                        <>
                        <div style={{display:'flex',width:'99%',marginTop:'20px'}}>
                          <div  style={{borderLeft:'2px solid #2687bf',padding:'10px',background:'#fcfcfc ',minWidth:'24%',maxWidth:'24%',marginRight:'1%',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Basic Info</h6>
                            <span>
                            <span>
                            {data?.is_verified ?  
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            :
                            <span style={{background:'#e3f3ff',color:'#4480ad',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>PENDING</span>}
                            </span>
                            </span>
                            </div>
                            <div className="row">
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Date of Birth</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.date_of_birth}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Date of Joining</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.date_of_joining}</h6>
                                </div>
                              </div>


                              <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Full Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.name}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Father Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.father_name}</h6>
                                </div>
                              </div>

                              

                              <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Addhar Card no</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.addhar_card_no}</h6>
                                </div>
                              </div>


                              <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Blood Group</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.blood_group}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Pan Card no</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.pan_card_no}</h6>
                                </div>
                              </div>



                              <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Gender</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.gender}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Esi no</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.previous_esi_no}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Martial Status</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.martial_status}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Prev UAN/PF no</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.previous_uan_pf_no}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Emergency Contact</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.emergency_contact}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Nationality</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.nationality}</h6>
                                </div>
                              </div>



                              <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Present Address</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.present_address}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Work Experience</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.total_work_experience}</h6>
                                </div>
                              </div>



                              <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Permanent Address</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.permanent_address}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Take Home</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.take_home}</h6>
                                </div>
                              </div>

                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>ESI / INSURANCE Type</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>{data?.insurance_or_esi}</h6>
                                </div>
                              </div>

                              <div >
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Salary Structure</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'40%'}}>Net Pay Take Home : {data?.salary_structure?.net_pay_take_home_salary} Rs</h6>
                                </div>
                              </div>



                              </div>
                          </div>
                          
                          <div style={{maxWidth:'24%',height:'100%',marginRight:'1%'}}>
                          <div  style={{borderLeft:'2px solid #a15c4d',padding:'10px',background:'#fcfcfc ',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Bank Info</h6>
                            <span>
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            </span>
                            </div>
                            <div className="row">
                            <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Bank Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.employee_banks[0]?.bank_name}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Account No</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.employee_banks[0]?.account_no}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>IFSC Code</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.employee_banks[0]?.ifsc_code}</h6>
                                </div>
                              </div>  

                               <div>
                                  <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                    <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Bank Address</h6>
                                    <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.employee_banks[0]?.bank_address}</h6>
                                  </div>
                                </div>

                                <div>
                                  <div style={{display:'flex',width:'100%',}}>
                                    <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Account Holder Name</h6>
                                    <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.employee_banks[0]?.account_holder_name}</h6>
                                  </div>
                                </div>
                              </div>
                          </div>



                          {data?.employee_banks[0]?.bank_photo != undefined &&

                          <div  style={{borderLeft:'2px solid #a15c4d',padding:'10px',background:'#fcfcfc ',marginTop:'20px',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Bank Pass Book</h6>
                          
                            </div>
                            <div className="row" >
                              <div style={{cursor:'pointer'}}>
                                {(data?.employee_banks[0]?.bank_photo != undefined && data?.employee_banks[0]?.bank_photo != '')}
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(6)}>
                                  <img src={`${base_url}${data?.employee_banks[0]?.bank_photo}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>Bank Pass Book</h6>
                                  </div>
                                  {visible === 6 &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Bank Pass Book'}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}} onClick={()=>handleDownload(`${base_url}${data?.employee_banks[0]?.bank_photo}`,data?.employee_banks[0]?.bank_photo.split('/')[data?.employee_banks[0]?.bank_photo.split('/').length - 1],'Bank Pass Book')}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px',marginRight:'10px'}} />
                                      Download
                                      </span>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px',marginLeft:'10px'}} onClick={()=> window.open(`${base_url}${data?.employee_banks[0]?.bank_photo}`, "_blank")} >
                                      <GiExpand size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px',marginRight:'10px'}} />
                                      Preview
                                      </span>
                                    </div>
                                  </div>}
                              
                                </div>
                              </div>  
                              </div>
                          </div>}

                          <div  style={{borderLeft:'2px solid #a15c4d',marginTop:'20px',padding:'10px',background:'#fcfcfc ',height:'100%',}}>

                          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                          <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>HR Policy Approval</h6>
                          <span>
                          <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                          </span>
                          </div>
                          <div className="row">
                          <div >
                              <div style={{display:'flex',width:'100%'}}>
                              <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'50%',maxWidth:'50%'}}>Hr Policy Approval</h6>
                              <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'50%',maxWidth:'50%'}}>{data?.employeepolicyapprovals[0]?.hr_policy_approval ? 'Approved' : 'Not Approved'}</h6>
                              </div>
                            </div>  
                            <div>
                              <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                              <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'50%',maxWidth:'50%'}}>Self Declaration Approval</h6>
                              <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'50%',maxWidth:'50%'}}>{data?.employeepolicyapprovals[0]?.self_declartion_form ? 'Approved' : 'Not Approved'}</h6>
                              </div>
                            </div>  


                            <div>
                              <div style={{display:'flex',width:'100%',}}>
                              <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'50%',maxWidth:'50%'}}>Digital Signature</h6>
                              <div  style={{minWidth:'120px',maxWidth:'120px'}}>
                              {data?.employeepolicyapprovals[0]?.employee_signature &&
                                <h6 onClick={()=>openDigitalSignature(data?.employeepolicyapprovals[0]?.employee_signature)}  style={{fontWeight:'600',color:'blue',cursor:'pointer',textDecoration:'underline',marginTop:4,fontSize:'8px',marginBottom:'0px',padding:'4px 2px',margin:'1px',marginTop:'5px',minWidth:'140px',maxWidth:'140px',wordBreak:'break-all'}}>{'View Signature'}</h6>}
                              </div>
                              </div>
                            </div>  

                            <div>
                              <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                              <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'50%',maxWidth:'50%'}}>Video Footage</h6>
                              <div style={{minWidth:'120px',maxWidth:'120px'}}>
                              {data?.employeepolicyapprovals[0]?.video?.split('/')[data?.employeepolicyapprovals[0]?.video?.split('/')?.length - 1] &&
                              <a target="_blank" href={base_url+data?.employeepolicyapprovals[0]?.video}><h6 style={{fontWeight:'600',color:'blue',textDecoration:'underline',marginTop:4,fontSize:'8px',marginBottom:'0px',padding:'4px 2px',margin:'1px',marginTop:'5px',minWidth:'140px',maxWidth:'140px',wordBreak:'break-all'}}>{'Watch Video'}</h6></a>}
                              </div>
                              </div>
                            </div>  
                            
                            </div>
                          </div>


                          </div>

                          {data?.formf?.length > 0 &&
                          <div  style={{borderLeft:'2px solid #4bb0b8',padding:'10px',background:'#fcfcfc ',minWidth:'24%',maxWidth:'24%',marginRight:'1%',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Form F</h6>
                            {!roles.includes('fms_data_view') &&
                           
                            <span>
                            
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            <BiEdit size={15} style={{marginLeft:'10px'}}  onClick={()=>navigate(`/fms/form_f_form/update/${data._id}`,{state:{datas:data}})}/>
                            </span>}
                            </div>
                            <div className="row">
                            <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>To</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.formf[0]?.to}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.formf[0]?.name}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Date</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.formf[0]?.date}</h6>
                                </div>
                              </div>  

                              
                              </div>
                          </div>}

                          {data?.statement?.length > 0 &&
                          <div  style={{borderLeft:'2px solid #cf5b87',padding:'10px',background:'#fcfcfc ',minWidth:'24%',maxWidth:'24%',marginRight:'1%',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Statement Form</h6>
                            <span>
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            <BiEdit size={15} style={{marginLeft:'10px'}} onClick={()=>navigate(`/fms/statement_form/update/${data._id}`,{state:{datas:data}})} />
                            </span>
                            
                            </div>
                            <div className="row">
                            <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Employee Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.name_of_employee_in_full}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Gender</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.sex}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Religion</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.religion}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Martial Status</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.martial_status}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Department Branch were deployed</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.martial_status}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Post held with Ticket or Serial no,if any</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.post_held_with_ticket}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Date of Appointment</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.date_of_appointment}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Permanent Address</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.permanent_address}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Village</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.village}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Thana</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.thana}</h6>
                                </div>
                              </div>  
                              {data?.statement[0]?.post_office !== '' &&
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Post Office</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.post_office}</h6>
                                </div>
                              </div>}  
                              {data?.statement[0]?.district !== '' &&
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>District</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.district}</h6>
                                </div>
                              </div> }
                            
                              {data?.statement[0]?.state !== '' &&
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>State</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.state}</h6>
                                </div>
                              </div> } 
                              {data?.statement[0]?.place !== '' &&
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Place</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.place}</h6>
                                </div>
                              </div>}  
                              {data?.statement[0]?.date !== '' &&
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Date of Appointment</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.statement[0]?.date_of_appointment}</h6>
                                </div>
                              </div> } 

                              
                              </div>
                          </div>}

                        </div>

                          <div style={{marginTop:'20px' ,borderTop:'2px solid #f3f3f3'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',paddingTop:'20px'}}>Attachments</h6>

                            <div className="row">
                            {data?.photo != undefined &&
                              <div className="col-md-2" style={{borderleft:'2px solid #945b4b'}}>
                              <div style={{cursor:'pointer'}}>
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(0)}>
                                  <img src={`${base_url}${data?.photo}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'User Photo'}</h6>
                                  </div>
                                  {visible === 0 &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'User Photo'}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}} onClick={()=>handleDownload(`${base_url}${data?.photo}`,data?.photo.split('/')[data?.photo.split('/').length - 1],'User Photo')}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Download */}
                                      </span>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px',marginLeft:'10px'}} onClick={()=> window.open(`${base_url}${data?.photo}`, "_blank")} >
                                      <GiExpand size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Preview */}
                                      </span>
                                    </div>
                                  </div>}
                              
                                </div>
                              </div>
                              </div>}

                              {(data?.addhar_file != undefined && data?.addhar_file != '') &&
                              <div className="col-md-2" style={{borderleft:'2px solid #945b4b'}}>
                              <div style={{cursor:'pointer'}}>
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(1)}>
                                  <img src={`${base_url}${data?.addhar_file}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Aadhar File'}</h6>
                                  </div>
                                  {visible === 1 && data?.addhar_file != undefined &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Aadhar File'}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}} onClick={()=>handleDownload(`${base_url}${data?.addhar_file}`,data?.addhar_file.split('/')[data?.addhar_file.split('/').length - 1],'AadharCard')}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Download */}
                                      </span>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px',marginLeft:'10px'}} onClick={()=> window.open(`${base_url}${data?.addhar_file}`, "_blank")} >
                                      <GiExpand size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Preview */}
                                      </span>
                                    </div>
                                  </div>}
                              
                                </div>
                              </div>
                              </div>}

                              {(data?.pan_file != undefined  && data?.pan_file != '') &&
                              <div className="col-md-2" style={{borderleft:'2px solid #945b4b'}}>
                              <div style={{cursor:'pointer'}}>
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(2)}>
                                  <img src={`${base_url}${data?.pan_file}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Pan Card'}</h6>
                                  </div>
                                  {visible === 2 &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Pan Card'}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}} onClick={()=>handleDownload(`${base_url}${data?.pan_file}`,data?.pan_file?.split('/')[data?.pan_file?.split('/').length - 1],'PanCard')}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Download */}
                                      </span>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px',marginLeft:'10px'}} onClick={()=> window.open(`${base_url}${data?.pan_file}`, "_blank")} >
                                      <GiExpand size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Preview */}
                                      </span>
                                    </div>
                                  </div>}
                              
                                </div>
                              </div>
                              </div>}

                              {(data?.signature != undefined && data?.signature != '') &&
                              <div className="col-md-2" style={{borderleft:'2px solid #945b4b'}}>
                              <div style={{cursor:'pointer'}}>
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(3)}>
                                  <img src={`${base_url}${data?.signature}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Signature'}</h6>
                                  </div>
                                  {visible === 3 &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Signature'}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}} onClick={()=>handleDownload(`${base_url}${data?.signature}`,data?.signature.split('/')[data?.signature.split('/').length - 1],'Signature')}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Download */}
                                      </span>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px',marginLeft:'10px'}} onClick={()=> window.open(`${base_url}${data?.signature}`, "_blank")} >
                                      <GiExpand size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Preview */}
                                      </span>
                                    </div>
                                  </div>}
                              
                                </div>
                              </div>
                              </div>}

                              {(data?.employee_banks[0]?.bank_photo != undefined && data?.employee_banks[0]?.bank_photo  != '') &&
                              <div className="col-md-2" style={{borderleft:'2px solid #945b4b'}}>
                              <div style={{cursor:'pointer'}}>
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(4)}>
                                  <img src={`${base_url}${data?.employee_banks[0]?.bank_photo}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Pass Book'}</h6>
                                  </div>
                                  {visible === 4 &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Pass Book '}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}} onClick={()=>handleDownload(`${base_url}${data?.employee_banks[0]?.bank_photo}`,data?.employee_banks[0]?.bank_photo.split('/')[data?.employee_banks[0]?.bank_photo.split('/').length - 1],'Bank Pass Book')}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Download */}
                                      </span>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px',marginLeft:'10px'}} onClick={()=> window.open(`${base_url}${data?.employee_banks[0]?.bank_photo}`, "_blank")} >
                                      <GiExpand size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Preview */}
                                      </span>
                                    </div>
                                  </div>}
                              
                                </div>
                              </div>
                              </div>}

                              {(data?.employeepolicyapprovals[0]?.employee_signature !== '' && data?.employeepolicyapprovals[0]?.employee_signature  != undefined && data?.employeepolicyapprovals[0]?.employee_signature  != null ) &&
                              <div className="col-md-2" style={{borderleft:'2px solid #945b4b'}}>
                              <div style={{cursor:'pointer'}}>
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(5)}>
                                  <img src={`${data?.employeepolicyapprovals[0]?.employee_signature}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Employee Digital Signature'}</h6>
                                  </div>
                                  {visible === 5 &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{'Employee Digital Signature'}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <a download="employee_approval_digi.png" href={data?.employeepolicyapprovals[0]?.employee_signature} target="_blank">
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px'}} />
                                      {/* Download */}
                                      </span>
                                      </a>

                                     

                                    </div>
                                  </div>}
                              
                                </div>
                              </div>
                              </div>}






                              
                            </div>
                          </div>
                        </>
                        }

                       {step === 2 &&
                        <>
                          <div style={{display:'flex',width:'99%',marginTop:'20px',flexWrap:'wrap'}}>
                          {data?.employee_languages?.length > 0 &&
                          <div  style={{borderLeft:'2px solid #2687bf',padding:'10px',background:'#fcfcfc ',minWidth:'280px',maxWidth:'24%',marginRight:'1%',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Languages Info</h6>
                            <span>
                            <span>
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            {/* <BiEdit size={15} style={{marginLeft:'10px'}} /> */}
                            </span>
                            </span>
                            </div>
                            <div className="row">
                              {data?.employee_languages?.map((l)=>(
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>{l.language}</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{l.read && 'Read'} {l.write && 'Write'} {l.speak && 'Speak'}  </h6>
                                </div>
                              </div>
                              ))}


                              



                              </div>
                          </div>}

                          {data?.employee_family_info?.length > 0 &&
                          <div style={{maxWidth:'24%',minWidth:'280px',height:'100%',marginRight:'1%'}}>
                          <div  style={{borderLeft:'2px solid #a15c4d',padding:'10px',background:'#fcfcfc ',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Family Info</h6>
                            <span>
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            </span>
                            </div>
                            <div className="row">

                            {/* {console.log("employee_family_info",data?.employee_family_info)}   */}

                            {data.employee_family_info.map((e)=>(  
                            <>
                            <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{e?.name}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Relationship</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{e?.relationship}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Age / Education</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{e?.age !== 'undefined' ? e?.age : '' } / {e?.education}</h6>
                                </div>
                              
                              </div>  

                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Occupation</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{e?.occupation}</h6>
                                </div>

                                {e?.file !== '' &&
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Addhar Card</h6>
                                <h6 style={{fontWeight:'600',fontSize:'10px',textDecorationLine:'underline',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'50%',maxWidth:'50%',cursor:'pointer',color:'blue',wordBreak:'break-all'}} onClick={()=>  window.open(`${base_url}${e?.file}`, "_blank")} >{e?.file !== undefined  ? 'View Addhar' : 'Not Added'}</h6>
                                </div>}
                                </div>
                                <div >
                                <div style={{display:'flex',width:'100%',height:'25px'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}></h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}></h6>
                                </div>
                              </div>  
                                </>
                              ))}
                              </div>
                            
                          </div>


                      


                          </div>}

                          {data?.employee_family_info?.length > 0 &&
                          <div style={{maxWidth:'24%',minWidth:'280px',height:'100%',marginRight:'1%'}}>
                          <div  style={{borderLeft:'2px solid rgb(77, 161, 161)',padding:'10px',background:'#fcfcfc ',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Dispensary Info</h6>
                            <span>
                           {(data.ip_dispensary?._id !== undefined || data?.family_dispensary?._id !== undefined)  && <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>}
                            </span>
                            </div>
                            <div className="row">

                            {/* {console.log("employee_family_info",data?.employee_family_info)}   */}

                            {(data.ip_dispensary?._id !== undefined || data?.family_dispensary?._id !== undefined)  && 
                            <>
                            <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Family Dispensary</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.family_dispensary?.name}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>IP Dispensary</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{data?.ip_dispensary?.name}</h6>
                                </div>
                              </div>  
                             
                                </>}
                              </div>
                            
                          </div>


                      


                          </div>}

                          {data?.nominess?.length > 0 &&
                          <div  style={{borderLeft:'2px solid #cf5b87',padding:'10px',background:'#fcfcfc ',minWidth:'280px',maxWidth:'24%',marginRight:'1%',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Nominees</h6>
                            <span>
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            <BiEdit size={15} style={{marginLeft:'10px'}} />
                            </span>
                            
                            </div>
                            <div className="row">
                            {data?.nominess.map((n)=>(
                              <>
                            <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{n?.name}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Address</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{n?.address}</h6>
                                </div>
                              </div> 
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Age / Relationship</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{n?.age / n?.relationship}</h6>
                                </div>
                              </div> 
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Proportion To Shared</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{n?.proportion_to_shared}</h6>
                                </div>
                              </div> 
                              <div>
                                <div style={{display:'flex',width:'100%',height:'20px'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}></h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}></h6>
                                </div>
                              </div> 
                              </>
                              ))}

                              
                              </div>
                          </div>}

                          {data?.witness?.length > 0 &&
                          <div  style={{borderLeft:'2px solid #4bb0b8',padding:'10px',background:'#fcfcfc ',maxWidth:'24%',minWidth:'280px',marginRight:'1%',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>Witness</h6>
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            </div>
                            <div className="row">
                              
                            {data?.witness.map((w)=>(
                              <>
                            <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{w?.name}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Address</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{w?.address}</h6>
                                </div>
                              </div> 

                              {(w?.signature !== '' && w?.signature !== undefined) &&
                              <div style={{cursor:'pointer'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Signature</h6>
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(w._id)}>
                                  <img src={`${base_url}${w?.signature}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{w?.address?.split('/')[w?.address?.split('/').length - 1]}</h6>
                                  </div>
                                  {visible === w._id &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{w?.signature.split('/')[w?.signature.split('/').length - 1]}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}} onClick={()=>handleDownload(`${base_url}${w?.signature}`,w?.signature?.split('/')[w?.signature?.split('/').length - 1],'File')}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px',marginRight:'10px'}} />
                                      Download
                                      </span>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px',marginLeft:'10px'}} onClick={()=> window.open(`${base_url}${w?.signature}`, "_blank")}>
                                      <GiExpand size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px',marginRight:'10px'}} />
                                      Preview
                                      </span>
                                    </div>
                                  </div>}
                              
                                </div>
                              </div>}



                            
                              </>
                              ))}

                              
                              </div>
                          </div>}

                          {data?.esiemployees?.length > 0 &&
                          <div  style={{borderLeft:'2px solid #4bb0b8',padding:'10px',background:'#fcfcfc ',maxWidth:'24%',minWidth:'280px',marginRight:'1%',height:'100%',}}>

                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'15px'}}>
                            <h6 style={{fontWeight:'800',fontSize:'13px',marginBottom:'0px'}}>ESI FAMILY INFO</h6>
                            <span style={{background:'#e9f5e1',color:'#54a14d',fontWeight:'700',padding:'4px 7px',fontSize:'10px'}}>COMPLETED</span>
                            </div>
                            <div className="row">
                              
                            {data?.esiemployees.map((w)=>(
                              <>
                            <div >
                                <div style={{display:'flex',width:'100%'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Name</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{w?.name}</h6>
                                </div>
                              </div>  
                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Realtion Ship</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{w?.relationship}</h6>
                                </div>
                              </div> 

                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>DOB</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{w?.dob}</h6>
                                </div>
                              </div> 

                              <div>
                                <div style={{display:'flex',width:'100%',background:'#f0f0f0'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>Addhar No</h6>
                                <h6 style={{fontWeight:'600',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'60%',maxWidth:'60%'}}>{w?.addhar_no}</h6>
                                </div>
                              </div> 

                              {(w?.file !== '' && w?.file !== undefined) &&
                              <div style={{cursor:'pointer'}}>
                                <h6 style={{fontWeight:'400',fontSize:'12px',marginBottom:'0px',padding:'4px 2px',margin:'1px',minWidth:'40%',maxWidth:'40%'}}>File</h6>
                                <div style={{display:'flex',width:'100%',height:'100px',position:'relative',border:'1px solid #f3f3f3'}} className="imgsection">
                                  <div style={{position:'relative',width:'100%'}} className="img_visible" onMouseOver={()=>setvisible(w._id)}>
                                  <img src={`${base_url}${w?.file}`} style={{height:'95px',width:'100%',objectFit:'contain'}} />
                                  {/* <h6 style={{width:'100%',position:'absolute',bottom:0,padding:'5px',fontSize:'11px',fontWeight:'700',marginBottom:'0px',background:'#000',opacity:0.3,color:'#fff',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{w?.address?.split('/')[w?.address?.split('/').length - 1]}</h6> */}
                                  </div>
                                  {visible === w._id &&
                                  <div onMouseLeave={()=>setvisible()} style={{width:'100%',height:'100%',position:'absolute',padding:'10px',cursor:'pointer',background:'#f3f3f3',border:'1px solid #f3f3f3'}} className="img_invisible">
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
                                    <img src="https://cdn-icons-png.flaticon.com/512/8344/8344913.png" alt="img" style={{width:'30px',height:'30px',marginRight:'15px'}} />
                                    <h6 style={{width:'70%',fontSize:'11px',fontWeight:'700',marginBottom:'0px',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{w?.file.split('/')[w?.file.split('/').length - 1]}</h6>
                                    </div>
                                    <div style={{marginTop:'5px',marginLeft:'45px'}}>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px'}} onClick={()=>handleDownload(`${base_url}${w?.file}`,w?.file?.split('/')[w?.file?.split('/').length - 1],'File')}>
                                      <MdFileDownload size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px',marginRight:'10px'}} />
                                      Download
                                      </span>
                                      <span style={{fontWeight:'600',borderRadius:'1px',fontSize:'11px',background:'#383737',color:'#fff',padding:'6px',marginLeft:'10px'}} onClick={()=> window.open(`${base_url}${w?.file}`, "_blank")} >
                                      <GiExpand size={14} style={{background:'#383737',color:'#fff',borderRadius:'2px',marginRight:'10px'}} />
                                      Preview
                                      </span>
                                    </div>
                                  </div>}
                              
                                </div>
                              </div>}          
                              </>
                              ))}

                              
                              </div>
                          </div>}

                          {(data?.is_verified && data?.is_approved) &&
                          <div style={{borderLeft:'2px solid #a8326b',padding:'10px',background:'#fcfcfc ',maxWidth:'24%',minWidth:'280px',marginRight:'1%',height:'100%',}}>
                            <div style={{display:'flex',width:'100%',flexDirection:'row',maxWidth:'24%',minWidth:'250px',alignItems:'center',justifyContent:'space-between'}}>
                              <h1 style={{fontSize:'13px',marginTop:'0px',fontWeight:'800'}}>Docs Jenerated </h1>
                              {['admin','fms_hr','controllers']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                              <IoMdPrint onClick={()=>setregenerate({...regenerate,visible:true})} size={20} />}
                            </div>
                            <div style={{fontSize:'11px',marginTop:'10px'}}>
                              {/* {console.log("hr_policy",letter_data)}  */}
                              
                              {letter_data !== null && <>
                              
                              {letter_data?.hr_policy !== undefined && <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px'}}>
                              <h6 style={{marginRight:10,fontSize:13}}>Hr Policy</h6>
                              <h6 onClick={()=>  window.open(`${base_url}${letter_data?.hr_policy?.replace('public','')}`, "_blank")} style={{marginRight:10,fontSize:12,color:'blue',textDecoration:'underline'}}>{( letter_data !== null && letter_data?.hr_policy !== undefined) &&  'View File'}</h6>
                              </div>}

                              {letter_data?.self_declartion !== undefined && <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px',background:'#f0f0f0',paddingLeft:'4px',paddingTop:'5px',}}>
                              <h6 style={{marginRight:10,fontSize:13}}>Self Declaration</h6>
                              <h6 onClick={()=>  window.open(`${base_url}${letter_data?.self_declartion?.replace('public','')}`, "_blank")} style={{marginRight:10,fontSize:12,color:'blue',textDecoration:'underline'}}>{( letter_data !== null && letter_data?.self_declartion !== undefined) &&  'View File'}</h6>
                              </div>}

                              {letter_data?.id_card !== undefined &&  <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px',paddingLeft:'4px',paddingTop:'5px',}}>
                              <h6 style={{marginRight:10,fontSize:13}}>Id Card</h6>
                              <h6 onClick={()=>  window.open(`${base_url}${letter_data?.id_card?.replace('public','')}`, "_blank")} style={{marginRight:10,fontSize:12,color:'blue',textDecoration:'underline'}}>{( letter_data !== null && letter_data?.id_card !== undefined) &&  'View File'}</h6>
                              </div>}

                              {letter_data?.appointment !== undefined && <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px',paddingLeft:'4px',paddingTop:'5px',background:'#f0f0f0'}}>
                              <h6 style={{marginRight:10,fontSize:13}}>Appointment Letter</h6>
                              <h6 onClick={()=>  window.open(`${base_url}${letter_data?.appointment?.replace('public','')}`, "_blank")} style={{marginRight:10,fontSize:12,color:'blue',textDecoration:'underline'}}>{( letter_data !== null && letter_data?.appointment !== undefined) &&  'View File'}</h6>
                              </div>}

                              {letter_data?.deployment_letter !== undefined &&  <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px',paddingLeft:'4px',paddingTop:'5px',}}>
                              <h6 style={{marginRight:10,fontSize:13}}>Deployment Letter</h6>
                              <h6 onClick={()=>  window.open(`${base_url}${letter_data?.deployment_letter?.replace('public','')}`, "_blank")} style={{marginRight:10,fontSize:12,color:'blue',textDecoration:'underline'}}>{( letter_data !== null && letter_data?.deployment_letter !== undefined) &&  'View File'}</h6>
                              </div>}

                              {letter_data?.offer_letter !== undefined && <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px',paddingLeft:'4px',paddingTop:'5px',background:'#f0f0f0'}}>
                              <h6 style={{marginRight:10,fontSize:13}}>Offer Letter</h6>
                              <h6 onClick={()=>  window.open(`${base_url}${letter_data?.offer_letter?.replace('public','')}`, "_blank")} style={{marginRight:10,fontSize:12,color:'blue',textDecoration:'underline'}}>{( letter_data !== null && letter_data?.offer_letter !== undefined) &&  'View File'}</h6>
                              </div>}
                              </>}


                              <div>

                           
                              </div>

                             
                              {/* <h6 onClick={createletters} style={{marginRight:10,fontSize:13,textDecoration:'underline',fontWeight:'700',cursor:'pointer'}}>{'Regenerate Docs'}</h6> */}
                            </div>

                            <div style={{marginTop:'15px',paddingTop:'10px',borderTop:'1px solid #ddd',}}>
                             <h1 style={{fontSize:'13px',marginTop:'0px',fontWeight:'800'}}>Id / Uniform Card Jenerated </h1>

                             <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px',paddingLeft:'4px',paddingTop:'5px',}}>
                              <h6 style={{marginRight:10,fontSize:13}}>Id Card Provided</h6>
                              <h6  style={{marginRight:10,fontSize:12,fontWeight:'bold'}}>{data?.id_card_provided !== undefined ? data?.id_card_provided ? 'Provided' : 'Pending' :  'Pending'}</h6>
                             </div> 

                             <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px',paddingLeft:'4px',paddingTop:'5px',background:'#f0f0f0'}}>
                              <h6 style={{marginRight:10,fontSize:13}}>Uniform Provided</h6>
                              <h6  style={{marginRight:10,fontSize:12,fontWeight:'bold'}}>{data?.uniform_provided !== undefined ? data?.uniform_provided ? 'Provided' : 'Pending' :  'Pending'}</h6>
                             </div> 

                             <div style={{display:'flex',alignItems:'center',cursor:'pointer',padding:'1px',marginTop:'15px',paddingTop:'10px',borderTop:'1px solid #ddd',}}>
                              <h6 style={{marginRight:10,fontSize:13,fontWeight:'bolder'}}>Uniform List</h6>
                             
                             </div> 

                             <div style={{border:'1px solid #ddd'}}>
                             <div style={{background:'#f0f0f0',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',paddingBottom:'0px'}} className="flex items-center justify-center">
                              <h6 style={{minWidth:'25%',maxWidth:'25%',fontSize:'11px',fontWeight:'bold',padding:'2px',borderRight:'1px solid #eee',marginBottom:'0px',paddingBottom:'0px'}}>Type</h6>
                              <h6 style={{minWidth:'25%',maxWidth:'25%',fontSize:'11px',fontWeight:'bold',padding:'2px',borderRight:'1px solid #eee',marginBottom:'0px',paddingBottom:'0px'}}>Size</h6>
                              <h6 style={{minWidth:'25%',maxWidth:'25%',fontSize:'11px',fontWeight:'bold',padding:'2px',borderRight:'1px solid #eee',marginBottom:'0px',paddingBottom:'0px'}}>Color</h6>
                              <h6 style={{minWidth:'25%',maxWidth:'25%',fontSize:'11px',fontWeight:'bold',padding:'2px',borderRight:'1px solid #eee',marginBottom:'0px',paddingBottom:'0px'}}>Pairs</h6>
                             </div> 

                             {data?.uniforms?.map((u)=>(
                               <div style={{display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',paddingBottom:'0px'}} >
                               <h6 style={{minWidth:'25%',maxWidth:'25%',fontSize:'10px',fontWeight:'400',padding:'2px',borderRight:'1px solid #eee',marginBottom:'0px',paddingBottom:'0px'}}>{u?.type}</h6>
                               <h6 style={{minWidth:'25%',maxWidth:'25%',fontSize:'10px',fontWeight:'400',padding:'2px',borderRight:'1px solid #eee',marginBottom:'0px',paddingBottom:'0px'}}>{u?.size}</h6>
                               <h6 style={{minWidth:'25%',maxWidth:'25%',fontSize:'10px',fontWeight:'400',padding:'2px',borderRight:'1px solid #eee',marginBottom:'0px',paddingBottom:'0px'}}>{u?.color}</h6>
                               <h6 style={{minWidth:'25%',maxWidth:'25%',fontSize:'10px',fontWeight:'400',padding:'2px',borderRight:'1px solid #eee',marginBottom:'0px',paddingBottom:'0px'}}>{u?.pairs}</h6>
                              </div> 
                              ))}
                              </div>

                            </div>

                          </div>}
                        </div>
                        </>
                       }

                       {step === 3 &&
                        <>
                         {serviceInfo?.length > 0 &&
                          <div  style={{padding:'10px',background:'#fcfcfc ',minWidth:'450px',maxWidth:'60%',marginRight:'1%',height:'100%',border:'1px solid #ddd',borderLeft:'2px solid #2687bf !important',}}>

                            <div style={{display:'flex',marginBottom:'15px',fontSize:'12px'}}>
                              <h6 style={{fontWeight:'800',minWidth:'10%',maxWidth:'10%',fontSize:'11px',marginBottom:'0px !important',padding:'0px 2px'}}>Sl No</h6>
                              <h6 style={{fontWeight:'800',minWidth:'20%',maxWidth:'20%',fontSize:'11px',marginBottom:'0px !important',padding:'0px 2px'}}>Date of Joining</h6>
                              <h6 style={{fontWeight:'800',minWidth:'20%',maxWidth:'20%',fontSize:'11px',marginBottom:'0px !important',padding:'0px 2px'}}>Date of Exit</h6>
                              <h6 style={{fontWeight:'800',minWidth:'20%',maxWidth:'20%',fontSize:'11px',marginBottom:'0px !important',padding:'0px 2px'}}>Status</h6>
                              <h6 style={{fontWeight:'800',minWidth:'30%',maxWidth:'40%',fontSize:'11px',marginBottom:'0px !important',padding:'0px 2px'}}>Created / Updated By</h6>
                            </div>
                           
                              {serviceInfo?.map((l,i)=>(
                                <div style={{display:'flex',marginBottom:'1px',borderTop:'1px solid #ddd'}}>
                                <h6 style={{fontWeight:'400',fontSize:'11px',minWidth:'10%',maxWidth:'10%',marginBottom:'0px',padding:'4px 2px',margin:'1px',}}>{i+1}</h6>
                                <h6 style={{fontWeight:'400',fontSize:'11px',minWidth:'20%',maxWidth:'20%',marginBottom:'0px',padding:'4px 2px',margin:'1px',}}>{moment(l.date_of_joining)?.format('ll')}</h6>
                                <h6 style={{fontWeight:'600',fontSize:'11px',minWidth:'20%',maxWidth:'20%',marginBottom:'0px',padding:'4px 2px',margin:'1px',}}>{l.date_of_exit !== '' && moment(l.date_of_exit)?.format('ll')}  </h6>
                                <h6 style={{fontWeight:'600',fontSize:'11px',minWidth:'20%',maxWidth:'20%',marginBottom:'0px',padding:'4px 2px',margin:'1px',marginLeft:'-5px'}}>{l.status ? 'Active' : 'InActive'}  </h6>
                                <h6 style={{fontWeight:'600',fontSize:'11px',minWidth:'30%',maxWidth:'30%',marginBottom:'0px',padding:'4px 2px',margin:'1px',}}>Created By : <span>{l?.created_by?.name}</span> {l?.updated_by?.name !== undefined && `/ Updated By : ${l?.updated_by?.name}`}  </h6>
                                </div>
                              ))}
                          </div>}

                        </>
                       }
                       </div>
                    



                      
                    </div> 
                    </div>

                </div>
                
            </div>
          </FmsDashboardMainRoot>
    )
}


export default FmsEmployeeDetail