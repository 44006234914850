import React,{useEffect, useState} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { useLocation, useNavigate, } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import TextArea from 'antd/lib/input/TextArea';
import { SmallText } from '../../../helpers/constants/ConstantsText';
import { Button, Input } from 'antd';
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {Select} from 'antd'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { theme_color } from '../../../helpers/constants/ConstantColor';
import { Upload,} from 'antd';
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { base_url } from '../../../App';
import { useSelector } from 'react-redux';


function FmsWarningLetterRequestCE() {
  

  const {state} = useLocation()
  const roles = useSelector(state => state.Auth.roles)



  const navigate = useNavigate()
  const statusArr = [{label:'Pending',value:'Pending'},{label:'Approved',value:'Approved'},{label:'Rejected',value:'Rejected'}]
  const [data,setdata] = useState({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',fms_employee:'',status:''})
  const [error,seterror] = useState({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',fms_employee:'',status:''})


  let disabled = roles?.includes('fms_user')

  useEffect(()=>{

    let passData = {
      ...state 
    }
    setdata(passData)


  },[state])

  function submitformfun(){

    if(!data.incident_title){
      seterror({...error,incident_title:'This Field is required!'})
    }else if(!data.summary){
      seterror({...error,summary:'This Field is required!'})
    }else{
      let send_data = {
        ...data
      }
      send_data['fms_employee'] = data?.fms_employee?._id
      authHeader['headers']['Content-Type'] = 'application/json'

      axios.put(`fms/warning_letter/update/${data._id}`,send_data,authHeader)
        .then((res)=>{
            console.log("res?.data",res?.data)
            toast.success("Update Successfully!!");
            resetform();
        }).catch((err)=>{
            if(err?.response?.status == 403){
                toast.error(err?.response?.data?.message)
            }
        })
    }

  }


  function resetform(){
    navigate(-1)
    setdata({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',fms_employee:'',status:''})
    seterror({info:'',attachment:'',pdf_file:'',incident_title:'',summary:'',fms_employee:'',status:''})
  }


  function uploadFile(v){
    authHeader['headers']['Content-Type'] = 'multipart/form-data'
    var fd = new FormData()
    fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
    axios.post(`fms/warning_letter/upload_file`,fd,authHeader)
    .then((res)=>{
      setdata({...data,pdf_file:res?.data?.data})
      seterror({...error,pdf_file:''})
    }).catch((err)=>{
        console.log("err data",err)
    })
  }

  function openfile(v){
      let url = `${base_url}${v}`
      window.open(url,'_blank')
   }

  return (
    <FmsDashboardMainRoot>
      <Toaster />
       <div style={{width:'25%'}}>
       <h6>Warning Letter Request</h6>
       <h6 style={{fontSize:'10px',background:'#3d7aba',fontWeight:'600',color:'#fff',padding:6}}>Use the below form to create or update the warning letter request of an onboarded employee</h6>
         
         

        <div style={{marginBottom:'10px'}}>
            <div style={{width:'150px',marginTop:'10px'}}>
            <SmallText fontWeight={'600'} val={'Info'}  />
            </div>
            <TextArea type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.info} onChange={(e)=>{setdata({...data,info:e.target.value});seterror({...error,info:''})}} />
        </div>
        <div style={{display:'flex'}}>
            {(error?.info) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.info}</p>
        </div>


        <div style={{marginBottom:'10px',marginTop:'20px',width:'100%'}}>
            <div style={{width:'100%',position:'relative'}}>
            <SmallText fontWeight={'600'} val={`Warning Letter Attachment`} />
            {(data?.attachment !== '') && <h6 style={{position:'absolute',fontSize:'8px',fontWeight:'700',right:'0px',top:'5px',cursor:'pointer'}} ><span style={{color:'black'}} onClick={()=>openfile(data?.attachment)} > View Image</span></h6>}
            </div>
            {(data?.attachment === null || data?.attachment == undefined || data?.attachment === '') ?
            <Upload showUploadList={false}  onChange={(v)=>uploadFile(v)} style={{width:'100%'}}>
                {/*  */}
                <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end',borderLeft:`4px solid ${theme_color}`}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
            </Upload>
            :
            <Button onClick={()=>{setdata({...data,attachment:''});seterror({...error,attachment:''})}}  style={{width:'100%',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',borderLeft:`4px solid ${theme_color}`}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{(data?.attachment != undefined && data?.attachment !== '')  ? data?.attachment : data?.attachment?.split('/')[data?.attachment?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
            }
            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
            {(error?.attachment) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.attachment}</p>
            </div>
        </div>


       
       {/* {['admin','fms_hr']?.filter((f)=>roles?.includes(f))?.length > 0 && */}
        <>
        <div style={{marginBottom:'10px'}}>
            <div style={{width:'150px',marginTop:'10px'}}>
            <SmallText fontWeight={'600'} val={'Incident Title'}  />
            </div>
            <Input disabled={disabled} type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.incident_title} onChange={(e)=>{setdata({...data,incident_title:e.target.value});seterror({...error,incident_title:''})}} />
        </div>
        <div style={{display:'flex'}}>
            {(error?.incident_title) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.incident_title}</p>
        </div>

        <div style={{marginBottom:'10px'}}>
            <div style={{width:'150px',marginTop:'10px'}}>
            <SmallText fontWeight={'600'} val={'Summary'}  />
            </div>
            <TextArea disabled={disabled} type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.summary} onChange={(e)=>{setdata({...data,summary:e.target.value});seterror({...error,summary:''})}} />
        </div>
        <div style={{display:'flex'}}>
            {(error?.summary) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.summary}</p>
        </div>

        <div style={{marginTop:'10px'}}>
        <SmallText fontWeight={'600'} val={'Status'}  />
        </div>
        <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961',}}>
          <Select 
            disabled={disabled}
            filterOption={false}
            style={{ width: '100%',boxShadow:'none' }}
            value={data?.status === '' ? null : data?.status}
            options={statusArr}
            onChange={(v)=>{setdata({...data,status:v});seterror({...error,status:''})}}
          />
        </div>

        <div style={{marginBottom:'10px',marginTop:'20px',width:'100%'}}>
            <div style={{width:'100%',position:'relative'}}>
            <SmallText fontWeight={'600'} val={`PDF File`} />
            {(data?.pdf_file !== '') && <h6 style={{position:'absolute',fontSize:'8px',fontWeight:'700',right:'0px',top:'5px',cursor:'pointer'}} ><span style={{color:'black'}} onClick={()=>openfile(data?.pdf_file)} > View Image</span></h6>}
            </div>
            {(data?.pdf_file === null || data?.pdf_file == undefined || data?.pdf_file === '') ?
            <Upload showUploadList={false}  onChange={(v)=>!disabled && uploadFile(v)} style={{width:'100%'}}>
                {/*  */}
                <Button style={{width:'300px',display:'flex',alignSelf:'flex-end',justifyContent:'end',borderLeft:`4px solid ${theme_color}`}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
            </Upload>
            :
            <Button onClick={()=>{disabled && setdata({...data,pdf_file:''});seterror({...error,pdf_file:''})}}  style={{width:'100%',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px',borderLeft:`4px solid ${theme_color}`}} ><p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{(data?.pdf_file != undefined && data?.pdf_file !== '')  ? data?.pdf_file : data?.pdf_file?.split('/')[data?.pdf_file?.split('/').length-1] }</p><CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/></Button>
            }
            <div style={{display:'flex',alignItems:'center',marginTop:'2px',marginBottom:'0px'}}>
            {(error?.pdf_file) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.pdf_file}</p>
            </div>
        </div>
        </>
        {/* } */}

       



        <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
          <PrimaryButton btn_name="Submit"  onClick={()=>submitformfun()}/>
          <SecondaryButton btn_name={"Clear"} onClick={()=>{resetform()}} />
        </div>
          



       </div>
    </FmsDashboardMainRoot>
  )
}

export default FmsWarningLetterRequestCE