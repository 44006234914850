import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import toast,{ Toaster } from 'react-hot-toast';
import { BoldText1 } from '../../../helpers/constants/ConstantsText';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import { useSelector } from 'react-redux'
import { Select } from 'antd';
import makeAnimated from 'react-select/animated';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import {FaWpforms} from 'react-icons/fa';
import {RiNewspaperLine} from 'react-icons/ri';
import { Pagination, Tag } from 'antd'
import Avatar from '@mui/material/Avatar';
import { base_url } from '../../../App';
import moment from 'moment';
import {BiEditAlt} from 'react-icons/bi'
import {MdDeleteOutline} from 'react-icons/md'


const animatedComponents = makeAnimated();

function FmsEmployeeAssignList() {

  const [data,setdata] = useState([])  
  const [pagination,setpagination] = useState([])  
  const [fmsEmployee,setfmsEmployee] = useState([])
  const [updateEmployee,setupdateEmployee] = useState([])
  const [page,setpage] = useState(1)

  const token = useSelector(state=>state.Auth.access_token)
  const roles = useSelector(state => state.Auth.roles)
  const fcpl_id = useSelector(state => state.Auth.fcpl_id)

  const navigate = useNavigate()

//   console.log("token",token)
console.log("data",data)

  const [modify,setmodify] = useState({employee:'',employee1:''})


  const AuthHeader = {headers:{
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }}

  useEffect(()=>{
    getfmsEmployee()
  },[])

  async function getfmsEmployee(v=''){
    await axios.get(`user/search_fms_employee?search=${v}`,AuthHeader)
    .then((res)=>{
        let arr = []
        res?.data?.data?.forEach(d => {
            arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
        });
        setfmsEmployee(arr)
        return arr
    }).catch((err)=>{
    })
  }


  useEffect(()=>{
    employeeFilter()
  },[page])

  async function searchuser(v){ 
        await axios.get(`fms/fms_in_house_employee/search?search=${v}`,AuthHeader)
            .then((res)=>{
                let arr = []
                res?.data?.datas?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
                });
                setupdateEmployee(arr)
                return arr
            }).catch((err)=>{
            })
    }        



    function handleChange1(v,name){  
        if(name === 'employee1'){ 
           let employee = updateEmployee?.find((v1)=>v1?.value === v)
           setmodify({...modify,[name]:employee})

        }
        if(name === 'employee'){ 
          setpage(1)
          let employee = fmsEmployee?.find((v1)=>v1?.value === v)
          setmodify({...modify,[name]:fmsEmployee?.find((v1)=>v1?.value === v)})
          axios.get(`fms/basicemployee/employee_based_user/${employee !== '' ? employee?.value  : ''}?page=${1}`,AuthHeader)
           .then((res)=>{
             setdata(res.data.datas)
             setpagination(res.data.pagination)
           })
         }
    }  


    async function employeeFilter(){
        axios.get(`fms/basicemployee/employee_based_user/${modify?.employee !== '' ? modify?.employee?.value  : ''}?page=${page}`,AuthHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }


    console.log("data",data)
    console.log("pagination",pagination)

    function deleterecord(v){
        axios.delete(`fms/basicemployee/delete/${v}`,AuthHeader)
        .then((res)=>{
            toast.success(res.data.message);
            employeeFilter()
        })
    }

    function setpagefuun(v){
        setpage(v)
    }

    function assignEmployee(){

        axios.put(`fms/basicemployee/update_fms_mass_employee/${modify?.employee !== '' ? modify?.employee?.value  : ''}/${modify?.employee1 !== '' ? modify?.employee1?.value  : ''}`,AuthHeader)
        .then((res)=>{
            if(res?.status === 200){
                toast.success("Assign Modified")
                getfmsEmployee()
                setTimeout(() => {
                  setpage(1) 
                  axios.get(`fms/basicemployee/employee_based_user/${''}?page=${1}`,AuthHeader)
                  .then((res)=>{
                    setdata(res.data.datas)
                    setpagination(res.data.pagination)
                  })
                }, 2500);
            }
        })
    }
 
  return (
    <FmsDashboardMainRoot> 
         <Toaster />
            <div style={{width:'96%'}}>

                <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between',borderBottom:'1px solid #eaeded',paddingBottom:'5px'}}>
                <BoldText1 val={`FMS Employees (${pagination?.total})`} />
                <div style={{display:'flex'}}>
               
                <h6 style={{width:'5px'}}></h6>
                
                <div style={{display:'flex',alignItems:'center'}}>
                <Select 
                    showSearch
                    cacheOptions
                    filterOption={false}
                    allowClear={true}
                    value={modify?.employee}
                    onSearch={getfmsEmployee}
                    placeholder="Search FMS Employee " 
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(v)=>handleChange1(v,'employee')}
                    options={fmsEmployee}
                    style={{ width: '150px',marginRight:'10px' }}
                  />

                  <PrimaryButton btn_name={'Filter'}  onClick={employeeFilter}/>

                <Select 
                    showSearch
                    cacheOptions
                    filterOption={false}
                    allowClear={true}
                    value={modify?.employee1}
                    onSearch={searchuser}
                    placeholder="Search Inhouse Employee " 
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(v)=>handleChange1(v,'employee1')}
                    options={updateEmployee}
                    style={{ width: '150px',margin:'0px 10px' }}
                  />

                    <PrimaryButton btn_name={'Assign Employee'}  onClick={assignEmployee}/>

                  </div>
                </div>
                </div>


                <div>
                {data.length > 0 &&
                    // <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
                    //     <div className='mateial_table_header'>
                    //         <p className='checked_here' style={{opacity:0}} ></p> 
                    //         <p style={{fontSize:'12px',minWidth: '3%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
                    //         <p style={{fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                    //         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                    //         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                    //         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                    //         <p style={{fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                    //         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                    //         <p style={{fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                    //         <p style={{fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                    //         {/* <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>User Info</p> */}
                    //         <p style={{fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                    //         <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                    //     </div>

                    //     {data.map((d, i) => (
                    //                     <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%'}}>
                    //                         <p   className='checked_here' style={{opacity:0}}>
                    //                         </p>
                    //                         <p style={{fontSize:'12px', minWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'-20px',marginLeft:'-15px',marginRight:'15px' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                    //                         <p style={{fontSize:'12px', minWidth: '5%',maxWidth:'5%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',textTransform:'uppercase' }}> <Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                    //                         <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                    //                         <Tooltip  title={d?.name}><p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                    //                         <Tooltip  title={d?.mobile_no}><p style={{fontSize:'12px', minWidth: '11.9%' ,maxWidth:'11.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                    //                         <Tooltip  title={d?.designation?.designation_name}><p style={{fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-25px' }}>{d?.designation?.designation_name}</p></Tooltip>
                    //                         <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{fontSize:'12px', minWidth: '11.5%',maxWidth:'11.5%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                    //                         <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}> 
                    //                         <p style={{fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}> 
                    //                         {d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> 
                    //                          : 
                    //                        <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}> 
                    //                        {d?.is_approved ? 'Approved' : 'Pending'}
                    //                        </Tag>}</p> 
                    
                    //                        </Tooltip>

                    //                         {/* <Tooxltip  title={d?.gender}><p style={{fontSize:'12px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'10px' }}>{d?.gender}</p></Tooltip> */}
                    //                     <p style={{fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}</span></p>
                    //                     <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
                    //                     {/* <p style={{ minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}>{JSON.stringify(d?.inactive_due_to_client)} - {d?.created_by?.name} - {d?.created_by_fms_in_employee?.name}</p> */}
                    //                     {!roles.includes('fms_data_view') &&

                    //                         <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                    //                             <Tooltip title='Edit'>
                    //                             <span>
                    //                             <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                    //                             </span>
                    //                             </Tooltip>
                    //                             <Tooltip title='Form (F)'>
                    //                             <span>
                    //                             <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                    //                             </span>
                    //                             </Tooltip>
                    //                             <Tooltip title='Statement Form'>
                    //                             <span>
                    //                             <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                    //                             </span>
                    //                             </Tooltip>
                    //                             {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                    //                             <Tooltip title='Delete Record'>
                    //                             <span>
                    //                             <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                    //                             </span>
                    //                             </Tooltip>}
                    //                         </p>}
                    //                     </div>
                    //                 ))}

                    //                   <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                    //                 {pagination?.total > pagination?.limit &&
                    //                  <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                    //                 }
                    //             </div>
                    //    </div>
                       
                                      <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                                                     <div className='mateial_table_header' style={{border:'1px solid #eaeded',}}>
                                                         <p className='checked_here' style={{opacity:0}} ></p> 
                                                         <p style={{fontSize:'11px',minWidth: '3%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                                         <p style={{fontSize:'12px',minWidth: '5%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Avatar</p>
                                                         <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Employee ID</p>
                                                         <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Employee Name</p>
                                                         <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Mobile No</p>
                                                         <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Designation</p>
                                                         <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Site Name</p>
                                                         <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Status</p>
                                                         <p style={{fontSize:'12px',minWidth: '6%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>DOJ</p>
                                                         {/* <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>User Info</p> */}
                                                         <p style={{fontSize:'12px',minWidth: '15.7%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Created At</p>
                                                         <p style={{fontSize:'12px',minWidth: '4.7%',maxWidth: '10%' }}>Actions</p>
                                                     </div>
                                                     {data.map((d,i)=>(
                                                     <div key={d?._id} style={{borderLeft:'1px solid #eaeded',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                                                         <p className='checked_here' style={{opacity:0}} ></p> 
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '4.23%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '4.73%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '7.97%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '9.68%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.name}</p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '9.88%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.mobile_no}</p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '9.6%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.designation?.designation_name}</p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '11.88%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.department_branch_were_deployed?.site_name}</p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '7.71%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>
                                                        
                                            {d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> 
                                             : 
                                           <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}> 
                                           {d?.is_approved ? 'Approved' : 'Pending'}
                                           </Tag>}

                                                         </p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '6%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.gender}</p>
                                                         {/* <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '29.4%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{moment(d?.date_of_joining).format('ll')}</p> */}
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '29.4%',maxWidth: '16%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '15.4%',maxWidth: '8%' }}>
                                                         <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '15.4%',maxWidth: '20%', marginRight: '0.5%' }}>
                                                               
                                                         <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                              <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />

                                                         <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                                         {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                                                                              <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />}
                                                         </p>
                                                         </p>
                                                     </div>))}
                                                     <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                                       {pagination?.total > pagination?.limit &&
                                           <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                                       }
                                                    </div>
                       
                                       </div> 
                       } 

                </div>
            </div> 


    </FmsDashboardMainRoot>    
  )
}

export default FmsEmployeeAssignList