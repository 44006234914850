import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { Input, Select } from 'antd'
import { SmallText } from '../../../helpers/constants/ConstantsText'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import TextArea from 'antd/lib/input/TextArea'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import makeAnimated from 'react-select/animated';
import { theme_color } from '../../../helpers/constants/ConstantColor'


function ReJoinBasicEmployeeToClient() {

  const navigation = useNavigate()
  const [data,setdata] = useState({employee:'',client:'',remarks:'',designation:'',date_of_joining:''})
  const [error,seterror] = useState({employee:'',client:'',remarks:'',designation:'',date_of_joining:''})
  const roles = useSelector(state=>state.Auth.roles)
  const animatedComponents = makeAnimated();


  const [clients,setclients] = useState([])
  const [designations,setdesignations] = useState([])
  const [employees,setemployees] = useState([])

  const {state} = useLocation()

  const status = [{label:'Pending',value:'Pending'},{label:'Progress',value:'Progress'},{label:'Completed',value:'Completed'},{label:'Invalid',value:'Invalid'}]

  useEffect(()=>{
     if(state !== null){
        let arr = []
        let setData = {
          ...state,
          date_of_joining:state?.date_of_joining !== undefined ? state?.date_of_joining : '',
          client:{label:`${state?.client?.site_name}[client name : ${state?.client?.client_name}]`,value:state?.client?._id},
          designation:{label:`${state?.designation?.designation_name}`,value:state?.designation?._id},
          status:status?.find((f)=>f?.value === state?.status),
          employee:{label:`${state?.employee?.name}-[${state?.employee?.employee_id}]`,value:state?.employee?._id}
        }

        setdata(setData)
        
     }
  },[state])



  async function searchData(v,type){ 
    if(type == 'client'){
      await axios.get(`fms/fmsclients/search?search_text=${v}&are_we_serving=1`,authHeader)
      .then((res)=>{
          let arr = []
          res?.data?.datas?.forEach(d => {
              arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
          });
          setclients(arr)
          return arr
      }).catch((err)=>{
      })
    }

    if(type == 'employee'){
     await axios.get(`fms/basicemployee/get_all?page=${1}&search=${v}`,authHeader)
      .then((res)=>{
        let arr = []
        res?.data?.datas?.forEach(d => {
          arr.push({label:`${d?.name}-[${d?.employee_id}]`,value:d?._id})
        });
        setemployees(arr)
      })
    }

    if(type == 'designation'){
      await axios.get(`fms/fmsdesignationonboardemployee/search?search_text=${v}`,authHeader)
      .then((res)=>{
          let arr = []
          res.data.datas.forEach((d)=>{
              let obj = {
                  value : d._id,
                  label:d.designation_name
              }
              arr.push(obj)
          })
          setdesignations(arr)
      }).catch((err)=>{
      })
     }
  
  }

  function handleChange(v,type){
    if(type == 'client'){
        setdata({...data,client:clients?.find((f)=>f?.value === v)})
        seterror({...error,client:''})
    }
    if(type == 'employee'){
      setdata({...data,employee:employees?.find((f)=>f?.value === v)})
      seterror({...error,employee:''})
    }
    if(type == 'designation'){
      setdata({...data,designation:designations?.find((f)=>f?.value === v)})
      seterror({...error,designation:''})
    }

    // if(type == 'employee'){
    //   if(v.length > 0){
    //     let arr = []
    //     v.forEach((v1)=>{
    //       let employeeFind = employees?.find((f)=>f?.value === v1)
    //       if(employeeFind !== null && employeeFind !== undefined){
    //         arr.push(employeeFind)
    //       }else{
    //         let employeeFind = data?.employee?.find((f)=>f?.value === v1)
    //         if(employeeFind !== null && employeeFind !== undefined){
    //           arr.push(employeeFind)
    //         }
    //       }
    //     })
    //     setdata({...data,employee:arr})
    //     seterror({...error,employee:''})
    //   }
    // }
  }

  async function submitData() {

    if(!data.employee){
      seterror({...error,employee:'This Field is required*'})
    }else if(!data.client){
      seterror({...error,client:'This Field is required*'})
    }else if(!data.designation){
      seterror({...error,designation:'This Field is required*'})
    }else if(!data.date_of_joining){
      seterror({...error,date_of_joining:'This Field is required*'})
    }else{
      let send_data = {...data}
      send_data['employee'] = data?.employee.value
      send_data['designation'] = data?.designation.value
      send_data['client'] = data?.client?.value 

      if(state?._id === undefined || state?._id === null){
        await axios.post(`fms/employee_rejoin/create`,send_data,authHeader)
        .then((res)=>{
            toast.success("Employee ReJoin Successfully!")
            setTimeout(() => {
            }, 1500);
            resetfrom()
        }).catch((err)=>{
            if(err?.response?.status === 403){
              seterror({...error,employee:err?.response?.data?.message})
            }
            if(err?.response?.data?.err?.keyValue?.employee !== undefined){
                seterror({...error,employee:'Oops something Error'})
            }
        })
      }else{
        send_data['status'] = data?.status?.value 

        await axios.put(`fms/employee_rejoin/update/${state?._id}`,send_data,authHeader)
        .then((res)=>{
            toast.success("Employee ReJoin Updated Successfully!")
            setTimeout(() => {
            }, 1500);
            navigation(-1)
            resetfrom()
        }).catch((err)=>{
          if(err?.response?.status === 403){
            seterror({...error,employee:err?.response?.data?.message})
          }
            toast.error(err?.response?.data?.message)
            if(err?.response?.data?.err?.keyValue?.employee !== undefined){
                seterror({...error,employee:'Oops something Error'})
            }
        })
      }

    }
    
  }

  function resetfrom(){
    setdata({employee:'',client:'',remarks:''})
    seterror({employee:'',client:'',remarks:''})
  }


 
  return (
    <FmsDashboardMainRoot>
        <Toaster />


        <div>
            
            <h6 style={{fontWeight:'800',fontSize:14,marginBottom:'4px'}}>Rejoin Request Employee / Update Designation </h6>
            <h6 style={{fontWeight:'400',fontSize:12.5,marginBottom:'4px',width:'27%',opacity:1}}>Use the below form to create or update the rejoin employee or update the designation for the clients based employee </h6>
             
            <div style={{margin:'10px 0px',width:'320px',}}>
            <div style={{width:'400px'}}>
              <SmallText fontWeight={'500'} val={'Employee'}  />
              <Select showSearch filterOption={false} value={data?.employee} mode='single' options={employees} onSearch={(v)=>searchData(v,'employee')} onChange={(e)=>handleChange(e,'employee')} style={{width:'80%',marginBottom:10,borderLeft:`4px solid ${theme_color}`}} />
            </div>

            <div style={{display:'flex'}}>
              {(error?.employee) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
              <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.employee}</p>
            </div>

            <div style={{width:'400px'}}>
              <SmallText fontWeight={'500'} val={'Client'}  />
              <Select showSearch filterOption={false} value={data?.client}  options={clients}  onSearch={(v)=>searchData(v,'client')} onChange={(e)=>handleChange(e,'client')}  style={{width:'80%',marginBottom:10,borderLeft:`4px solid ${theme_color}`}} />
            </div>

            <div style={{display:'flex'}}>
              {(error?.client) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
              <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.client}</p>
            </div>

            <div style={{width:'400px'}}>
              <SmallText fontWeight={'500'} val={'Designation'}  />
              <Select showSearch filterOption={false} value={data?.designation}  options={designations}  onSearch={(v)=>searchData(v,'designation')} onChange={(e)=>handleChange(e,'designation')}  style={{width:'80%',marginBottom:10,borderLeft:`4px solid ${theme_color}`}} />
            </div>

            <div style={{display:'flex'}}>
              {(error?.designation) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
              <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.designation}</p>
            </div>

            <div style={{marginBottom:'10px'}}>
              <div style={{width:'150px'}}>
              <SmallText fontWeight={'600'} val={'Date of Joining'}  />
              </div>
              <Input type="date" style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  value={data.date_of_joining} onChange={(e)=>setdata({...data,date_of_joining:e.target.value})} />
              <div style={{display:'flex'}}>
                {(error?.date_of_joining) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.date_of_joining}</p>
              </div>
            </div>

            

            <div style={{width:'400px'}}>
              <SmallText fontWeight={'500'} val={'Remarks'}  />
              <TextArea value={data?.remarks} onChange={(e)=>{setdata({...data,remarks:e.target.value});seterror({...error,remarks:''})}}  style={{width:'80%',marginBottom:10}} />
            </div>

            <div style={{display:'flex'}}>
              {(error?.remarks) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
              <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.remarks}</p>
            </div>

              {(roles?.includes('admin') || roles?.includes('fms_hr')) && state !== null &&
                <>
                <SmallText fontWeight={'600'} val={'Transfer Status'}  />
               <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
                <Select 
                    cacheOptions
                    value={data?.status?.label}
                    placeholder="" 
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    onChange={(v)=>setdata({...data,status:status?.find((f)=>f?.label == v)})}
                    options={status}
                    style={{ width: '100%', }}
                />
                
                </div>

                <div style={{width:'400px'}}>
                  <SmallText fontWeight={'500'} val={'HR Remarks'}  />
                  <TextArea value={data?.hr_remarks}   onChange={(e)=>{setdata({...data,hr_remarks:e.target.value});seterror({...error,hr_remarks:''})}}  style={{width:'80%',marginBottom:10}} />
                </div>

                <div style={{display:'flex'}}>
                  {(error?.hr_remarks) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                  <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.hr_remarks}</p>
                </div>
                
                </>}
            
            {(state?._id === undefined || (state?.status !== 'Completed' || roles?.includes('fms_hr'))) &&
            <div style={{width:'320px',display:'flex',flexDirection:'row',justifyItems:'flex-end',justifyContent:'flex-end',justifySelf:'flex-end'}}>
                <SecondaryButton btn_name={'Cancel'} onClick={()=>{navigation(-1)}} />
                <PrimaryButton btn_name={'Save'} onClick={submitData} />
            </div>}
            </div>

        </div>

    </FmsDashboardMainRoot>    
  )
}

export default ReJoinBasicEmployeeToClient