import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { useNavigate, } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import {BiEditAlt} from 'react-icons/bi'
import moment from 'moment';
import { Pagination, Tabs } from 'antd'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import { data_not_found } from '../../../helpers/Constants';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Toaster, toast } from 'react-hot-toast'
import {AiOutlineDelete} from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { BsSearch } from 'react-icons/bs';
import { MdOutlineRefresh } from 'react-icons/md'

const { TabPane } = Tabs;

function FmsExitEmployeelist() {
  
  const roles = useSelector(state => state.Auth.roles)
  const fcpl_id = useSelector(state => state.Auth.fcpl_id)

  const [search,setsearch] = useState('')

  const navigate = useNavigate()
  const [activetab,setactivetab] = useState('1')
  const [filterApplied,setfilterApplied] = useState(false)
  const [page,setpage] = useState(1)
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})


  useEffect(()=>{
    getdata()
  },[page])

  function setactivetabfunc(v){
    setpage(1)
    if(v == '1'){
      getdata('1',v)
    }else{
      getdata('1',v)
    }
    }

    function setpagefuun(v){
        setpage(v)
    }

    function getdata(v,v1){
        // console.log('page',page)
      axios.get(`fms/exitform/get/${v1?v1:activetab}?page=${v ? v : page}&search=${search}`,authHeader)
      .then((res)=>{
        setdata(res.data.data)
        setpagination(res.data.pagination)
      }).catch((err)=>{
        // console.log("err",err)
        if(err?.response?.status === 401){
        //    dispatch(crm_logout())
        }
      })
    }

    // console.log("activetab",activetab)

    function getfilterdata1(){
      // if(search === ''){
      //     getdata()
      // }else{
      setpage(1)
      axios.get(`fms/exitform/get/${activetab}?page=${1}&search=${search}`,authHeader)
      .then((res)=>{
          // console.log("res?.data",res?.data)
          setdata(res.data.data)
          setpagination(res.data.pagination)
      })
      // }
  }


    const deleteexitemployee = (v) =>{
      axios.delete(`fms/exitform/delete/${v}`,authHeader)
      .then((res)=>{
        toast.success(res?.data?.message)
        getdata()
      }).catch((err)=>{
        if(err?.response?.status === 401){
        //    dispatch(crm_logout())
        }
      })
    } 


    const uploadmass = () =>{
      axios.post(`fms/exitform/update_many`,{},authHeader)
      .then((res)=>{
        toast.success(res?.data?.message)
        getdata()
      }).catch((err)=>{
        if(err?.response?.status === 401){
        //    dispatch(crm_logout())
        }
      })
    }

    function applyFilter(){
      setpage(1)
      // if(search?.length > 2){
      //     setfilterApplied(true)
      // }
  }

  function resetFilter(){
      setsearch('')
      setpage(1)
      axios.get(`fms/exitform/get/${activetab}?page=${1}&search=${''}`,authHeader)
      .then((res)=>{
          // console.log("res?.data",res?.data)
          setdata(res.data.data)
          setpagination(res.data.pagination)
      })
  }


  return (
    <FmsDashboardMainRoot>
      <Toaster />
       <div style={{width:'96%'}}>
            <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
              <BoldText1 val={`Exit Employees Form`} />
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:'10px'}}>
              <input value={search} onChange={(e)=>setsearch(e.target.value)} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} onKeyPress={(e)=>e.key === 'Enter' && getfilterdata1()} /> 
              <BsSearch onClick={()=>{applyFilter();getfilterdata1()}} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
              <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
              </div>

              <PrimaryButton btn_name={'Add Exit Form'} onClick={()=>navigate('/fms/exit_employee',{state:null})} />
              {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
              <>
              <h6 style={{opacity:0}}>H</h6> 
              <PrimaryButton btn_name={'Update Approved Mass'} onClick={()=>uploadmass()} />
              </>
               }
               </div>
            </div>
            <Tabs
                size="small"
                activeKey={activetab}
                activetab={activetab}
                defaultValue={activetab}
                style={{ width: '100%', margin: '-10px 0px', marginBottom: '0px', position: 'sticky', top: '40px' }}
                onChange={(v) =>{setactivetab(v);setactivetabfunc(v)}}>
                    <TabPane tab="Approved" key="1">
                           <BoldText1 val={`Approved Employee (${pagination.total})`} fontSize={'12px'}  />   

                          {data.length > 0 ? 
                          <>
                            <div style={{border:'1px solid #eaeded',marginTop:'20px', width:'100%' }}>
                              <div className='mateial_table_header' >
                                      <p className='checked_here' style={{opacity:0}} ></p> 
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '4%', color: 'black',marginLeft:'-10px' }}>Sl No</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '12%', marginLeft:'2px' }}>Employee Name</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '12%', marginLeft:'2px' }}>Onboarded By</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '12%', marginLeft:'2px' }}>Exited By</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '10%', marginLeft:'0.5%' }}>DOE</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '10%', marginRight: '0.5%',paddingLeft:'10px' }}>Status</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '10%', marginRight: '0.5%' }}>Reporting To</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '10%', marginRight: '1%' }}>Created At</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '12%', marginRight: '0.5%' }}> Approval Pending</p>
                                      <p style={{minWidth: '8%' }}>Actions</p>
                              </div>
                              {data.map((d, i) => (
                                  <div key={i} className={'mateial_table_body'} style={{borderTop:'1px solid #eaeded',}}>
                                      <p   className='checked_here' style={{opacity:0}}>
                                      </p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '3.8%', color: 'black',marginLeft:'-20px',fontSize:'10px' }}>{page > 1 ? ((i+1) + ((page-1) * pagination.limit)) : i+1}</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '11.8%',maxWidth:'11.8%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px'}}>{d?.employee?.name}</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '11.8%',maxWidth:'11.8%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.employee?.created_by_fms_in_employee?.name}</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '11.7%',maxWidth:'11.7%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px'}}>{d?.fmsemployee?.name}</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'7px !important',fontWeight:'400', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px'}}>{moment(d?.doe).format('ll')}</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '9.6%' ,maxWidth:'9.6%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'0px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.exit_status}</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '10.1%',marginLeft:'30px',textAlign:'start',maxWidth:'12.4%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d?.reporting_to}</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '9.9%',maxWidth:'10%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'2px',marginRight: '1%'  }}>{moment(d?.createdAt).format('ll')}</p>
                                      <p style={{borderRight:'1px solid #eaeded', minWidth: '11.7%',maxWidth:'11.7%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'4px',marginRight: '1%'  }}>{!d?.department_head && 'HOD , '} {!d?.vp && 'VP , '} {!d?.operation_head && 'Ops Head , '} {!d?.human_resource_head && 'HR , '} {!d?.head_finance_accounts && 'Finance , '} {!d?.asset_head && 'Assets'}</p>

                                      {!roles.includes('fms_data_view') &&
                                      <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                          <Tooltip title='Edit'>
                                          <span>
                                          <BiEditAlt onClick={()=>navigate(`/fms/exit_employee`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                                          </span>
                                          </Tooltip>

                                          {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                          <Tooltip title='Delete'>
                                          <span>
                                          <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />
                                          </span>
                                          </Tooltip>}
                                          
                                        
                                      </p>}
                                  </div>
                              ))}
                            </div> 

                            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                  {pagination.total > 15 &&
                                  <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                  }
                            </div>
                          </>
                          :
                          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                              <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any Exit Employee Form created yet"}</h4>
                              <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see exit employee list added by you click on the add button and create a new exit form and submit"}</h4>
                          </div>}
                    </TabPane>
                    <TabPane tab="Not Approved" key="2">
                         <BoldText1 val={`Not Approved Employee (${pagination.total})`} fontSize={'12px'}  />   

                         {data.length > 0 ? 
                          <>
                          <div style={{border:'1px solid #eaeded',marginTop:'20px', width:'100%' }}>
                            <div className='mateial_table_header' >
                                    <p className='checked_here' style={{opacity:0}} ></p> 
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '4%', color: 'black',marginLeft:'-10px' }}>Sl No</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '12%', marginLeft:'2px' }}>Employee Name</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '12%', marginLeft:'2px' }}>Onboarded By</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '12%', marginLeft:'2px' }}>Exited By</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '10%', marginLeft:'0.5%' }}>DOE</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '10%', marginRight: '0.5%',paddingLeft:'10px' }}>Status</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '10%', marginRight: '0.5%' }}>Reporting To</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '10%', marginRight: '1%' }}>Created At</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '12%', marginRight: '0.5%' }}> Approval Pending</p>
                                    <p style={{minWidth: '8%' }}>Actions</p>
                            </div>
                            {data.map((d, i) => (
                                <div key={i} className={'mateial_table_body'} style={{borderTop:'1px solid #eaeded',}}>
                                    <p   className='checked_here' style={{opacity:0}}>
                                    </p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '3.8%', color: 'black',marginLeft:'-20px',fontSize:'10px' }}>{page > 1 ? ((i+1) + ((page-1) * pagination.limit)) : i+1}</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '11.8%',maxWidth:'11.8%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px'}}>{d?.employee?.name}</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '11.8%',maxWidth:'11.8%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.employee?.created_by_fms_in_employee?.name}</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '11.7%',maxWidth:'11.7%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px'}}>{d?.fmsemployee?.name}</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'7px !important',fontWeight:'400', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px'}}>{moment(d?.doe).format('ll')}</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '9.6%' ,maxWidth:'9.6%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'0px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.exit_status}</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '10.1%',marginLeft:'30px',textAlign:'start',maxWidth:'12.4%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d?.reporting_to}</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '9.9%',maxWidth:'10%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'2px',marginRight: '1%'  }}>{moment(d?.createdAt).format('ll')}</p>
                                    <p style={{borderRight:'1px solid #eaeded', minWidth: '11.7%',maxWidth:'11.7%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'4px',marginRight: '1%'  }}>{!d?.department_head && 'HOD , '} {!d?.vp && 'VP , '} {!d?.operation_head && 'Ops Head , '} {!d?.human_resource_head && 'HR , '} {!d?.head_finance_accounts && 'Finance , '} {!d?.asset_head && 'Assets'}</p>

                                    {!roles.includes('fms_data_view') &&
                                    <p style={{ width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                        <Tooltip title='Edit'>
                                        <span>
                                        <BiEditAlt onClick={()=>navigate(`/fms/exit_employee`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                                        </span>
                                        </Tooltip>

                                        {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                        <Tooltip title='Delete'>
                                        <span>
                                        <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />
                                        </span>
                                        </Tooltip>}
                                        
                                      
                                    </p>}
                                </div>
                            ))}
                          </div> 

                          <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                {pagination.total > 15 &&
                                <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                                }
                          </div>
                        </>
                          :
                          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                              <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any Exit Employee Form created yet"}</h4>
                              <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see exit employee list added by you click on the add button and create a new exit form and submit"}</h4>
                          </div>}
                    </TabPane>
                 

            </Tabs>
       </div>
    </FmsDashboardMainRoot>
  )
}

export default FmsExitEmployeelist