import React, { useEffect, useState } from 'react'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { useSelector } from 'react-redux';
import { Col, Row,Tag,Pagination } from 'antd';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';
import moment from 'moment';
import { Toaster, toast } from 'react-hot-toast';


function MonthlyCalendarTask() {

  const navigate = useNavigate()

  const roles = useSelector(state=>state.Auth.roles)
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)

  const user = useSelector(state=>state.Auth)
  // console.log("user",user)
  // console.log("roles",roles)

  useEffect(()=>{
    getData()
  },[])


  async function getData(){ 
    await axios.get(`fms/monthly_calendar_task/get`,authHeader)
     .then((res)=>{
      console.log("res?.data",res?.data)
      setdata(res?.data?.datas)
      setpagination(res?.data?.pagiantion)
        
     }).catch((err)=>{
     })
   }

   async function deleteData(v){
    await axios.delete(`fms/monthly_calendar_task/delete/${v}`,authHeader)
    .then((res)=>{
      console.log("res data here",res?.data)
      getData()
      toast.success("Deleted Successfully")
    }).catch((err)=>{
    })
   }

   function setpagefuun(v){
    setpage(v)
   }


  return (
    <FmsDashboardMainRoot>
      <Toaster />
        <div style={{width:'98%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid #eee'}}>
            <BoldText1 val={`Monthly Calendar Task (${pagination?.total})`} />
            {['fms_hr','fms_manager','fms_crm_head','admin'].filter((f)=>roles?.includes(f)).length > 0 &&
            <PrimaryButton btn_name={'Monthly Task'} onClick={()=>navigate('/fms/monthly_calendar_task_ce')} />}
            </div>

            <Row style={{marginTop:'10px'}}>
              {data?.map((d)=>(
              <Col span={4} style={{border:'1px solid #eee',padding:'5px',margin:'2px',position:'relative'}}>
                 <AiOutlineDelete onClick={()=>deleteData(d?._id)} fontSize={11} style={{position:'absolute',right:20,top:2,background:'white',cursor:'pointer'}} />
                 <AiOutlineEdit onClick={()=>navigate('/fms/monthly_calendar_task_ce',{state:d})} fontSize={11} style={{position:'absolute',right:2,top:2,background:'white',cursor:'pointer'}} />
                 {/* <h6 style={{fontSize:'12.5px',marginTop:'15px'}}>{d?.assigned_user_type == 'InHouse-Employee' ? 'Field Officer' : 'Deployed Officer'} : <span style={{fontWeight:'600',fontSize:'11px'}}>{d?.assigned_user_type == 'InHouse-Employee' && d?.fms_in_house_employee?.name}{d?.assigned_user_type == 'Deployed Employee' && d?.fms_deployed_employee?.name}</span> </h6> */}
                 <Tag style={{fontSize:'10px',marginBottom:'10px'}}>Client : {d?.client?.client_name}</Tag>

                 <h6 style={{fontSize:'11px'}}>Assigned To: {d?.assigned_user_type}</h6>
                 <h6 style={{fontWeight:'800',fontSize:11}}>{d?.assigned_user_type == 'InHouse-Employee' ? d?.fms_in_house_employee?.name : d?.fms_deployed_employee?.name}</h6>
                 <h6 style={{fontWeight:'800',fontSize:11}}>Date : {moment(d?.date)?.format('LLL')}</h6>
                 <h6 style={{fontWeight:'800',fontSize:11}}>Status : {d?.status}</h6>

                 
                 <h6 style={{fontSize:'8px',background:'#fafafa',padding:4,marginTop:10}}>Created At : <span style={{marginLeft:'4px',fontWeight:'600'}}>{moment(d?.createdAt)?.format('LL')}</span></h6>
              </Col>
              ))}
            </Row>

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination?.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
            </div>




        </div>
    </FmsDashboardMainRoot>
  )
}

export default MonthlyCalendarTask