import React,{useEffect, useState} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { useLocation, useNavigate, } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import TextArea from 'antd/lib/input/TextArea';
import { SmallText } from '../../../helpers/constants/ConstantsText';
import { Button, Input } from 'antd';
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {Select} from 'antd'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { theme_color } from '../../../helpers/constants/ConstantColor';
import { Upload,} from 'antd';
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { base_url } from '../../../App';
import { useSelector } from 'react-redux';


function FmsUniformRequestCE() {
  

  const {state} = useLocation()

  const roles = useSelector(state => state.Auth.roles)
  
  console.log("roles here",roles)



  const navigate = useNavigate()
  const statusArr = [{label:'Pending',value:'Pending'},{label:'Progress',value:'Progress'},{label:'Completed',value:'Completed'}]
  const [data,setdata] = useState({uniform_required:false,id_required:false,uniform_info:'',remarks:'',status:''})
  const [error,seterror] = useState({uniform_required:"",id_required:"",uniform_info:'',remarks:'',status:''})


  useEffect(()=>{

    let passData = {
      ...state 
    }
    setdata(passData)


  },[state])

  function submitformfun(){

    if(!data.uniform_info){
      seterror({...error,uniform_info:'This Field is required!'})
    }else{
      let send_data = {
        ...data
      }
      delete send_data.created_by

      authHeader['headers']['Content-Type'] = 'application/json'

      axios.put(`fms/uniform_request/update/${data._id}`,send_data,authHeader)
        .then((res)=>{
            toast.success("Update Successfully!!");
            resetform();
        }).catch((err)=>{
            if(err?.response?.status == 403){
                toast.error(err?.response?.data?.message)
            }
        })
    }

  }


  function resetform(){
    navigate(-1)
    setdata({uniform_required:false,id_required:false,uniform_info:'',remarks:'',status:''})
    seterror({iuniform_required:"",id_required:"",uniform_info:'',remarks:'',status:''})
  }


  function uploadFile(v){
    authHeader['headers']['Content-Type'] = 'multipart/form-data'
    var fd = new FormData()
    fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
    axios.post(`fms/warning_letter/upload_file`,fd,authHeader)
    .then((res)=>{
      setdata({...data,pdf_file:res?.data?.data})
      seterror({...error,pdf_file:''})
    }).catch((err)=>{
        console.log("err data",err)
    })
  }

  

  const disabledComapre = ((roles?.includes('fms_hr') || roles?.includes('admin') || roles?.includes('fms_vendor') || roles?.includes('fms_vendor')) || data?.status == 'Pending')


  console.log("disabledComapre here im : ",disabledComapre)
  return (
    <FmsDashboardMainRoot>
      <Toaster />
       <div style={{width:'25%'}}>
       <h6>Uniform Raised Request</h6>
       <h6 style={{fontSize:'10px',background:'#3d7aba',fontWeight:'600',color:'#fff',padding:6}}>Use the below form to create or update the uniform raised request of an onboarded employee</h6>
         
         

        <div style={{marginBottom:'10px'}}>
            <div style={{width:'150px',marginTop:'10px'}}>
            <SmallText fontWeight={'600'} val={'Uniform Info'}  />
            </div>
            <TextArea  readOnly={!disabledComapre} type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.uniform_info} onChange={(e)=>{setdata({...data,uniform_info:e.target.value});seterror({...error,uniform_info:''})}} />
        </div>
        <div style={{display:'flex'}}>
            {(error?.uniform_info) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.uniform_info}</p>
        </div>

      

      

        {/* {(roles?.includes('fms_hr') || roles?.includes('admin') || roles?.includes('fms_vendor') || roles?.includes('fms_vendor')) &&  */}
        <>

          <div style={{marginBottom:'10px'}}>
              <div style={{width:'150px',marginTop:'10px'}}>
              <SmallText fontWeight={'600'} val={'Remarks'}  />
              </div>
              <TextArea readOnly={!disabledComapre} type="text"  style={{borderLeft:'4px solid #292961'}}  value={data.remarks} onChange={(e)=>{setdata({...data,remarks:e.target.value});seterror({...error,remarks:''})}} />
          </div>
          <div style={{display:'flex'}}>
              {(error?.remarks) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
              <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.remarks}</p>
          </div>

          <div style={{marginTop:'10px',display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <SmallText fontWeight={'600'} val={'Status'}  />
            {state?.status !== 'Completed' &&
            <h6 onClick={()=>navigate(`/fms/uniform_assign/${data?.employee?._id}`)} style={{cursor:'pointer',fontSize:'11px',fontWeight:'800',textDecorationLine:'underline',color:'#4d86d1'}}>Assign Uniform</h6>
            }
          </div>
          <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961',}}>
            <Select 
              showSearch
              filterOption={false}
              style={{ width: '100%',boxShadow:'none' }}
              value={data?.status === '' ? null : data?.status}
              options={statusArr}
              onChange={(v)=>{disabledComapre && setdata({...data,status:v});seterror({...error,status:''})}}
            />
          </div>
        </>
        {/* } */}


          
          <div style={{marginTop:'14px',marginRight:'10px',display:'flex'}}>
            <p className={data.uniform_required ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>disabledComapre && setdata({...data,uniform_required:!data.uniform_required}) }>
              {data.uniform_required && <p className='checked_here1' style={{background:theme_color}}></p>}
            </p>
            <SmallText fontWeight={'600'} val={'Uniform Required'}  />
          </div>

          <div style={{marginTop:'8px',marginRight:'10px',display:'flex'}}>
            <p className={data.id_required ? 'checked_here2': 'checked_here'} style={{marginRight:'10px'}} onClick={()=>disabledComapre && setdata({...data,id_required:!data.id_required}) }>
              {data.id_required && <p className='checked_here1' style={{background:theme_color}}></p>}
            </p>
            <SmallText fontWeight={'600'} val={'ID Required'}  />
          </div>

      


        {disabledComapre && state?.status !== 'Completed' &&
        <div style={{marginLeft:'0px',display:'flex',width:'100px',justifyContent:'space-between'}}>
          <PrimaryButton btn_name="Submit"  onClick={()=>submitformfun()}/>
          <SecondaryButton btn_name={"Clear"} onClick={()=>{resetform()}} />
        </div>}
          



       </div>
    </FmsDashboardMainRoot>
  )
}

export default FmsUniformRequestCE