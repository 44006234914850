import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { Drawer, Input,Pagination, Select } from 'antd'
import { IoIosClose } from 'react-icons/io';
import { theme_color } from '../../../helpers/constants/ConstantColor'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { useLocation } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import { Col, Row } from 'antd';
import moment from 'moment';
import {AiOutlineEdit,AiOutlineScan,AiOutlineDelete} from 'react-icons/ai'
import { base_url, local_base_url } from '../../../App'
import FileDownload from 'js-file-download';

function FmsClientsPoints() {

  const {state} = useLocation()
  

  const [modal,setmodal] = useState(false)
  const [data,setdata] = useState({name:'',lats:'',long:'',reminder_slots:[],scan_time_slots:[]})
  const [error,seterror] = useState({name:'',lats:'',long:'',reminder_slots:'',scan_time_slots:''})
  const [datas,setdatas] = useState([])
  const [selectedChecklist,setselectedcheckList] = useState([])
  const [checkList,setcheckList] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)


  const scanSlots = [
    {label:'05:00-07:00',value:'05:00-07:00'},
    {label:'07:00-09:00',value:'07:00-09:00'},
    {label:'09:00-11:00',value:'09:00-11:00'},
    {label:'11:00-13:00',value:'11:00-13:00'},
    {label:'11:00-12:55',value:'11:00-12:55'},
    {label:'13:00-15:00',value:'13:00-15:00'},
    {label:'15:00-17:00',value:'15:00-17:00'},
    {label:'17:00-19:00',value:'17:00-19:00'},
    {label:'19:00-21:00',value:'19:00-21:00'},
    {label:'21:00-23:00',value:'21:00-23:00'},
    {label:'23:00-01:00',value:'23:00-01:00'},
    {label:'01:00-03:00',value:'01:00-03:00'},
    {label:'03:00-05:00',value:'03:00-05:00'}
  ]

  const timeSlots = [
    {label:'02:00',value:'02:00'},
    {label:'03:00',value:'03:00'},
    {label:'04:00',value:'04:00'},
    {label:'05:00',value:'05:00'},
    {label:'06:00',value:'06:00'},
    {label:'07:00',value:'07:00'},
    {label:'08:00',value:'08:00'},
    {label:'09:00',value:'09:00'},
    {label:'10:00',value:'10:00'},
    {label:'11:00',value:'11:00'},
    {label:'12:00',value:'12:00'},
    {label:'13:00',value:'13:00'},
    {label:'14:00',value:'14:00'},
    {label:'15:00',value:'15:00'},
    {label:'16:00',value:'16:00'},
    {label:'17:00',value:'17:00'},
    {label:'18:00',value:'18:00'},
    {label:'19:00',value:'19:00'},
    {label:'20:00',value:'20:00'},
    {label:'21:00',value:'21:00'},
    {label:'22:00',value:'22:00'},
    {label:'23:00',value:'23:00'},
    {label:'24:00',value:'24:00'},
  ]

  useEffect(()=>{
    getData()
    getCheckList()
  },[page])


  function getData(){
    axios.get(`fms/fmsclientspoints/get/${state._id}?page=${page}`,authHeader).then((res)=>{
            setpagination(res.data.pagination)
            setdatas(res.data.datas)
            }).catch((err)=>{
            })
  }

  async function getCheckList(){
    await axios.get(`fms/scancheck_list/get?page=${1}&limit_count=1000`,authHeader)
    .then((res)=>{
      setcheckList(res?.data?.datas)
    }).catch((err)=>{
    })
  }


  function setpagefuun(v){
    setpage(v)
  }

  function submitform(){
    if(!data.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data.lats){
        seterror({...error,lats:'This Field is required*'})
    }else if(!data.long){
        seterror({...error,long:'This Field is required*'})
    }else{
        let arr = []

        selectedChecklist?.forEach((f)=>{
          arr.push(f?._id)
        })
        let send_Data = {...data}
        send_Data['client'] = state._id
        send_Data['check_point_list'] = arr
        send_Data['scan_time_slots'] = data?.scan_time_slots
        send_Data['reminder_slots'] = data?.reminder_slots


        if(data?.id === null || data?.id === undefined){
         axios.post(`fms/fmsclientspoints/create`,send_Data,authHeader).then((res)=>{
            if(res.status === 201){
                toast.success(res?.data?.message)
                setdata({name:'',lats:'',long:'',scan_time_slots:[],reminder_slots:[]})
                seterror({name:'',lats:'',long:'',scan_time_slots:'',reminder_slots:''})
                setmodal(false)
                getData()
            }
            }).catch((err)=>{
            })
        } else{
            axios.put(`fms/fmsclientspoints/update/${data?.id}`,send_Data,authHeader).then((res)=>{
                if(res.status === 200){
                    toast.success(res?.data?.message)
                    setdata({name:'',lats:'',long:'',scan_time_slots:[],reminder_slots:[]})
                seterror({name:'',lats:'',long:'',scan_time_slots:'',reminder_slots:''})
                    setmodal(false)
                    getData()
                }
                }).catch((err)=>{
                })
        }  
    }
  }


  async function downloadqrCode(d){
        let url = `${base_url}${d?.file}`

            axios.get(url,{ responseType: 'blob',})
            .then((res)=>{
                FileDownload(res.data, 'scanner.png');
            })
    }

  function deletedata(id){
    axios.delete(`fms/fmsclientspoints/delete/${id}`,authHeader).then((res)=>{

        if(res.status === 200){
            toast.success(res?.data?.message)
            getData()
            setmodal(false)
        }
        }).catch((err)=>{
        })
  }

  function setsetlectedcheckList(v){

    if(selectedChecklist?.length > 0){
      console.log("selectedChecklist.includes((f)=>f?._id == v?._id)",selectedChecklist.includes((f)=>f?._id == v?._id))
      if(selectedChecklist.filter((f)=>f?._id == v?._id)?.length > 0){
        let arr = selectedChecklist.filter(f=>f?._id != v?._id)
        setselectedcheckList(arr)
      }else{
        let arr = [...selectedChecklist]
        arr?.push(v)
        setselectedcheckList(arr)
      }

    }else{
      let arr = []
      arr.push(v)
      setselectedcheckList(arr)
    }

  }

  console.log("selectedcheckList",selectedChecklist)

  return (
    <FmsDashboardMainRoot>
        <Toaster/>
        <Drawer visible={modal} footer={false} closable={false} maskClosable={true}>
            <div style={{position:'relative',overflowY:'scroll'}}>
            <IoIosClose  onClick={() => setmodal(false)}size={20} style={{position:'absolute',top:'-20px',cursor:'pointer',right:'-10px'}} />
                <h6 style={{background:'#eee',padding:'4px',fontSize:'11px',fontWeight:'400',marginTop:'10px'}}>Use the below form to create or update the client points for performance</h6>


                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Point Name'}  />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.name} onChange={(e)=>setdata({...data,name:e.target.value})} />
                </div>

                <div style={{display:'flex',flexDirection:'row !important'}}>
                {(error?.name) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.name}</p>
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Latitude '}  />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.lats} onChange={(e)=>setdata({...data,lats:e.target.value})} />
                </div>

                <div style={{display:'flex',flexDirection:'row !important'}}>
                {(error?.lats) &&  
                <span style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}><AiOutlineInfoCircle size={14} style={{color:'red'}}/> {error?.lats}</span>}
                </div>

                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Longitude '}  />
                </div>
                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.long} onChange={(e)=>setdata({...data,long:e.target.value})} />
                </div>

                <div style={{display:'flex',flexDirection:'row !important'}}>
                {(error?.long) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.long}</p>
                </div>


                <div style={{marginBottom:'10px'}}>
                <div style={{width:'150px'}}>
                <SmallText fontWeight={'600'} val={'Check List Section '}  />
                </div>
                
                <div style={{display:'flex',flexWrap:'wrap'}}>
                {checkList?.map((c)=>(
                 <h6 onClick={()=>setsetlectedcheckList(c)} style={{fontSize:'12px',padding:'4px 10px',margin:'4px',cursor:'pointer',border:'1px solid #ddd',textTransform:'capitalize',backgroundColor:selectedChecklist?.filter((f)=>f?._id == c?._id)?.length > 0 ? theme_color : '#fff',color:selectedChecklist?.filter((f)=>f?._id == c?._id)?.length > 0 ? '#fff' : '#000',fontWeight:'400'}}>{c?.name}</h6> 
                ))}
                </div>

                <div style={{display:'flex',flexDirection:'column',flexWrap:'wrap',marginTop:'5px'}}>
                <SmallText fontWeight={'600'} val={'Scan Slots '}  />
                <Select 
                  value={data?.scan_time_slots}
                  onChange={(v)=>{setdata({...data,scan_time_slots:v});seterror({...error,scan_time_slots:''})}}
                  mode='multiple'
                  options={scanSlots}
                />
                </div>

                <div style={{display:'flex',flexDirection:'row !important'}}>
                {(error?.scan_time_slots) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.scan_time_slots}</p>
                </div>

                <div style={{display:'flex',flexDirection:'column',flexWrap:'wrap',marginTop:'5px'}}>
                <SmallText fontWeight={'600'} val={'Reminder Slots '}  />
                <Select 
                  value={data?.reminder_slots}
                  onChange={(v)=>{setdata({...data,reminder_slots:v});seterror({...error,reminder_slots:''})}}
                  mode='multiple'
                  options={timeSlots}
                />
                </div>

                <div style={{display:'flex',flexDirection:'row !important'}}>
                {(error?.reminder_slots) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.reminder_slots}</p>
                </div>

                </div>


                <div style={{display:'flex'}}>
                    <PrimaryButton btn_name={'Save'} onClick={submitform}/>
                    <SecondaryButton btn_name={'Close'} onClick={()=>setmodal(false)}/>
                </div>


            </div>

        </Drawer>
        <div style={{width:'98%'}}>
            <div style={{borderBottom:'1px solid #ddd',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
            <BoldText1 val={`Clients Scan Points (${pagination?.total})`} />

            <PrimaryButton btn_name={'Add Point'} onClick={()=>setmodal(true)} />
            </div>

            <Row style={{marginTop:'5px'}}>
            {datas?.map((d)=>(
              <Col span={6}>
                <div style={{border:'1px solid #eee',padding:'6px 12px',width:'98%',margin:'1%',position:'relative'}}>
                    <span style={{position:'absolute',right:'5px',top:'0px'}}><AiOutlineDelete onClick={()=>deletedata(d?._id)} size={12}  style={{marginRight:'4px'}} /> <AiOutlineEdit onClick={()=>{setdata({...data,id:d?._id,name:d?.name,lats:d?.lats,long:d?.long,reminder_slots:d?.reminder_slots !== undefined ? d?.reminder_slots : [],scan_time_slots:d?.scan_time_slots !== undefined ? d?.scan_time_slots : []});setselectedcheckList(d?.check_point_list);setmodal(true)}} size={12}  style={{marginRight:'4px'}} /> <AiOutlineScan size={12} onClick={()=>downloadqrCode(d)} /></span>
                    <h6 style={{fontSize:'11px',marginBottom:'2px'}}>Name : <span style={{fontWeight:'700'}}>{d?.name}</span></h6>
                    <h6 style={{fontSize:'11px'}}>Latitude /Longitude : <span style={{fontWeight:'700'}}>{d?.lats} </span>/ <span style={{fontWeight:'700'}}>{d?.long} </span></h6>
                    <h6 style={{fontSize:'11px'}}>Total Check List : <span style={{fontWeight:'700'}}>{d?.check_point_list?.length}</span></h6>
                    <h6 style={{fontSize:'10px',background:'#f2f2f2',padding:'4px'}}>Created At : <span style={{fontWeight:'700'}}>{moment(d?.createdAt)?.format('LLL')}</span></h6>
                </div>
              </Col>
            ))}
            </Row>

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                }
            </div>
        </div>

    </FmsDashboardMainRoot>
  )
}

export default FmsClientsPoints