import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { Pagination, Select } from 'antd'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { useLocation, useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import {AiOutlineEdit} from 'react-icons/ai'
import { MdOutlineRefresh,MdFence } from 'react-icons/md'

function FmsClientsSalaryStatement() {

  const {state} = useLocation()
  const navigate = useNavigate()

  const [modal,setmodal] = useState(false)
  const [data,setdata] = useState({designation:'',basic:''})
  const [error,seterror] = useState({designation:'',basic:''})
  const [datas,setdatas] = useState([])
  const [fmsemployee_designation,setfmsemployee_designation] = useState([])
  const [search,setsearch] = useState('')
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)


  useEffect(()=>{
    getData()
  },[page,search])


  function getData(){
    axios.get(`fms/fmsclientsbasedsalarystructure/get/${state}?page=${page}&designation=${search?.value !== undefined ? search?.value : ''}`,authHeader).then((res)=>{
            setpagination(res.data.pagination)
            setdatas(res.data.datas)
            }).catch((err)=>{
                console.log("err",err?.response?.data)
            })
  }

  async function searchdesignation(e){
    if(e?.length > 2){
        await axios.get(`fms/fmsdesignationonboardemployee/search?search_text=${e}`,authHeader)
        .then((res)=>{
            let arr = []
            res.data.datas.forEach((d)=>{
                let obj = {
                    value : d._id,
                    label:d.designation_name
                }
                arr.push(obj)
            })
            setfmsemployee_designation(arr)

            return arr.filter((i) =>
                i.label.toLowerCase().includes(e.toLowerCase())
                );

        }).catch((err)=>{
        })
    }
  }

 


  async function searchData(){
    setpage(1)
    axios.get(`fms/fmsclientsbasedsalarystructure/get/${state}?page=${1}&designation=${search?.value !== undefined ? search?.value : ''}`,authHeader).then((res)=>{
        setpagination(res.data.pagination)
        setdatas(res.data.datas)
        }).catch((err)=>{
            console.log("err",err?.response?.data)
        })
  }


  function setpagefuun(v){
    setpage(v)
  }

 


  return (
    <FmsDashboardMainRoot>
        <Toaster/>
     
       
       
        <div style={{width:'98%'}}>
            <div style={{borderBottom:'1px solid #ddd',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
            <BoldText1 val={`Clients Salary Statement (${pagination?.total})`} />

            <div style={{display:'flex',flexDirection:'row',alignItems:'center',}}>
             <Select allowClear={true} filterOption={false} options={fmsemployee_designation} showSearch={true} onSearch={(e)=>{searchdesignation(e)}}  style={{width:'200px',zIndex:100,fontSize:'13px',marginRight:'10px',fontWeight:'600'}}  value={search}  onChange={(e)=>{setsearch(fmsemployee_designation.find((c)=>c?.value === e))}} />
             <MdFence onClick={()=>{searchData('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
             <MdOutlineRefresh onClick={()=>{setpage(1);setsearch('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
             
             <PrimaryButton btn_name={'Add Salary Statement'} onClick={()=>navigate('/fms/fms_clients/salary_statement/create',{state:state})} />
            </div>
            </div>

             <div style={{marginTop:'10px',display:'flex',flexDirection:'row',overflow:'hidden'}}>
                <div style={{minWidth:'35%',width:'35%',maxWidth:'35%',borderLeft:'1px solid #ddd',borderTop:'1px solid #ddd'}}>

                   <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff',height:'40px'}}>Designation</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Basic</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>DA</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Services weightage - 2% on Basic DA</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Washing Allowance(Fixed)</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>HRA - Basic DA * 40%</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Advance Bonus 8.33%  for Monthly - Basic DA * 8.33%</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>CCA @5% Basic & DA </h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Privileged Leaves - Basic DA for 18 days </h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Special Allowance</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Other Allowance</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd',}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>Total Gross</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',height:'50px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>PF Employee 12 % - 15000/12% - (Restricted Mode) (Basic DA + Leave Encashment (If Provided Monthly) + Special Allowances + Conveynence)*0.12 - (Unrestricted Mode)</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',height:'50px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>ESI Employee 0.75 % - (Gross Wages - Washing Allowances)*0.75%</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>PT</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>LWF Ee</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Total Deduction</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Reliever Charges</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>OT Wages </h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>Net Pay(Take Home Salary)</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>PF Employer 13 % - 15000/13% - (Restricted Mode) </h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>ESI Employer 3.25% - (Gross Wages - Washing Allowances)*3.25%</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>LWF Er</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Health Insurance</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>EC Policy</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Outskirts allowance</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Training Charges</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Accidental Coverage</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Uniform</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Gratuity - 4.81% on Basic Wages</h6>
                    </div>


                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Employer Contribution </h6>
                    </div>


                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>One Time Payment PVC & BVG</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>Total</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Management Fee @  12%</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Grand Total (CTC)</h6>
                    </div>

                    <div style={{borderBottom:'1px solid #ddd'}}>
                        <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Over Time Duty per hour</h6>
                    </div>



                </div>
                
                <div style={{width:'75vw',overflowX:'scroll',display:'flex',flexDirection:'row'}}>
                    {datas?.map((d,i)=>(
                    <div key={i} style={{minWidth:'15%',width:'15%',maxWidth:'15%',borderLeft:'1px solid #ddd',borderTop:'1px solid #ddd'}}>
                        {/* {console.log("d here anna",d)} */}

                        <div style={{borderBottom:'1px solid #ddd',position:'relative'}}>
                            <AiOutlineEdit size={16} style={{marginRight:'10px',position:'absolute',padding:'4px',color:'#fff',cursor:'pointer',right:'-10px',background:'#5b698f'}} onClick={()=>navigate('/fms/fms_clients/salary_statement/edit',{state:d})} />
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',height:'40px'}}>{d?.designation?.designation_name} / {JSON.stringify(d?.is_approved)}</h6>
                        </div>
                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.basic}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.da}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.service_weightage_percent_da}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.washing_allownace}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.hra_is_basic_da_40_per}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.advance_bonus_basic_da_833percent}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.cca_5per_basic_da}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.privileged_leaves_basic_da_18days}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.special_allowance}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.other_allowance}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd',}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>{d?.total_gross}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',height:'50px'}}>{d?.pf_employee_12per}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',height:'50px'}}>{d?.esi_emplyee075per}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.pt}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.lwf_ee}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',}}>{d?.total_deduction}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.reliever_charges}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',}}>{d?.ot_wages}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>{d?.net_pay_take_home_salary}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.pf_employee_13per}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.esi_emplyee325per}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.lwf_er}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.health_insurance}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.ex_policy}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.outskirts_allowance}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.training_charges}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.accidental_coverage}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.uniform}</h6>
                        </div>


                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.gratuity_481per_basic_wages}</h6>
                        </div>


                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',}}>{d?.employer_contribution}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.one_time_payment_pvc_and_bvg !== undefined ? d?.one_time_payment_pvc_and_bvg : 0}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>{d?.total}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.management_fee_12per}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.grand_total_ctc}</h6>
                        </div>

                        <div style={{borderBottom:'1px solid #ddd'}}>
                            <h6 style={{width:'100%',fontWeight:'bold',padding:'4px',fontSize:'11px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px'}}>{d?.over_time_duty_per_hr}</h6>
                        </div>



                    </div>))}
                </div>

            {/* {datas?.map((d,i)=>(
              <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                 <h6 style={{fontSize:'12px',color:'#0594f2',fontWeight: '800',minWidth:'5%',maxWidth:'5%',borderRight:'1px solid #ddd',padding:'4px',marginBottom:'0px'}}>{i+1}</h6>
                 <h6 style={{fontSize:'12px',minWidth:'10%',maxWidth:'10%',borderRight:'1px solid #ddd',padding:'4px',marginBottom:'0px'}}>{d?.designation?.designation_name}</h6>
                 <h6 style={{fontSize:'12px',minWidth:'10%',maxWidth:'10%',borderRight:'1px solid #ddd',padding:'4px',marginBottom:'0px'}}>{d?.basic}</h6>
                 <h6 style={{fontSize:'12px',minWidth:'5%',maxWidth:'5%',padding:'4px',marginBottom:'0px'}}>
                    
                    <AiOutlineDelete onClick={()=>deletedata(d?._id)} />
                 </h6>
              </div>
        
            ))
        } */}
            </div> 

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                }
            </div>
        </div>

    </FmsDashboardMainRoot>
  )
}

export default FmsClientsSalaryStatement