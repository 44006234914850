import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { useNavigate, } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import {BiEditAlt} from 'react-icons/bi'
import moment from 'moment';
import { Pagination, Tabs } from 'antd'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import { data_not_found } from '../../../helpers/Constants';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Toaster, toast } from 'react-hot-toast'
import {AiOutlineDelete} from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { BsSearch } from 'react-icons/bs';
import { MdOutlineRefresh } from 'react-icons/md'

const { TabPane } = Tabs;

function FmsUniformRequestFormList() {
  
  const roles = useSelector(state => state.Auth.roles)
  const fcpl_id = useSelector(state => state.Auth.fcpl_id)

  const [search,setsearch] = useState('')

  const navigate = useNavigate()
  const [activetab,setactivetab] = useState('Pending')
  const [filterApplied,setfilterApplied] = useState(false)
  const [page,setpage] = useState(1)
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})


  useEffect(()=>{
    getdata(page,activetab)
  },[page])

  function setactivetabfunc(v){
    setpage(1)
    getdata('1',v)
  }

  function setpagefuun(v){
    setpage(v)
  }

  function getdata(v,v1){
    axios.get(`fms/uniform_request/get?page=${v ? v : page}&status=${v1}&search=${search}`,authHeader)
    .then((res)=>{
      setdata(res.data.data)
      setpagination(res.data.pagination)
    }).catch((err)=>{
      if(err?.response?.status === 401){
      }
    })
  }


    function getfilterdata1(){
      setpage(1)
      axios.get(`fms/uniform_request/get?page=${page}&status=${activetab}&search=${search}`,authHeader)
      .then((res)=>{
          setdata(res.data.data)
          setpagination(res.data.pagination)
      })
  }


    const deleteexitemployee = (v) =>{
      axios.delete(`fms/uniform_request/delete/${v}`,authHeader)
      .then((res)=>{
        toast.success(res?.data?.message)
        getdata()
      }).catch((err)=>{
        if(err?.response?.status === 401){
        }
      })
    } 

    function applyFilter(){
      setpage(1)
  }

  function resetFilter(){
      setsearch('')
      setpage(1)
      axios.get(`fms/uniform_request/get?page=${1}&status=${activetab}&search=${''}`,authHeader)
      .then((res)=>{
          setdata(res.data.data)
          setpagination(res.data.pagination)
      })
  }


  return (
    <FmsDashboardMainRoot>
      <Toaster />
       <div style={{width:'96%'}}>
            <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
              <BoldText1 val={`Uniform Request Raised`} />
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:'10px'}}>
              <input value={search} onChange={(e)=>setsearch(e.target.value)} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} onKeyPress={(e)=>e.key === 'Enter' && getfilterdata1()} /> 
              <BsSearch onClick={()=>{applyFilter();getfilterdata1()}} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
              <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
              </div>
              </div>
            </div>
            <Tabs
                size="small"
                activeKey={activetab}
                activetab={activetab}
                defaultValue={activetab}
                style={{ width: '100%', margin: '10px 0px', marginBottom: '0px', position: 'sticky', top: '40px' }}
                onChange={(v) =>{setactivetab(v);setactivetabfunc(v)}}>
                    <TabPane tab="Pending" key="Pending">
                           <BoldText1 val={`Pending Request(${pagination.total})`} fontSize={'12px'}  />   

                           {data.length > 0 ? 
                          // <div style={{ border: '0px solid lightgray',marginTop:'20px', width:'100%' }}>
                          //      <div className='mateial_table_header'>
                          //           <p className='checked_here' style={{opacity:0}} ></p> 
                          //           <p style={{maxWidth:"5%" ,minWidth: '5%', color: 'black', }}>Sl No</p>
                          //           <p style={{maxWidth:"20%" ,minWidth: '20%', }}>Employee Name</p>
                          //           <p style={{maxWidth:"30%" ,minWidth: '30%', }}>Uniform Info</p>
                          //           <p style={{maxWidth:"15%" ,minWidth: '15%', }}>Requested By</p>
                          //           <p style={{maxWidth:"15%" ,minWidth: '15%',  }}>Provided  By</p>
                          //           <p style={{maxWidth:"10%" ,minWidth: '10%',  }}>Created At</p>
                          //           <p style={{maxWidth:"5%" ,minWidth: '5%' }}>Actions</p>
                          //       </div>


                          //     {data.map((d, i) => (
                          //         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',}}>
                          //             <p style={{ minWidth: '5%',maxWidth: '5%', }}>{page > 1 ? ((i+1) + ((page-1) * pagination.limit)) : i+1}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '20%',maxWidth:'20%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.employee?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '30%',maxWidth:'30%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.uniform_info}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '15%',maxWidth:'15%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.created_by?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '15%',maxWidth:'15%', wordBreak: 'break-word', fontSize:'7px !important',fontWeight:'400', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.approved_by?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{moment(d?.createdAt)?.format('LL')}</p>

                          //             {!roles.includes('fms_data_view') &&
                          //             <p style={{ minWidth: '5%',maxWidth: '5%', display: 'flex',alignItems:'center',paddingLeft:'0px'}}>
                          //                 <Tooltip title='Edit'>
                          //                 <span>
                          //                 <BiEditAlt onClick={()=>navigate(`/fms/uniform_request_raised/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                          //                 </span>
                          //                 </Tooltip>

                          //                 {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                          //                 <Tooltip title='Delete'>
                          //                 <span>
                          //                 <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />
                          //                 </span>
                          //                 </Tooltip>}
                                          
                                          
                          //             </p>}
                          //         </div>
                          //     ))}

                          // <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                          //     {pagination.total > 15 &&
                          //       <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                          //     }
                          // </div>

                          // </div> 
                                         <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                                                        <div className='mateial_table_header' style={{border:'1px solid #eaeded'}}>
                                                            <p className='checked_here' style={{opacity:0}} ></p> 
                                                            <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                                            <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Employee Name</p>
                                                            <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Uniform Info</p>
                                                            <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Requested By</p>
                                                            <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Provided  By</p>
                                                            <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Created At</p>                                                 
                                                            <p style={{fontSize:'12px',minWidth: '10.83%',maxWidth: '10%' }}>Actions</p>
                                                        </div>
                                                        {data?.map((d,i)=>(
                                                        <div key={d?._id} style={{borderLeft:'1px solid #ddd',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #ddd',borderBottom:'1px solid #ddd',height:'100%'}}>
                                                            <p className='checked_here' style={{opacity:0}} ></p> 
                                                            <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '3.8%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                                            <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '30%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.employee?.name}</p>
                                                            <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.uniform_info}</p>
                                                            <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.3%',maxWidth: '30%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.created_by?.name}</p>
                                                            <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.approved_by?.name}</p>
                                                            <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{moment(d?.createdAt)?.format('LL')}</p>
                                                            <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                                                            <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                                                  
                                                            <BiEditAlt onClick={()=>navigate(`/fms/uniform_request_raised/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                                                            {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                                             <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />}
                                                            </p>
                                                            </p>
                                                        </div>))}
                                                        <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                                          {pagination?.total > pagination?.limit &&
                                              <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                                          }
                                                       </div>
                          
                                          </div> 
                          :
                          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                              <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any Exit Employee Form created yet"}</h4>
                              <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see exit employee list added by you click on the add button and create a new exit form and submit"}</h4>
                          </div>}
                    </TabPane>
                    <TabPane tab="Progress" key="Progress">
                         <BoldText1 val={`Process Request(${pagination.total})`} fontSize={'12px'}  />   

                         {data.length > 0 ? 
                          // <div style={{ border: '0px solid lightgray',marginTop:'20px', width:'100%' }}>
                          //      <div className='mateial_table_header'>
                          //           <p className='checked_here' style={{opacity:0}} ></p> 
                          //           <p style={{maxWidth:"5%" ,minWidth: '5%', color: 'black', }}>Sl No</p>
                          //           <p style={{maxWidth:"20%" ,minWidth: '20%', }}>Employee Name</p>
                          //           <p style={{maxWidth:"30%" ,minWidth: '30%', }}>Uniform Info</p>
                          //           <p style={{maxWidth:"15%" ,minWidth: '15%', }}>Requested By</p>
                          //           <p style={{maxWidth:"15%" ,minWidth: '15%',  }}>Provided  By</p>
                          //           <p style={{maxWidth:"10%" ,minWidth: '10%',  }}>Created At</p>
                          //           <p style={{maxWidth:"5%" ,minWidth: '5%' }}>Actions</p>
                          //       </div>


                          //     {data.map((d, i) => (
                          //         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',}}>
                          //             <p style={{ minWidth: '5%',maxWidth: '5%', }}>{page > 1 ? ((i+1) + ((page-1) * pagination.limit)) : i+1}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '20%',maxWidth:'20%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.employee?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '30%',maxWidth:'30%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.uniform_info}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '15%',maxWidth:'15%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.created_by?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '15%',maxWidth:'15%', wordBreak: 'break-word', fontSize:'7px !important',fontWeight:'400', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.approved_by?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{moment(d?.createdAt)?.format('LL')}</p>

                          //             {!roles.includes('fms_data_view') &&
                          //             <p style={{ minWidth: '5%',maxWidth: '5%', display: 'flex',alignItems:'center',paddingLeft:'0px'}}>
                          //                 <Tooltip title='Edit'>
                          //                 <span>
                          //                 <BiEditAlt onClick={()=>navigate(`/fms/uniform_request_raised/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                          //                 </span>
                          //                 </Tooltip>

                          //                 {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                          //                 <Tooltip title='Delete'>
                          //                 <span>
                          //                 <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />
                          //                 </span>
                          //                 </Tooltip>}
                                          
                                          
                          //             </p>}
                          //         </div>
                          //     ))}

                          // <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                          //     {pagination.total > 15 &&
                          //       <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                          //     }
                          // </div>

                          // </div> 
                          <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                          <div className='mateial_table_header' style={{border:'1px solid #eaeded'}}>
                              <p className='checked_here' style={{opacity:0}} ></p> 
                              <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Employee Name</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Uniform Info</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Requested By</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Provided  By</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Created At</p>                                                 
                              <p style={{fontSize:'12px',minWidth: '10.83%',maxWidth: '10%' }}>Actions</p>
                          </div>
                          {data?.map((d,i)=>(
                          <div key={d?._id} style={{borderLeft:'1px solid #ddd',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #ddd',borderBottom:'1px solid #ddd',height:'100%'}}>
                              <p className='checked_here' style={{opacity:0}} ></p> 
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '3.8%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '30%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.employee?.name}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.uniform_info}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.3%',maxWidth: '30%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.created_by?.name}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.approved_by?.name}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{moment(d?.createdAt)?.format('LL')}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                    
                              <BiEditAlt onClick={()=>navigate(`/fms/uniform_request_raised/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                              {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                               <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />}
                              </p>
                              </p>
                          </div>))}
                          <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
            {pagination?.total > pagination?.limit &&
                <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
            }
                         </div>

            </div> 
                          :
                          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                              <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any Exit Employee Form created yet"}</h4>
                              <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see exit employee list added by you click on the add button and create a new exit form and submit"}</h4>
                          </div>}
                    </TabPane>
                    <TabPane tab="Completed" key="Completed">
                         <BoldText1 val={`Approved Request(${pagination.total})`} fontSize={'12px'}  />   

                         {data.length > 0 ? 
                          // <div style={{ border: '0px solid lightgray',marginTop:'20px', width:'100%' }}>
                          //      <div className='mateial_table_header'>
                          //           <p className='checked_here' style={{opacity:0}} ></p> 
                          //           <p style={{maxWidth:"5%" ,minWidth: '5%', color: 'black', }}>Sl No</p>
                          //           <p style={{maxWidth:"20%" ,minWidth: '20%', }}>Employee Name</p>
                          //           <p style={{maxWidth:"30%" ,minWidth: '30%', }}>Uniform Info</p>
                          //           <p style={{maxWidth:"15%" ,minWidth: '15%', }}>Requested By</p>
                          //           <p style={{maxWidth:"15%" ,minWidth: '15%',  }}>Provided  By</p>
                          //           <p style={{maxWidth:"10%" ,minWidth: '10%',  }}>Created At</p>
                          //           <p style={{maxWidth:"5%" ,minWidth: '5%' }}>Actions</p>
                          //       </div>


                          //     {data.map((d, i) => (
                          //         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',}}>
                          //             <p style={{ minWidth: '5%',maxWidth: '5%', }}>{page > 1 ? ((i+1) + ((page-1) * pagination.limit)) : i+1}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '20%',maxWidth:'20%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.employee?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '30%',maxWidth:'30%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.uniform_info}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '15%',maxWidth:'15%', wordBreak: 'break-word', fontSize:'10px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.created_by?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '15%',maxWidth:'15%', wordBreak: 'break-word', fontSize:'7px !important',fontWeight:'400', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.approved_by?.name}</p>
                          //             <p style={{ marginLeft:'-5px',minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{moment(d?.createdAt)?.format('LL')}</p>

                          //             {!roles.includes('fms_data_view') &&
                          //             <p style={{ minWidth: '5%',maxWidth: '5%', display: 'flex',alignItems:'center',paddingLeft:'0px'}}>
                          //                 <Tooltip title='Edit'>
                          //                 <span>
                          //                 <BiEditAlt onClick={()=>navigate(`/fms/uniform_request_raised/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                          //                 </span>
                          //                 </Tooltip>

                          //                 {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                          //                 <Tooltip title='Delete'>
                          //                 <span>
                          //                 <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />
                          //                 </span>
                          //                 </Tooltip>}
                                          
                                          
                          //             </p>}
                          //         </div>
                          //     ))}

                          // <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                          //     {pagination.total > 15 &&
                          //       <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                          //     }
                          // </div>

                          // </div> 

<div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
<div className='mateial_table_header' style={{border:'1px solid #eaeded'}}>
    <p className='checked_here' style={{opacity:0}} ></p> 
    <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
    <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Employee Name</p>
    <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Uniform Info</p>
    <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Requested By</p>
    <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Provided  By</p>
    <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Created At</p>                                                 
    <p style={{fontSize:'12px',minWidth: '10.83%',maxWidth: '10%' }}>Actions</p>
</div>
{data?.map((d,i)=>(
<div key={d?._id} style={{borderLeft:'1px solid #ddd',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #ddd',borderBottom:'1px solid #ddd',height:'100%'}}>
    <p className='checked_here' style={{opacity:0}} ></p> 
    <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '3.8%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
    <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '30%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.employee?.name}</p>
    <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.uniform_info}</p>
    <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.3%',maxWidth: '30%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.created_by?.name}</p>
    <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.approved_by?.name}</p>
    <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{moment(d?.createdAt)?.format('LL')}</p>
    <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
    <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
          
    <BiEditAlt onClick={()=>navigate(`/fms/uniform_request_raised/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
    {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
     <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />}
    </p>
    </p>
</div>))}
<div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
{pagination?.total > pagination?.limit &&
<Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
}
</div>

</div> 

                          :
                          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                              <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any Exit Employee Form created yet"}</h4>
                              <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see exit employee list added by you click on the add button and create a new exit form and submit"}</h4>
                          </div>}
                    </TabPane>
                 

            </Tabs>
       </div>
    </FmsDashboardMainRoot>
  )
}

export default FmsUniformRequestFormList