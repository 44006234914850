import React,{useState,useEffect} from 'react';
import { BoldHeadText, BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Input,Modal,Pagination, Select} from 'antd'
import {useSelector} from 'react-redux'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import {BiEditAlt} from 'react-icons/bi'
import toast,{ Toaster } from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import { IoIosClose } from 'react-icons/io';
import { data_not_found } from '../../../helpers/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdDeleteOutline,MdOutlineRefresh } from 'react-icons/md'
import { AiOutlineFileExcel } from "react-icons/ai";
import { authHeader } from '../../../helpers/usertoken/UserToken';
import {  local_base_url } from '../../../App';
import fileDownload from "js-file-download";


function FmsUniform(){

    const { Option } = Select; 

    const roles = useSelector(state => state.Auth.roles)
    
    // console.log("roles",roles)

    // const AuthHeader = {headers:{
    //     'Content-Type': 'application/json',
    // }}

    const {pathname} = useLocation()

    // console.log("pathname",pathname)

    const [drawer,set_drawer] = useState(false)
    const [data,setdata] = useState({id:'',type:'',gender:'',size:'',color:'',pairs:''})
    const [error,seterror] = useState({id:'',type:'',gender:'',size:'',color:'',pairs:''})
    const [modalvisible,setmodalvisible] = useState(false)


    const [search,setsearch] = useState('')



    const [fmsuniform,setfmsuniform] = useState([])
    const { TextArea } = Input;
    const theme_color = useSelector(state=>state.Theme.theme_color)

    const [pagination,setpagination] = useState({})
    const [page,setpage] = useState(1)

    const navigator = useNavigate()

    function func(){

    }

    function setpagefuun(v){
        setpage(v)
    }


    useEffect(()=>{
        getdata()
    },[page])


    useEffect(()=>{
        getdata()
    },[pathname])

    function getdata(){
        axios.get(`fms/fms_uniform/get?page=${page}&search=${search}`,authHeader)
        .then((res)=>{
            setpagination(res.data.pagination)
            setfmsuniform(res.data.datas)
        }).catch((err)=>{
        })
    }

    console.log("fmsuniform",fmsuniform)
    
    function submitform(){
        if(data?.type == ''){
            seterror({...error,type:'This Field is required*'})
        }else if(data?.pairs == ''){
            seterror({...error,pairs:'This Field is required*'})
        }else{
            if(data.id === ''){
                axios.post('fms/fms_uniform/create',data,authHeader)
                .then((res)=>{
                    getdata()
                    toast.success(res.data.message)
                    set_drawer(false)
                    setdata({type:'',gender:'',size:'',color:''})
                }).catch((err)=>{
                })
            }else{
                axios.put(`fms/fms_uniform/update/${data.id}`,data,authHeader)
                .then((res)=>{
                    getdata()
                    set_drawer(false)
                    toast.success(res.data.message)
                    setdata({id:'',type:'',gender:'',size:'',color:'',pairs:''})
                }).catch((err)=>{
                })
            }
        }
      
    }


    function deletefunc(){
        axios.delete(`fms/fms_uniform/delete/${data.id}`,authHeader)
        .then((res)=>{
            getdata()
            setmodalvisible(false)
            toast.success(res.data.message)
            setdata({id:'',type:'',gender:'',size:'',color:'',pairs:''})
        }).catch((err)=>{
        // console.log('err data here na',err)
        })
    }


    function searchData(){
        setpage(1)
        axios.get(`fms/fms_uniform/get?page=${1}&search=${search}`,authHeader)
        .then((res)=>{
            setpagination(res.data.pagination)
            setfmsuniform(res.data.datas)
        }).catch((err)=>{
        })
    }

    function resetdata(){
        setpage(1)
        setsearch('')
        axios.get(`fms/fms_uniform/get?page=${1}&search=${''}`,authHeader)
        .then((res)=>{
            setpagination(res.data.pagination)
            setfmsuniform(res.data.datas)
        }).catch((err)=>{
        })
    }

    async function downloadExcel(){   
        await axios.get(`fms/fms_uniform/downloadExcel`,authHeader)
        .then((res)=>{
            let path = res.data.path.replace('public/','')
            handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
            // console.log("res?.data",res?.data)


            // getdata('1')
        })
    //    console.log("download Excel Function")
    }

    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Roles Excel Downloaded") 
            })
    }

    const gender_Arr = [
        'Male',
        'Female',
        'Others',
       ]

     function handleChange(e){
        setdata({...data,[e.target.name]:e.target.value})
        seterror({...error,[e.target.name]:''})
     }  

     console.log("roles",roles)

    return(
         <FmsDashboardMainRoot>
            <Toaster />

            <SwipeableDrawer
                anchor='right'
                style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
                    open={drawer}
                    onClose={()=>{set_drawer(!drawer);setdata({id:'',role_name:'',display_name:''})}}
                > 
                        <div style={{width:'300px',padding:'30px'}}> 
                           
                        {/* <IoClose  style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={()=>set_drawer(false)} /> */}

                           <div>
                            <BoldHeadText  val="Add/Edit Fms Uniform" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>
                              

                            <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Type'}  />
                                </div>
                                <Input name="type" type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.type} onChange={handleChange} />
                                </div>

                               <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Gender'}  />
                                </div>
                                <Select  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={data?.gender}  onChange={(e)=>{setdata({...data,gender:e})}} >
                                {gender_Arr?.map((l)=>(
                                    <Option style={{zIndex:100,marginBottom:'0px',fontWeight:'500',fontSize:'13px'}} key={l} value={l}>{l}</Option>
                                    ))}
                                </Select>  
                                </div>

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Size'}  />
                                </div>
                                <Input name="size" type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.size} onChange={handleChange} />
                                </div>

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Color'}  />
                                </div>
                                <Input name="color" type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.color} onChange={handleChange} />
                                </div>

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Pairs'}  />
                                </div>
                                <Input name="pairs" type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.pairs} onChange={handleChange} />
                                </div>


                               


                               

                            <p style={{marginTop:'1px'}}></p>
                            
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <PrimaryButton btn_name={'Continue'} onClick={submitform} />
                            <SecondaryButton btn_name={'Close'} onClick={()=>set_drawer(!drawer)} />
                            </div>

                          </div>
                        </div>
            </SwipeableDrawer>
              
            <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
              <BoldText1 val={`${'Fms Uniform'} (${pagination?.total !== undefined ? pagination?.total : fmsuniform?.length})`} />
              <div style={{display:'flex',flexDirection:'row'}}>

                
              {/* <PrimaryButton btn_name={'Update All'} onClick={updatealldata}/> */}
              <h6 style={{width:'10px'}}></h6>
              {pathname !== '/fms/exit_clients' &&              
              <div style={{display:'flex',alignItems:'center'}}>
               <Input type="text" style={{marginRight:0,width:'40%'}} value={search} onChange={(e)=>setsearch(e.target.value)} onKeyPress={(e)=>e.key === 'Enter' && searchData()} />
               <MdOutlineRefresh onClick={()=>{getdata();resetdata();setsearch('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
               {(roles.includes('admin') || roles.includes('fms_hr'))  && 
               <AiOutlineFileExcel onClick={()=>{downloadExcel();}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}} />}
              {(roles.includes('admin') || roles.includes('fms_hr') || roles.includes('fms_vendor') || roles.includes('fms_vendor'))  && 
              <div style={{width:'140px'}}>
              <PrimaryButton btn_name={'Add Uniform'} onClick={()=>{set_drawer(true);;setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true})}}/>
              </div>} 
              </div>}
              </div>
              </div>

              <Modal visible={modalvisible} footer={false} closable={false} onCancel={() => setmodalvisible(false)} style={{ maxWidth: '280px' }} centered>
                    <div style={{ maxWidth: '200px', }}>
                    <IoIosClose  onClick={() => setmodalvisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
                        <h6 style={{ padding: '1%', fontWeight: '700', fontSize: '13px' }}>Delete Client</h6>
                        <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#def0ff', border: '1px solid #236499', borderRadius: '2px' }}><strong>Note:</strong>do you really want to delete the selected contact once deleted cannot be retrieved back</h6>
                        {/* <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
                            <BoldText1 val={'Are You sure ?'} />
                            <SmallText val={'do you really want to delete the selected contact '} />
                        </Row> */}
                        <div style={{ display:'flex',width:'100%',marginLeft:'90px',justifyItems:'flex-end'}} >
                            <SecondaryButton btn_name={' Cancel '} width="100%" onClick={()=>{setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true});setmodalvisible(false)}}/>
                            <PrimaryButton btn_name={' Delete'}  width="100%"  onClick={deletefunc}/>
                        </div>
                    </div>
              </Modal>
               

            



        

                <div style={{width:'95%'}}> 
                {fmsuniform?.length > 0 ? 
               <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                                            <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                                                <p className='checked_here' style={{opacity:0}} ></p> 
                                                <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                                <p style={{fontSize:'12px',minWidth: '19%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Type / Pair</p>
                                                <p style={{fontSize:'12px',minWidth: '19%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Gender</p>
                                                <p style={{fontSize:'12px',minWidth: '19%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Size</p>
                                                <p style={{fontSize:'12px',minWidth: '19%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Color</p>
                                                <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%' }}>Actions</p>
                                            </div>
                                            {fmsuniform?.map((d,i)=>(
                                            <div key={d?._id} style={{borderLeft:'1px solid #eaeded',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                                                <p className='checked_here' style={{opacity:0}} ></p> 
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '18.5%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.type} / {d?.pairs}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '18.7%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.gender}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '18.6%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.size}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '18.7%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.color}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                                {['fms_hr','admin','fms_manager','fms_vendor']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                                                <>      
                                                <BiEditAlt size={12} className='bg-slate-100' onClick={() =>{setdata({...data,id:d._id,type:d?.type,gender:d?.gender,size:d?.size,color:d?.color});set_drawer(true)}} style={{ color: '#000',marginRight:'15px' }}  />
                                                <MdDeleteOutline size={12} onClick={() => {setdata({...data,id:d._id,role_name:d?.role_name,display_name:d?.display_name});setmodalvisible(true)}} style={{ color: '#000' }} />
                                                </>}
                                               </p>
                                                </p>
                                            </div>))}
                                            <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                                                {pagination?.total > pagination?.limit &&
                                                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                                                }
                                           </div>
              
                              </div> 
                :
                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                  <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                  <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                  <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                              </div>
                }
                </div>

              
         </FmsDashboardMainRoot>
    )
}




export default FmsUniform