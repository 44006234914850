import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { MdSpaceDashboard,MdOutlinePolicy, MdBusiness,MdOutlineExitToApp,MdOutlineDocumentScanner,MdOutlineAssignmentReturned } from 'react-icons/md'
import { IoOptionsOutline } from 'react-icons/io5'
import {fcpl_white_logo} from '../../../helpers/Constants'
import './FmsDashboard.css'
import { ItSmallText, ItSmallText1 } from '../../../helpers/constants/ConstantsText'
import {useSelector,useDispatch} from 'react-redux'
import { HiLogout } from 'react-icons/hi'
import {FiUserPlus,FiUsers} from 'react-icons/fi';
import {DiHtml5DeviceAccess} from 'react-icons/di';
import { logout } from '../../../redux/actionPayload/AuthPayload'
import {TfiTicket} from 'react-icons/tfi';
import {AiOutlineCalendar,AiOutlineSisternode,AiOutlineCloudUpload} from 'react-icons/ai';
import { CgOptions } from "react-icons/cg";
import { MdNoiseControlOff } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { TbTransferIn } from "react-icons/tb";
import { TiBusinessCard } from "react-icons/ti";
import { FaNetworkWired } from "react-icons/fa6";
import { MdOutlineHolidayVillage } from "react-icons/md";
import { AiOutlineGroup } from "react-icons/ai";
import { PiSealWarningBold } from "react-icons/pi"
import { PiDress } from "react-icons/pi";
import { TbGenderTransgender } from "react-icons/tb";
import { LuGitPullRequestDraft } from "react-icons/lu";
import { TbArrowsJoin2 } from "react-icons/tb";
import { RxMixerHorizontal } from "react-icons/rx";
import { BsGeoFill } from "react-icons/bs";

function FmsDashboard() {

    const roles = useSelector(state => state.Auth.roles)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const { pathname } = location
    const theme_color = useSelector(state=>state.Theme.theme_color)



    function navigatetopage(v) {
        navigate(v)
    }


    return (
        <>
       


<div style={{ backgroundColor: theme_color, height: '100vh', position: 'fixed', left: '0px', maxHeight: '100vh', top: '0px', alignItems: 'center',minWidth:'200px',maxWidth:'200px',overflowY:'scroll' }}>
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', justifyItems: 'center', width: '100%',padding:'20px' }}>
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column',alignContent:'center',justifyContent:'center' }}>

        <div style={{display:'flex',alignItems:'center',borderBottom:'1px solid #e2e0e0',paddingBottom:'10px' }}>
            <img className="inactive" src={fcpl_white_logo} style={{ width: '25px', height: '25px',marginTop:'0px',marginBottom:'0px',opacity:1 }} alt="#" />
            <ItSmallText col="#fff" val={'FMS DASHBOARD '} marginfun={'0px 0px 0px 10px'} /> 

        </div> 
        
        <ItSmallText val={'MENU'}  col="#e2e0e0" marginfun={'25px 0px 5px 10px'}/>
        <div className={pathname !== '/fms/fms_dashboard' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_dashboard')}>
            <MdSpaceDashboard size={15} className={pathname !== '/fms/fms_dashboard' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Dashboard'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr')) &&
        <>
        <div className={pathname !== '/fms/fms_roles' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_roles')}>
            <MdNoiseControlOff size={15} className={pathname !== '/fms/fms_roles' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'FMS Roles'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/fms_inhouse_employee' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_inhouse_employee')}>
            <FaRegUserCircle size={15} className={pathname !== '/fms/fms_inhouse_employee' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'In House Employee'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
        </>}
      
       <div className={pathname !== '/fms/ticket' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/ticket')}>
            <TfiTicket size={15} className={pathname !== '/fms/ticket' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'My Tickets'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
        
        {(roles?.includes('admin') || roles?.includes('fms_hr') || roles?.includes('fms_crm_head')) &&
        <>
        {/* <div className={pathname !== '/fms/upload_excel' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/upload_excel')}>
            <AiOutlineCloudUpload size={15} className={pathname !== '/fms/upload_excel' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Upload Excel'} marginfun={'0px 0px 0px 5px'} /> 
        </div> */}

    

        <div className={pathname !== '/fms/hr_policy' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/hr_policy')}>
            <MdOutlinePolicy size={15} className={pathname !== '/fms/hr_policy' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'HR Policy'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        </>
        
        }


       
        <div className={pathname !== '/fms/work_assigned' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/work_assigned')}>
            <FaNetworkWired size={15} className={pathname !== '/fms/work_assigned' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Team Work'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/fms_clients' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_clients')}>
            <MdBusiness size={15} className={pathname !== '/fms/fms_clients' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'My Clients'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr') || roles?.includes('fms_vendor')) &&
        <>
        <div className={pathname !== '/fms/fms_uniform' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_uniform')}>
            <AiOutlineGroup size={15} className={pathname !== '/fms/fms_uniform' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'My Uniform'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        </>
        }

        {/* {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr') || roles?.includes('fms_vendor')) &&
        <>
        <div className={pathname !== '/fms/uniform_assign' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/uniform_assign')}>
            <VscGroupByRefType size={15} className={pathname !== '/fms/uniform_assign' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Uniform Assign'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
        </>} */}

        {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr')) &&
        <>
        <div className={pathname !== '/fms/check_list_option' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/check_list_option')}>
            <CgOptions size={15} className={pathname !== '/fms/check_list_option' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'My CheckList Option'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/attendance_option' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/attendance_option')}>
            <IoOptionsOutline size={15} className={pathname !== '/fms/attendance_option' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Attendance Option'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
        </>}

        {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr') || roles?.includes('fms_manager')) &&
        <>
        <div className={pathname !== '/fms/attendance_option_base_list' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/attendance_option_base_list')}>
            <AiOutlineCalendar size={15} className={pathname !== '/fms/attendance_option_base_list' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Attendance Base'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/monthly_calendar_task' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/monthly_calendar_task')}>
            <AiOutlineCalendar size={15} className={pathname !== '/fms/monthly_calendar_task' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Calendar Task'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/assigned_clients' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/assigned_clients')}>
            <MdOutlineAssignmentReturned size={15} className={pathname !== '/fms/assigned_clients' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Assign Clients'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
        
        <div className={pathname !== '/fms/fms_onboarded_employee_designation' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_onboarded_employee_designation')}>
            <DiHtml5DeviceAccess size={15} className={pathname !== '/fms/fms_onboarded_employee_designation' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Designation'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        </>}

        <div className={pathname !== '/fms/transfer_employee_clients' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/transfer_employee_clients')}>
            <TbGenderTransgender size={15} className={pathname !== '/fms/transfer_employee_clients' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Transfer Employee'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/transfer_employee_clients_list' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/transfer_employee_clients_list')}>
            <LuGitPullRequestDraft size={15} className={pathname !== '/fms/transfer_employee_clients_list' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Transfer Employee Request'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/rejoin_employee_clients_list' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/rejoin_employee_clients_list')}>
            <RxMixerHorizontal size={15} className={pathname !== '/fms/rejoin_employee_clients_list' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'ReJoin Employee Request'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        {(roles?.includes('admin') || roles?.includes('fms_crm_head')  || roles?.includes('fms_hr')  || roles?.includes('fms_user')  || roles?.includes('fms_vendor') || roles?.includes('fms_vendor')) &&
        <div className={pathname !== '/fms/uniform_request_raised' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/uniform_request_raised')}>
            <PiDress size={15} className={pathname !== '/fms/uniform_request_raised' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Uniform Request'} marginfun={'0px 0px 0px 5px'} /> 
        </div>}

        

        <div className={pathname !== '/fms/fms_warning_letter_request' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_warning_letter_request')}>
            <PiSealWarningBold size={15} className={pathname !== '/fms/fms_warning_letter_request' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Warning Letter Request'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        

        {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr')) &&
        <div className={pathname !== '/fms/fms_onboarded_employee_dispensary' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_onboarded_employee_dispensary')}>
            <AiOutlineSisternode size={15} className={pathname !== '/fms/fms_onboarded_employee_dispensary' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Dispensary'} marginfun={'0px 0px 0px 5px'} /> 
        </div>}

        <div className={pathname !== '/fms/clients_handling' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/clients_handling')}>
            <AiOutlineCalendar size={15} className={pathname !== '/fms/clients_handling' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Handling Client'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        {/* {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
        <div className={pathname !== '/fms/fms_employee_assign' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_employee_assign')}>
            <TbTransferIn size={15} className={pathname !== '/fms/fms_employee_assign' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Employee Assign Format'} marginfun={'0px 0px 0px 5px'} /> 
        </div>} */}

        <div className={pathname !== '/fms/job_card' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/job_card')}>
            <TiBusinessCard size={15} className={pathname !== '/fms/job_card' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Job Card'} marginfun={'0px 0px 0px 5px'} /> 
        </div>


        {(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr') || roles?.includes('fms_manager')) &&
        <div className={pathname !== '/fms/fms_employee_overall' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_employee_overall')}>
            <FiUsers size={15} className={pathname !== '/fms/fms_employee_overall' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'OverAll Employee'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
        }


        <div className={pathname !== '/fms/fms_employee' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_employee')}>
            <FiUsers size={15} className={pathname !== '/fms/fms_employee' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Employee List'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/fms_client_reliever' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_client_reliever')}>
            <FiUsers size={15} className={pathname !== '/fms/fms_client_reliever' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Reliever List'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/epi_form' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/epi_form')}>
            <FiUserPlus size={15} className={pathname !== '/fms/epi_form' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Add Employee'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/fms_employee_exit' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/fms_employee_exit')}>
            <MdOutlineExitToApp size={15} className={pathname !== '/fms/fms_employee_exit' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Exit Form'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/exit_clients' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/exit_clients')}>
            <MdBusiness size={15} className={pathname !== '/fms/fms_employee_exit' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Exit Clients'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
        
        {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
        <div className={pathname !== '/fms/exit_employee_based_on_clients' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/exit_employee_based_on_clients')}>
            <FiUsers size={15} className={pathname !== '/fms/fms_employee_exit' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Exit Employee'} marginfun={'0px 0px 0px 5px'} /> 
        </div>}

        <div className={pathname !== '/fms/long_leave' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/long_leave')}>
            <MdOutlineHolidayVillage size={15} className={pathname !== '/fms/long_leave' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Long Leave Employee'} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        
        {(roles?.includes('admin') || roles?.includes('fms_crm_head')) &&
        <>
         <div className={pathname !== '/fms/geotag_attendance_client' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/geotag_attendance_client')}>
            <BsGeoFill size={15} className={pathname !== '/fms/geotag_attendance_client' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'GeoTag Attendance '} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/missed_client_scan_report' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/missed_client_scan_report')}>
            <MdOutlineDocumentScanner size={15} className={pathname !== '/fms/missed_client_scan_report' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Missed Scan '} marginfun={'0px 0px 0px 5px'} /> 
        </div>

        <div className={pathname !== '/fms/client_scan_report' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/client_scan_report')}>
            <MdOutlineDocumentScanner size={15} className={pathname !== '/fms/client_scan_report' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Client Scan'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
        </>}

        {(roles?.includes('admin') || roles?.includes('fms_manager') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr') || roles?.includes('fms_finance')) &&
        <div className={pathname !== '/fms/client_invoice' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => navigatetopage('/fms/client_invoice')}>
            <IoOptionsOutline size={15} className={pathname !== '/fms/client_invoice' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Client Attachments'} marginfun={'0px 0px 0px 5px'} /> 
        </div>}
      
        <div className={pathname !== '/fms/log_out' ? 'menu_option_it_non_active' : 'menu_option_it_active'} onClick={() => {dispatch(logout(false));navigate('/login')} }>
            <HiLogout size={16} className={pathname !== '/fms/log_out' ? 'icon_kp' : 'icon_kp_active'} />
            <ItSmallText1 col="#fff" val={'Logout'} marginfun={'0px 0px 0px 5px'} /> 
        </div>
    </div>

</div>
</div>
</>
    )
}


export default FmsDashboard