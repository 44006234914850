import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { SmallText } from '../../../helpers/constants/ConstantsText'
import { Input,DatePicker,Select,Upload,Button } from 'antd'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import {useSelector} from 'react-redux'
import moment from 'moment'
import makeAnimated from 'react-select/animated';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { useNavigate,useLocation } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import TextArea from 'antd/lib/input/TextArea'
import {AiOutlineCloseCircle} from 'react-icons/ai'
// import Select from 'antd'
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';
import { base_url } from '../../../App'


const animatedComponents = makeAnimated();

function FmsExitFormCreate() {

  const {state} = useLocation() 

//   console.log("state",state)


  const [data,setdata] = useState({id:'',employee:'',exit_status:'',user:'',date_of_exit:'',reporting_to:'',date_of_relieving:'',employee_department_office_files:false,employee_department_mobile_data:false,employee_department_na:false,account_finance_advance_agains_salary:false,account_finance_any_payables:false,account_finance_na:false,it_computer:false,it_user_id:false,it_sim_card:false,it_na:false,facilities_uniform_issued:false,facilities_stationeries:false,facilities_id_card:false,facilities_na:false,hr_resignation_letter:false,hr_na:false,others:'',department_head:false,vp:false,operation_head:false,human_resource_head:false,head_finance_accounts:false,asset_head:false,file:null})
  const [error,seterror] = useState({id:'',employee:'',exit_status:'',user:'',date_of_exit:'',reporting_to:'',date_of_relieving:'',employee_department_office_files:'',employee_department_mobile_data:'',account_finance_advance_agains_salary:'',account_finance_any_payables:'',it_computer:'',it_user_id:'',it_sim_card:'',facilities_uniform_issued:'',facilities_stationeries:'',facilities_id_card:'',hr_resignation_letter:'',others:'',department_head:'',vp:'',operation_head:'',human_resource_head:'',head_finance_accounts:'',asset_head:'',file:null})
  const [employeeArr,setemployeeArr] = useState([])
  const roles = useSelector(state => state.Auth.roles)
  const navigator = useNavigate()
   
  
  useEffect(()=>{
      if(state !== null ){
        let d = state

        console.log("file here",d?.file === '')
        setdata({
            id:d?._id,
            employee:{label:`[${d?.employee?.employee_id}]_${d?.employee?.name}_[client:${d?.employee?.department_branch_were_deployed?.client_name}]`,value:d?.employee?._id},
            date_of_exit:d?.date_of_exit,
            exit_status:d?.exit_status,
            reporting_to:d?.reporting_to,
            date_of_relieving:d?.date_of_relieving,
            employee_department_office_files:d?.employee_department_office_files,
            employee_department_mobile_data:d?.employee_department_mobile_data,
            employee_department_na:d?.employee_department_na !== undefined ? d?.employee_department_na : false,
            account_finance_advance_agains_salary:d?.account_finance_advance_agains_salary,
            account_finance_any_payables:d?.account_finance_any_payables,
            account_finance_na:d?.account_finance_na !== undefined ? d?.account_finance_na : false,
            it_computer:d?.it_computer,
            it_user_id:d?.it_user_id,
            it_sim_card:d?.it_sim_card,
            it_na:d?.it_na !== undefined ? d?.it_na : false,
            facilities_uniform_issued:d?.facilities_uniform_issued,
            facilities_stationeries:d?.facilities_stationeries,
            facilities_id_card:d?.facilities_id_card,
            facilities_na:d?.facilities_na !== undefined ? d?.facilities_na : false,
            hr_resignation_letter:d?.hr_resignation_letter,
            hr_na:d?.hr_na !== undefined ? d?.hr_na : false,
            others:d?.others,
            department_head:d?.department_head,
            vp:d?.vp,
            operation_head:d?.operation_head,
            human_resource_head:d?.human_resource_head,
            head_finance_accounts:d?.head_finance_accounts,
            file:(d?.file === undefined || d?.file === '') ? '' : d?.file,
            asset_head:d?.asset_head})
      }
  },[])

  function handleChange1(v,name){   
    setdata({...data,[name]:employeeArr?.find(v1=>v1.value === v)})
    seterror({...error,[name]:''})
  }


function openfile(v){
    let url = `${base_url}${v}`
    window.open(url,'_blank')
 }


  const filterEmployee = async (v) => {

    if(v.length > 3){
       await axios.post(`fms/basicemployee/search?search_text=${v}`,{},authHeader)
        .then((res)=>{
            let arr = []
            if(res?.data?.data?.length > 0){
                res?.data?.data?.forEach((d)=>{
                    console.log("d annaya",d)
                    arr.push({value:d?.id,label:`[${d?.employee_id}]_${d?.name}_[client:${d?.department_branch_were_deployed?.client_name}]`})
                })
                setemployeeArr(arr)
                
                return arr.filter((i) =>
                i.label.toLowerCase().includes(v.toLowerCase())
                );

            }else{
                return arr
            }

        }).catch((err)=>{
            // console.log("err data",err?.response?.data)
        })
    }
   
  };


  async function submitdata(){

   

    
    if(!data?.employee?.value){
        seterror({...error,employee:'This Field is required*'})
    }else if(!data?.date_of_exit){
        seterror({...error,date_of_exit:'This Field is required*'})
    }else if(!data.reporting_to){
        seterror({...error,reporting_to:'This Field is required*'})
    }else if(!data.date_of_relieving){
        seterror({...error,date_of_relieving:'This Field is required*'})
    }else if(!data.exit_status){
        seterror({...error,exit_status:'This Field is required*'})
    }else if(data?.exit_status !== 'Abscond' && data.file === ''){
        seterror({...error,file:'This Field is required*'})
    }else{

        // console.log("data.exit_status",data.exit_status)
         
        // console.log("AuthHeader",AuthHeader)
        if(state === null){
             let send_data = {...data}
             send_data['employee'] = data?.employee?.value
            // send_data['exit_status'] = data?.exit_status


            // const fd = new FormData()
            // const keys = Object.keys(data)
            // keys?.forEach((k)=>{
            //     if(k !== 'employee'){
            //         fd.append(k,data[k])
            //     }
            // })
            // fd.append('employee',data?.employee?.value)
            authHeader['headers']['Content-Type'] = 'application/json'

           await axios.post(`fms/exitform/create`,send_data,authHeader)
            .then((res)=>{
                toast.success(res?.data?.message)
                setTimeout(() => {
                }, 1500);
                setdata({id:'',employee:'',exit_status:'',user:'',file:null,date_of_exit:'',reporting_to:'',date_of_relieving:'',employee_department_office_files:false,employee_department_mobile_data:false,employee_department_na:false,account_finance_advance_agains_salary:false,account_finance_any_payables:false,account_finance_na:false,it_computer:false,it_user_id:false,it_sim_card:false,it_na:false,facilities_uniform_issued:false,facilities_stationeries:false,facilities_id_card:false,facilities_na:false,hr_resignation_letter:false,hr_na:false,others:'',department_head:false,vp:false,operation_head:false,human_resource_head:false,head_finance_accounts:false,asset_head:false})
            }).catch((err)=>{
                if(err?.response?.data?.err?.keyValue?.employee !== undefined){
                    seterror({...error,employee:'Exit form for this employee already exist'})
                 }
            })
        }else{
            let send_data = {...data}
            send_data['employee'] = data?.employee?.value
            // send_data['exit_status'] = data?.exit_status

            // const fd = new FormData()
            // const keys = Object.keys(data)
            // keys?.forEach((k)=>{
            //     if(k !== 'employee'){
            //         fd.append(k,data[k])
            //     }
            // })
            // fd.append('employee',data?.employee?.value)
            // fd.append('exit_status',data?.exit_status)
            authHeader['headers']['Content-Type'] = 'application/json'

           await axios.put(`fms/exitform/update/${data?.id}`,send_data,authHeader)
            .then((res)=>{
                toast.success(res?.data?.message)
                setTimeout(() => {
                    navigator(-1)
                }, 1000);
                setdata({id:'',employee:'',exit_status:'',user:'',file:null,date_of_exit:'',reporting_to:'',date_of_relieving:'',employee_department_office_files:false,employee_department_mobile_data:false,employee_department_na:false,account_finance_advance_agains_salary:false,account_finance_any_payables:false,account_finance_na:false,it_computer:false,it_user_id:false,it_sim_card:false,it_na:false,facilities_uniform_issued:false,facilities_stationeries:false,facilities_id_card:false,facilities_na:false,hr_resignation_letter:false,hr_na:false,others:'',department_head:false,vp:false,operation_head:false,human_resource_head:false,head_finance_accounts:false,asset_head:false})
            }).catch((err)=>{
                if(err?.response?.data?.err?.keyValue?.employee !== undefined){
                    seterror({...error,employee:'Exit form for this employee already exist'})
                 }
            })
        }


    }
  }

//   ,{value:'Long-leave',label:'Long-leave'}


  function handleUpload(v){
   
    authHeader['headers']['Content-Type'] = 'multipart/form-data'
    var fd = new FormData()
    fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
    axios.post(`fms/basicemployee/upload_file`,fd,authHeader)
    .then((res)=>{
        setdata({...data,file:res?.data?.data})
        seterror({...error,file:''})
    }).catch((err)=>{
        console.log("err data",err)
    })
  }

   function disableBeforeThirtyDays(current){
      if(data?.id == '' && !(roles?.includes('admin') || roles?.includes('fms_crm_head') || roles?.includes('fms_hr'))){
          return current && current < moment().subtract(30, "days").startOf("day");
      }else{
          return null
      }
    }


  return (
    <FmsDashboardMainRoot>
        <Toaster/>
       <div >

        <div style={{width:'25%'}}>
            <h6>Exit Employee Form</h6>
            <h6 style={{fontSize:'10px',background:'#3d7aba',fontWeight:'600',color:'#fff',padding:6}}>Use the below form to create or update the exit formalities of an onboardede employee</h6>
        </div>
        <div style={{display:'flex',marginTop:'40px'}}>
        <div style={{width:'25%',marginRight:'30px',borderRight:'1px solid #ddd',paddingRight:'20px'}}>

        <SmallText fontWeight={'600'} val={'Select Employee'}  />
        {(data?.employee !==  '') &&
        <>
        <div style={{position:'relative'}}>
        <h6 style={{border:'1px solid #ddd',padding:'8px',fontSize:'13px',borderLeft:'4px solid #292961'}}>{data?.employee?.label}</h6>
            <AiOutlineCloseCircle style={{position:'absolute',right:10 ,top:10}}  onClick={()=>setdata({...data,employee:''})}/>
        </div>

        {/* <SmallText fontWeight={'600'} val={'Employee Id'}  /> */}
        {/* <h6 style={{border:'1px solid #ddd',padding:'8px',fontSize:'13px',borderLeft:'4px solid #292961'}}>{data?.employee?.label?.split('_')[0]?.replace('[','')?.replace(']','')}</h6> */}
        {/* <SmallText fontWeight={'600'} val={'Client Place'}  /> */}
        {/* <h6 style={{border:'1px solid #ddd',padding:'8px',fontSize:'13px',borderLeft:'4px solid #292961'}}>{data?.employee?.label?.split('_')[2]?.replace('[','')?.replace(']','')?.replace('client:','')}</h6> */}
        </>
        }
        {(data?.employee ===  '') &&
        <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961',}}>
                 <Select 
                   showSearch
                   filterOption={false}
                   style={{ width: '100%',boxShadow:'none' }}
                   value={data?.employee === '' ? null : data?.employee?.label}
                   options={employeeArr}
                   onChange={(v)=>handleChange1(v,'employee')}
                   onSearch={filterEmployee}
                  
                 />
        </div>}


        <div style={{display:'flex'}}>
            {(error?.employee) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.employee  }</p>
        </div>

                
                <div  style={{margin:'0px 0px'}}>
        <SmallText fontWeight={'600'} val={'Date of Exit'}  />

                 {/* <p style={{marginBottom:'8px',fontSize:'14px'}}>Date of Exit</p>   */}
                 <DatePicker 
                   disabledDate={disableBeforeThirtyDays} 
                    value={data?.date_of_exit !== '' ? moment(data.date_of_exit) : null}
                    size="small"  
                    placeholder=''
                    format={'YYYY-MM-DD'}  
                    onChange={(v,v1)=>{setdata({...data,date_of_exit:v1});seterror({...error,date_of_exit:''})}} 
                    style={{width:'100%',padding:'4px',borderLeft:'4px solid #292961',borderRadius:'0px'}} 
                 />
                </div>

            <div>
                {(error?.date_of_exit) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.date_of_exit}</p>
            </div>

                
         
        <div style={{marginBottom:'10px'}}>
            <div style={{width:'150px',marginTop:'10px'}}>
            <SmallText fontWeight={'600'} val={'Reporting To'}  />
            </div>
            <Input type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.reporting_to} onChange={(e)=>{setdata({...data,reporting_to:e.target.value});seterror({...error,reporting_to:''})}} />
           
        </div>
        <div style={{display:'flex'}}>
            {(error?.reporting_to) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.reporting_to}</p>
        </div>

       


                <div  style={{margin:'0px 0px'}}>
        <SmallText fontWeight={'600'} val={'Date of Relieving'}  />
                 {/* <p style={{marginBottom:'8px',fontSize:'14px'}}>Date of Relieving</p>   */}
                 <DatePicker 
                    value={data?.date_of_relieving !== '' ? moment(data.date_of_relieving) : null}
                    size="small"  
                    placeholder=''
                    format={'YYYY-MM-DD'}  
                    onChange={(v,v1)=>{setdata({...data,date_of_relieving:v1});seterror({...error,date_of_relieving:''})}} 
                    style={{width:'100%',padding:'4px',borderLeft:'4px solid #292961',borderRadius:'0px'}} 

                 />
                </div>

                <div>
                {(error?.date_of_relieving) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.date_of_relieving}</p>
                </div>
                
                  
                 <div style={{margin:'10px 0px 0px 0px'}}>
                <SmallText fontWeight={'600'} val={'Exit Status'}  />
                </div> 
                {/* <p style={{marginBottom:'8px',fontSize:'14px'}}>Exit Status</p>   */}

                <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961',}}>
                <Select
                value={data?.exit_status}
                style={{ width: '100%' }}
                onChange={(v)=>{setdata({...data,exit_status:v});seterror({...error,exit_status:'',file:''})}}
                options={[{value:'Resigned',label:'Resigned'},{value:'Abscond',label:'Abscond'},{value:'Other',label:'Other'},{value:'Termination',label:'Termination'}]}
                />
                </div>

                <div style={{display:'flex'}}>
                {(error?.exit_status) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.exit_status}</p>
                </div>

       


        <div style={{marginBottom:'10px'}}>
            <div style={{width:'150px'}}>
            <SmallText fontWeight={'600'} val={'Other '}  />
            </div>
            <TextArea type="text"   value={data.others} onChange={(e)=>setdata({...data,others:e.target.value})} />
            <div style={{display:'flex'}}>
            {(error?.others) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.others  }</p>
            </div>
        </div>

        <div style={{marginBottom:'10px'}}>
            <div style={{width:'280px',position:'relative'}}>
            <SmallText fontWeight={'600'} val={'Attachment'}  />
            {(data?.file !== '' && data?.file !== null && data?.file !== undefined && data?.file !== 'null' && data?.file !== 'undefined') && <h6 style={{position:'absolute',fontSize:'8px',fontWeight:'700',right:'0px',top:'5px',cursor:'pointer'}} ><span style={{color:'black'}} onClick={()=>openfile(data?.file)} > View Image</span></h6>}
            </div>


            <div style={{minWidth:'200px !important'}}>
            {(data.file === '' || data.file === null || data.file == undefined) ?
                <Upload showUploadList={false} onChange={(v)=>handleUpload(v)} style={{width:'200px'}}>
                    <Button style={{width:'280px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                </Upload>
                 :
                <Button onClick={()=>{setdata({...data,file:null});seterror({...error,file:null})}}  style={{width:'280px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px'}} >
                    <p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{data.file?.name != undefined ? data.file?.name : data?.file?.split('/')[data?.file?.split('/').length-1] }</p>
                    {(data.file !== '' && data.file !== null && data.file !== undefined) && <CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/>}
                </Button>
                }
            </div>

            <div style={{display:'flex',marginTop:1.5}}>
                {(error?.file) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.file}</p>
            </div>

        </div>    
       
        <h6 style={{fontSize:11,fontWeight:'500',paddingTop:20,width:'94%',marginLeft:'1%'}}>Note :- Settlement will made only after obtaining this certificate duly approved by all concerned.</h6>

        </div>




        <div>

        {/* Employees Department: */}
        <div>

            <h6 style={{fontSize:15,fontWeight:'600',marginBottom:15}}>Employees Department:</h6>
            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,employee_department_office_files:!data.employee_department_office_files})}>
                        {data.employee_department_office_files &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'1. Office Files/Folders/Documents/Customer data etc.'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,employee_department_mobile_data:!data.employee_department_mobile_data})}>
                        {data.employee_department_mobile_data &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'2. Mobile /Data Card – Recovery if any.'}  />
                </div> 
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,employee_department_na:!data.employee_department_na})}>
                        {data.employee_department_na &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'3. Not Applicable.'}  />
                </div> 
            </div>

        </div>


        {/* Accounts & Finance: */}
        <div>

            <h6 style={{fontSize:15,fontWeight:'600',marginBottom:15}}>Accounts & Finance:</h6>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,account_finance_advance_agains_salary:!data.account_finance_advance_agains_salary})}>
                        {data.account_finance_advance_agains_salary &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'1. Advance Against salary.'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,account_finance_any_payables:!data.account_finance_any_payables})}>
                        {data.account_finance_any_payables &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'2. Any Payables.'}  />
                </div> 
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,account_finance_na:!data.account_finance_na})}>
                        {data.account_finance_na &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'3. Not Applicable'}  />
                </div>
                
            </div>

        </div>


        {/* IT: */}
        <div>

            <h6 style={{fontSize:15,fontWeight:'600',marginBottom:15}}>IT:</h6>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,it_computer:!data.it_computer})}>
                        {data.it_computer &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'1. Computer Hardware / PC / Laptop / Mobile.'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,it_sim_card:!data.it_sim_card})}>
                        {data.it_sim_card &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'2. Sim Card.'}  />
                </div> 
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,it_user_id:!data.it_user_id})}>
                        {data.it_user_id &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                        
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'3. User ID/Password’s.'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,it_na:!data.it_na})}>
                        {data.it_na &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'4. Not Applicable'}  />
                </div>
                
            </div>
        </div>


        {/* Facilities: */}
        <div>
            <h6 style={{fontSize:15,fontWeight:'600',marginBottom:15}}>Facilities:</h6>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                     <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,facilities_uniform_issued:!data.facilities_uniform_issued})}>
                        {data.facilities_uniform_issued &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                        
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'1. Uniform Issued.'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                     <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,facilities_stationeries:!data.facilities_stationeries})}>
                        {data.facilities_stationeries &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                        
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'2. Stationeries / Cupboard or draw keys.'}  />
                </div> 
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                     <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,facilities_id_card:!data.facilities_id_card})}>
                        {data.facilities_id_card &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                        
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'3. ID card'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                <p>
                    <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,facilities_na:!data.facilities_na})}>
                    {data.facilities_na &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                
                </p>
            <div style={{width:'400px',marginLeft:'20px'}}>
            <SmallText fontWeight={'600'} val={'4. Not Applicable'}  />
            </div>
            
            </div>
        </div>

        {/* HR & Admin: */}
        <div>

            <h6 style={{fontSize:15,fontWeight:'600',marginBottom:15}}>HR & Admin:</h6>
            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,hr_resignation_letter:!data.hr_resignation_letter})}>
                        {data.hr_resignation_letter &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'1. Resignation Letter'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,hr_na:!data.hr_na})}>
                        {data.hr_na &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'2. Not Applicable'}  />
                </div>
                
            </div>
        </div>


        {/* Approval Data: */}
        <div>

            <h6 style={{fontSize:15,fontWeight:'600',marginBottom:15}}>Approval Data:</h6>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex',opacity:(roles.includes('fms_hod') || roles.includes('admin') ) ? 1 : 0.6}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{(roles.includes('fms_hod') || roles.includes('admin')) ? setdata({...data,department_head:!data.department_head}) : console.log() }}>
                        {data.department_head &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'1. Department Head'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex',opacity:(roles.includes('fms_vp') || roles.includes('admin') ) ? 1 : 0.6}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{(roles.includes('fms_vp') || roles.includes('admin')) ? setdata({...data,vp:!data.vp}) : console.log() }}>
                        {data.vp &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'2. VP'}  />
                </div>
                
            </div>

            {/* <div style={{marginBottom:'2px',marginTop:'1px',display:'flex',opacity:(roles.includes('fms_ops_head') || roles.includes('admin') ) ? 1 : 0.6}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{(roles.includes('fms_ops_head') || roles.includes('admin')) ? setdata({...data,operation_head:!data.operation_head}) : console.log() }}>
                        {data.operation_head &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'3. Operational Head'}  />
                </div>
                
            </div> */}

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex',opacity:(roles.includes('fms_hr') || roles.includes('admin') ) ? 1 : 0.6}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{(roles.includes('fms_hr') || roles.includes('admin')) ? setdata({...data,human_resource_head:!data.human_resource_head}) : console.log() }}>
                        {data.human_resource_head &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'3. Human Resources Head'}  />
                </div>
                
            </div>


            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex',opacity:(roles.includes('fms_head_finance') || roles.includes('admin')) ? 1 : 0.6}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{(roles.includes('fms_head_finance') || roles.includes('admin')) ? setdata({...data,head_finance_accounts:!data.head_finance_accounts}) : console.log() }}>
                        {data.head_finance_accounts &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'4. Head-Finance & Accounts'}  />
                </div>
                
            </div>

            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex',opacity:(roles.includes('fms_asset_head') || roles.includes('admin') ) ? 1 : 0.6}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>{(roles.includes('fms_asset_head') || roles.includes('admin')) ? setdata({...data,asset_head:!data.asset_head}) : console.log() }}>
                        {data.asset_head &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'20px'}}>
                <SmallText fontWeight={'600'} val={'5. Asset Head'}  />
                </div>
                
            </div>

        </div>

        </div>
        </div>



        <div style={{display:'flex',zIndex:10,paddingBottom:'60px',width:'40px',marginLeft:10}}>
            <div style={{width: data.edit ? '200px' : '150px',zIndex:1}} onClick={submitdata}>
            <PrimaryButton  btn_name = {data.id !== '' ? `Save` : `Save`}  />
            </div>
            <div onClick={()=>navigator(-1)}>
            <SecondaryButton btn_name = 'cancel' />
            </div>
        </div>

       </div>
    </FmsDashboardMainRoot>
  )
}

export default FmsExitFormCreate