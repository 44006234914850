import React,{useState,useEffect} from 'react';
import { BoldHeadText, BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Checkbox, Input,Modal,Pagination} from 'antd'
import {useSelector} from 'react-redux'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFilter } from "react-icons/ai";
import {BiEditAlt} from 'react-icons/bi'
import toast,{ Toaster } from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import { IoIosClose } from 'react-icons/io';
import { data_not_found } from '../../../helpers/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import {TbEaseInOutControlPoints} from 'react-icons/tb';
import { MdDeleteOutline,MdOutlineRefresh } from 'react-icons/md'
import {AiOutlineUserSwitch} from 'react-icons/ai';
import { AiOutlineFileExcel } from "react-icons/ai";
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { base_url, local_base_url } from '../../../App';
import { TbCashBanknote } from "react-icons/tb";
import fileDownload from "js-file-download";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { IoCalendarNumberOutline } from "react-icons/io5";
import Inputerror from '../../../components/errors/Inputerror';

const animatedComponents = makeAnimated();

function FmsClients(){

    const roles = useSelector(state => state.Auth.roles)
    const token = useSelector(state=>state.Auth.access_token)
    
    // console.log("roles",roles)

    const AuthHeader = {headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }}

    const {pathname} = useLocation()

    // console.log("pathname",pathname)

    const [drawer,set_drawer] = useState(false)
    const [data,setdata] = useState({id:'',client_name:'',client_no:'',client_email:'',site_name:'',site_address:'',are_we_serving:true,password:'',roles:[],app_access:false,generate_termination_employee:false})
    const [error,seterror] = useState({id:'',client_name:'',client_no:'',client_email:'',site_name:'',site_address:'',are_we_serving:true,password:'',roles:[],app_access:false,generate_termination_employee:false})
    const [modalvisible,setmodalvisible] = useState(false)


    const [exitClient,setexitClient] = useState({date_of_exit:'',supervisor_name:'',reason:'',payment_made:false})

    const [search,setsearch] = useState('')



    const [fmsclients,setfmsclients] = useState([])
    const [roles_arr, setroles_arr] = useState([])

    const { TextArea } = Input;
    const theme_color = useSelector(state=>state.Theme.theme_color)

    const [pagination,setpagination] = useState({})
    const [page,setpage] = useState(1)

    const navigator = useNavigate()

    function func(){

    }

    function setpagefuun(v){
        setpage(v)
    }


    useEffect(()=>{
        getdata()
        getRoles()
    },[page])


    useEffect(()=>{
        getdata()
        getRoles()
    },[pathname])

    function getdata(){
        if(pathname === '/fms/fms_clients'){
            axios.get(`fms/fmsclients/get?page=${page}`,AuthHeader)
            .then((res)=>{
                setpagination(res.data.pagination)
                setfmsclients(res.data.datas)
            }).catch((err)=>{
            })
        }else if(pathname === '/fms/exit_clients'){
            axios.get(`fms/fmsclients/get_inactive?page=${page}`,AuthHeader)
            .then((res)=>{
                setpagination(res.data.pagination)
                setfmsclients(res.data.datas)
            }).catch((err)=>{
            })
        }
        
    }
    
    // console.log("data.roles.map((d)=>d?._id)",data.roles)

    function submitform(){
        if(!data.site_name){
            seterror({...error,site_name:"This Field is required*"})
        }else if(!data.client_name){
            seterror({...error,client_name:"This Field is required*"})
        }else if(!data.client_no){
            seterror({...error,client_no:"This Field is required*"})
        }else if(!data.client_email){
            seterror({...error,client_email:"This Field is required*"})
        }else{
            if(data.id === ''){
                let sendData = {...data}
                let arr = []
                if(data?.roles !== undefined && data?.roles?.length > 0){
                    data?.roles?.forEach((d)=>{
                        arr?.push(d?.id)
                    })
                    sendData['roles'] = arr
            }
                axios.post('fms/fmsclients/create',sendData,AuthHeader)
                .then((res)=>{
                    getdata()
                    toast.success(res.data.message)
                    set_drawer(false)
                    setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true,password:'',roles:[],app_access:false,generate_termination_employee:false})
                }).catch((err)=>{
                })
            }else{
                let sendData = {...data,...exitClient}
                let arr = []
                data.roles.forEach((d)=>{
                    arr?.push(d?.id)
                })
                sendData['roles'] = arr
                axios.put(`fms/fmsclients/update/${data.id}`,sendData,AuthHeader)
                .then((res)=>{
                    getdata()
                    set_drawer(false)
                    toast.success(res.data.message)
                    setdata({id:'',client_name:'',client_no:'',client_email:'',site_name:'',site_address:'',are_we_serving:true,password:'',roles:[],app_access:false,generate_termination_employee:false})
                }).catch((err)=>{
                })
            }
        }
      
    }

    function deletefunc(){
        axios.delete(`fms/fmsclients/delete/${data.id}`,data,AuthHeader)
        .then((res)=>{
            getdata()
            setmodalvisible(false)
            toast.success(res.data.message)
            setdata({client_name:'',client_no:'',client_email:'',site_name:'',site_address:'',are_we_serving:true,id:'',generate_termination_employee:false})
        }).catch((err)=>{
        // console.log('err data here na',err)
        })
    }

    function searchData(){
        if(search?.length === 0){
            getdata()
        }else{
            axios.get(`fms/fmsclients/search?search_text=${search}&are_we_serving=${pathname === '/fms/fms_clients' ? '1' : '0'}`,AuthHeader)
            .then((res)=>{
                setpagination(res.data.pagination)
                setfmsclients(res.data.datas)
            }).catch((err)=>{
            })
        }    
    }

    // async function downloadqrCode(d){
    //   if(d?.file === undefined || d?.file === null){
    //     await axios.get(`fms/fmsclients/get_scanner/${d._id}`,AuthHeader)
    //     .then((res)=>{
    //         getdata()
    //         if(res?.status == 200){
    //             let url = `${base_url}fms/scanner/${res?.data?.data?.file}`
    //                 axios.get(url,{ responseType: 'blob',})
    //                 .then((res)=>{
    //                     FileDownload(res.data, 'scanner.png');
    //                 })
    //         }
    //     }).catch((err)=>{
    //     })
    //   }else{
    //     let url = `${base_url}fms/scanner/${d?.file}`
    //     axios.get(url,{ responseType: 'blob',})
    //     .then((res)=>{
    //         FileDownload(res.data, 'scanner.png');
    //     })
    //   }
    // }

    // async function updatealldata(){
    //     axios.put(`fms/fmsclients/updateall`,{},AuthHeader)
    //     .then((res)=>{
    //         getdata()
    //         set_drawer(false)
    //         toast.success(res.data.message)
    //         setdata({client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true,id:''})
    //     }).catch((err)=>{
    //     })
    // }


    async function downloadExcel(){   
        await axios.get(`fms/fmsclients/downloadExcel`,authHeader)
        .then((res)=>{
            let path = res.data.path.replace('public/','')
            handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
        })
    }

    const handleDownload = (url, filename) => {
        // console.log('url',url,'filename',filename)

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Clients Excel Downloaded") 
            })
    }

    function getRoles(){
        axios.get('fms/app_roles/get',authHeader)
             .then((res)=>{
                const role_arr_created = []
                res.data.datas.forEach(d=>{
                     let data = {}
                     data['label'] = d.role_name
                     data['value'] = d.display_name
                     data['id'] = d._id
                     role_arr_created.push(data)
                })
                setroles_arr(role_arr_created)

            })
    }  
    
    function handleChange1(selectedOptions,datakey_name){   
        const array = [] 
        if(selectedOptions){
            selectedOptions.forEach((t) => {
                array.push(t);
              });
        }
        setdata({...data,[datakey_name]:array})
    }

    function setdatafunc(d){

        let roles = []

        d?.roles.forEach(d=>{
            if(d !== null){
            let data = {}
            data['label'] = d.role_name
            data['value'] = d.display_name
            data['id'] = d._id
            roles.push(data)
            }
       })

    //    console.log("roles",d)
        setdata({
            ...data,
            ...exitClient,
            id:d._id,
            client_name:d.client_name,
            client_no:d.client_no,
            site_name:d.site_name,
            client_email:d.client_email !== undefined ? d.client_email : '',
            site_address:d.site_address,
            are_we_serving:d.are_we_serving,
            app_access:d.app_access,
            password:d.password,
            roles:roles
        })
    }


    

    return(
         <FmsDashboardMainRoot>
            <Toaster />

            <SwipeableDrawer
                anchor='right'
                style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
                    open={drawer}
                    onClose={()=>{set_drawer(!drawer);setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true})}}
                > 
                        <div style={{width:'300px',padding:'30px'}}> 
                           
                        {/* <IoClose  style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={()=>set_drawer(false)} /> */}

                           <div>
                            <BoldHeadText  val="Add/Edit My Client" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>
                              

                            <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Site Name'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.site_name} onChange={(e)=>{setdata({...data,site_name:e.target.value});seterror({...error,site_name:''})}} />
                                </div>
                                <Inputerror message={error?.site_name} />

                               <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Client Name'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.client_name} onChange={(e)=>{setdata({...data,client_name:e.target.value});seterror({...error,client_name:''})}} />
                                </div>
                                <Inputerror message={error?.client_name} />

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Client Number'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.client_no} onChange={(e)=>{setdata({...data,client_no:e.target.value});seterror({...error,client_no:''})}} />
                                </div>
                                <Inputerror message={error?.client_no} />



                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Client Email'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.client_email} onChange={(e)=>{setdata({...data,client_email:e.target.value});seterror({...error,client_email:''})}} />
                                </div>
                                <Inputerror message={error?.client_email} />

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Site Address'}  />
                                </div>
                                <TextArea type="text"   value={data.site_address} onChange={(e)=>setdata({...data,site_address:e.target.value})} />
                                </div>
                            
                                {(data?.id !== '' && data?.id !== undefined && data?.id !== null) &&  
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',flexWrap:'wrap'}}> 
                                   <div style={{marginBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center',width:'50%'}}>
                            
                                    <Checkbox type="checkbox" checked={data.are_we_serving} onChange={(e)=>setdata({...data,are_we_serving:!data.are_we_serving})} />
                                    <div style={{width:'150px',marginLeft:'10px' ,marginTop:'9px'}}>
                                    <SmallText fontWeight={'600'} val={'Active Client'}  />
                                    </div>
                                    </div> 

                                   

                                    <div style={{marginBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <Checkbox type="checkbox" checked={data.app_access} onChange={(e)=>setdata({...data,app_access:!data.app_access})} />
                                    <div style={{width:'150px',marginLeft:'10px' ,marginTop:'9px'}}>
                                    <SmallText fontWeight={'600'} val={'App Access'}  />
                                    </div>
                                 </div>   

                                </div>}

                                {(data?.id !== '' && data?.id !== undefined && data?.id !== null && data?.app_access) &&  
                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Client Password'}  />
                                </div>
                                <Input type="text"   value={data.password} onChange={(e)=>setdata({...data,password:e.target.value})} />
                                </div>}

                                <div style={{marginBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}}>
                                        
                                        <Checkbox type="checkbox" checked={data.generate_termination_employee} onChange={(e)=>setdata({...data,generate_termination_employee:!data.generate_termination_employee})} />
                                        <div style={{width:'100%',marginLeft:'10px' ,marginTop:'9px'}}>
                                        <SmallText fontWeight={'600'} val={'Generate Termination Employee'}  />
                                        </div>
                                    </div> 


                                {data?.generate_termination_employee &&
                                <div>

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'SuperVisor Name'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={exitClient.supervisor_name} onChange={(e)=>{setexitClient({...exitClient,supervisor_name:e.target.value});}} />
                                </div>

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Reason'}  />
                                </div>
                                <TextArea type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={exitClient.reason} onChange={(e)=>{setexitClient({...exitClient,reason:e.target.value});}} />
                                </div>

                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Date of Exit'}  />
                                </div>
                                <Input type="date" style={{borderLeft:`4px solid ${theme_color}`}}  value={exitClient.date_of_exit} onChange={(e)=>{setexitClient({...exitClient,date_of_exit:e.target.value});}} />
                                </div>

                                <div style={{marginBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center',width:'50%'}}>
                                
                                <Checkbox type="checkbox" checked={exitClient.payment_made} onChange={(e)=>setexitClient({...exitClient,payment_made:!exitClient.payment_made})} />
                                <div style={{width:'150px',marginLeft:'10px' ,marginTop:'9px'}}>
                                <SmallText fontWeight={'600'} val={'Payment Made'}  />
                                </div>
                                </div> 



                                </div>}



                                {(data?.id !== '' && data?.id !== undefined && data?.id !== null && data?.app_access) &&  
                                <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Roles'}  />
                                </div>
                                <Select
                                    value={data.roles}
                                    closeMenuOnSelect={true}
                                    placeholder=''
                                    components={animatedComponents}
                                    isMulti
                                    onChange={(v)=>handleChange1(v,'roles')}
                                    options={roles_arr}
                                    style={{ width: '100%',borderLeft:'4px solid #292961',borderRadius:'5px',boxShadow:'none' }}
                                >
                                </Select>
                                </div>}



                               

                            <p style={{marginTop:'1px'}}></p>
                            
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <PrimaryButton btn_name={'Continue'} onClick={submitform} />
                            <SecondaryButton btn_name={'Close'} onClick={()=>set_drawer(!drawer)} />
                            </div>

                          </div>
                        </div>
            </SwipeableDrawer>
              
            <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
              <BoldText1 val={`${pathname === '/fms/exit_clients' ? 'Fms Exit Clients' : 'Fms Clients'} (${pagination?.total !== undefined ? pagination?.total : fmsclients?.length})`} />
              <div style={{display:'flex',flexDirection:'row',alignContent:'end'}}>
              <h6 style={{width:'10px'}}></h6>
            
              {pathname !== '/fms/exit_clients' &&              
              <div style={{display:'flex',alignItems:'center',marginLeft:'16%'}}>
               <Input type="text" style={{marginRight:0,width:'40%'}} value={search} onChange={(e)=>setsearch(e.target.value)} onKeyPress={(e)=>e.key === 'Enter' && searchData()} />
               <MdOutlineRefresh onClick={()=>{getdata();setsearch('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
               {(roles.includes('admin') || roles.includes('fms_hr') ||  roles.includes('fms_crm_head'))  && 
               <AiOutlineFileExcel onClick={()=>{downloadExcel();}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}} />}
              {pathname !== '/fms/exit_clients' &&  <PrimaryButton btn_name={'Add Client'} onClick={()=>{set_drawer(true);;setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true})}}/>}
              </div>}
              </div>
            </div>

              <Modal visible={modalvisible} footer={false} closable={false} onCancel={() => setmodalvisible(false)} style={{ maxWidth: '280px' }} centered>
                    <div style={{ maxWidth: '200px', }}>
                    <IoIosClose  onClick={() => setmodalvisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
                        <h6 style={{ padding: '1%', fontWeight: '700', fontSize: '13px' }}>Delete Client</h6>
                        <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#def0ff', border: '1px solid #236499', borderRadius: '2px' }}><strong>Note:</strong>do you really want to delete the selected contact once deleted cannot be retrieved back</h6>
                        <div style={{ display:'flex',width:'100%',marginLeft:'90px',justifyItems:'flex-end'}} >
                            <SecondaryButton btn_name={' Cancel '} width="100%" onClick={()=>{setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true});setmodalvisible(false)}}/>
                            <PrimaryButton btn_name={' Delete'}  width="100%"  onClick={deletefunc}/>
                        </div>
                    </div>
              </Modal>
               

               <div style={{width:'78%'}}>
              

                {fmsclients.length > 0 ? 
                <>
                <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'125%',overflow:'hidden',border:'1px solid #eaeded',}}>
                            <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                                                <p style={{fontSize:'11px',minWidth: '4.5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'0px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                                <p style={{fontSize:'12px',minWidth: '18%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Client Name</p>
                                                <p style={{fontSize:'12px',minWidth: '20%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Site Name</p>
                                                <p style={{fontSize:'12px',minWidth: '15%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Client Phone</p>
                                                <p style={{fontSize:'12px',minWidth: '18%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Address</p>
                                                <p style={{fontSize:'12px',minWidth: '20%',maxWidth: '20%' }}>Actions</p>
                                            </div>
                                            {fmsclients?.map((d,i)=>(
                                            <div key={d?._id} style={{borderLeft:'1px solid #eaeded',width:'125%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                                                <p className='checked_here' style={{opacity:0}} ></p> 
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '3.1%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '14%',maxWidth: '14%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }} >{d?.client_name}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '15.7%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.site_name}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '11.5%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.client_no}</p>
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '14.1%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.site_address}</p>
                                                
                                                {['fms_hr','admin','fms_manager']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '18%',maxWidth: '20%',  marginRight: '0.5%'  }}>
                                                        <AiOutlineEdit style={{marginRight:'25px'}} onClick={() =>{setdatafunc(d);set_drawer(true)}}  />
                                                        <AiOutlineDelete onClick={() => {setdata({...data,id:d._id,role_name:d?.role_name,display_name:d?.display_name});setmodalvisible(true)}}  />
                                                       <TbEaseInOutControlPoints size={12} onClick={()=>navigator('/fms/fms_clients/scan_point',{state:d})} style={{ color: '#000',marginLeft:'25px' }} />
                                                       <AiOutlineUserSwitch size={12} onClick={()=>navigator('/fms/fms_clients/client_employee',{state:d._id})} style={{ color: '#000',marginLeft:'25px' }} />
                                                       <TbCashBanknote size={12} onClick={()=>navigator('/fms/fms_clients/salary_statement',{state:d._id})} style={{ color: '#000',marginLeft:'25px' }} />
                                                        <IoCalendarNumberOutline size={12} onClick={()=>navigator('/fms/fms_clients/download_attendance',{state:d})} style={{ color: '#000',marginLeft:'25px' }} />
                                                </p>
                                                }
                                            </div>))}
                                         
              
                </div> 

                <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                {pagination?.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
                </div>
                </>
                :
                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                </div>
                }
                
                </div>
              
         </FmsDashboardMainRoot>
    )
}




export default FmsClients





//////old code
// import React,{useState,useEffect} from 'react';
// import { BoldHeadText, BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
// import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import {Checkbox, Input,Modal,Pagination} from 'antd'
// import {useSelector} from 'react-redux'
// import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
// import axios from 'axios';
// import {BiEditAlt} from 'react-icons/bi'
// import toast,{ Toaster } from 'react-hot-toast';
// import Tooltip from '@mui/material/Tooltip';
// import { IoIosClose } from 'react-icons/io';
// import { data_not_found } from '../../../helpers/Constants';
// import { useLocation, useNavigate } from 'react-router-dom';
// import {TbEaseInOutControlPoints} from 'react-icons/tb';
// import { MdDeleteOutline,MdOutlineRefresh } from 'react-icons/md'
// import {AiOutlineUserSwitch} from 'react-icons/ai';
// import { AiOutlineFileExcel } from "react-icons/ai";
// import { authHeader } from '../../../helpers/usertoken/UserToken';
// import { base_url, local_base_url } from '../../../App';
// import { TbCashBanknote } from "react-icons/tb";
// import fileDownload from "js-file-download";
// import Select from 'react-select'
// import makeAnimated from 'react-select/animated';
// import { IoCalendarNumberOutline } from "react-icons/io5";
// import Inputerror from '../../../components/errors/Inputerror';

// const animatedComponents = makeAnimated();

// function FmsClients(){

//     const roles = useSelector(state => state.Auth.roles)
    
//     // console.log("roles",roles)

//     const AuthHeader = {headers:{
//         'Content-Type': 'application/json',
//     }}

//     const {pathname} = useLocation()

//     // console.log("pathname",pathname)

//     const [drawer,set_drawer] = useState(false)
//     const [data,setdata] = useState({id:'',client_name:'',client_no:'',client_email:'',site_name:'',site_address:'',are_we_serving:true,password:'',roles:[],app_access:false})
//     const [error,seterror] = useState({id:'',client_name:'',client_no:'',client_email:'',site_name:'',site_address:'',are_we_serving:true,password:'',roles:[],app_access:false})
//     const [modalvisible,setmodalvisible] = useState(false)


//     const [search,setsearch] = useState('')



//     const [fmsclients,setfmsclients] = useState([])
//     const [roles_arr, setroles_arr] = useState([])

//     const { TextArea } = Input;
//     const theme_color = useSelector(state=>state.Theme.theme_color)

//     const [pagination,setpagination] = useState({})
//     const [page,setpage] = useState(1)

//     const navigator = useNavigate()

//     function func(){

//     }

//     function setpagefuun(v){
//         setpage(v)
//     }


//     useEffect(()=>{
//         getdata()
//         getRoles()
//     },[page])


//     useEffect(()=>{
//         getdata()
//         getRoles()
//     },[pathname])

//     function getdata(){
//         if(pathname === '/fms/fms_clients'){
//             axios.get(`fms/fmsclients/get?page=${page}`,AuthHeader)
//             .then((res)=>{
//                 setpagination(res.data.pagination)
//                 setfmsclients(res.data.datas)
//             }).catch((err)=>{
//             })
//         }else if(pathname === '/fms/exit_clients'){
//             axios.get(`fms/fmsclients/get_inactive?page=${page}`,AuthHeader)
//             .then((res)=>{
//                 setpagination(res.data.pagination)
//                 setfmsclients(res.data.datas)
//             }).catch((err)=>{
//             })
//         }
        
//     }
    
//     // console.log("data.roles.map((d)=>d?._id)",data.roles)

//     function submitform(){
//         if(!data.site_name){
//             seterror({...error,site_name:"This Field is required*"})
//         }else if(!data.client_name){
//             seterror({...error,client_name:"This Field is required*"})
//         }else if(!data.client_no){
//             seterror({...error,client_no:"This Field is required*"})
//         }else if(!data.client_email){
//             seterror({...error,client_email:"This Field is required*"})
//         }else{
//             if(data.id === ''){
//                 let sendData = {...data}
//                 let arr = []
//                 if(data?.roles !== undefined && data?.roles?.length > 0){
//                     data?.roles?.forEach((d)=>{
//                         arr?.push(d?.id)
//                     })
//                     sendData['roles'] = arr
//             }
//                 axios.post('fms/fmsclients/create',sendData,AuthHeader)
//                 .then((res)=>{
//                     getdata()
//                     toast.success(res.data.message)
//                     set_drawer(false)
//                     setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true,password:'',roles:[],app_access:false})
//                 }).catch((err)=>{
//                 })
//             }else{
//                 let sendData = {...data}
//                 let arr = []
//                 data.roles.forEach((d)=>{
//                     arr?.push(d?.id)
//                 })
//                 sendData['roles'] = arr
//                 axios.put(`fms/fmsclients/update/${data.id}`,sendData,AuthHeader)
//                 .then((res)=>{
//                     getdata()
//                     set_drawer(false)
//                     toast.success(res.data.message)
//                     setdata({id:'',client_name:'',client_no:'',client_email:'',site_name:'',site_address:'',are_we_serving:true,password:'',roles:[],app_access:false})
//                 }).catch((err)=>{
//                 })
//             }
//         }
      
//     }

//     function deletefunc(){
//         axios.delete(`fms/fmsclients/delete/${data.id}`,data,AuthHeader)
//         .then((res)=>{
//             getdata()
//             setmodalvisible(false)
//             toast.success(res.data.message)
//             setdata({client_name:'',client_no:'',client_email:'',site_name:'',site_address:'',are_we_serving:true,id:''})
//         }).catch((err)=>{
//         // console.log('err data here na',err)
//         })
//     }

//     function searchData(){
//         if(search?.length === 0){
//             getdata()
//         }else{
//             axios.get(`fms/fmsclients/search?search_text=${search}&are_we_serving=${pathname === '/fms/fms_clients' ? '1' : '0'}`,AuthHeader)
//             .then((res)=>{
//                 setpagination(res.data.pagination)
//                 setfmsclients(res.data.datas)
//             }).catch((err)=>{
//             })
//         }    
//     }

//     // async function downloadqrCode(d){
//     //   if(d?.file === undefined || d?.file === null){
//     //     await axios.get(`fms/fmsclients/get_scanner/${d._id}`,AuthHeader)
//     //     .then((res)=>{
//     //         getdata()
//     //         if(res?.status == 200){
//     //             let url = `${base_url}fms/scanner/${res?.data?.data?.file}`
//     //                 axios.get(url,{ responseType: 'blob',})
//     //                 .then((res)=>{
//     //                     FileDownload(res.data, 'scanner.png');
//     //                 })
//     //         }
//     //     }).catch((err)=>{
//     //     })
//     //   }else{
//     //     let url = `${base_url}fms/scanner/${d?.file}`
//     //     axios.get(url,{ responseType: 'blob',})
//     //     .then((res)=>{
//     //         FileDownload(res.data, 'scanner.png');
//     //     })
//     //   }
//     // }

//     // async function updatealldata(){
//     //     axios.put(`fms/fmsclients/updateall`,{},AuthHeader)
//     //     .then((res)=>{
//     //         getdata()
//     //         set_drawer(false)
//     //         toast.success(res.data.message)
//     //         setdata({client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true,id:''})
//     //     }).catch((err)=>{
//     //     })
//     // }


//     async function downloadExcel(){   
//         await axios.get(`fms/fmsclients/downloadExcel`,authHeader)
//         .then((res)=>{
//             let path = res.data.path.replace('public/','')
//             handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
//         })
//     }

//     const handleDownload = (url, filename) => {
//         // console.log('url',url,'filename',filename)

//         axios.get(url, {
//             responseType: 'blob',
//         })
//             .then((res) => {
//                 fileDownload(res.data, filename)
//                 toast.success("Clients Excel Downloaded") 
//             })
//     }

//     function getRoles(){
//         axios.get('fms/app_roles/get',authHeader)
//              .then((res)=>{
//                 const role_arr_created = []
//                 res.data.datas.forEach(d=>{
//                      let data = {}
//                      data['label'] = d.role_name
//                      data['value'] = d.display_name
//                      data['id'] = d._id
//                      role_arr_created.push(data)
//                 })
//                 setroles_arr(role_arr_created)

//             })
//     }  
    
//     function handleChange1(selectedOptions,datakey_name){   
//         const array = [] 
//         if(selectedOptions){
//             selectedOptions.forEach((t) => {
//                 array.push(t);
//               });
//         }
//         setdata({...data,[datakey_name]:array})
//     }

//     function setdatafunc(d){

//         let roles = []

//         d?.roles.forEach(d=>{
//             if(d !== null){
//             let data = {}
//             data['label'] = d.role_name
//             data['value'] = d.display_name
//             data['id'] = d._id
//             roles.push(data)
//             }
//        })

//     //    console.log("roles",d)
//         setdata({
//             ...data,
//             id:d._id,
//             client_name:d.client_name,
//             client_no:d.client_no,
//             site_name:d.site_name,
//             client_email:d.client_email !== undefined ? d.client_email : '',
//             site_address:d.site_address,
//             are_we_serving:d.are_we_serving,
//             app_access:d.app_access,
//             password:d.password,
//             roles:roles
//         })
//     }


//     return(
//          <FmsDashboardMainRoot>
//             <Toaster />

//             <SwipeableDrawer
//                 anchor='right'
//                 style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
//                     open={drawer}
//                     onClose={()=>{set_drawer(!drawer);setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true})}}
//                 > 
//                         <div style={{width:'300px',padding:'30px'}}> 
                           
//                         {/* <IoClose  style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={()=>set_drawer(false)} /> */}

//                            <div>
//                             <BoldHeadText  val="Add/Edit My Client" fontSize={'15px'} fontWeight='900'/>
//                             <p style={{borderBottom:'1px solid #eee'}}></p>
                              

//                             <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Site Name'}  />
//                                 </div>
//                                 <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.site_name} onChange={(e)=>{setdata({...data,site_name:e.target.value});seterror({...error,site_name:''})}} />
//                                 </div>
//                                 <Inputerror message={error?.site_name} />

//                                <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Client Name'}  />
//                                 </div>
//                                 <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.client_name} onChange={(e)=>{setdata({...data,client_name:e.target.value});seterror({...error,client_name:''})}} />
//                                 </div>
//                                 <Inputerror message={error?.client_name} />

//                                 <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Client Number'}  />
//                                 </div>
//                                 <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.client_no} onChange={(e)=>{setdata({...data,client_no:e.target.value});seterror({...error,client_no:''})}} />
//                                 </div>
//                                 <Inputerror message={error?.client_no} />



//                                 <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Client Email'}  />
//                                 </div>
//                                 <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.client_email} onChange={(e)=>{setdata({...data,client_email:e.target.value});seterror({...error,client_email:''})}} />
//                                 </div>
//                                 <Inputerror message={error?.client_email} />

//                                 <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Site Address'}  />
//                                 </div>
//                                 <TextArea type="text"   value={data.site_address} onChange={(e)=>setdata({...data,site_address:e.target.value})} />
//                                 </div>
                            
//                                 {(data?.id !== '' && data?.id !== undefined && data?.id !== null) &&  
//                                 <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}> 
//                                    <div style={{marginBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center',width:'50%'}}>
                            
//                                     <Checkbox type="checkbox" checked={data.are_we_serving} onChange={(e)=>setdata({...data,are_we_serving:!data.are_we_serving})} />
//                                     <div style={{width:'150px',marginLeft:'10px' ,marginTop:'9px'}}>
//                                     <SmallText fontWeight={'600'} val={'Active Client'}  />
//                                     </div>
//                                     </div> 


//                                     <div style={{marginBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            
//                                     <Checkbox type="checkbox" checked={data.app_access} onChange={(e)=>setdata({...data,app_access:!data.app_access})} />
//                                     <div style={{width:'150px',marginLeft:'10px' ,marginTop:'9px'}}>
//                                     <SmallText fontWeight={'600'} val={'App Access'}  />
//                                     </div>
//                                  </div>   

//                                 </div>}

//                                 {(data?.id !== '' && data?.id !== undefined && data?.id !== null && data?.app_access) &&  
//                                 <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Client Password'}  />
//                                 </div>
//                                 <Input type="text"   value={data.password} onChange={(e)=>setdata({...data,password:e.target.value})} />
//                                 </div>}

//                                 {(data?.id !== '' && data?.id !== undefined && data?.id !== null && data?.app_access) &&  
//                                 <div style={{marginBottom:'10px'}}>
//                                 <div style={{width:'150px'}}>
//                                 <SmallText fontWeight={'600'} val={'Roles'}  />
//                                 </div>
//                                 <Select
//                                     value={data.roles}
//                                     closeMenuOnSelect={true}
//                                     placeholder=''
//                                     components={animatedComponents}
//                                     isMulti
//                                     onChange={(v)=>handleChange1(v,'roles')}
//                                     options={roles_arr}
//                                     style={{ width: '100%',borderLeft:'4px solid #292961',borderRadius:'5px',boxShadow:'none' }}
//                                 >
//                                 </Select>
//                                 </div>}



                               

//                             <p style={{marginTop:'1px'}}></p>
                            
//                             <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
//                             <PrimaryButton btn_name={'Continue'} onClick={submitform} />
//                             <SecondaryButton btn_name={'Close'} onClick={()=>set_drawer(!drawer)} />
//                             </div>

//                           </div>
//                         </div>
//             </SwipeableDrawer>
              
//             <div style={{display:'flex',alignItems:'center',width:'84%',justifyContent:'space-between'}}>
//               <BoldText1 val={`${pathname === '/fms/exit_clients' ? 'Fms Exit Clients' : 'Fms Clients123'} (${pagination?.total !== undefined ? pagination?.total : fmsclients?.length})`} />
//               <div style={{display:'flex',flexDirection:'row'}}>

                
//               {/* <PrimaryButton btn_name={'Update All'} onClick={updatealldata}/> */}
//               <h6 style={{width:'10px'}}></h6>
//               {pathname !== '/fms/exit_clients' &&              
//               <div style={{display:'flex',alignItems:'center'}}>
//               <Input type="text" style={{marginRight:0,width:'40%'}} value={search} onChange={(e)=>setsearch(e.target.value)} onKeyPress={(e)=>e.key === 'Enter' && searchData()} />
//                <MdOutlineRefresh onClick={()=>{getdata();setsearch('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
//                {(roles.includes('admin') || roles.includes('fms_hr') ||  roles.includes('fms_crm_head'))  && 
//                <AiOutlineFileExcel onClick={()=>{downloadExcel();}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}} />}
//               <div style={{width:'140px'}}>

//               {pathname !== '/fms/exit_clients' &&  <PrimaryButton btn_name={'Add Client'} onClick={()=>{set_drawer(true);;setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true})}}/>}
//               </div>  
//               </div>}
//               </div>
//               </div>

//               <Modal visible={modalvisible} footer={false} closable={false} onCancel={() => setmodalvisible(false)} style={{ maxWidth: '280px' }} centered>
//                     <div style={{ maxWidth: '200px', }}>
//                     <IoIosClose  onClick={() => setmodalvisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px'}} />
//                         <h6 style={{ padding: '1%', fontWeight: '700', fontSize: '13px' }}>Delete Client</h6>
//                         <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#def0ff', border: '1px solid #236499', borderRadius: '2px' }}><strong>Note:</strong>do you really want to delete the selected contact once deleted cannot be retrieved back</h6>
//                         {/* <Row style={{ width: '300px', margin: 'auto', display: 'flex' }}>
//                             <BoldText1 val={'Are You sure ?'} />
//                             <SmallText val={'do you really want to delete the selected contact '} />
//                         </Row> */}
//                         <div style={{ display:'flex',width:'100%',marginLeft:'90px',justifyItems:'flex-end'}} >
//                             <SecondaryButton btn_name={' Cancel '} width="100%" onClick={()=>{setdata({id:'',client_name:'',client_no:'',site_name:'',site_address:'',are_we_serving:true});setmodalvisible(false)}}/>
//                             <PrimaryButton btn_name={' Delete'}  width="100%"  onClick={deletefunc}/>
//                         </div>
//                     </div>
//               </Modal>
               

//                <div style={{width:'78%'}}>

//                 {fmsclients.length > 0 ? 
//                 <div style={{ border: '0px solid lightgray',marginTop:'0px', overflowY: 'scroll',width:'100%' }}>
//                     <div className='mateial_table_header'>
//                         <p style={{ width: '8%', color: 'black',marginRight:'2%' }}>Sl No</p>
//                         <p style={{ width: '12%', marginRight: '2%' }}>Client Name</p>
//                         <p style={{ width: '12%', marginRight: '2%' }}>Site Name</p>
//                         <p style={{ width: '15%', marginRight: '2%' }}>Client Phone</p>
//                         <p style={{ width: '27%', marginRight: '2%' }}>Address</p>
//                         <p style={{ width: '13%' }}>Actions</p>
//                     </div>


//                     {fmsclients.map((d, i) => (
//                         <div key={i} className={'mateial_table_body'} >
//                             <Tooltip><p style={{ minWidth: '8%',maxWidth:'8%',color: "#000", wordBreak: 'break-word', fontWeight: '700',marginRight:'2%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }} onClick={() => func(d)}>{page == 1 ?  i+1 : (pagination?.limit * (page-1)) + (i+1)}</p></Tooltip>
//                             <Tooltip  title={d?.client_name}><p style={{ minWidth: '12%',width:'12%',maxWidth:'12%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'2%' }} onClick={() => func(d)}>{d?.client_name}</p></Tooltip>
//                             <Tooltip  title={d?.site_name}><p style={{ minWidth: '12%',width:'12%',maxWidth:'12%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'2%'}}>{d?.site_name}</p></Tooltip>
//                             <Tooltip  title={d?.client_no}><p style={{ maxWidth: '15%',width:'15%',maxWidth:'12%', wordBreak: 'break-word',marginLeft:'0px', marginRight: '2%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'2%' }}>{d?.client_no}</p></Tooltip>

//                             <Tooltip  title={d?.site_address}><p style={{ minWidth: '32%',width:'32%',maxWidth:'32%', wordBreak: 'break-word', marginRight: '-2%',paddingRight:'3%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'20px' }}>{d?.site_address}</p></Tooltip>
//                             <p style={{minWidth: '18%',maxWidth:'18%',paddingLeft:'3%', display: 'flex',alignItems:'center', }}>
//                             {(roles?.includes('admin') || roles?.includes('fms_hr') || roles?.includes('fms_crm_head')) &&
//                             <>
//                             <Tooltip title='Edit'>
//                                 <span >
//                                 <BiEditAlt size={12} className='bg-slate-100' onClick={() =>{setdatafunc(d);set_drawer(true)}} style={{ color: '#000',marginRight:'15px' }}  />
//                                 </span>
//                             </Tooltip>
//                             <Tooltip title='Delete'>
//                                 <span>
//                                 <MdDeleteOutline size={12} onClick={() => {setdata({...data,id:d._id,client_name:d.client_name,client_no:d.client_no,site_name:d.site_name,site_address:d.site_address,are_we_serving:d.are_we_serving});setmodalvisible(true)}} style={{ color: '#000' }} />
//                                 </span>
//                             </Tooltip>

                              
//                             <Tooltip title='Check Points'>
//                                 <span>
//                                 <TbEaseInOutControlPoints size={12} onClick={()=>navigator('/fms/fms_clients/scan_point',{state:d})} style={{ color: '#000',marginLeft:'15px' }} />
//                                 </span>
//                             </Tooltip>

//                             <Tooltip title='Client Employee'>
//                                 <span>
//                                 <AiOutlineUserSwitch size={12} onClick={()=>navigator('/fms/fms_clients/client_employee',{state:d._id})} style={{ color: '#000',marginLeft:'15px' }} />
//                                 </span>
//                             </Tooltip>

//                             <Tooltip title='Salary Stages'>
//                                 <span>
//                                 <TbCashBanknote size={12} onClick={()=>navigator('/fms/fms_clients/salary_statement',{state:d._id})} style={{ color: '#000',marginLeft:'15px' }} />
//                                 </span>
//                             </Tooltip>

//                             <Tooltip title='Attendance '>
//                                 <span>
//                                 <IoCalendarNumberOutline size={12} onClick={()=>navigator('/fms/fms_clients/download_attendance',{state:d})} style={{ color: '#000',marginLeft:'15px' }} />
//                                 </span>
//                             </Tooltip>

                            
//                             </>}
//                             </p>
//                         </div>
//                     ))}


//                 <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
//                 {pagination?.total > pagination?.limit &&
//                     <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
//                 }
//                 </div>

//                 </div> 
//                 :
//                 <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
//                     <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
//                     <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
//                     <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
//                 </div>}
//                 </div>
              
//          </FmsDashboardMainRoot>
//     )
// }




// export default FmsClients