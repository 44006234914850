import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import {Input,Drawer, Select,Tag,Pagination,Row,Col} from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import Inputerror from '../../../components/errors/Inputerror'
import {AiOutlineCreditCard, AiOutlineEdit} from 'react-icons/ai'
import { Toaster, toast } from 'react-hot-toast'
import {MdOutlineRefresh} from 'react-icons/md'
import { BsFilterSquare } from "react-icons/bs";
import { AiOutlineInfoCircle } from 'react-icons/ai'


function EmployeeBasedOnClient() {

  const {state} = useLocation()
  const [search,setsearch] = useState({text:'',designation:''})
  const user = useSelector(state=>state.Auth)

  const [page,setpage] = useState(1)
  const [fmsemployee_designation,setfmsemployee_designation] = useState([])
  const [employee_selected,setemployee_selected] = useState([])

  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})

  const [data1,setdata1] = useState([])
  const [pagination1,setpagination1] = useState({})

  const theme_color = useSelector(state=>state.Theme.theme_color)
  const [shifts,setshifts] = useState([])
  const [stages,setstages] = useState([])
  const [add_attendance,setadd_attendance] = useState({year:'',month:'',date:'',field_officer:'',shift:[],employee_stage:'',modified_by:'',modified_reason:'',ot:false,ot_hrs:''})
  const [add_attendance_error,setadd_attendance_error] = useState({year:'',month:'',date:'',field_officer:'',shift:'',employee_stage:'',modified_by:'',modified_reason:'',ot_hrs:''})
  const [visible,setvisible] = useState(false)
  const [visible1,setvisible1] = useState(false)
  const [monthyear,setmonthyear] = useState({month:'',year:'',days:''})
  const [day_start,setday_start] = useState(0)
  const [date,setdate] = useState(new Date().getDate())
  const navigation = useNavigate()

  const [designations,setdesignations] = useState([])
  

  const [step,setstep] = useState(1)
  const [step1,setstep1] = useState(1)


  const [reliever,setreliever] = useState({name:'',reliever_id:'',designation:'',department_branch_were_deployed:'',active_status:true})
  const [reliever_error,setreliever_error] = useState({name:'',reliever_id:'',designation:'',department_branch_were_deployed:'',active_status:''})

  async function searchDesignation(v){ 

      await axios.get(`fms/fmsdesignationonboardemployee/search?search_text=${v}`,authHeader)
      .then((res)=>{
          let arr = []
          res.data.datas.forEach((d)=>{
              let obj = {
                  value : d._id,
                  label:d.designation_name
              }
              arr.push(obj)
          })
          setdesignations(arr)
      }).catch((err)=>{
      })
  }

  // console.log("designations",des)
  //   const moment 


// console.log("new Date().getDay()",new Date().getDay())



  useEffect(()=>{
    // setday_start(new Date(monthyear?.year,monthyear?.month,1).getDay())
    getemployeeBasedClient()
    getOptions()
  },[page])
 
  async function getemployeeBasedClient(){

    if(monthyear?.month === '' && monthyear?.year === ''){
      setmonthyear({month:new Date().getMonth() + 1,year:new Date().getFullYear(),days:new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate()})
      setday_start(new Date(new Date().getFullYear(),new Date().getMonth() + 1,1).getDay())
    }
    if(step == 1){ 
        await axios.get(`fms/fmsclients/employee_based_on_client/${state}?page=${page}`,authHeader)
        .then((res)=>{
            setpagination(res?.data?.pagination)
            setdata(res?.data?.datas)
        }).catch((err)=>{
        })
    }else if(step == 2){
        await axios.get(`fms/get_client_reliever/reliever_based_on_client/${state}?page=${page}&step=${step1 == 1 ? 1 : 2}`,authHeader)
        .then((res)=>{
            setpagination1(res?.data?.pagination)
            setdata1(res?.data?.datas)
        }).catch((err)=>{
        })
    }
  }  

  function getOptions(){
    axios.get(`fms/attendanceshift/get`,authHeader)
    .then((res)=>{
      let arr = []
      res?.data?.datas?.filter((d)=>{
        // arr.push({value:d?._id,label:`${d?.name} [ST : ${d?.start_time} - ET : ${d?.end_time} ]`})
        arr.push({value:d?._id,label:`${d?.name}`})
      })
      setshifts(arr)
    }).catch((err)=>{

    })

    axios.get(`fms/attendancestage/get`,authHeader)
    .then((res)=>{
      let arr = []
      res?.data?.datas?.filter((d)=>{
        arr.push({value:d?._id,label:`${d?.name}`})
      })
      setstages(arr)
    }).catch((err)=>{

    })
   }

  async function handle_employee_select(v){
    if(employee_selected?.length < 16){
     let array = [...employee_selected]
     if(array?.length > 0){
      if(array?.find(f=>f?._id === v?._id) !== undefined){
        let index = array.findIndex(e=>e?._id == v?._id)
        array.splice(index,1)
      }else{
        array?.push(v)
      }
     }else{
      array?.push(v)
    }
    setemployee_selected(array)
   }else{
    toast.error('Maximum selection reached')
   }
  }

  function addshifts(e){
    let arr = []
    e?.forEach(k=>{
        arr?.push(shifts?.find((s)=>s?.value === k))
    })
    setadd_attendance({...add_attendance,shift:arr})
    setadd_attendance_error({...add_attendance_error,shift:''})
  }

  function submitData(){
     if(add_attendance?.shift?.length === 0){
      setadd_attendance_error({...add_attendance_error,shift:'This Field is required*'})
     }else if(add_attendance?.stage?.label === undefined){
      setadd_attendance_error({...add_attendance_error,stage:'This Field is required*'})
     }else{
          if(step == 1){
            let send_data = {
              date:new Date(monthyear?.year,monthyear?.month-1,date+1)?.toISOString()?.slice(0,10),
              field_officer:user.id,
              users:employee_selected?.map((m)=>m._id),
              employee_stage:add_attendance?.stage?.label,
              shift:add_attendance?.shift?.map((m)=>m?.label),
              client:state
            }


            axios.post(`fms/basicemployee/add_attendance_mass`,send_data,authHeader)
            .then((res)=>{
              if(res?.status === 201){
                setvisible(false)
                getemployeeBasedClient()
                setemployee_selected([])
                setadd_attendance({...add_attendance,shift:[],stage:''})
                toast.success('Data Updated Successfully')
              }
            }).catch((err)=>{
        
            })
          }else if(step == 2){

          }
     }
  }
  console.log("add_attendance",add_attendance)


  function submitData1(){
    let send_data = {
     date:new Date(monthyear?.year,monthyear?.month-1,date+1)?.toISOString()?.slice(0,10),
     field_officer:user.id,
     users:employee_selected?.map((m)=>m._id),
     employee_stage:add_attendance?.stage?.label,
     shift:add_attendance?.shift?.map((m)=>m?.label)
    }



    axios.post(`fms/basicemployee/add_attendance_mass1`,send_data,authHeader)
    .then((res)=>{
     console.log("res?.data",res?.data)
     if(res?.status === 201){
      setvisible(false)
      getemployeeBasedClient()
      setemployee_selected([])
      setadd_attendance({...add_attendance,shift:[],stage:''})
      toast.success('Data Updated Successfully')
     }
    }).catch((err)=>{
     console.log("err kkp",err?.response?.data)

    })
 }


  function setpagefuun(v){
    setpage(v)
  }

  async function searchData(){
    setpage(1)
    await axios.get(`fms/fmsclients/employee_based_on_client/${state}?page=${1}&search=${search.text}&designation=${search.designation}`,authHeader)
    .then((res)=>{
        setpagination(res?.data?.pagination)
        setdata(res?.data?.datas)
    }).catch((err)=>{
    })
  }  

  async function searchdesignation(e){
    if(e?.length > 2){
        await axios.get(`fms/fmsdesignationonboardemployee/search?search_text=${e}`,authHeader)
        .then((res)=>{
            let arr = []
            res.data.datas.forEach((d)=>{
                let obj = {
                    value : d._id,
                    label:d.designation_name
                }
                arr.push(obj)
            })
            setfmsemployee_designation(arr)

            return arr.filter((i) =>
                i.label.toLowerCase().includes(e.toLowerCase())
                );

        }).catch((err)=>{
        })
    }
  }



  async function resetfilter(){
    setpage(1)
    setsearch({...search,text:'',designation:''})
    await axios.get(`fms/fmsclients/employee_based_on_client/${state}?page=${1}&search=${''}&designation=${''}`,authHeader)
    .then((res)=>{
        setpagination(res?.data?.pagination)
        setdata(res?.data?.datas)
    }).catch((err)=>{
    })
  }

  function selectAllData(){
    if(step == 1){
        let array = []
        data.forEach((d)=>{
          array?.push(d)
        })

        setemployee_selected(array)
    }else if(step == 2){
      let array = []
      data1.forEach((d)=>{
        array?.push(d)
      })

      setemployee_selected(array)
    }
  }


  async function setstepfunc(v){
    setstep(v)
    setemployee_selected([])
    if(v == 1){ 
        await axios.get(`fms/fmsclients/employee_based_on_client/${state}?page=${page}`,authHeader)
        .then((res)=>{
            setpagination(res?.data?.pagination)
            setdata(res?.data?.datas)
        }).catch((err)=>{
        })
    }else if(v == 2){
      await axios.get(`fms/client_reliever/get_reliever_based_on_client/${state}?page=${page}`,authHeader)
      .then((res)=>{
          setpagination1(res?.data?.pagination)
          setdata1(res?.data?.datas)
      }).catch((err)=>{
      })
    }

  }

  async function setstepfunc1(v){
    setstep1(v)
    setemployee_selected([])
    await axios.get(`fms/client_reliever/get_reliever_based_on_client/${state}?page=${page}&step=${v == 1 ? 1 : 2}`,authHeader)
    .then((res)=>{
        setpagination1(res?.data?.pagination)
        setdata1(res?.data?.datas)
    }).catch((err)=>{
    })

  }


  function handleChange(e){
    setreliever({...reliever,designation:designations?.find((d)=>d?.value == e)})
    setreliever_error({...reliever_error,designation:''})
  }


  async function submitreliever(){
      if(!reliever?.name){
        setreliever_error({...reliever_error,name:'This Field is required'})
      }else if(!reliever?.reliever_id){
        setreliever_error({...reliever_error,reliever_id:'This Field is required'})
      }else if(!reliever?.designation){
        setreliever_error({...reliever_error,designation:'This Field is required'})
      }else{
        if(reliever?._id !== undefined){
          let sendData = {
            ...reliever,
            department_branch_were_deployed:state,
            designation:reliever?.designation?.value
          }
          await axios.put(`fms/client_reliever/update/${reliever?._id}`,sendData,authHeader)
          .then((res)=>{
              setreliever({name:'',reliever_id:'',designation:'',department_branch_were_deployed:'',active_status:true})
              setreliever_error({name:'',reliever_id:'',designation:'',department_branch_were_deployed:'',active_status:""})
              toast.success("Reliever Updated Successfully!")
              setvisible1(false)
              getemployeeBasedClient()
          }).catch((err)=>{
          })
        }else{
          let sendData = {
            ...reliever,
            department_branch_were_deployed:state,
            designation:reliever?.designation?.value
          }
          await axios.post(`fms/client_reliever/create`,sendData,authHeader)
          .then((res)=>{
              setvisible1(false)
              setreliever({name:'',reliever_id:'',designation:'',department_branch_were_deployed:'',active_status:true})
              setreliever_error({name:'',reliever_id:'',designation:'',department_branch_were_deployed:'',active_status:""})
              toast.success("Reliever Added Successfully!")
          }).catch((err)=>{
          })
        }
      }
  }

  function setrelieverfunc(d){
    setreliever({
      ...d,
      designation:{label:d?.designation?.designation_name,value:d?.designation?._id}
    })
  }


  // console.log("date",new Date(monthyear.year,monthyear.month,date))
  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <Drawer open={visible}  closable={false}>

            <div>
                <h5 style={{fontSize:'16px'}}>Add / Update Attendance Daily</h5>
                <h6 style={{fontSize:'11px'}}>Use the below for mt ocreate or update the daily attendance of the employee</h6>
                
                <div style={{width:'340px',marginTop:20}}>
                <SmallText fontWeight={'500'} val={'Date'}  />

                {/* onClick={()=>setdate(i+1)} */}
                <Row style={{marginTop:'20px'}}>
                  {new Array(monthyear?.days)?.fill('')?.map((d,i)=>(
                 <Col span={2} style={{cursor:'pointer'}} >
                    <h6 style={{border:'1px solid #eee',width:'100%',fontSize:'11px',padding:'5px',background:date === i+1 ? 
                    '#ccc' : '#fff',textAlign:'center' }}> {i+1}</h6>
                 </Col>
                 ))}
                </Row> 
                </div>
                
                <div style={{width:'400px',marginTop:20}}>
                <SmallText fontWeight={'500'} val={'Employee Selected'}  />
                {employee_selected?.map((e)=>(
                  <Tag>{e?.name}</Tag>
                ))}
                {/* <Select options={[{label:'P',value:'P'},{label:'A',value:'A'},{label:'OT',value:'OT'},{label:'W/O',value:'W/O'}]} style={{width:'80%'}} /> */}
                </div>
               
                <div style={{display:'flex',flexDirection:'row',width:'96%'}}>
                <div style={{marginTop:'8px',width:'50%',marginRight:'10px'}}>
                <div style={{width:'100%'}}>
                  <SmallText fontWeight={'600'} val={'Shift'} />
                  <Select mode="multiple" options={shifts} style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={add_attendance?.shift}  onChange={(e)=>{addshifts(e)}} >
                  </Select>  
                </div>
                <Inputerror message={add_attendance_error?.shift} />

                </div>


                <div style={{marginTop:'8px',width:'50%'}}>
                <div style={{width:'100%'}}>
                  <SmallText fontWeight={'600'} val={'Stage'} />
                  <Select options={stages} style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}} value={add_attendance?.stage !== '' ? add_attendance?.stage?.label : null }  onChange={(e)=>{setadd_attendance({...add_attendance,stage:stages?.find((s)=>s?.value === e)});setadd_attendance_error({...add_attendance_error,stage:''})}} >
                  </Select>  
                </div>
                <Inputerror message={add_attendance_error?.stage} />
                </div>
                </div>

                
                <div style={{display:'flex',flexDirection:'row',marginTop:'10px'}}>
                <PrimaryButton btn_name={'Add'} onClick={step == 1 ? submitData : submitData1} />
                <SecondaryButton btn_name={'Close'} onClick={()=>setvisible(false)}/>
                </div>

            </div>

        </Drawer>

        <Drawer open={visible1}  closable={false}>
          <div>
            <h5 style={{fontSize:'16px'}}>Add / Update Reliever</h5>
            <h6 style={{fontSize:'11px'}}>Use the below form to create or update the reliever for the client</h6>
          
            <div style={{marginBottom:'10px'}}>
              <div style={{width:'150px'}}>
              <SmallText fontWeight={'600'} val={'Name'}  />
              </div>
              <Input style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  value={reliever.name} onChange={(e)=>{setreliever({...reliever,name:e.target.value});setreliever_error({...reliever_error,name:''})}} />
              <div style={{display:'flex'}}>
                {(reliever_error?.name) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{reliever_error?.name}</p>
              </div>
            </div>

            <div style={{marginBottom:'10px'}}>
              <div style={{width:'150px'}}>
              <SmallText fontWeight={'600'} val={'Reliever Id'}  />
              </div>
              <Input style={{width:'100%',borderLeft:`4px solid ${theme_color}`}}  value={reliever.reliever_id} onChange={(e)=>{setreliever({...reliever,reliever_id:e.target.value});setreliever_error({...reliever_error,reliever_id:''})}} />
              <div style={{display:'flex'}}>
                {(reliever_error?.reliever_id) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{reliever_error?.reliever_id}</p>
              </div>
            </div>

            <div style={{width:'400px'}}>
              <SmallText fontWeight={'500'} val={'Designation'}  />
              <Select showSearch filterOption={false} value={reliever?.designation}  options={designations}  onSearch={(v)=>searchDesignation(v,'designation')} onChange={(e)=>handleChange(e,'designation')}  style={{width:'80%',marginBottom:10,borderLeft:`4px solid ${theme_color}`}} />
            </div>

            <div style={{display:'flex'}}>
              {(reliever_error?.designation) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
              <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{reliever_error?.designation}</p>
            </div>

       
            <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                    <p>
                        <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setreliever({...reliever,active_status:!reliever.active_status})}>
                        {reliever.active_status &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                    </p>
                    </p>
                <div style={{width:'400px',marginLeft:'10px'}}>
                <SmallText fontWeight={'600'} val={'Active Status'}  />
                </div>
            </div>

            <div style={{display:'flex',flexDirection:'row',marginTop:'10px'}}>
                <PrimaryButton btn_name={'Add'} onClick={submitreliever} />
                <SecondaryButton btn_name={'Close'} onClick={()=>setvisible1(false)}/>
                </div>

           </div>         

        </Drawer>


        <div style={{display:'flex',borderBottom:'1px solid #ddd',cursor:'pointer'}}>
          <h6 onClick={()=>setstepfunc(1)} style={{background:step == 1 && '#96b9fa',padding:'4px',fontSize:'13px'}}>Deployed Employee</h6>
          <h6 onClick={()=>setstepfunc(2)} style={{background:step == 2 && '#96b9fa',padding:'4px',fontSize:'13px'}}>Reliever Employee</h6>
        </div>
        
        {step == 1 &&
        <div style={{width:'97%'}}>
            <div style={{borderBottom:'1px solid #eee',minHeight:'52px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
            <BoldText1 val={`Employee List (${pagination?.total})`} />


            <div style={{display:'flex',alignItems:'center',justifySelf:'flex-end',justifyContent:'flex-end',width:'auto'}}>
            
           {step == 1 &&
            <>
            <Select placeholder='Search Designation' onClear={()=>setsearch({...search,designation:''})} allowClear={true} filterOption={false} options={fmsemployee_designation} showSearch={true} onSearch={(e)=>{searchdesignation(e)}}  style={{width:'120px',zIndex:100,fontSize:'13px',fontWeight:'600',marginRight:'10px'}}  value={data?.designation}  onChange={(e)=>{setsearch({...search,designation:e});}} />

            <Input placeholder='Search Employee' type="text" style={{marginRight:'8px',width:'130px'}} value={search?.text} onChange={(e)=>setsearch({...search,text:e.target.value})} />
            
            <BsFilterSquare onClick={searchData} size={28} style={{marginLeft:'5px',marginRight:'5px',background:theme_color,color:'white',padding:'6px'}} />
            <MdOutlineRefresh onClick={resetfilter} size={30} style={{marginLeft:'5px',marginRight:'10px',background:'#eee',padding:'4px'}} />
            </>}

            {employee_selected?.length > 0 &&
            <div style={{width:'130px'}}>
               <PrimaryButton btn_name={'Add Attendance'} onClick={()=>setvisible(true)} />
            </div>
            }
            </div> 
            </div>

            <h1 onClick={()=>selectAllData()} style={{fontSize:'11px',fontWeight:'bold',marginTop:'5px',cursor:'pointer'}}> <span style={{textDecorationLine:'underline',}}>Select All </span>   {employee_selected?.length > 0  && ` /  Total Selected - ${employee_selected?.length}`}</h1>
            <div style={{display:'flex',flexDirection:'row',borderTop:'1px solid #eee',marginTop:'10px',borderBottom:'1px solid #eee',borderLeft:'1px solid #eaeded',}}>
            <h6 style={{width:'4%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}></h6>
            <h6 style={{width:'12%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Employee Name</h6>
            <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Employee Id</h6>
            <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>DOJ</h6>
            <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Designation</h6>
            <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> Stage</h6>
            <h6 style={{width:'20%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> Shift</h6>
            <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> OT </h6>
            <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> OT Hrs</h6>
            <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eaeded',}}> Action </h6>
            </div>
            {data?.map((d1)=>(
            
            <div  style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #eee',borderLeft:'1px solid #eaeded',}}>
              <h6 style={{width:'4%',fontSize:'11px',marginBottom:'0px',borderRight:'1px solid #eee'}}>
              <div style={{marginTop:'4px',marginLeft:'15px',display:'flex'}}>
                <p className={employee_selected?.find((f)=>f?._id === d1?._id) !== undefined ? 'checked_here2': 'checked_here'}  onClick={()=>handle_employee_select(d1)}>
                {employee_selected?.find((f)=>f?._id === d1?._id) !== undefined && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
              </div>
              </h6>
              <h6 style={{width:'12%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.name}</h6>
              <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.employee_id}</h6>
              <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.date_of_joining}</h6>
              <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.designation?.display_name}</h6>
              <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> {d1?.attendance?.length !== 0 ? d1?.attendance[0]?.employee_stage : 'NA'}</h6>
              <h6 style={{width:'20%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> {d1?.attendance?.length !== 0 ? d1?.attendance[0]?.shift?.map((s)=>(<Tag size={'small'} style={{fontSize:'8px'}}>{s}</Tag>)) : 'NA'}</h6>
              <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> { d1?.attendance?.length !== 0 ? <p className={ d1?.attendance[0].ot ? 'checked_here2': 'checked_here'}>
                {d1?.attendance[0].ot && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>  : 'NA'} </h6>
              <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> { d1?.attendance?.length !== 0 ? d1?.attendance[0].ot_hrs : 'NA'}</h6>
              <h6  style={{width:'7.5%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',color:'#000',marginLeft:'5px',borderRight:'1px solid #eaeded',}}> 
                <AiOutlineCreditCard onClick={()=>navigation('/fms/employee_based_on_clients/add_attendance',{state:{...d1,type:'onboarded_employee'}})}  size={15} />
                <AiOutlineEdit size={15} style={{marginLeft:'10px'}} onClick={()=>navigation(`/fms/fms_employee/${d1?._id}`)} />
              </h6>
            </div>
            ))
            }

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
            </div>

        </div>}

        {step == 2 &&
        <div style={{width:'97%'}}>
            
            <div style={{display:'flex',borderBottom:'1px solid #ddd'}}>
            <h6 onClick={()=>setstepfunc1(1)} style={{background:step1 == 1 && '#96b9fa',padding:'4px',fontSize:'13px'}}>Active</h6>
            <h6 onClick={()=>setstepfunc1(2)} style={{background:step1 == 2 && '#96b9fa',padding:'4px',fontSize:'13px'}}>InActive</h6>
            </div>
            <div style={{borderBottom:'1px solid #eee',minHeight:'52px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
            <BoldText1 val={`Employee List (${pagination1?.total})`} />


            <div style={{display:'flex',alignItems:'center',justifySelf:'flex-end',justifyContent:'flex-end',width:'auto'}}>

            {employee_selected?.length > 0 &&
            <div style={{width:'130px'}}>
               <PrimaryButton btn_name={'Add Attendance'} onClick={()=>setvisible(true)} />
            </div>
            }

            {step == 2 &&
            <div style={{width:'130px'}}>
               <PrimaryButton btn_name={'Add Reliever'} onClick={()=>setvisible1(true)} />
            </div>}

            </div> 
            </div>
            {step1 == 1 &&
            <h1 onClick={()=>selectAllData()} style={{fontSize:'11px',fontWeight:'bold',marginTop:'5px',cursor:'pointer'}}> <span style={{textDecorationLine:'underline',}}>Select All </span>   {employee_selected?.length > 0  && ` /  Total Selected - ${employee_selected?.length}`}</h1>
            }
            <div style={{display:'flex',flexDirection:'row',borderTop:'1px solid #eee',marginTop:'10px',borderBottom:'1px solid #eee',borderLeft:'1px solid #eaeded',}}>
            <h6 style={{width:'4%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}></h6>
            <h6 style={{width:'12%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Employee Name</h6>
            <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Employee Id</h6>
            <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>DOJ</h6>
            <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>Designation</h6>
            <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> Stage</h6>
            <h6 style={{width:'20%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> Shift</h6>
            <h6 style={{width:'10%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> OT </h6>
            <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> OT Hrs</h6>
            <h6 style={{width:'8%',fontSize:'11px',fontWeight:'700',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eaeded',}}> Action </h6>
            </div>
            {data1?.map((d1)=>(
            
            <div  style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #eee',borderLeft:'1px solid #eaeded',}}>
              <h6 style={{width:'4%',fontSize:'11px',marginBottom:'0px',borderRight:'1px solid #eee'}}>
              {step1 == 1 &&
              <div style={{marginTop:'4px',marginLeft:'15px',display:'flex'}}>
                <p className={employee_selected?.find((f)=>f?._id === d1?._id) !== undefined ? 'checked_here2': 'checked_here'}  onClick={()=>handle_employee_select(d1)}>
                {employee_selected?.find((f)=>f?._id === d1?._id) !== undefined && <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
              </div>}
              </h6>
              <h6 style={{width:'12%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.name}</h6>
              <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.reliever_id}</h6>
              <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{"NA"}</h6>
              <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}>{d1?.designation?.display_name}</h6>
              <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> {'NA'}</h6>
              <h6 style={{width:'20%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> { 'NA'}</h6>
              <h6 style={{width:'10%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> {'NA'} </h6>
              <h6 style={{width:'8%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',borderRight:'1px solid #eee'}}> {'NA'}</h6>
              <h6  style={{width:'7.5%',fontSize:'11px',padding:'6px 4px',marginBottom:'0px',color:'#000',marginLeft:'5px',borderRight:'1px solid #eaeded',}}> 
                {step1 == 1 &&
                <AiOutlineCreditCard onClick={()=>navigation('/fms/employee_based_on_clients/add_attendance',{state:{...d1,type:'reliever'}})}  size={15} />}
                <AiOutlineEdit size={15} style={{marginLeft:'10px'}} onClick={()=>{setrelieverfunc(d1);setvisible1(true)}} />
              </h6>
            </div>
            ))
            }

            <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                {pagination.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
            </div>

        </div>}
    </FmsDashboardMainRoot>
  )
}

export default EmployeeBasedOnClient