import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { SmallText } from '../../../helpers/constants/ConstantsText'
import { Input,DatePicker,Select,Upload,Button } from 'antd'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import {useSelector} from 'react-redux'
import moment from 'moment'
import makeAnimated from 'react-select/animated';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { useNavigate,useLocation } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import TextArea from 'antd/lib/input/TextArea'
import {AiOutlineCloseCircle} from 'react-icons/ai'
// import Select from 'antd'
import { base_url } from '../../../App'
import { CloseOutlined, UploadOutlined } from '@mui/icons-material';



function FmsLongLeaveCreate() {

  const {state} = useLocation() 



  const [data,setdata] = useState({id:'',employee:'',from_date:'',to_date:'',reporting_to:'',remarks:'',hr_approved:false,leave_letter:''})
  const [error,seterror] = useState({id:'',employee:'',from_date:'',to_date:'',reporting_to:'',remarks:'',hr_approved:'',leave_letter:''})
  const [employeeArr,setemployeeArr] = useState([])
  const roles = useSelector(state => state.Auth.roles)
  const navigator = useNavigate()
   
  
  useEffect(()=>{
      if(state !== null ){
        let d = state
        // console.log("file here",d?.file === '')
        setdata({
            id:d?._id,
            employee:{label:`[${d?.employee?.employee_id}]_${d?.employee?.name}_[client:${d?.employee?.department_branch_were_deployed?.client_name}]`,value:d?.employee?._id},
            from_date:d?.from_date?.slice(0,10),
            to_date:d?.to_date?.slice(0,10),
            reporting_to:d?.reporting_to,
            remarks:d?.remarks,
            hr_approved:d?.hr_approved,
            leave_letter:(d?.leave_letter !== null && d?.leave_letter !== undefined && d?.leave_letter !== '') ?  d?.leave_letter : ''
            
           
        })
      }
  },[])

  function handleChange1(v,name){   
    setdata({...data,[name]:employeeArr?.find(v1=>v1.value === v)})
    seterror({...error,[name]:''})
  }


  const filterEmployee = async (v) => {

    if(v.length > 3){
       await axios.post(`fms/basicemployee/search?search_text=${v}`,{},authHeader)
        .then((res)=>{
            let arr = []
            if(res?.data?.data?.length > 0){
                res?.data?.data?.forEach((d)=>{
                    console.log("d annaya",d)
                    arr.push({value:d?.id,label:`[${d?.employee_id}]_${d?.name}_[client:${d?.department_branch_were_deployed?.client_name}]`})
                })
                setemployeeArr(arr)
                
                return arr.filter((i) =>
                i.label.toLowerCase().includes(v.toLowerCase())
                );

            }else{
                return arr
            }

        }).catch((err)=>{
            // console.log("err data",err?.response?.data)
        })
    }
   
  };


  async function submitdata(){

   

    
    if(!data?.employee?.value){
        seterror({...error,employee:'This Field is required*'})
    }else if(!data?.from_date){
        seterror({...error,from_date:'This Field is required*'})
    }else if(!data.to_date){
        seterror({...error,to_date:'This Field is required*'})
    }else if(!data.reporting_to){
        seterror({...error,reporting_to:'This Field is required*'})
    }else if(!data.leave_letter){
        seterror({...error,leave_letter:'This Field is required*'})
    }else{

      
        if(state === null){
             let send_data = {...data}
             send_data['employee'] = data?.employee?.value
             authHeader['headers']['Content-Type'] = 'application/json'

             await axios.post(`fms/longleave/create`,send_data,authHeader)
                .then((res)=>{
                    toast.success(res?.data?.message)
                    setTimeout(() => {
                    }, 1500);
                    resetform()
                }).catch((err)=>{
                    if(err?.response?.data?.err?.keyValue?.employee !== undefined){
                        seterror({...error,employee:'Exit form for this employee already exist'})
                    }
                })
        }else{
            let send_data = {...data}
            send_data['employee'] = data?.employee?.value
            authHeader['headers']['Content-Type'] = 'application/json'

           await axios.put(`fms/longleave/update/${data?.id}`,send_data,authHeader)
            .then((res)=>{
                toast.success(res?.data?.message)
                setTimeout(() => {
                    navigator(-1)
                }, 1000);
                resetform()
            }).catch((err)=>{
                if(err?.response?.data?.err?.keyValue?.employee !== undefined){
                    seterror({...error,employee:'Exit form for this employee already exist'})
                 }
            })
        }


    }
  }

  function handleUpload(v){
   
    authHeader['headers']['Content-Type'] = 'multipart/form-data'
    var fd = new FormData()
    fd.append('file',v?.fileList[v?.fileList.length - 1]?.originFileObj)
    axios.post(`fms/basicemployee/upload_file`,fd,authHeader)
    .then((res)=>{
        setdata({...data,leave_letter:res?.data?.data})
        seterror({...error,leave_letter:''})
    }).catch((err)=>{
        console.log("err data",err)
    })
  }


  function resetform(){
    setdata({id:'',employee:'',from_date:'',to_date:'',reporting_to:'',remarks:'',hr_approved:false})
    seterror({id:'',employee:'',from_date:'',to_date:'',reporting_to:'',remarks:'',hr_approved:''})
  }

  function openfile(v){
      let url = `${base_url}${v}`
      window.open(url,'_blank')
   }


  return (
    <FmsDashboardMainRoot>
        <Toaster/>
       <div >

        <div style={{width:'25%'}}>
            <h6>Long Leave Employee Form</h6>
            <h6 style={{fontSize:'10px',background:'#3d7aba',fontWeight:'600',color:'#fff',padding:6}}>Use the below form to create or update the exit formalities of an onboardede employee</h6>
        </div>
        <div style={{display:'flex',marginTop:'40px'}}>
        <div style={{width:'25%',marginRight:'30px',borderRight:'1px solid #ddd',paddingRight:'20px'}}>

        <SmallText fontWeight={'600'} val={'Select Employee'}  />
        {(data?.employee !==  '') &&
        <>
        <div style={{position:'relative'}}>
        <h6 style={{border:'1px solid #ddd',padding:'8px',fontSize:'13px',borderLeft:'4px solid #292961'}}>{data?.employee?.label?.split('_')[1]}</h6>
            <AiOutlineCloseCircle style={{position:'absolute',right:10 ,top:10}}  onClick={()=>setdata({...data,employee:''})}/>
        </div>
        <SmallText fontWeight={'600'} val={'Employee Id'}  />

        <h6 style={{border:'1px solid #ddd',padding:'8px',fontSize:'13px',borderLeft:'4px solid #292961'}}>{data?.employee?.label?.split('_')[0]?.replace('[','')?.replace(']','')}</h6>
        {/* <SmallText fontWeight={'600'} val={'Client Place'}  /> */}
        {/* <h6 style={{border:'1px solid #ddd',padding:'8px',fontSize:'13px',borderLeft:'4px solid #292961'}}>{data?.employee?.label?.split('_')[2]?.replace('[','')?.replace(']','')?.replace('client:','')}</h6> */}
        </>
        }
        {(data?.employee ===  '') &&
        <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961',}}>
                 <Select 
                   showSearch
                   filterOption={false}
                   style={{ width: '100%',boxShadow:'none' }}
                   value={data?.employee === '' ? null : data?.employee?.label}
                   options={employeeArr}
                   onChange={(v)=>handleChange1(v,'employee')}
                   onSearch={filterEmployee}
                  
                 />
        </div>}


        <div style={{display:'flex'}}>
            {(error?.employee) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.employee  }</p>
        </div>

                
                <div  style={{margin:'0px 0px'}}>
               <SmallText fontWeight={'600'} val={'From Date'}  />

                 <DatePicker 
                    value={data?.from_date !== '' ? moment(data?.from_date) : null}
                    size="small"  
                    placeholder=''
                    format={'YYYY-MM-DD'}  
                    onChange={(v,v1)=>{setdata({...data,from_date:v1});seterror({...error,from_date:''})}} 
                    style={{width:'100%',padding:'4px',borderLeft:'4px solid #292961',borderRadius:'0px'}} 
                 />
                </div>

            <div>
                {(error?.date_of_exit) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.date_of_exit}</p>
            </div>


            <div  style={{margin:'10px 0px'}}>
               <SmallText fontWeight={'600'} val={'To Date'}  />

                <DatePicker 
                    value={data?.to_date !== '' ? moment(data.to_date) : null}
                    size="small"  
                    placeholder=''
                    format={'YYYY-MM-DD'}  
                    onChange={(v,v1)=>{setdata({...data,to_date:v1});seterror({...error,to_date:''})}} 
                    style={{width:'100%',padding:'4px',borderLeft:'4px solid #292961',borderRadius:'0px'}} 
                 />
                </div>

            <div>
                {(error?.to_date) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.to_date}</p>
            </div>

                
         
        <div style={{marginBottom:'10px'}}>
            <div style={{width:'150px',marginTop:'10px'}}>
            <SmallText fontWeight={'600'} val={'Reporting To'}  />
            </div>
            <Input type="text"  style={{borderLeft:'4px solid #292961',}}  value={data.reporting_to} onChange={(e)=>{setdata({...data,reporting_to:e.target.value});seterror({...error,reporting_to:''})}} />
        </div>

        <div style={{display:'flex'}}>
           {(error?.reporting_to) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
           <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.reporting_to}</p>
        </div>

       


            
        <div style={{marginBottom:'10px'}}>
                   <div style={{width:'280px',position:'relative'}}>
                   <SmallText fontWeight={'600'} val={'Leave Letter'}  />
                   {(data?.leave_letter !== '' && data?.leave_letter !== null && data?.leave_letter !== undefined && data?.leave_letter !== 'null' && data?.leave_letter !== 'undefined') && <h6 style={{position:'absolute',fontSize:'8px',fontWeight:'700',right:'0px',top:'5px',cursor:'pointer'}} ><span style={{color:'black'}} onClick={()=>openfile(data?.leave_letter)} > View Image</span></h6>}
                   </div>
          <div style={{minWidth:'200px !important'}}>
            {(data.leave_letter === '' || data.leave_letter === null || data.leave_letter == undefined) ?
                <Upload showUploadList={false} onChange={(v)=>handleUpload(v)} style={{width:'200px'}}>
                    <Button style={{width:'280px',display:'flex',alignSelf:'flex-end',justifyContent:'end'}} icon={<UploadOutlined   style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px'}}/>}></Button>
                </Upload>
                :
                <Button onClick={()=>{setdata({...data,leave_letter:null});seterror({...error,leave_letter:null})}}  style={{width:'280px',display:'flex',alignItems:'center',fontSize:'10px',marginBottom:'0px'}} >
                    <p style={{marginBottom:'0px', wordBreak: 'break-word',whiteSpace:'nowrap',width:'90%',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',textAlign:'left'}}>{data.leave_letter?.name != undefined ? data.leave_letter?.name : data?.leave_letter?.split('/')[data?.leave_letter?.split('/').length-1] }</p>
                    {(data.leave_letter !== '' && data.leave_letter !== null && data.leave_letter !== undefined) && <CloseOutlined size={15}  style={{borderLeft:'1px solid #eee',color:'#999',paddingLeft:'0px',position:'absolute',right:'0px'}}/>}
                </Button>
                }
          </div>
         </div> 

         <div style={{display:'flex'}}>
           {(error?.leave_letter) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
           <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.leave_letter}</p>
        </div>
       


        <div style={{marginBottom:'10px'}}>
            <div style={{width:'150px'}}>
            <SmallText fontWeight={'600'} val={'Remarks '}  />
            </div>
            <TextArea type="text"   value={data.remarks} onChange={(e)=>setdata({...data,remarks:e.target.value})} />
            <div style={{display:'flex'}}>
            {(error?.remarks) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
            <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.remarks  }</p>
            </div>
        </div>



        {(roles.includes('fms_hr') || roles.includes('admin')) &&
        <div style={{marginBottom:'2px',marginTop:'1px',display:'flex'}}>
                <p>
                    <p className={'checked_here2'} style={{cursor:'pointer'}} onClick={()=>setdata({...data,hr_approved:!data.hr_approved})}>
                    {data.hr_approved &&  <p className='checked_here1' style={{background:theme_color}}></p>}
                </p>
                </p>
            <div style={{width:'400px',marginLeft:'10px'}}>
            <SmallText fontWeight={'600'} val={'HR Approved'}  />
            </div>
        </div>}
     
       

        </div>
        </div>



        {((roles.includes('fms_user') && !data?.hr_approved) || (roles.includes('fms_hr') || roles.includes('admin'))) &&
        <div style={{display:'flex',zIndex:10,paddingBottom:'60px',width:'40px',marginLeft:0}}>
            <div style={{width: data.edit ? '200px' : '150px',zIndex:1}} onClick={submitdata}>
            <PrimaryButton  btn_name = {data.id !== '' ? `Save` : `Save`}  />
            </div>
            <div onClick={()=>navigator(-1)}>
            <SecondaryButton btn_name = 'cancel' />
            </div>
        </div>}

       </div>
    </FmsDashboardMainRoot>
  )
}

export default FmsLongLeaveCreate