import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Pagination,DatePicker ,Tag} from 'antd';
import moment from 'moment'
import { data_not_found } from '../../../helpers/Constants';
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import { Toaster, toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { local_base_url } from '../../../App'
import fileDownload from "js-file-download";
import { useSelector } from 'react-redux'
import { Select } from 'antd'
import { BsSearch } from 'react-icons/bs';
import { MdOutlineRefresh } from 'react-icons/md'
import { AiOutlineDelete } from 'react-icons/ai';


function MissedClientScan() {

  const navigate = useNavigate()
  const roles = useSelector(state => state.Auth.roles)

  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)

  const [clients,setclients] = useState([])
  const [checkpoints,setcheckpoints] = useState([])

  const [filter,setfilter] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',client:'',check_point:''})

  useEffect(()=>{
    getdata(page)
  },[page])

 

  async function getdata(v){
    await axios.get(`fms/missed_client_place_scanner/get/''?page=${v ? v : page}&from_date=${filter?.from_date1}&to_date=${filter?.to_date1}`,authHeader)
    .then((res)=>{
      setdata(res.data.datas)
      setpagination(res.data.pagination)
    }).catch((err)=>{
    })
  }

  

  async function searchData(v){ 
    await axios.get(`fms/fmsclients/search?search_text=${v}&are_we_serving=1`,authHeader)
     .then((res)=>{
         let arr = []
         res?.data?.datas?.forEach(d => {
             arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
         });
         setclients(arr)
         return arr
     }).catch((err)=>{
     })
   }

   async function getcheckpointsbasedclient(v){ 
    await axios.get(`fms/fmsclientspoints/get/${v}?limit=1000&page=1`,authHeader)
     .then((res)=>{
        let arr = []
        res?.data?.datas?.forEach(d => {
            arr.push({label:`${d?.name}`,value:d?._id})
        });
        setcheckpoints(arr)
     }).catch((err)=>{
     })
   }

  function setpagefuun(v){
    setpage(v)
  }

  async function applyFilter(){
    await axios.get(`fms/missed_client_place_scanner/get?page=${1}&from_date=${filter?.from_date1}&to_date=${filter?.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}&check_point=${filter.check_point !== '' ?  filter?.check_point?.value : ''}`,authHeader)
    .then((res)=>{
      setdata(res.data.datas)
      setpagination(res.data.pagination)
    }).catch((err)=>{
    })
  }

  async function resetFilter(){
     setfilter({from_date:'',to_date:'',from_date1:'',to_date1:'',client:'',check_point:''})
     await axios.get(`fms/missed_client_place_scanner/get?page=${1}&from_date=${''}&to_date=${''}&client=${''}&check_point=${''}`,authHeader)
     .then((res)=>{
       setdata(res.data.datas)
       setpagination(res.data.pagination)
     }).catch((err)=>{
     })
  }

  async function downloadReport(){
    await axios.post(`fms/missed_client_place_scanner/download_report?page=${1}&from_date=${filter.from_date1}&to_date=${filter.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}&check_point=${filter.check_point !== '' ?  filter?.check_point?.value : ''}`,{},authHeader)
    .then((res)=>{
      let path = res.data.path.replace('public/','')
      handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
    }).catch((err)=>{
    })
  }  

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Employee Excel Downloaded") 
        })
  }

  function convertTo12HourFormat(timeRange) {
    return timeRange.split('-').map(time => {
        let [hours, minutes] = time.split(':').map(Number);
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12 and handle PM conversion
        return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
    }).join(' - ');
  }

  async function handlechangeclient(e){
    setfilter({...filter,client:clients?.find(f=>f.value === e)})
    getcheckpointsbasedclient(e)
  }

  async function deleteDatafunc(d) {
   await axios.delete(`fms/missed_client_place_scanner/delete/${d}`,authHeader)
    .then((res)=>{
      toast.success("Missed Client Span Deleted Successfully")
     getdata()
    }).catch((err)=>{

    })
  }

  return (
    <FmsDashboardMainRoot>
      <Toaster />
      <div style={{width:'98%'}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingBottom:'2px',borderBottom:'1px solid #ddd'}}>
        <BoldText1 val={`Missed Client Scan Report (${pagination?.total})`} /> 

        {(roles.includes('admin') || roles.includes('fms_hr') || roles.includes('fms_crm_head')) &&
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:'-20px'}}>
         
         <Select showSearch filterOption={false} placeholder={'Select Client'} value={filter?.client === '' ? null : filter?.client} options={clients}  onSearch={searchData} onChange={(e)=>handlechangeclient(e)} style={{width:'120px',marginBottom:0,marginRight:10}} />
         
          {checkpoints?.length > 0 &&
          <Select placeholder={'Select CheckPoint'} value={filter?.check_point === '' ? null : filter?.check_point} options={checkpoints}  onChange={(e)=>setfilter({...filter,check_point:checkpoints?.find(f=>f.value === e)})} style={{width:'120px',marginBottom:0,marginRight:10}} />
           }

          <DatePicker value={filter?.from_date} style={{width:'130px'}} onChange={(e,e1)=>setfilter({...filter,from_date:e,from_date1:e1})} size='medium' placeholder='From Date' />
          <h6 style={{opacity:0}}>W</h6>
          <DatePicker value={filter?.to_date} style={{width:'130px'}} onChange={(e,e1)=>setfilter({...filter,to_date:e,to_date1:e1})} placeholder='To Date'  />
          <h6 style={{opacity:0}}>W</h6>
        
          <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'0px',background:'#eee',padding:'7px'}}/>
          <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>

          <h6 style={{opacity:0}}>W</h6>
          <PrimaryButton onClick={downloadReport} btn_name={'Download Excel'} />

        </div>}
        </div>
      </div>

      <div>
      {data.length > 0 ? 
      <div style={{ border: '0px solid lightgray',marginTop:'20px', width:'98%' }}>
    

        <div>
              
            {data.length > 0 ? 
              <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
                <div style={{border:'1px solid #eaeded',}}>
                  <div className='mateial_table_header'>
                      <p className='checked_here' style={{opacity:0}} ></p> 
                      <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',maxWidth:'8%',minWidth: '8%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
                      <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',maxWidth:'10%',minWidth: '10%' }}>Client Info </p>
                      <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',maxWidth:'15%',minWidth: '15%',marginLeft:'20px', }}>Client Place </p>
                      <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',maxWidth:'15%',minWidth: '15%',paddingLeft:'10px' }}>Point Lat / Lon </p>
                      <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',maxWidth:'15%',minWidth: '15%',paddingLeft:'10px' }}>Time Slots </p>
                      <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',maxWidth:'20%',minWidth: '20%',paddingLeft:'10px' }}>Scan Access</p>
                      <p style={{fontSize:'12px',maxWidth:'12%',minWidth: '12%',paddingLeft:'10px' }}>Created At</p>
                  </div>


                  {data?.map((d, i) => (
                      <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%',borderTop:'1px solid #eaeded',}}>
                          <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', maxWidth:'8.45%',minWidth: '8.45%', color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'10px',marginLeft:'0px', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                          <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', maxWidth:'10.5%',minWidth: '10.5%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',paddingLeft:'0px' }}>{d?.point?.client?.client_name == '' ? 'Not Added' : d?.point?.client?.client_name}</p>
                          <p style={{paddingLeft:'10px',borderRight:'1px solid #eaeded',fontSize:'12px', maxWidth:'15%',minWidth: '15%',  wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'15px',}}>{d?.point?.name}</p>
                          <p style={{paddingLeft:'10px',borderRight:'1px solid #eaeded',fontSize:'12px', maxWidth:'15%',minWidth: '15%', marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-5px'}}>{d?.point?.lats} / {d?.point?.long}</p>
                          <p style={{paddingLeft:'10px',borderRight:'1px solid #eaeded',fontSize:'12px', maxWidth:'15%',minWidth: '15%', textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-5px' }}> {convertTo12HourFormat(d?.selectTimeSlots)}</p>
                          <p style={{paddingLeft:'10px',borderRight:'1px solid #eaeded',fontSize:'12px', maxWidth:'20%',minWidth: '20%', textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-5px' }}>
                            
                           {d?.field_officer?.length> 0 &&  
                            <>
                            <h6 style={{fontSize:'11px',fontWeight:'bold'}}>Field Officer</h6>
                            {d?.field_officer?.map((f)=> <span style={{padding:'4px 5px',background:'#dbe9ff',fontSize:10,margin:'4px 5px'}}>{f?.name}</span>)}
                            </>}

                            {d?.deployed_officer?.length> 0 && 
                            <>
                            <h6 style={{fontSize:'11px',fontWeight:'bold'}}>Deployed Officer</h6>
                            {d?.deployed_officer?.map((f)=> <span style={{padding:'4px 5px',background:'#dbe9ff',fontSize:10,margin:'4px 5px'}}>{f?.name}</span>)}
                            </>}
                        
                          </p>
                          <p style={{paddingLeft:'10px',display:'flex',flexDirection:'row',maxWidth:'12%',minWidth: '12%', fontWeight:'800',marginLeft:'-5px',fontSize:'10px !important',}}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> 
                           <AiOutlineDelete style={{marginLeft:'10px'}} onClick={()=>deleteDatafunc(d?._id)} />
                          </p>
                      </div>
                  ))}

              </div>    

              <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                  {pagination.total > 15 &&
                      <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                  }
              </div>

              </div> 
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                  <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                  <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                  <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
              </div>}
        </div>  

      </div> 
      :
      <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
          <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
          <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients scan report created yet"}</h4>
      </div>}
      </div>

    </FmsDashboardMainRoot>
  )
}

export default MissedClientScan