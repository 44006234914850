import React,{useState,useEffect} from 'react';
import { BoldHeadText, BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
import FmsDashboardMainRoot from '../FmsDashboardMainRoot';
import Drawer from '@mui/material/Drawer';
import {Input,Modal,Pagination} from 'antd'
import {useSelector} from 'react-redux'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import {BiEditAlt} from 'react-icons/bi'
import toast,{ Toaster } from 'react-hot-toast';
import { IoClose } from 'react-icons/io5';
import Tooltip from '@mui/material/Tooltip';
import { IoIosClose } from 'react-icons/io';
import { data_not_found } from '../../../helpers/Constants';
import { MdDeleteOutline,MdOutlineRefresh } from 'react-icons/md'
import { AiOutlineFileExcel } from "react-icons/ai";
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { local_base_url } from '../../../App';
import fileDownload from "js-file-download";


function FmsDispensaryOnboardEmployee(){

    const AuthHeader = {headers:{
        'Content-Type': 'application/json',
    }}

    const roles = useSelector(state => state.Auth.roles)

    // console.log("roles kp here",roles)


    const [drawer,set_drawer] = useState(false)
    const [data,setdata] = useState({id:'',dispensary:''})
    const [error,seterror] = useState({dispensary:''})
    const [modalvisible,setmodalvisible] = useState(false)
    const [search,setsearch] = useState('')
    const [dispensary,setdispensary] = useState([])
    
    const [pagination,setpagination] = useState({})
    const [page,setpage] = useState(1)

    // const { TextArea } = Input;
    const theme_color = useSelector(state=>state.Theme.theme_color)

    // const [pagination,setpagination] = useState()




    function func(){

    }

    function setpagefuun(v){
        setpage(v)
    }




    useEffect(()=>{
        getdata()
    },[page])

    function getdata(){
        axios.get(`fms/fmsdispensary/get?page=${page}`,AuthHeader)
        .then((res)=>{
            setpagination(res.data.pagination)
            setdispensary(res.data.datas)
        }).catch((err)=>{
            // console.log('err data here na',err)
        })
    }

    function searchData(){
        axios.get(`fms/fmsdispensary/search?search_text=${search}`,AuthHeader)
        .then((res)=>{
            setpagination(res.data.pagination)
            setdispensary(res.data.datas)
        }).catch((err)=>{
            // console.log('err data here na',err)
        })
    }
    
    function submitform(){
        if(!data.dispensary){
            seterror({...error,dispensary:'The Dispensary field is required*'})
        }else{
            if(data.id === ''){
                let sendData = {
                    name : data?.dispensary
                }
                axios.post('fms/fmsdispensary/create',sendData,AuthHeader)
                .then((res)=>{
                    getdata()
                    toast.success(res.data.message)
                    set_drawer(false)
                    setdata({dispensary:'',id:''})
                    seterror({dispensary:''})
                }).catch((err)=>{
                // console.log('err data here na',err)
                })
            }else{
                let sendData = {
                    name : data?.dispensary
                }
                axios.post(`fms/fmsdispensary/update/${data.id}`,sendData,AuthHeader)
                .then((res)=>{
                    getdata()
                    set_drawer(false)
                    toast.success(res.data.message)
                    setdata({dispensary:'',id:''})
                    seterror({dispensary:''})
                }).catch((err)=>{
                // console.log('err data here na',err)
                })
            }
            }
    }


    function deletefunc(){
        axios.delete(`fms/fmsdispensary/delete/${data.id}`,data,AuthHeader)
        .then((res)=>{
            getdata()
            setmodalvisible(false)
            toast.success(res.data.message)
            setdata({dispensary:'',id:''})
            seterror({dispensary:''})
        }).catch((err)=>{
        // console.log('err data here na',err)
        })
    }

    async function downloadExcel(){   
        await axios.get(`fms/fmsdispensary/downloadExcel`,authHeader)
        .then((res)=>{
            let path = res.data.path.replace('public/','')
            handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
        })
    }

    const handleDownload = (url, filename) => {
        // console.log('url',url,'filename',filename)

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Clients Excel Downloaded") 
            })
    }

    return(
         <FmsDashboardMainRoot>
            <Toaster />

            <Drawer
                anchor='right'
                style={{minWidth:'200px',maxWidth:'200px',zIndex:10}}
                    open={drawer}
                    onClose={()=>{set_drawer(!drawer);setdata({id:'',dispensary:''})}}
                > 
                        <div style={{width:'300px',padding:'30px'}}> 
                           
                        <IoClose  style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={()=>set_drawer(false)} />

                           <div>
                            <BoldHeadText  val="Add/Edit My Dispensary" fontSize={'15px'} fontWeight='900'/>
                            <p style={{borderBottom:'1px solid #eee'}}></p>
                              

                            <div style={{marginBottom:'10px'}}>
                                <div style={{width:'150px'}}>
                                <SmallText fontWeight={'600'} val={'Dispensary Place'}  />
                                </div>
                                <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.dispensary} onChange={(e)=>{setdata({...data,dispensary:e.target.value});seterror({...error,dispensary:''})}} />
                            </div>
                            
                            

                               
                               

                            <p style={{marginTop:'1px'}}></p>
                            
                            <PrimaryButton btn_name={'Continue'} onClick={submitform} />
                            

                          </div>
                        </div>
            </Drawer>
              
              <div style={{display:'flex',alignItems:'center',width:'49%',justifyContent:'space-between'}}>
              <BoldText1 val={`Dispensary (${pagination?.total !== undefined ? pagination?.total : dispensary?.length})`} />
              <div style={{display:'flex',alignItems:'center'}}>
              <Input type="text" style={{marginRight:0,width:'40%'}} value={search} onChange={(e)=>setsearch(e.target.value)} onKeyPress={(e)=>e.key === 'Enter' && searchData()} />
               <MdOutlineRefresh onClick={()=>{getdata();setsearch('')}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}}/>
               {(roles.includes('admin') || roles.includes('fms_hr'))  && 
               <AiOutlineFileExcel onClick={()=>{downloadExcel();}} size={30} style={{marginLeft:'5px',marginRight:'5px',background:'#eee',padding:'4px'}} />}
              <PrimaryButton btn_name={'Add Dispensary'} onClick={()=>{set_drawer(true);setdata({id:'',dispensary:''})}}/>
              </div>
              </div>

              <Modal open={modalvisible} footer={false} closable={false} onCancel={() => setmodalvisible(false)} style={{ maxWidth: '280px' }} centered>
                    <div style={{ maxWidth: '200px', }}>
                    <IoIosClose  onClick={() => setmodalvisible(false)}size={20} style={{position:'absolute',top:'20px',cursor:'pointer',right:'25px',zIndex:100}} />
                        <h6 style={{ padding: '1%', fontWeight: '700', fontSize: '13px' }}>Delete dispensary</h6>
                        <h6 style={{ fontSize: '11px', width: '112%', wordBreak: 'break-word', padding: '8px', background: '#def0ff', border: '1px solid #236499', borderRadius: '2px' }}><strong>Note:</strong>do you really want to delete the dispensary once deleted cannot be retrieved back</h6>
                        <div style={{ display:'flex',width:'100%',marginLeft:'90px',justifyItems:'flex-end'}} >
                            <SecondaryButton btn_name={' Cancel '} width="100%" onClick={()=>{setdata({id:'',dispensary:''});setmodalvisible(false)}}/>
                            <PrimaryButton btn_name={' Delete'}  width="100%"  onClick={deletefunc}/>
                        </div>
                    </div>
                </Modal>
             

                <div style={{width:'48%'}}>
                {dispensary.length > 0 ? 
//                 <div style={{ border: '0px solid lightgray',marginTop:'0px', overflowY: 'scroll',width:'100%' }}>
//                     <div className='mateial_table_header'>
//                         {/* <p className='checked_here' style={{opacity:0}} ></p>  */}
//                         <p style={{ width: '8%', color: 'black',marginLeft:'15px' }}>Sl No</p>
//                         <p style={{ width: '70%', marginRight: '2%',marginLeft:'15px' }}>dispensary Name</p>
                       
//                         <p style={{ width: '10%' }}>Actions</p>
//                     </div>


//                     {dispensary.map((d, i) => (
//                         <div key={i} className={'mateial_table_body'}>
//                             {/* <p  onClick={()=>selected_client !== d._id ? set_selected_client(d._id) : set_selected_client() } className='checked_here' style={{opacity:0}}>
//                             {selected_client === d._id && <p className='checked_here1' style={{background:theme_color}}></p>}
//                             </p> */}
//                             <Tooltip><p style={{ minWidth: '8%',maxWidth:'8%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textAlign:'center' }} onClick={() => func(d)}>{page == 1 ?  i+1 : (pagination?.limit * (page-1)) + (i+1)}</p></Tooltip>
//                             <Tooltip  title={d?.name}><p style={{ minWidth: '70%',maxWidth:'70%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'25px',marginRight:'2%' }} onClick={() => func(d)}>{d?.name}</p></Tooltip>
//                             {!roles.includes('fms_data_view') && (roles.includes('fms_hr') || roles?.includes('fms_crm_head') || roles?.includes('admin')) &&
                       
//                             <p style={{ width: '10%', display: 'flex',alignItems:'center',marginLeft:'0px', }}>
//                             <Tooltip title={'Edit'}>
//                                 <span>
//                                 <BiEditAlt size={14} onClick={() =>{setdata({...data,id:d._id,dispensary:d?.name});set_drawer(true)}} style={{ color: '#000',marginRight:'10px' }}  />
//                                 </span>
//                             </Tooltip>
//                             {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
//                             <Tooltip title={'Delete'}>
//                                 <span>
//                                 <MdDeleteOutline size={14} onClick={() => {setdata({...data,id:d._id,dispensary:d?.dispensary});setmodalvisible(true)}} style={{ color: '#000' }} />
//                                 </span>
//                             </Tooltip>}
//                             </p>
//                             }
//                         </div>
//                     ))}

// <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
//                 {pagination?.total > pagination?.limit &&
//                     <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
//                 }
//                 </div>


//                 </div> 

               <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                              <div className='mateial_table_header' style={{border:'1px solid #eaeded'}}>
                                  <p className='checked_here' style={{opacity:0}} ></p> 
                                  <p style={{fontSize:'11px',minWidth: '10%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                  <p style={{fontSize:'12px',minWidth: '28%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Dispensary Name</p>
                                  <p style={{fontSize:'12px',minWidth: '7%',maxWidth: '10%' }}>Actions</p>
                              </div>
                              {dispensary?.map((d,i)=>(
                              <div key={d?._id} style={{borderLeft:'1px solid #eaeded',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                                  <p className='checked_here' style={{opacity:0}} ></p> 
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '9.9%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '26.6%',maxWidth: '9.9%',borderRight:'1px solid #eaeded', marginRight: '0.5%', color: "#0594f2",wordBreak: 'break-word', fontWeight: '700',fontSize:'13px'}} onClick={() => func(d)}>{d?.name}</p>
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                                  <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                        
                                       <BiEditAlt size={14} onClick={() =>{setdata({...data,id:d._id,dispensary:d?.name});set_drawer(true)}} style={{ color: '#000',marginRight:'10px' }}  />

                                       {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                               <MdDeleteOutline size={14} onClick={() => {setdata({...data,id:d._id,dispensary:d?.dispensary});setmodalvisible(true)}} style={{ color: '#000' }} />}

                                  </p>
                                  </p>
                              </div>))}
                              <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                {pagination?.total > pagination?.limit &&
                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                }
                             </div>

                </div> 
                :
                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any dispensary created yet"}</h4>
                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To add employee dispensary click on the add button and create a new dispensary and submit"}</h4>
                </div>}
                </div>
              
         </FmsDashboardMainRoot>
    )
}




export default FmsDispensaryOnboardEmployee