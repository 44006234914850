import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText';
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import {BiEditAlt} from 'react-icons/bi';

import Tooltip from '@mui/material/Tooltip';
import { data_not_found } from '../../../helpers/Constants';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { Button, Input, Modal, Pagination, Select, Tabs, Tag } from 'antd'
import Avatar from '@mui/material/Avatar';
import { base_url, local_base_url } from '../../../App';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {FaWpforms} from 'react-icons/fa';
import {RiNewspaperLine} from 'react-icons/ri';
import {MdDeleteOutline,MdOutlineRefresh} from 'react-icons/md'
import toast,{ Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { MdExitToApp } from 'react-icons/md';
import {BsSearch} from 'react-icons/bs';
import fileDownload from "js-file-download";
import { GoDuplicate } from "react-icons/go";
import { RiShareForward2Line } from "react-icons/ri";
import { theme_color } from '../../../helpers/constants/ConstantColor';
import { AiOutlineInfoCircle } from 'react-icons/ai';


const { TabPane } = Tabs;


function FmsEmployeeOverallList(){


    const navigate = useNavigate()
    const roles = useSelector(state => state.Auth.roles)


    const [search,setsearch] = useState('')
   
    const [modal,setmodal] = useState(false)
    const [page,setpage] = useState(1)
    const [data,setdata] = useState([])
    const [pagination,setpagination] = useState({})

    const [selectedData,setselectedData] = useState({user:'',designation:'',client:'',date_of_joining:''})
    const [selectedError,setselectedError] = useState({user:'',designation:'',client:'',date_of_joining:''})

    const [clients,setclients] = useState([])
    const [designations,setdesignations] = useState([])

    useEffect(()=>{
        getdata()
    },[page])



    function getdata(){
      axios.get(`fms/basicemployee/get_all?page=${page}&search=${search}`,authHeader)
      .then((res)=>{
        setdata(res.data.datas)
        setpagination(res.data.pagination)
      })
    }

   
   
   
    function deleterecord(v){
        axios.delete(`fms/basicemployee/delete/${v}`,authHeader)
        .then((res)=>{
            toast.success(res.data.message);
            getdata('1')
        })
    }

    function setpagefuun(v){
        setpage(v)
    }

    function applyFilter(){
        setpage(1)
        axios.get(`fms/basicemployee/get_all?page=${1}&search=${search}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }

    function resetFilter(){
        setsearch('')
        setpage(1)
        axios.get(`fms/basicemployee/get_all?page=${1}&search=${''}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }


    function setselectedDatafunc(e){
        let setData = {
            employee:e,
            client:{label:`${e?.department_branch_were_deployed?.site_name}[client name : ${e?.department_branch_were_deployed?.client_name}]`,value:e?.department_branch_were_deployed?._id},
            designation:{label:e?.designation?.designation_name,value:e?.designation?._id}
        }
        setselectedData(setData)
        setmodal(true)
    }

    async function updaterejoinfunc(){
        if(selectedData?.client?.value == undefined){
            setselectedError({...selectedError,client:"This Field is required"})
        }else if(selectedData?.designation?.value == undefined){
            setselectedError({...selectedError,designation:"This Field is required"})
        }else if(!selectedData?.date_of_joining){
            setselectedError({...selectedError,date_of_joining:"This Field is required"})
        }else{
            let send_data = {...selectedData}
            send_data['employee'] = selectedData?.employee._id
            send_data['designation'] = selectedData?.designation.value
            send_data['client'] = selectedData?.client?.value 
      
            await axios.post(`fms/employee_rejoin/create`,send_data,authHeader)
            .then((res)=>{
                toast.success("Employee ReJoin Successfully!")
                setTimeout(() => {
                }, 1500);
                setmodal(false)
                setselectedData({employee:'',designation:'',client:''})
            }).catch((err)=>{
                if(err?.response?.status === 403){
                setselectedError({...selectedError,employee:err?.response?.data?.message})
                }
                if(err?.response?.data?.err?.keyValue?.employee !== undefined){
                    setselectedError({...selectedError,employee:'Oops something Error'})
                }
            })
        }
    }



  async function searchData(v,type){ 
    if(type == 'client'){
      await axios.get(`fms/fmsclients/search?search_text=${v}&are_we_serving=1`,authHeader)
      .then((res)=>{
          let arr = []
          res?.data?.datas?.forEach(d => {
              arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
          });
          setclients(arr)
          return arr
      }).catch((err)=>{
      })
    }

    if(type == 'designation'){
      await axios.get(`fms/fmsdesignationonboardemployee/search?search_text=${v}`,authHeader)
      .then((res)=>{
          let arr = []
          res.data.datas.forEach((d)=>{
              let obj = {
                  value : d._id,
                  label:d.designation_name
              }
              arr.push(obj)
          })
          setdesignations(arr)
      }).catch((err)=>{
      })
     }
  
  }

  function handleChange(v,type){
    if(type == 'client'){
        setselectedData({...selectedData,client:clients?.find((f)=>f?.value === v)})
        setselectedError({...selectedError,client:''})
    }
    if(type == 'designation'){
      setselectedData({...selectedData,designation:designations?.find((f)=>f?.value === v)})
      setselectedError({...selectedError,designation:''})
    }
  }

    return(
        <FmsDashboardMainRoot> 

            <Modal open={modal} closable={false} footer={false} width={350}>
                <div>
                    <h6 style={{ padding: '2%', fontWeight: '900', fontSize: '15px' }}>ReJoin Employee</h6>
                    <p style={{ padding: '2%', fontSize: '12px',background:'#f2f2f2' }}>Are you sure you want to rejoin the employee who has been exited.</p>

                    <h6 style={{fontSize:'13px'}}>Selected Employee : <span style={{fontWeight:'800'}}>{selectedData?.employee?.name}</span> / Employee Id : <span style={{fontWeight:'800'}}>{selectedData?.employee?.employee_id}</span></h6>

                    <div style={{width:'375px'}}>
                        <SmallText fontWeight={'500'} val={'Client'}  />
                        <Select showSearch filterOption={false} value={selectedData?.client}  options={clients}  onSearch={(v)=>searchData(v,'client')} onChange={(e)=>handleChange(e,'client')}  style={{width:'80%',marginBottom:10,borderLeft:`4px solid ${theme_color}`}} />
                    </div>

                    <div style={{display:'flex'}}>
                    {(selectedError?.client) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{selectedError?.client}</p>
                    </div>

                    <div style={{width:'375px'}}>
                        <SmallText fontWeight={'500'} val={'Designation'}  />
                        <Select showSearch filterOption={false} value={selectedData?.designation}  options={designations}  onSearch={(v)=>searchData(v,'designation')} onChange={(e)=>handleChange(e,'designation')}  style={{width:'80%',marginBottom:10,borderLeft:`4px solid ${theme_color}`}} />
                    </div>

                    <div style={{display:'flex'}}>
                    {(selectedError?.designation) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{selectedError?.designation}</p>
                    </div>

                    <div style={{width:'300px'}}>
                        <SmallText fontWeight={'500'} val={'Date of Joining'}  />
                        <Input type="date" value={selectedData?.date_of_joining} onChange={(e)=>{setselectedData({...selectedData,date_of_joining:e.target.value});setselectedError({...selectedError,date_of_joining:''})}} style={{borderLeft:`4px solid ${theme_color}`}} />
                    </div>

                    <div style={{display:'flex'}}>
                    {(selectedError?.date_of_joining) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{selectedError?.date_of_joining}</p>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', alignItems: 'flex-end', justifyItems: 'flex-end', justifySelf: 'flex-end', alignSelf: 'flex-end', alignContent: 'flex-end' }}>
                        <SecondaryButton btn_name={"Cancel"} onClick={()=>setmodal(false)}  />
                        <PrimaryButton btn_name="Submit"  onClick={()=>updaterejoinfunc()} />
                       
                    </div>
                </div>    
            </Modal>

            <Toaster />
            <div style={{width:'98%',marginBottom:'40px'}}>

             <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
              <BoldText1 val={`FMS Employees (${pagination?.total})`} />
              <div className='flex' style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <input value={search} onChange={(e)=>{setsearch(e.target.value)}} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px'}} /> 
                        <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                        <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                    </div>
              </div>   
             </div>
                

              <div style={{borderTop:'1px solid lightgray',marginTop:'5px'}}>
              
                  

                    {data.length > 0 ? 
                

                    <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                    <div className='mateial_table_header' style={{border:'1px solid #eaeded'}}>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '4%',maxWidth: '4%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>SL No</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #ddd',marginLeft:'1px' }}>Employee ID</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd',marginLeft:'-2px' }}>Employee Name</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd',marginLeft:'1px' }}>Mobile No</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #ddd',marginLeft:'-2px' }}>Designation</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #ddd',marginLeft:'1px' }}>Site Name</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #ddd',marginLeft:'-2px' }}>Status</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #ddd',marginLeft:'1px' }}>DOJ</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #ddd',marginLeft:'1px' }}>DOE</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #ddd',marginLeft:'1px' }}>Created At</p>
                        <p style={{paddingLeft:'5px',fontSize:'11px',minWidth: '5%',maxWidth: '5%' }}>Actions</p>
                    </div>
                    {data.map((d,i)=>(
                    <div key={d?._id} style={{borderLeft:'1px solid #ddd',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #ddd',borderBottom:'1px solid #ddd',height:'100%'}}>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '4.7%',maxWidth: '4.7%',borderRight:'1px solid #ddd', color: 'black',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #ddd',  }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '9.7%',maxWidth: '9.7%',borderRight:'1px solid #ddd',  }}>{d?.name}</p>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '9.9%',maxWidth: '9.9%',borderRight:'1px solid #ddd',  }}>{d?.mobile_no}</p>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '9.6%',maxWidth: '9.6%',borderRight:'1px solid #ddd',  }}>{d?.designation?.designation_name}</p>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '11.8%',maxWidth: '11.8%',borderRight:'1px solid #ddd',  }}>{d?.department_branch_were_deployed?.site_name}</p>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '7.8%',maxWidth: '7.8%',borderRight:'1px solid #ddd', }}>
                       
                       {d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> 
                        : 
                        <Tag style={{fontSize:'9px'}} color={(d?.is_approved && !d?.is_terminated && !d?.inactive_due_to_client && ['Newly Joined','Rejoined']?.includes(d?.employee_status)) ? "green" : (d?.is_terminated || d?.inactive_due_to_client || d?.employee_status === 'Exited') ? "red" : "blue" }> 
                        {(d?.is_approved && !d?.is_terminated && !d?.inactive_due_to_client && ['Newly Joined','Rejoined']?.includes(d?.employee_status)) ? 'Approved' : (d?.is_terminated || d?.inactive_due_to_client || d?.employee_status === 'Exited') ? 'Exited' : 'Pending'}
                        </Tag>}

                        </p>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #ddd', }}>{moment(d?.date_of_joining).format('ll')}</p>
                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '8%',maxWidth: '8%',borderRight:'1px solid #ddd', }}>{d?.terminated  && moment(d?.date_of_exit).format('ll')}</p>

                        <p style={{paddingLeft:'10px',marginBottom:'0px',padding:'4px 0px 0px 10px',fontSize:'11px',minWidth: '12%',maxWidth: '12%',borderRight:'1px solid #ddd', }}><span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'}</span> </p>
                        <p style={{paddingLeft:'5px',marginBottom:'0px',padding:'4px 0px 4px 4px',fontSize:'11px',minWidth: '5%',maxWidth: '5%',display:'flex' }}>
                            
                             
                        {['admin','fms_hr','fms_manager']?.filter((r)=>(roles.includes(r)))?.length > 0 &&  
                        <>
                        {(d?.is_approved && !d?.is_terminated && !d?.inactive_due_to_client && !d?.employee_status) &&
                        <>
                            <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={35} style={{ marginRight:'8px' }}  />
                            <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={35} style={{ marginRight:'8px' }}  />
                            <GoDuplicate onClick={()=>navigate(`/fms/epi_form/duplicate`,{state:d})} size={35} style={{ marginRight:'0px' }}  />
                            {(roles.includes('admin')) &&  <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={35}   style={{marginLeft:'6px'}} /> }
                         </>
                         }
                        
                         {(d?.is_terminated || d?.inactive_due_to_client || d?.employee_status === 'Exited') &&
                         <>
                         <RiShareForward2Line onClick={()=>{setselectedDatafunc(d)}} size={18} style={{padding:'5px 0px',marginLeft:'-5px'}} />
                         
                         </>}
                        </>
                        }
                      
                        </p>
                    </div>))}
                    <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                        {pagination?.total > pagination?.limit &&
                            <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                        }
                   </div>

                    </div> 
                    :
                    <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                        <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                        <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                        <h4 style={{ fontSize: '11px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                    </div>}
               </div>  
            </div>


        </FmsDashboardMainRoot>
    )
}


export default FmsEmployeeOverallList