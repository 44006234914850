import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { Pagination, Tabs, Tag } from 'antd'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { data_not_found } from '../../../helpers/Constants'
import { authHeader } from '../../../helpers/usertoken/UserToken'


const { TabPane } = Tabs;
function ReJoinBasicEmployeeToClientList() {

  const navigation = useNavigate()
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [activetab,setactivetab] = useState('Pending')

  const navigate = useNavigate()



  const roles = useSelector(state=>state.Auth.roles)
  
  const [page,setpage] = useState(1)


  useEffect(()=>{
    getData()
 },[page])
 
 async function getData(){
   await axios.get(`fms/employee_rejoin/get?page=${page}&status=${activetab}`,authHeader)
    .then((res)=>{
        console.log("res?.data",res?.data)
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
 }

 function setpagefuun(v){
    setpage(v)
 }

  function setpagefuun(v){

  }

  function deleteData(){

  }

  function setactivetabfunc(v){
    setactivetab(v)
    setpage(1)
    axios.get(`fms/employee_rejoin/get?page=${1}&status=${v}`,authHeader)
    .then((res)=>{
        setdata(res?.data?.datas)
        setpagination(res?.data?.pagination)
    })
  }


  return (
    <FmsDashboardMainRoot>
        <Toaster />


        <div>
            
            <h6 style={{fontWeight:'800',marginTop:'20px',fontSize:14,marginBottom:'4px'}}>Re Join Employee List ({pagination?.total})</h6>
             

            <Tabs
                  size="small"
                  activeKey={activetab}
                  activetab={activetab}
                  defaultValue={activetab}
                  style={{ width: '100%', margin: '10px 0px', marginBottom: '0px', position: 'sticky', top: '40px' }}
                  onChange={(v) =>{setactivetab(v);setactivetabfunc(v)}}>
                    <TabPane tab="Pending" key="Pending">
                        {data?.length > 0 ? 
                        <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'98%',overflow:'hidden' }}>
                            <div className='mateial_table_header' style={{border:'1px solid #eaeded',}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',opacity:'1' }}>SL NO</p>
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '13%',maxWidth: '13%',borderRight:'1px solid #eee', color: 'black',opacity:'1' }}>Client</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '18%',maxWidth: '18%',borderRight:'1px solid #eee'}}>Employee / Designation</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Remarks</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Requested By</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>HR Remarks</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Approved By</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Status</p>
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Created Date</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '5%',maxWidth: '5%' }}>Actions</p>
                            </div>


                            {data.map((d, i) => (
                                <div key={i}   style={{borderLeft:'1px solid #eee',padding:'0px !important',display:'flex',borderRight:'1px solid #eee',borderBottom:'1px solid #eee',height:'100%'}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5.8%',maxWidth: '5.8%',borderRight:'1px solid #eee', color: 'black',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12.8%',maxWidth: '12.8%',borderRight:'1px solid #eee', }}>{d?.client?.client_name} </p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '17.8%',maxWidth: '17.8%',borderRight:'1px solid #eee', }}>{d?.employee?.name} / {d?.designation?.designation_name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.remarks}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.created_by?.name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.hr_remarks}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.approved_by?.name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.status}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}><span style={{fontSize:'10px'}}>{moment(d?.createdAt)?.format('lll')}</span> </p>

                                <p style={{fontSize:'12px',minWidth: '5%',maxWidth: '5%',paddingLeft:'5px' }}>
                                    {!roles?.includes('fms_data_view') &&
                                        <>
                                        <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('/fms/rejoin_employee_clients',{state:d})} />
                                        {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                        <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                                        </>
                                    }
                                </p>
                                </div>
                            ))}

                        <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                            {pagination.total > 15 &&
                                <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                            }
                        </div>

                        </div> 
                        :
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                            <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                            <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                            <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                        </div>}
                    </TabPane>

                    <TabPane tab="Progress" key="Progress">
                        {data?.length > 0 ? 
                        <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'98%',overflow:'hidden' }}>
                            <div className='mateial_table_header' style={{border:'1px solid #eaeded',}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',opacity:'1' }}>SL NO</p>
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '13%',maxWidth: '13%',borderRight:'1px solid #eee', color: 'black',opacity:'1' }}>Client</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '18%',maxWidth: '18%',borderRight:'1px solid #eee'}}>Employee / Designation</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Remarks</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Requested By</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>HR Remarks</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Approved By</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Status</p>
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Created Date</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '5%',maxWidth: '5%' }}>Actions</p>
                            </div>


                            {data.map((d, i) => (
                                <div key={i}   style={{borderLeft:'1px solid #eee',padding:'0px !important',display:'flex',borderRight:'1px solid #eee',borderBottom:'1px solid #eee',height:'100%'}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5.8%',maxWidth: '5.8%',borderRight:'1px solid #eee', color: 'black',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12.8%',maxWidth: '12.8%',borderRight:'1px solid #eee', }}>{d?.client?.client_name} </p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '17.8%',maxWidth: '17.8%',borderRight:'1px solid #eee', }}>{d?.employee?.name} / {d?.designation?.designation_name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.remarks}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.created_by?.name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.hr_remarks}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.approved_by?.name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.status}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}><span style={{fontSize:'10px'}}>{moment(d?.createdAt)?.format('lll')}</span> </p>

                                <p style={{fontSize:'12px',minWidth: '5%',maxWidth: '5%',paddingLeft:'5px' }}>
                                {!roles?.includes('fms_data_view') &&
                                    <>
                                        <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('/fms/rejoin_employee_clients',{state:d})} />
                                        {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                        <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                                        </>
                                    }
                                </p>
                                </div>
                            ))}

                        <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                            {pagination.total > 15 &&
                                <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                            }
                        </div>

                        </div> 
                        :
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                            <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                            <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                            <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                        </div>}
                    </TabPane>

                    <TabPane tab="Completed" key="Completed">
                        {data?.length > 0 ? 
                        <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'98%',overflow:'hidden' }}>
                            <div className='mateial_table_header' style={{border:'1px solid #eaeded',}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',opacity:'1' }}>SL NO</p>
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '13%',maxWidth: '13%',borderRight:'1px solid #eee', color: 'black',opacity:'1' }}>Client</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '18%',maxWidth: '18%',borderRight:'1px solid #eee'}}>Employee / Designation</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Remarks</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Requested By</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>HR Remarks</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Approved By</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Status</p>
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Created Date</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '5%',maxWidth: '5%' }}>Actions</p>
                            </div>


                            {data.map((d, i) => (
                                <div key={i}   style={{borderLeft:'1px solid #eee',padding:'0px !important',display:'flex',borderRight:'1px solid #eee',borderBottom:'1px solid #eee',height:'100%'}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5.8%',maxWidth: '5.8%',borderRight:'1px solid #eee', color: 'black',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12.8%',maxWidth: '12.8%',borderRight:'1px solid #eee', }}>{d?.client?.client_name} </p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '17.8%',maxWidth: '17.8%',borderRight:'1px solid #eee', }}>{d?.employee?.name} / {d?.designation?.designation_name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.remarks}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.created_by?.name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.hr_remarks}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.approved_by?.name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.status}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}><span style={{fontSize:'10px'}}>{moment(d?.createdAt)?.format('lll')}</span> </p>

                                <p style={{fontSize:'12px',minWidth: '5%',maxWidth: '5%',paddingLeft:'5px' }}>
                                {!roles?.includes('fms_data_view') &&
                                    <>
                                        <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('/fms/rejoin_employee_clients',{state:d})} />
                                        {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                        <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                                        </>
                                    }
                                </p>
                                </div>
                            ))}

                        <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                            {pagination.total > 15 &&
                                <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                            }
                        </div>

                        </div> 
                        :
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                            <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                            <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                            <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                        </div>}
                    </TabPane>

                    <TabPane tab="Invalid" key="Invalid">
                        {data?.length > 0 ? 
                        <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'98%',overflow:'hidden' }}>
                            <div className='mateial_table_header' style={{border:'1px solid #eaeded',}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eee', color: 'black',opacity:'1' }}>SL NO</p>
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '13%',maxWidth: '13%',borderRight:'1px solid #eee', color: 'black',opacity:'1' }}>Client</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '18%',maxWidth: '18%',borderRight:'1px solid #eee'}}>Employee / Designation</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Remarks</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Requested By</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>HR Remarks</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Approved By</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Status</p>
                                <p style={{fontSize:'11px',paddingLeft:'5px',minWidth: '10%',maxWidth: '10%',borderRight:'1px solid #eee'}}>Created Date</p>
                                <p style={{fontSize:'12px',paddingLeft:'5px',minWidth: '5%',maxWidth: '5%' }}>Actions</p>
                            </div>


                            {data.map((d, i) => (
                                <div key={i}   style={{borderLeft:'1px solid #eee',padding:'0px !important',display:'flex',borderRight:'1px solid #eee',borderBottom:'1px solid #eee',height:'100%'}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '5.8%',maxWidth: '5.8%',borderRight:'1px solid #eee', color: 'black',opacity:'1',textAlign:'center' }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '12.8%',maxWidth: '12.8%',borderRight:'1px solid #eee', }}>{d?.client?.client_name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '17.8%',maxWidth: '17.8%',borderRight:'1px solid #eee', }}>{d?.employee?.name} / {d?.designation?.designation_name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.remarks}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.created_by?.name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.hr_remarks}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.approved_by?.name}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}>{d?.status}</p>
                                <p style={{paddingLeft:'5px',paddingBottom:'0px',marginBottom:'0px',fontSize:'12px',minWidth: '9.8%',maxWidth: '9.8%',borderRight:'1px solid #eee', }}><span style={{fontSize:'10px'}}>{moment(d?.createdAt)?.format('lll')}</span> </p>

                                <p style={{fontSize:'12px',minWidth: '5%',maxWidth: '5%',paddingLeft:'5px' }}>
                                {!roles?.includes('fms_data_view') &&
                                    <>
                                        <AiOutlineEdit style={{marginRight:'10px'}} onClick={()=>navigate('/fms/rejoin_employee_clients',{state:d})} />
                                        {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                        <AiOutlineDelete onClick={()=>deleteData(d?._id)} />}
                                        </>
                                    }
                                </p>
                                </div>
                            ))}

                        <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                            {pagination.total > 15 &&
                                <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
                            }
                        </div>

                        </div> 
                        :
                        <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                            <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                            <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                            <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                        </div>}
                    </TabPane>
            </Tabs>          
          
           

        </div>

    </FmsDashboardMainRoot>    
  )
}

export default ReJoinBasicEmployeeToClientList