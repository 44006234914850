import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import axios from 'axios';
import {BiEditAlt} from 'react-icons/bi'
import Tooltip from '@mui/material/Tooltip';
import { data_not_found } from '../../../helpers/Constants';
import { authHeader } from '../../../helpers/usertoken/UserToken';
import { Pagination, Tabs, Tag } from 'antd'
import Avatar from '@mui/material/Avatar';
import { base_url } from '../../../App';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import {FaWpforms} from 'react-icons/fa';
import {RiNewspaperLine} from 'react-icons/ri';
import {MdDeleteOutline,MdOutlineRefresh} from 'react-icons/md'
import toast,{ Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { MdExitToApp } from 'react-icons/md';
import {BsSearch} from 'react-icons/bs';
import fileDownload from "js-file-download";
import { theme_color } from '../../../helpers/constants/ConstantColor';

function FmsEmployeeBasedClient() {

  const {state} = useLocation()
  const navigate = useNavigate()
  const roles = useSelector(state => state.Auth.roles)
  const fcpl_id = useSelector(state => state.Auth.fcpl_id)


  const [pagination,setpagination] = useState({})  
  const [filterApplied,setfilterApplied] = useState(false)
  const [modal,setmodal] = useState(false)  
  const [search,setsearch] = useState('')
  const [data,setdata] = useState([])
  const [selectedData,setselectedData] = useState([])
  const [page,setpage] = useState(1)

  useEffect(()=>{
    if(filterApplied){
        searchData()
    }else{
        getData()
    }
  },[filterApplied,page])


  async function getData(){
        await axios.get(`fms/fmsclients/employee_based_on_client_all/${state}?page=${page}`,authHeader)
        .then((res)=>{
            setpagination(res?.data?.pagination)
            setdata(res?.data?.datas)
        }).catch((err)=>{
        })
  }  

  async function searchData(){
   await axios.get(`fms/fmsclients/search_employee_based_on_client_all/${state}?search=${search}&page=${page}`,authHeader)
    .then((res)=>{
      setdata(res.data.datas)
      setpagination(res.data.pagination)
    })
  }

  function setpagefuun(v){
    setpage(v)
  }

  function deleterecord(v){
    axios.delete(`fms/basicemployee/delete/${v}`,authHeader)
    .then((res)=>{
        toast.success(res.data.message);
        getData('1')
    })
  }

  async function applyFilter(){
    console.log("kp",search)
    setpage(1)
    if(search?.length > 2){
        setfilterApplied(true)
        await axios.get(`fms/fmsclients/search_employee_based_on_client_all/${state}?search=${search}&page=${page}`,authHeader)
        .then((res)=>{
          setdata(res.data.datas)
          setpagination(res.data.pagination)
        })
    }
  }

  function resetFilter(){
    setfilterApplied(false)
    setsearch('')
  }


  function addselectedData(v){
    let filterArr = []
    if(selectedData.includes(v)){
      filterArr = selectedData.filter((v1)=>v1 !== v)
    }else{
      filterArr = [...selectedData,v]
    }

    setselectedData(filterArr)
  }


  async function exitselectedData(){
    if(selectedData?.length === 0){
      toast.error("Please Select any one employee to exit")
    }else{
      await axios.post(`fms/basicemployee/exit_employee`,{employees:selectedData},authHeader)
      .then((res)=>{
        toast.success("Exit Successfully!")
        getData()
        setselectedData([])
      })
    }
  }

  

  return (
    <FmsDashboardMainRoot>
      <Toaster />
           <div style={{width:'98%'}}>
                <div style={{borderBottom:'1px solid #f3f3f3',paddingBottom:'10px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                <BoldText1 val={`Employee Based Client (${pagination?.total})`} />

                {/* <PrimaryButton btn_name={'Add Point'} onClick={()=>setmodal(true)} /> */}
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {selectedData?.length > 0 &&
                            <PrimaryButton onClick={exitselectedData} btn_name={"Exit Employee"} />}
                            <input value={search} onChange={(e)=>setsearch(e.target.value)} type='text' style={{border:'1px solid #eee',outline:'0',padding:'3px',marginLeft:'10px'}} /> 
                            <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'7px'}}/>
                            <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
                            </div>
                </div>

                {data.length > 0 ? 
                                <>
                                 <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%',border:'1px solid #eaeded'}}>
                                   <div  className='mateial_table_header'>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '4%', color: 'black',marginLeft:'0px',marginRight:'10px' }}>Sl No</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '5%', marginRight: '0.5%',marginLeft:'10PX' }}>Avatar</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>Employee ID</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Employee Name</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Mobile No</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '10%', marginRight: '0.5%' }}>Designation</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '1%' }}>Site Name</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '6%', marginRight: '0.5%' }}>Status</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '8%', marginRight: '0.5%' }}>DOJ</p>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px',minWidth: '12%', marginRight: '0.5%' }}>Created At</p>
                                       <p style={{fontSize:'12px',minWidth: '8%' }}>Actions</p>
                                   </div>


                                   {data.map((d, i) => (
                                       <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',borderTop:'1px solid #eaeded'}}>
                                          
                                           <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth:'4%',maxWidth:'4%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
                                           <p style={{borderRight:'1px solid #eaeded',paddingLeft:'10px',fontSize:'12px', minWidth: '6.25%',maxWidth:'6.25%', wordBreak: 'break-word', marginRight: '0.5%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',textTransform:'uppercase' }}><Avatar className='w-6'  sx={{ width: 18, height: 18,fontSize:11  }} alt={d?.name} src={`${base_url}${d?.photo}`} /> </p>
                                           <Tooltip  title={d?.employee_id}><p onClick={()=>navigate(`/fms/fms_employee/${d._id}`)} style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%',maxWidth:'8%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px',marginRight:'0.5%' }}>{d?.employee_id == '' ? 'Not Added' : d?.employee_id}</p></Tooltip>
                                           <Tooltip  title={d?.name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginRight:'0.5%'}}>{d?.name}</p></Tooltip>
                                           <Tooltip  title={d?.mobile_no}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '9.9%' ,maxWidth:'9.9%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{d?.mobile_no}</p></Tooltip>
                                           <Tooltip  title={d?.designation?.designation_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '10%',textAlign:'start',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',}}>{d?.designation?.designation_name}</p></Tooltip>
                                           <Tooltip  title={d?.department_branch_were_deployed?.site_name}><p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '12.1%',maxWidth:'12.1%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px',marginRight: '1%'  }}>{d?.department_branch_were_deployed?.site_name}</p></Tooltip>
                                           <Tooltip  title={d.is_rejected ? 'Rejected' : 'Approved'}><p style={{borderRight:'1px solid #eaeded',fontSize:'11px', minWidth: '6%',maxWidth:'6%', wordBreak: 'break-word', marginRight: '0.5%',wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'0px' }}>{d.is_rejected ?  <Tag style={{fontSize:'9px'}} color="red">Rejected</Tag> : <Tag style={{fontSize:'9px'}} color={d?.is_approved ? "green" : "blue"}>{d?.is_approved ? 'Approved' : 'Pending'}</Tag>}  </p></Tooltip>
                                       <p style={{borderRight:'1px solid #eaeded',fontSize:'12px', minWidth: '8%', marginRight: '0.5%',fontWeight:'800' }}><span style={{fontSize:'10px'}}>{moment(d?.date_of_joining).format('ll')}  </span></p>
                                       <p style={{borderRight:'1px solid #eaeded',minWidth: '12%', marginRight: '0.5%',fontWeight:'800',fontSize:'10px !important',}}> <span style={{fontSize:'10px'}}>{(d?.createdAt !== null && d?.createdAt !== '' && d?.createdAt !== undefined) ? moment(d?.createdAt).format('lll') : 'Not Added'} </span> </p>
                                       {!roles.includes('fms_data_view') &&

                                           <p style={{fontSize:'12px', width: '8%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'0px'}}>
                                                <Tooltip title='Exit Form'>
                                                <span>
                                                <MdExitToApp onClick={()=>navigate(`/fms/exit_employee_step`,{state:d})} size={14} style={{ marginRight:'8px' }}  />
                                                </span>
                                                </Tooltip>
                                               <Tooltip title='Edit'>
                                               <span>
                                               <BiEditAlt onClick={()=>navigate(`/fms/epi_form/edit/${d._id}`,{data_send:d})} size={14} style={{fontSize:'12px', marginRight:'5px' }}  />
                                               </span>
                                               </Tooltip>
                                               <Tooltip title='Form (F)'>
                                               <span>
                                               <FaWpforms onClick={()=>{d?.formf?.length == 0 ? navigate(`/fms/form_f_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/form_f_form/update/${d._id}`,{state:{datas:d}})}} size={12} style={{ marginRight:'8px' }}  />
                                               </span>
                                               </Tooltip>
                                               <Tooltip title='Statement Form'>
                                               <span>
                                               <RiNewspaperLine onClick={()=>{d?.statement?.length == 0 ? navigate(`/fms/statement_form/create/${d._id}`,{state:{datas:d}}) : navigate(`/fms/statement_form/update/${d._id}`,{state:{datas:d}})}} size={14}   />
                                               </span>
                                               </Tooltip>
                                               {(roles.includes('admin') || fcpl_id == 'fcpl_240') &&
                                               <Tooltip title='Delete Record'>
                                               <span>
                                               <MdDeleteOutline onClick={()=>deleterecord(d?._id)} size={15}   style={{marginLeft:'6px'}} />
                                               </span>
                                               </Tooltip>}
                                           </p>}
                                       </div>
                                   ))}
                               </div> 

                                <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
                                    {pagination?.total > pagination?.limit &&
                                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                                    }
                                </div>
                                </>
                                :
                                <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                    <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                                    <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                                    <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
                                </div>}
          </div>
        
    </FmsDashboardMainRoot>
  )
}

export default FmsEmployeeBasedClient