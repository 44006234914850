import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { Select } from 'antd'
import { SmallText } from '../../../helpers/constants/ConstantsText'
import PrimaryButton, { SecondaryButton } from '../../../components/buttons/PrimaryButton'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Toaster, toast } from 'react-hot-toast'
import makeAnimated from 'react-select/animated';
import { authHeader } from '../../../helpers/usertoken/UserToken'

const animatedComponents = makeAnimated();

function AssignClientsCE() {

  const [clients,setclients] = useState([])  
  const [old_data_client,setold_data_client] = useState([])  
  const [users,setusers] = useState([])  
  const navigation = useNavigate()
  const {state} = useLocation()

  const assigned_to_arr = [{label:'InHouse-Employee',value:'InHouse-Employee'},{label:'Deployed Employee',value:'Deployed Employee'}]



  const [data,setdata] = useState({client:[],field_officer:'',assigned_to:'',deployed_officer:''})
  const [error,seterror] = useState({client:'',field_officer:'',assigned_to:'',deployed_officer:''})

//   const 




  useEffect(()=>{
     if(state?._id !== undefined){
        let client = []
        state?.client?.forEach((d)=>{
            client?.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id}) 
        })

        let passData = {
            client:client,
            assigned_to:{label:state?.assigned_to,value:state?.assigned_to}
        }

        // console.log("state",state)

        if(state?.assigned_to == 'InHouse-Employee'){
            passData['field_officer'] = {label:`${state?.field_officer?.name}[Employee Id : ${state?.field_officer?.employee_id}]`,value:state?.field_officer?._id}
            // send_data['field_officer'] = data?.field_officer?.value
        }else if(state?.assigned_to == 'Deployed Employee'){
            passData['deployed_officer'] = {label:`${state?.deployed_officer?.name}[Employee Id : ${state?.deployed_officer?.employee_id}]`,value:state?.deployed_officer?._id}
            // send_data['deployed_officer'] = data?.field_officer?.value
        }


        // field_officer:{label:`${state?.field_officer?.name}[Employee Id : ${state?.field_officer?.employee_id}]`,value:state?.field_officer?._id},

          setdata(passData)
          setclients(client)
     }else{
        setdata({client:[],field_officer:''})
        seterror({client:'',field_officer:''})
     }
  },[])



async function searchuser(v){ 
    if(data?.assigned_to !== undefined){
        if(data?.assigned_to?.label === 'InHouse-Employee'){
            await axios.get(`fms/fms_in_house_employee/search?search=${v}`,authHeader)
            .then((res)=>{
                let arr = []
                res?.data?.datas?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?._id})
                });
                setusers(arr)
                return arr
            }).catch((err)=>{
            })
        }else if(data?.assigned_to?.label === 'Deployed Employee'){{
            await axios.post(`fms/basicemployee/search?search_text=${v}`,{},authHeader)
            .then((res)=>{
                // console.log("res?.data",res?.data)
                let arr = []
                res?.data?.data?.forEach(d => {
                    arr.push({label:`${d?.name}[Employee Id : ${d?.employee_id}]`,value:d?.id})
                });
                setusers(arr)
                return arr
            }).catch((err)=>{
            })
        }
   
    }
   }
   }

  async function searchData(v){ 
   await axios.get(`fms/fmsclients/search?search_text=${v}`,authHeader)
    .then((res)=>{
        let arr = []
        res?.data?.datas?.forEach(d => {
            arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
        });
        if(data?.client?.length > 0){
            setold_data_client(data?.client)
        }
        setclients(arr)
        return arr
    }).catch((err)=>{
    })
  }


  async function submitData(){
        if(data?.client?.length === 0){
            seterror({...error,client:'This Field is required*'})
        }else{
            const send_data = {
                client:data?.client?.map((d)=>d.value),
                assigned_to:data?.assigned_to?.label
            }

            if(send_data?.assigned_to === 'InHouse-Employee'){
                send_data['field_officer'] = data?.field_officer?.value
            }else if(send_data?.assigned_to === 'Deployed Employee'){
                send_data['deployed_officer'] = data?.deployed_officer?.value
            }

            // console.log("send_data",send_data)
            
            if(state?._id !== undefined){
                axios.put(`fms/fmsclients/assign_clients_update/${state?._id}`,send_data,authHeader)
                .then((res)=>{
                    toast.success(res?.data?.message)
                    setdata({client:[],field_officer:''})
                    seterror({client:'',field_officer:''})
                    setTimeout(() => {
                     navigation(-1)
                    }, 1500);
                }).catch((err)=>{
                })
            }else{
                axios.post(`fms/fmsclients/assign_clients`,send_data,authHeader)
                .then((res)=>{
                    toast.success(res?.data?.message)
                    setdata({client:[],field_officer:''})
                    seterror({client:'',field_officer:''})
                }).catch((err)=>{
                })
            }
        }
  }
//   console.log("datas",clients)


function handleChange(v){

    let arr = []
    v?.forEach((d)=>{
        let data_found = clients?.find((f)=>f.value === d)

        if(data_found !== null && data_found !== undefined){
            arr?.push(data_found)
        }else{
            let old_data_found = old_data_client?.find((f)=>f.value === d)
            arr?.push(old_data_found)
        }
    })
    setdata({...data,client:arr})
}

function handleChange1(v,name){   
    setdata({...data,[name]:assigned_to_arr?.find((v1)=>v1?.label === v),field_officer:'',deployed_officer:''})
    seterror({...error,[name]:''})
}   


  return (
    <FmsDashboardMainRoot>
        <Toaster />
        <div>
            
        <div style={{maxWidth:'400px',}}>

            <h6 style={{fontWeight:'800',fontSize:14,marginBottom:'4px'}}>Assign Client </h6>
            <h6 style={{fontWeight:'400',fontSize:12.5,marginBottom:'4px',width:'100%',opacity:1}}>Use the below form to create or update the assign client </h6>
             
            <div style={{margin:'10px 0px',width:'320px',}}>
                    <SmallText marginfun={'0px 0px 10px 0px'} fontWeight={'600'} val={'Assigned To'}  />
                    <div  style={{margin:'0px 0px 10px 0px',zIndex:200,borderLeft:'4px solid #292961'}}>
                    <Select 
                    cacheOptions
                        value={data?.assigned_to?.label}
                        placeholder="" 
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        onChange={(v)=>handleChange1(v,'assigned_to')}
                        options={assigned_to_arr}
                        style={{ width: '100%', }}
                    />
                    </div>
                </div>

             

            {data?.assigned_to?.label == 'InHouse-Employee' && 
            <div style={{width:'400px',marginTop:0}}>
            <SmallText fontWeight={'500'} val={'Field Officer'}  />
            <Select showSearch filterOption={false} value={data?.field_officer === '' ? null : data?.field_officer} options={users} onSearch={searchuser} onChange={(e)=>setdata({...data,field_officer:users?.find(f=>f.value === e)})} style={{width:'80%',marginBottom:10}} />
            </div>}

            {data?.assigned_to?.label == 'Deployed Employee' && 
            <div style={{width:'400px',marginTop:0}}>
            <SmallText fontWeight={'500'} val={'Deployed Officer'}  />
            <Select showSearch filterOption={false} value={data?.deployed_officer === '' ? null : data?.deployed_officer} options={users} onSearch={searchuser} onChange={(e)=>setdata({...data,deployed_officer:users?.find(f=>f.value === e)})} style={{width:'80%',marginBottom:10}} />
            </div>}


            <div style={{width:'400px'}}>
            <SmallText fontWeight={'500'} val={'Clients'}  />
            <Select showSearch filterOption={false} value={data?.client} mode='multiple' options={clients} onSearch={searchData} onChange={handleChange} style={{width:'80%',marginBottom:10}} />
            </div>


            <div style={{display:'flex',flexDirection:'row',}}>
                <SecondaryButton btn_name={'Cancel'} onClick={()=>{navigation(-1)}} />
                <PrimaryButton btn_name={'Save'} onClick={submitData} />
            </div>
        </div>

        </div>
    </FmsDashboardMainRoot>
  )
}

export default AssignClientsCE