import React,{useState,useEffect} from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import { useNavigate, } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import {BiEditAlt} from 'react-icons/bi'
import moment from 'moment';
import { Pagination, Tabs } from 'antd'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import { data_not_found } from '../../../helpers/Constants';
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Toaster, toast } from 'react-hot-toast'
import {AiOutlineDelete} from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { base_url } from '../../../App';

const { TabPane } = Tabs;

function FmsWarningLetterRequest() {
  
  const roles = useSelector(state => state.Auth.roles)
  const fcpl_id = useSelector(state => state.Auth.fcpl_id)

  const navigate = useNavigate()
  const [activetab,setactivetab] = useState('1')
  const [page,setpage] = useState(1)
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})


  useEffect(()=>{
    getdata()
  },[page])

  function setactivetabfunc(v){
    setpage(1)
    setactivetab(v)
    getdata(1,v)
    }

    function setpagefuun(v){
        setpage(v)
    }

    function getdata(v,v1){
        // console.log('page',page)
      axios.get(`fms/warning_letter/get?page=${v !== undefined ? v : page}&status=${v1 !== undefined ? v1 : activetab}`,authHeader)
      .then((res)=>{
        setdata(res.data)
      }).catch((err)=>{
        // console.log("err",err)
        if(err?.response?.status === 401){
        //    dispatch(crm_logout())
        }
      })
    }


    const deleteexitemployee = (v) =>{
      axios.delete(`fms/warning_letter/delete/${v}`,authHeader)
      .then((res)=>{
        toast.success(res?.data?.message)
        getdata()
      }).catch((err)=>{
        if(err?.response?.status === 401){
        //    dispatch(crm_logout())
        }
      })
    } 


  return (
    <FmsDashboardMainRoot>
      <Toaster />
       <div style={{width:'96%'}}>
            <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between',margin:'10px 0px'}}>
              <BoldText1 val={`Warning Letter Request`} />
            </div>
            <Tabs
                size="small"
                activeKey={activetab}
                activetab={activetab}
                defaultValue={activetab}
                style={{ width: '100%', margin: '-10px 0px', marginBottom: '0px', position: 'sticky', top: '40px' }}
                onChange={(v) =>{setactivetab(v);setactivetabfunc(v)}}>
                    <TabPane tab="Pending" key="1">
                           <BoldText1 val={`Total Pending (${data?.pagination?.total})`} fontSize={'12px'}  />   

                          {data?.datas?.length > 0 ? 
                          <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                            <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Employee Name</p>
                                <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Client Place</p>
                                <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Attachment</p>
                                <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Info</p>
                                <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Created At</p>                                                 
                                <p style={{fontSize:'12px',minWidth: '10.83%',maxWidth: '10%' }}>Actions</p>
                            </div>
                            {data?.datas?.map((d, i)=>(
                            <div key={d?._id} style={{borderLeft:'1px solid #eaeded',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                                <p className='checked_here' style={{opacity:0}} ></p> 
                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '3.8%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.fms_employee?.name}</p>
                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.fms_employee?.department_branch_were_deployed?.client_name}</p>
                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.3%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{ <img style={{width:"15px",height:"15px"}} src={`${base_url}${d?.attachment}`} />}</p>
                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.info}</p>
                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{moment(d?.createdAt)?.format('LL')}</p>
                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                                <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                      
                                <BiEditAlt onClick={()=>navigate(`/fms/fms_warning_letter_request/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                                {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                  <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />}
                                </p>
                                </p>
                            </div>))}
                            <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                                {pagination?.total > pagination?.limit &&
                                    <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                                }
                            </div>
                          </div> 
                          :
                          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                              <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any Exit Employee Form created yet"}</h4>
                              <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see exit employee list added by you click on the add button and create a new exit form and submit"}</h4>
                          </div>}
                    </TabPane>
                    <TabPane tab="Approved" key="2">
                         <BoldText1 val={`Total Provided (${data?.pagination?.total})`} fontSize={'12px'}  />   

                         {data?.datas?.length > 0 ? 
                         
                          <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                                                                                  <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                                                                                      <p className='checked_here' style={{opacity:0}} ></p> 
                                                                                      <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                                                                      <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Employee Name</p>
                                                                                      <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Client Place</p>
                                                                                      <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Attachment</p>
                                                                                      <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Info</p>
                                                                                      <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Created At</p>                                                 
                                                                                      <p style={{fontSize:'12px',minWidth: '10.83%',maxWidth: '10%' }}>Actions</p>
                                                                                  </div>
                                                                                  {data?.datas?.map((d, i)=>(
                                                                                  <div key={d?._id} style={{borderLeft:'1px solid #eaeded',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                                                                                      <p className='checked_here' style={{opacity:0}} ></p> 
                                                                                      <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '3.8%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                                                                      <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.fms_employee?.name}</p>
                                                                                      <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.fms_employee?.department_branch_were_deployed?.client_name}</p>
                                                                                      <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.3%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{ <img style={{width:"15px",height:"15px"}} src={`${base_url}${d?.attachment}`} />}</p>
                                                                                      <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.info}</p>
                                                                                      <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{moment(d?.createdAt)?.format('LL')}</p>
                                                                                      <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                                                                                      <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                                                                            
                                                                                      <BiEditAlt onClick={()=>navigate(`/fms/fms_warning_letter_request/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                                                                                      {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                                                                                       <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />}
                                                                                      </p>
                                                                                      </p>
                                                                                  </div>))}
                                                                                  <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                                                                    {pagination?.total > pagination?.limit &&
                                                                        <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                                                                    }
                                                                                 </div>
                                                    
                                                                    </div> 
                          :
                          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                              <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any Exit Employee Form created yet"}</h4>
                              <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see exit employee list added by you click on the add button and create a new exit form and submit"}</h4>
                          </div>}
                    </TabPane>
                    <TabPane tab="Rejected" key="3">
                         <BoldText1 val={`Total Rejected (${data?.pagination?.total})`} fontSize={'12px'}  />   

                         {data?.datas?.length > 0 ? 
                        
                          <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                          <div className='mateial_table_header' style={{borderLeft:'1px solid #eaeded',borderRight:'1px solid #eaeded',borderTop:'1px solid #eaeded',borderBottom:'1px solid #eaeded'}}>
                              <p className='checked_here' style={{opacity:0}} ></p> 
                              <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Employee Name</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Client Place</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Attachment</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'1px', marginRight: '0.5%' }}>Info</p>
                              <p style={{fontSize:'12px',minWidth: '16.83%',maxWidth: '30%',borderRight:'1px solid #eaeded',marginLeft:'-2px', marginRight: '0.5%' }}>Created At</p>                                                 
                              <p style={{fontSize:'12px',minWidth: '10.83%',maxWidth: '10%' }}>Actions</p>
                          </div>
                          {data?.datas?.map((d, i)=>(
                          <div key={d?._id} style={{borderLeft:'1px solid #eaeded',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #eaeded',borderBottom:'1px solid #eaeded',height:'100%'}}>
                              <p className='checked_here' style={{opacity:0}} ></p> 
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '3.8%',maxWidth: '5%',borderRight:'1px solid #eaeded', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.fms_employee?.name}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.fms_employee?.department_branch_were_deployed?.client_name}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.3%',maxWidth: '30%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{ <img style={{width:"15px",height:"15px"}} src={`${base_url}${d?.attachment}`} />}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.63%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{d?.info}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '16.4%',maxWidth: '14.6%',borderRight:'1px solid #eaeded', marginRight: '0.5%' }}>{moment(d?.createdAt)?.format('LL')}</p>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                    
                              <BiEditAlt onClick={()=>navigate(`/fms/fms_warning_letter_request/edit`,{state:d})} size={11} style={{ marginRight:'10px' }}  />
                              {(roles?.includes('admin') || roles?.includes('fms_hr')) &&
                               <AiOutlineDelete onClick={()=>deleteexitemployee(d?._id)} size={11} style={{ marginRight:'10px' }}  />}
                              </p>
                              </p>
                          </div>))}
                          <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
            {pagination?.total > pagination?.limit &&
                <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
            }
                         </div>

            </div> 
                          :
                          <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                              <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                              <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any Exit Employee Form created yet"}</h4>
                              <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see exit employee list added by you click on the add button and create a new exit form and submit"}</h4>
                          </div>}
                    </TabPane>
                 

            </Tabs>
       </div>
    </FmsDashboardMainRoot>
  )
}

export default FmsWarningLetterRequest