import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { Pagination,DatePicker } from 'antd';
import Tooltip from '@mui/material/Tooltip';
import {MdDeleteOutline,MdOutlineRefresh} from 'react-icons/md';
import moment from 'moment'
import { data_not_found } from '../../../helpers/Constants';
import { BoldText1 } from '../../../helpers/constants/ConstantsText'
import { Toaster, toast } from 'react-hot-toast'
import { theme_color } from '../../../helpers/constants/ConstantColor'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { base_url, local_base_url } from '../../../App'
import fileDownload from "js-file-download";
import { useSelector } from 'react-redux'
import { Select } from 'antd'
import { BsSearch } from 'react-icons/bs';


function FmsClientScan() {
  const roles = useSelector(state => state.Auth.roles)

  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)

  const [filter,setfilter] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',client:'',check_point:''})
  
  const [clients,setclients] = useState([])
  const [checkpoints,setcheckpoints] = useState([])


  useEffect(()=>{
    getdata(page)
  },[page])

  async function getdata(v){
    await axios.get(`fms/clientplacescanner/get?page=${v ? v : page}&from_date=${filter?.from_date1}&to_date=${filter?.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}&check_point=${filter.check_point !== '' ?  filter?.check_point?.value : ''}`,authHeader)
    .then((res)=>{
      setdata(res.data.datas)
      setpagination(res.data.pagiantion)
    }).catch((err)=>{
    })
  }

  async function searchData(v){ 
    await axios.get(`fms/fmsclients/search?search_text=${v}&are_we_serving=1`,authHeader)
     .then((res)=>{
         let arr = []
         res?.data?.datas?.forEach(d => {
             arr.push({label:`${d?.site_name}[client name : ${d?.client_name}]`,value:d?._id})
         });
         setclients(arr)
         return arr
     }).catch((err)=>{
     })
  }

  async function getcheckpointsbasedclient(v){ 
    await axios.get(`fms/fmsclientspoints/get/${v}?limit=1000&page=1`,authHeader)
     .then((res)=>{
        let arr = []
        res?.data?.datas?.forEach(d => {
            arr.push({label:`${d?.name}`,value:d?._id})
        });
        setcheckpoints(arr)
     }).catch((err)=>{
     })
  }

  function setpagefuun(v){
    setpage(v)
  }

  function deleteData(id){
    axios.delete(`fms/clientplacescanner/delete/${id}`,authHeader)
    .then((res)=>{
      if(res?.status == 200){
        getdata()
        toast.success('Deleted Successfully')
      }
    }).catch((err)=>{
    })
  }

  async function applyFilter(){
    await axios.get(`fms/clientplacescanner/get?page=${1}&from_date=${filter?.from_date1}&to_date=${filter?.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}&check_point=${filter.check_point !== '' ?  filter?.check_point?.value : ''}`,authHeader)
    .then((res)=>{
      setdata(res.data.datas)
      setpagination(res.data.pagiantion)
    }).catch((err)=>{
    })
  }

  async function resetFilter(){
     setfilter({from_date:'',to_date:'',from_date1:'',to_date1:'',client:'',check_point:''})
     await axios.get(`fms/clientplacescanner/get?page=${1}&from_date=${''}&to_date=${''}&client=${''}&check_point=${''}`,authHeader)
     .then((res)=>{
       console.log("res.data KP",res.data)
       setdata(res.data.datas)
       setpagination(res.data.pagiantion)
     }).catch((err)=>{
     })
  }

  async function downloadReport(){
    await axios.post(`fms/clientplacescanner/download_report?page=${1}&from_date=${filter.from_date1}&to_date=${filter.to_date1}&client=${filter.client !== '' ?  filter?.client?.value : ''}&check_point=${filter.check_point !== '' ?  filter?.check_point?.value : ''}`,{},authHeader)
    .then((res)=>{
      let path = res.data.path.replace('public/','')
      handleDownload(`${local_base_url}${path}`,path.split('/')[path.split('/')?.length - 1])
    }).catch((err)=>{
    })
  }  

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    }).then((res) => {
        fileDownload(res.data, filename)
        toast.success("Employee Excel Downloaded") 
    })
  }

  async function handlechangeclient(e){
    setfilter({...filter,client:clients?.find(f=>f.value === e)})
    getcheckpointsbasedclient(e)
  }

  function openfile(v){
      let url = `${base_url}` + v
      window.open(url,'_blank')
   }

   function kmToMeters(km) {
    return parseFloat(km).toFixed(3);
}


  return (
    <FmsDashboardMainRoot>
      <Toaster />
      <div style={{width:'98%'}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingBottom:'2px',borderBottom:'1px solid #ddd'}}>
        <BoldText1 val="Client Scan Report" /> 

        {(roles.includes('admin') || roles.includes('fms_hr') || roles.includes('fms_crm_head')) &&
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:'-20px'}}>
          <Select showSearch filterOption={false} placeholder={'Select Client'} value={filter?.client === '' ? null : filter?.client} options={clients}  onSearch={searchData} onChange={(e)=>handlechangeclient(e)} style={{width:'120px',marginBottom:0,marginRight:10}} />
          {checkpoints?.length > 0 &&
          <Select placeholder={'Select CheckPoint'} value={filter?.check_point === '' ? null : filter?.check_point} options={checkpoints}  onChange={(e)=>setfilter({...filter,check_point:checkpoints?.find(f=>f.value === e)})} style={{width:'120px',marginBottom:0,marginRight:10}} />
          }

          <DatePicker value={filter?.from_date} style={{width:'130px'}} onChange={(e,e1)=>setfilter({...filter,from_date:e,from_date1:e1})} size='medium' placeholder='From Date' />
          <h6 style={{opacity:0}}>W</h6>
          <DatePicker value={filter?.to_date} style={{width:'130px'}} onChange={(e,e1)=>setfilter({...filter,to_date:e,to_date1:e1})} placeholder='To Date'  />
          <h6 style={{opacity:0}}>W</h6>
          <BsSearch onClick={applyFilter} size={30} style={{marginLeft:'0px',background:'#eee',padding:'7px'}}/>
          <MdOutlineRefresh onClick={resetFilter} size={30} style={{marginLeft:'10px',background:'#eee',padding:'4px'}}/>
          <h6 style={{opacity:0}}>W</h6>
          <PrimaryButton onClick={downloadReport} btn_name={'Download Excel'} />
        </div>}
        </div>
      </div>

      <div>
      {data.length > 0 ? 
      <div style={{ border: '0px solid lightgray',marginTop:'20px', width:'98%' }}>
      
      {/* <div className='row' style={{marginLeft:'2px'}}>
      {data.map((d,i) => (
          <div className="col-md-3" key={i} style={{border:'1px solid #ddd',position:'relative',width:'24%',margin:'0.2%'}} >
               <AiOutlineDelete onClick={()=>deleteData(d?._id)} size={12} style={{position:'absolute',right:'2px',top:'2px'}} />
               <h6 style={{fontSize:'12.5px',fontWeight:'800',marginTop:'6px'}}>Client Info : {d?.point?.client?.client_name}</h6>
               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Client Place : {d?.point?.name}</h6>
               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Point Lat / Lon : {d?.point?.lats} / {d?.point?.long}</h6>
               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scan Lat / Lon : {d?.latitude} / {d?.longitude}</h6>
               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned User : {d?.user?.name}</h6>
               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Place : {d?.location}</h6>
               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Points : 
               <div style={{display:'flex',marginTop:10,flexDirection:'row',flexWrap:'wrap'}}>
               {d?.check_point_list?.map((f)=>(
                 <h6 style={{fontSize:'11px',fontWeight:'400',border:'1px solid #eee',padding:'2px 4px',textTransform:'capitalize',background:theme_color,fontWeight:'700',color:'#fff'}}>{f?.name}</h6>
               ))}

               </div></h6>

               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Remarks : {d?.remarks !== null ? d?.remarks : ''} </h6>

               
               <h6 style={{fontSize:'11px',fontWeight:'700',marginTop:'-3px'}}>{moment(d?.createdAt).format('lll')}</h6>

          </div>
      ))}
      </div>
         */}



              {data.length > 0 ? 
              // <div style={{ border: '0px solid lightgray',marginTop:'10px', overflowY: 'scroll',width:'100%' }}>
              //     <div className='mateial_table_header'>
              //         <p className='checked_here' style={{opacity:0}} ></p> 
              //         <p style={{fontSize:'12px',minWidth: '5%',maxWidth: '5%', color: 'black',marginLeft:'-10px',marginRight:'10px' }}>Sl No</p>
              //         <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%', }}>Client Info</p>
              //         <p style={{fontSize:'12px',minWidth: '15%',maxWidth:'15%' }}>Client Place </p>
              //         <p style={{fontSize:'12px',minWidth: '10%',minWidth: '10%' }}> Point Lat / Lon </p>
              //         <p style={{fontSize:'12px',minWidth: '10%',minWidth: '10%' }}>Scan Lat / Lon </p>
              //         <p style={{fontSize:'12px',minWidth: '10%',minWidth: '10%',}}>Scanned User</p>
              //         <p style={{fontSize:'12px',minWidth: '25%',maxWidth:'25%' }}>Scanned Place/ Points / Remarks</p>
              //         <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%', }}>Created At</p>
              //         <p style={{fontSize:'12px',minWidth: '5%',maxWidth: '5%', }}>Action</p>
              //     </div>


              //     {data.map((d, i) => (
              //         <div key={i} className={'mateial_table_body'} style={{maxWidth:'100%',marginLeft:'0%'}}>
                        
              //             <p style={{fontSize:'12px', minWidth: '5%',maxWidth: '5%',color: "#000", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',paddingLeft:'0px',marginLeft:'0px', }} >{page > 1 ? (page - 1) * 15 + i + 1 : i+1}</p>
              //             <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%', color: "#0594f2", wordBreak: 'break-word', fontWeight: '700',fontSize:'13px', wordBreak: 'break-word',whiteSpace:'nowrap',overflow:'hidden',fontSize:'13px',textOverflow:'ellipsis',marginLeft:'0px' }}>{d?.point?.client?.client_name}</p>
              //             <p style={{fontSize:'12px', minWidth: '15%',minWidth: '15%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-10px',}}>{d?.point?.name}</p>
              //             <p style={{fontSize:'12px', minWidth: '10%' ,maxWidth:'10%',marginLeft:'10px', wordBreak: 'break-word',marginLeft:'2px',fontSize:'13px', overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-5px'}}>{d?.point?.lats} / {d?.point?.long}</p>
              //             <p style={{fontSize:'12px', minWidth: '10%',minWidth: '10%',maxWidth:'10%', wordBreak: 'break-word', fontSize:'13px', wordBreak: 'break-word',overflow:'hidden',textOverflow:'ellipsis',marginLeft:'-5px',paddingRight:'15px' }}> {d?.latitude} / {d?.longitude}</p>
              //             <p style={{fontSize:'12px', minWidth: '10%',maxWidth:'10%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-all',overflow:'hidden',marginLeft:'-10px',marginRight: '0%'  }}>{d?.added_by == "InHouse-Employee" ? d?.field_officer?.name : d?.deployed_officer?.name}</p>
              //             <p style={{fontSize:'12px', minWidth: '25%',maxWidth:'25%',paddingRight:'10px', wordBreak: 'break-word', marginRight: '1%',fontSize:'13px', wordBreak: 'break-word',textOverflow:'ellipsis  ',marginLeft:'10px',marginRight: '0%'  }}>
              //               {d?.location} / 

              //               <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Points : </h6>
              //               <div style={{display:'flex',marginTop:10,flexDirection:'row',flexWrap:'wrap'}}>
              //               {d?.check_point_list?.map((f)=>(
              //                 <h6 style={{fontSize:'11px',fontWeight:'400',border:'1px solid #eee',padding:'2px 4px',textTransform:'capitalize',background:theme_color,fontWeight:'700',color:'#fff'}}>{f?.name}</h6>
              //               ))}
              //               </div>

              //             <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Remarks : {d?.remarks !== null ? d?.remarks : ''} </h6>

              //             </p>


              //         <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '10%',marginLeft:'0px', marginRight: '0.5%',fontWeight:'800' }}> <span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('ll')}</span></p>
              //         {!roles.includes('fms_data_view') &&
                          
              //             <p style={{ width: '5%', display: 'flex',alignItems:'center',paddingLeft:'0px',marginLeft:'-10px',}}>
                            

              //                 {(roles.includes('admin')) &&
              //                 <Tooltip title='Delete Record'>
              //                 <span>
              //                 <MdDeleteOutline onClick={()=>deleteData(d?._id)}  size={16}   style={{marginLeft:'6px'}} />
              //                 </span>
              //                 </Tooltip>}
              //             </p>}
              //         </div>
              //     ))}

              // <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
              //     {pagination.total > 15 &&
              //         <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={15} />
              //     }
              // </div>

              // </div> 
               <div style={{ border: '0px solid lightgray',marginTop:'10px', width:'100%',overflow:'hidden' }}>
                                                          <div className='mateial_table_header' style={{border:'1px solid #eaeded',}}>
                                                              <p className='checked_here' style={{opacity:0}} ></p> 
                                                              <p style={{fontSize:'11px',minWidth: '5%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginLeft:'-9px',marginRight:'10px',opacity:'1' }}>SL No</p>
                                                              <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Client Info</p>
                                                              <p style={{fontSize:'12px',minWidth: '12%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Client Place</p>
                                                              <p style={{fontSize:'12px',minWidth: '10%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Point Lat / Lon</p>
                                                              <p style={{fontSize:'12px',minWidth: '13.1%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Scan Lat / Lon </p>
                                                              <p style={{fontSize:'12px',minWidth: '8.3%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Scanned User</p>
                                                              <p style={{fontSize:'12px',minWidth: '21.4%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'-2px', marginRight: '0.5%' }}>Scanned Place/ Points / Remarks</p>
                                                              <p style={{fontSize:'12px',minWidth: '8%',maxWidth: '30%',borderRight:'1px solid #ddd',marginLeft:'1px', marginRight: '0.5%' }}>Created At </p>
                                                              <p style={{fontSize:'12px',minWidth: '5%',maxWidth: '10%' }}>Actions</p>
                                                          </div>
                                                          {data?.map((d,i)=>(
                                                          <div key={d?._id} style={{borderLeft:'1px solid #ddd',width:'100%',padding:'0px 0px !important',display:'flex',borderRight:'1px solid #ddd',borderBottom:'1px solid #ddd',height:'100%'}}>
                                                              <p className='checked_here' style={{opacity:0}} ></p> 
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'11px',paddingLeft:'10px',minWidth: '6.2%',maxWidth: '5%',borderRight:'1px solid #ddd', color: 'black',marginRight:'10px',opacity:'1' }}>{page > 1 ? (page - 1) * pagination?.limit + i + 1 : i+1}</p>
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '9.65%',maxWidth: '30%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.point?.client?.client_name}</p>
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '11.78%',maxWidth: '14.8%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.point?.name}</p>
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '9.7%',maxWidth: '30%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.point?.lats} / {d?.point?.long}</p>
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '13%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.latitude} / {d?.longitude} <br></br> Distance : {kmToMeters(d?.distance)} KM</p>
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8.2%',maxWidth: '8.2%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{d?.added_by == "InHouse-Employee" ? d?.field_officer?.name : d?.deployed_officer?.name}</p>
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '20.9%',maxWidth: '20.9%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>
                                                                           {d?.location} / 
                                                                          <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Points : </h6>
                                                                          <div style={{display:'flex',marginTop:10,flexDirection:'row',flexWrap:'wrap'}}>
                                                                            {d?.check_point_list?.map((f)=>(
                                                                            <h6 style={{fontSize:'11px',fontWeight:'400',border:'1px solid #eee',padding:'2px 4px',textTransform:'capitalize',background:theme_color,fontWeight:'700',color:'#fff'}}>{f?.name}</h6>
                                                                            ))}
                                                                          </div>

                                                                          <h6 style={{fontSize:'11px',fontWeight:'400',marginTop:'-3px'}}>Scanned Remarks : {d?.remarks !== null ? d?.remarks : ''} </h6>
                                                                        {d?.image &&  <h6 onClick={()=>openfile(d?.image)} style={{fontSize:'11px',fontWeight:'900',color:'#446ebd',textDecorationLine:'underline',cursor:'pointer',marginTop:'-3px'}}>View Image</h6>}
                                                              </p>
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '14.6%',borderRight:'1px solid #ddd', marginRight: '0.5%' }}>{<span style={{fontSize:'10px'}}>{moment(d?.createdAt).format('ll')}</span>}</p>
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',minWidth: '8%',maxWidth: '8%' }}>
                                                              {(roles.includes('admin')) &&
                                                              <p style={{marginBottom:'0px',padding:'4px 0px',fontSize:'12px',display:'inline',minWidth: '8%',maxWidth: '8%', marginRight: '0.5%' }}>
                                                                    
                                                                     
                                                              <MdDeleteOutline onClick={()=>deleteData(d?._id)}  size={16}   style={{marginLeft:'6px'}} />

                                                              </p>}
                                                              </p>
                                                          </div>))}
                                                          <div style={{marginTop:'40px',display:'flex',justifyContent:'center'}}> 
                                            {pagination?.total > pagination?.limit &&
                                                <Pagination current={page} size="small" total={pagination?.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
                                            }
                                                         </div>
                            
                                            </div> 
              :
              <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                  <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
                  <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients created yet"}</h4>
                  <h4 style={{ fontSize: '12px', fontWeight: '500', marginTop: '2px', textAlign: 'center',opacity:'0.8' }}>{"To see clients details click on the add button and create a new client form and submit"}</h4>
              </div>}
      <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'center', margin: '40px auto' }}>
          {pagination?.total > pagination?.limit &&
            <Pagination current={page} size="small" total={pagination.total} onChange={(v) => setpagefuun(v)}  pageSize={pagination?.limit} />
          }
      </div>

      </div> 
      :
      <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
          <img src={data_not_found} alt="nodatafouund" style={{ margin: 'auto', width: '180px', objectFit: 'contain' }} />
          <h4 style={{ fontSize: '15px', fontWeight: '700', marginTop: '10px', textAlign: 'center',marginBottom:'0px' }}>{"You don't have any clients scan report created yet"}</h4>
      </div>}
      </div>

    </FmsDashboardMainRoot>
  )
}

export default FmsClientScan