import React, { useEffect, useState } from 'react'
import FmsDashboardMainRoot from '../FmsDashboardMainRoot'
import { BoldText1, SmallText } from '../../../helpers/constants/ConstantsText'
import PrimaryButton, { PrimaryButtonFilter, SecondaryButton } from '../../../components/buttons/PrimaryButton'
import { Input, Select } from 'antd'
import { IoIosClose } from 'react-icons/io';
import { theme_color } from '../../../helpers/constants/ConstantColor'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import axios from 'axios'
import { authHeader } from '../../../helpers/usertoken/UserToken'
import { useLocation, useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import {useSelector} from 'react-redux'

function FmsClientsSalaryStatementCE() {

  const {state,pathname} = useLocation()
  const navigator = useNavigate()

  const path = pathname.split('/')[pathname.split('/')?.length - 1]
  const roles = useSelector(state=>state.Auth.roles)
  const user = useSelector(state=>state.Auth)

  const [modal,setmodal] = useState(false)
  
  const [data,setdata] = useState({
    designation:'',
    basic:'0',
    da:'0',
    service_weightage_percent_da:'0',
    washing_allownace:'0',
    hra_is_basic_da_40_per:'0',
    hra_is_basic_da_40_per_val:'0',
    advance_bonus_basic_da_833percent:'0',
    cca_5per_basic_da:'0',
    privileged_leaves_basic_da_18days:'0',
    special_allowance:'0',
    other_allowance:'0',
    total_gross:'0',

    // employee 
    pf_employee_12per:'0',
    esi_emplyee075per:'0',
    pt:'0',
    lwf_ee:'2',
    total_deduction:'0',
    reliever_charges:'0',
    ot_wages:'0',
    net_pay_take_home_salary:'0',

    // employeer 
    pf_employee_13per:'0',
    esi_emplyee325per:'0',
    lwf_er:'3',
    health_insurance:'0',
    ex_policy:'0',
    outskirts_allowance:'0',
    training_charges:'0',
    accidental_coverage:'0',
    uniform:'0',
    gratuity_481per_basic_wages:'0',
    employer_contribution:'0',
    one_time_payment_pvc_and_bvg:'0',
    total:'0',
    management_fee_12per:'0',
    grand_total_ctc:'0',
    over_time_duty_per_hr:'0',
    is_approved:false





    // salary:'',
    // washing_allownace:'',
    // leave_wages:'',
    // cca:'',
    // advance_bonus:'',
    // gross:'',
    // one_time:'',
    // bonus:'',
    // nfh:'',
    // other_allowance:'',
    // new_take_home:'',
    // leave_encashment:'',
    // uniform_cost:'',
    // reliever_charges:'',
    // health_insurance:'',
    // gratuity_le:'',
    // mng_fee:''
  })
  
  const [error,seterror] = useState({
    designation:'',
    basic:'',
    da:'',
    service_weightage_percent_da:'',
    washing_allownace:'',
    hra_is_basic_da_40_per:'',
    hra_is_basic_da_40_per_val:'',
    advance_bonus_basic_da_833percent:'',
    cca_5per_basic_da:'',
    privileged_leaves_basic_da_18days:'',
    special_allowance:'',
    other_allowance:'',
    total_gross:'',

    // employee 
    pf_employee_12per:'',
    esi_emplyee075per:'',
    pt:'',
    lwf_ee:'',
    total_deduction:'',
    reliever_charges:'',
    ot_wages:'',
    net_pay_take_home_salary:'',

    // employeer 
    pf_employee_13per:'',
    esi_emplyee325per:'',
    lwf_er:'',
    health_insurance:'',
    ex_policy:'',
    outskirts_allowance:'',
    training_charges:'',
    accidental_coverage:'',
    uniform:'',
    gratuity_481per_basic_wages:'',
    employer_contribution:'',
    one_time_payment_pvc_and_bvg:'',
    total:'',
    management_fee_12per:'',
    grand_total_ctc:'',
    over_time_duty_per_hr:'',

  })
  
  const [fmsemployee_designation,setfmsemployee_designation] = useState([])

  async function searchdesignation(e){
    if(e?.length > 2){
        await axios.get(`fms/fmsdesignationonboardemployee/search?search_text=${e}`,authHeader)
        .then((res)=>{
            let arr = []
            res.data.datas.forEach((d)=>{
                let obj = {
                    value : d._id,
                    label:d.designation_name
                }
                arr.push(obj)
            })
            setfmsemployee_designation(arr)

            return arr.filter((i) =>
                i.label.toLowerCase().includes(e.toLowerCase())
                );

        }).catch((err)=>{
        })
    }
  }

  useEffect(()=>{
      if(path === 'edit'){
        delete state.created_by
        delete state.updated_by
        setdata({...state,designation:{label:state?.designation?.designation_name,value:state?.designation?._id}})
      }else{
      }


  },[path])

 

  function submitform(){
    if(!data.designation){
        seterror({...error,designation:'This Field is required*'})
    }else if(!data.basic){
        seterror({...error,basic:'This Field is required*'})
    }else{
       
        let send_Data = {...data}
        send_Data['client'] = state
        send_Data['designation'] = data?.designation?.value


        if(data?._id === null || data?._id === undefined){
          
         axios.post(`fms/fmsclientsbasedsalarystructure/create`,send_Data,authHeader).then((res)=>{
            if(res.status === 201){
                toast.success(res?.data?.message)
                resetform()
                setmodal(false)
            }
            }).catch((err)=>{
            })
        } else{
            if(data.is_approved){
              send_Data['approved_by'] = user.id
            }
            send_Data['client'] = state?.client?._id
            send_Data['designation'] = data?.designation?.value


            axios.put(`fms/fmsclientsbasedsalarystructure/update/${state?._id}`,send_Data,authHeader).then((res)=>{
                if(res.status === 200){
                    toast.success(res?.data?.message)
                    resetform()
                    navigator(-1)
                    setmodal(false)
                }
                }).catch((err)=>{
                })
        }  
    }
  }


  function resetform(){
     setdata({
      designation:'',
      basic:'0',
      da:'0',
      service_weightage_percent_da:'0',
      washing_allownace:'0',
      hra_is_basic_da_40_per:'0',
      hra_is_basic_da_40_per_val:'0',
      advance_bonus_basic_da_833percent:'0',
      cca_5per_basic_da:'0',
      privileged_leaves_basic_da_18days:'0',
      special_allowance:'0',
      other_allowance:'0',
      total_gross:'0',
  
      // employee 
      pf_employee_12per:'0',
      esi_emplyee075per:'0',
      pt:'0',
      lwf_ee:'2',
      total_deduction:'0',
      reliever_charges:'0',
      ot_wages:'0',
      net_pay_take_home_salary:'0',
  
      // employeer 
      pf_employee_13per:'0',
      esi_emplyee325per:'0',
      lwf_er:'3',
      health_insurance:'0',
      ex_policy:'0',
      outskirts_allowance:'0',
      training_charges:'0',
      accidental_coverage:'0',
      uniform:'0',
      gratuity_481per_basic_wages:'0',
      employer_contribution:'0',

      one_time_payment_pvc_and_bvg:'0',
      total:'0',
      management_fee_12per:'0',
      grand_total_ctc:'0',
      over_time_duty_per_hr:'0',
  
  
  
  
  
      // salary:'',
      // washing_allownace:'',
      // leave_wages:'',
      // cca:'',
      // advance_bonus:'',
      // gross:'',
      // one_time:'',
      // bonus:'',
      // nfh:'',
      // other_allowance:'',
      // new_take_home:'',
      // leave_encashment:'',
      // uniform_cost:'',
      // reliever_charges:'',
      // health_insurance:'',
      // gratuity_le:'',
      // mng_fee:''
     })
    
     seterror({
      designation:'',
      basic:'',
      da:'',
      service_weightage_percent_da:'',
      washing_allownace:'',
      hra_is_basic_da_40_per:'',
      hra_is_basic_da_40_per_val:'',
      advance_bonus_basic_da_833percent:'',
      cca_5per_basic_da:'',
      privileged_leaves_basic_da_18days:'',
      special_allowance:'',
      other_allowance:'',
      total_gross:'',

      // employee 
      pf_employee_12per:'',
      esi_emplyee075per:'',
      pt:'',
      lwf_ee:'',
      total_deduction:'',
      reliever_charges:'',
      ot_wages:'',
      net_pay_take_home_salary:'',

      // employeer 
      pf_employee_13per:'',
      esi_emplyee325per:'',
      lwf_er:'',
      health_insurance:'',
      ex_policy:'',
      outskirts_allowance:'',
      training_charges:'',
      accidental_coverage:'',
      uniform:'',
      gratuity_481per_basic_wages:'',
      employer_contribution:'',
      one_time_payment_pvc_and_bvg:'',
      total:'',
      management_fee_12per:'',
      grand_total_ctc:'',
      over_time_duty_per_hr:'',
     })
  }

  async function handlechange(e){
    // const {name,value} = e.target
    // console.log("name",name)
    // console.log("value",value)
    // setdata({...data,[e.target.name]:e.target.value})

    if(!data.is_approved && data.is_approved !== undefined){
      setdata({...data,[e.target.name]:e.target.value})
    }else{
      toast.error("It's in approve stage cant modified")
    }
  }



  async function calculateFixedVal1(){

    let basic_da =  Math.round(parseFloat(data.basic) + parseFloat(data.da))
    let hra_is_basic_da_40_per = data?.hra_is_basic_da_40_per

    if(basic_da > 0){
        let service_weightage_percent_da = Math.round((basic_da / 100) * 2).toString()
        let hra_is_basic_da_40_per_val = Math.round(((basic_da) /100) * hra_is_basic_da_40_per).toString()
        let advance_bonus_basic_da_833percent = Math.round(((basic_da) / 100) * 8.33).toString()
        // let cca_5per_basic_da = Math.round(((basic_da)/100) * 5).toString()
        let privileged_leaves_basic_da_18days =  Math.round(((basic_da) * 18 ) / 30 /12).toString()


        // console.log("service_weightage_percent_da",service_weightage_percent_da)
        // console.log("hra_is_basic_da_40_per_val",hra_is_basic_da_40_per_val)
        // console.log("advance_bonus_basic_da_833percent",advance_bonus_basic_da_833percent)
        // console.log("cca_5per_basic_da",cca_5per_basic_da)
        // console.log("privileged_leaves_basic_da_18days",privileged_leaves_basic_da_18days)
        
        

        if(basic_da && service_weightage_percent_da && hra_is_basic_da_40_per_val && advance_bonus_basic_da_833percent && data?.cca_5per_basic_da && privileged_leaves_basic_da_18days){
          
          let total_gross =  Math.round(basic_da + parseFloat(service_weightage_percent_da) + parseFloat(hra_is_basic_da_40_per_val) + parseFloat(advance_bonus_basic_da_833percent) + parseFloat(data?.cca_5per_basic_da) + parseFloat(privileged_leaves_basic_da_18days) + parseFloat(data?.special_allowance) + parseFloat(data?.other_allowance)).toString()
          
          // console.log("total_gross",total_gross)

            
            let reliever_charges = 0
            let pf_employee_12per = 0
            let pf_employee_13per = 0
            let ot_wages = 0
    
            if(total_gross > 25000){
                reliever_charges = Math.round(parseInt(basic_da / 30) * 4)
                // ot_wages = Math.round((parseInt((total_gross) / 30) / 8) * 4 * 31)
            }else{
                reliever_charges = 0
                // ot_wages = 0
            }
            
            if(total_gross !== ''){
              if(total_gross > 15000){
                pf_employee_12per = 1800
                pf_employee_13per = 1950
              }else{
                pf_employee_12per = Math.round((parseFloat(total_gross)/100) * 12).toString()
                pf_employee_13per = Math.round((parseFloat(total_gross)/100) * 13).toString()
              }
              
            
              if(data?.ot_wages !== '0'){
                 if(total_gross > 25000){
                    ot_wages = Math.round((parseFloat(total_gross / 30) / 8 )*4*31)
                 }else{
                    ot_wages = 0
                 }
              }

            // console.log("pf_employee_12per",pf_employee_12per)
            // console.log("pf_employee_13per",pf_employee_13per)

              
            // let ot_wages = 

            // console.log("ot_wages",ot_wages)

            let obj = {
              service_weightage_percent_da:service_weightage_percent_da,
              hra_is_basic_da_40_per:hra_is_basic_da_40_per,
              hra_is_basic_da_40_per_val:hra_is_basic_da_40_per_val,
              advance_bonus_basic_da_833percent:advance_bonus_basic_da_833percent,
              // cca_5per_basic_da:cca_5per_basic_da,
              privileged_leaves_basic_da_18days:privileged_leaves_basic_da_18days,
              total_gross:total_gross,
              reliever_charges:reliever_charges,
              ot_wages:ot_wages,
              pf_employee_12per:pf_employee_12per,
              pf_employee_13per:pf_employee_13per,
            }


            //  console.log("pf_employee_12per",pf_employee_12per)
            //  console.log("data?.esi_emplyee075per",data?.esi_emplyee075per)
            //  console.log("data.pt",data.pt)
            //  console.log("data.lwf_ee",data.lwf_ee)
            //  console.log("reliever_charges",reliever_charges)
            //  console.log("ot_wages",ot_wages)


              if(pf_employee_12per && data?.esi_emplyee075per !== '' && data.pt !== '' && data.lwf_ee !== ''  && reliever_charges !== '' && ot_wages !== ''){

                // console.log("hello")
                    let total_deduction = Math.round(parseFloat(pf_employee_12per) + parseFloat(data?.esi_emplyee075per) + parseFloat(data?.pt) + parseFloat(data?.lwf_ee))
                    let net_pay_take_home_salary = Math.round(parseFloat(total_gross) - parseFloat(total_deduction))
                    
                    obj['total_deduction'] = total_deduction
                    obj['net_pay_take_home_salary'] = net_pay_take_home_salary


                    // console.log("im here atleast")
                     
                    if(pf_employee_13per && data.esi_emplyee325per && data.lwf_er && data.health_insurance && data.ex_policy && data.outskirts_allowance && data.training_charges && data.accidental_coverage && data?.uniform && data?.gratuity_481per_basic_wages && data?.employer_contribution){
                     
                      let employer_contribution = Math.round(parseFloat(data?.pf_employee_13per) + parseFloat(data.esi_emplyee325per) + parseFloat(data.lwf_er) + parseFloat(data.health_insurance) + parseFloat(data.ex_policy) + parseFloat(data.outskirts_allowance) + parseFloat(data.training_charges) + parseFloat(data.accidental_coverage) + parseFloat(data?.uniform) + parseFloat(data?.gratuity_481per_basic_wages))
                      let total = Math.round(parseFloat(employer_contribution)  + parseFloat(total_gross))
                      let management_fee_12per = Math.round((parseFloat(total)) / 100 * 12)
                      let grand_total_ctc = Math.round(total + management_fee_12per)


                      let over_time_duty_per_hr = Math.round(grand_total_ctc / 30 / 8)

                      obj['total'] = total
                      obj['management_fee_12per'] = management_fee_12per
                      obj['grand_total_ctc'] = grand_total_ctc
                      obj['over_time_duty_per_hr'] = over_time_duty_per_hr

                      setdata({...data,...obj})
                    }else{
                      setdata({...data,...obj})
                    }
              }else{
                setdata({...data,...obj})
              } 
            }
        }else{
          setdata({...data,
            service_weightage_percent_da:service_weightage_percent_da,
            hra_is_basic_da_40_per:hra_is_basic_da_40_per,
            advance_bonus_basic_da_833percent:advance_bonus_basic_da_833percent,
            // cca_5per_basic_da:cca_5per_basic_da,
            privileged_leaves_basic_da_18days:privileged_leaves_basic_da_18days
        })
        }
     }
  }
  


 
  // console.log("data",data)


  return (
    <FmsDashboardMainRoot>
        <Toaster/>
            <div style={{position:'relative',overflowY:'scroll'}}>
                
                <div style={{width:'35%'}}>
                <BoldText1 val={'Add / Edit Salary Statement'} />
                <h6 style={{background:'#eee',padding:'4px',fontSize:'11px',fontWeight:'400',marginTop:'10px'}}>Use the below form to create or update the client points for performance</h6>
                </div>


                <div>
                  <PrimaryButton onClick={calculateFixedVal1} btn_name={'Calculate'} />
                </div>

{/* 
                <div style={{width:'65%',marginTop:'20px',display:'flex'}}>
                    <div style={{marginRight:'2%'}}>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Designation Name'}  />
                    </div>
                    <Select allowClear={true} filterOption={false} options={fmsemployee_designation} showSearch={true} onSearch={(e)=>{searchdesignation(e)}}  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}  value={data?.designation}  onChange={(e)=>{setdata({...data,designation:fmsemployee_designation.find((c)=>c?.value === e)});seterror({...error,designation:''})}} />

                    </div>



                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Basic'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.basic} onChange={(e)=>setdata({...data,basic:e.target.value})} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.basic) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.basic}</p>
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'HRA'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.hra} onChange={(e)=>setdata({...data,hra:e.target.value})} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.hra) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.hra}</p>
                    </div>


                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Salary'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.salary} onChange={(e)=>setdata({...data,salary:e.target.value})} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.salary) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.salary}</p>
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'DA'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.da} onChange={(e)=>setdata({...data,da:e.target.value})} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.da) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.da}</p>
                    </div>


                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Washing Allowance'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.washing_allownace} onChange={(e)=>setdata({...data,washing_allownace:e.target.value})} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.washing_allownace) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.washing_allownace}</p>
                    </div>


                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Leave Wages'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.leave_wages} onChange={(e)=>setdata({...data,leave_wages:e.target.value})} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.leave_wages) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.leave_wages}</p>
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'CCA'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.cca} onChange={(e)=>setdata({...data,cca:e.target.value})} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.cca) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.cca}</p>
                    </div>

                    <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Advance Bonus'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.advance_bonus} onChange={(e)=>setdata({...data,advance_bonus:e.target.value})} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.advance_bonus) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.advance_bonus}</p>
                    </div>

                    <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Gross'}  />
                        </div>
                        <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.gross} onChange={(e)=>setdata({...data,gross:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.gross) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.gross}</p>
                        </div>

                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'One Time'}  />
                        </div>
                        <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.one_time} onChange={(e)=>setdata({...data,one_time:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.one_time) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.one_time}</p>
                        </div>


                    </div>


                    <div style={{marginLeft:'2%'}}>
                      

                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Bonus'}  />
                        </div>
                        <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.bonus} onChange={(e)=>setdata({...data,bonus:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.bonus) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.bonus}</p>
                        </div>

                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Nfh'}  />
                        </div>
                        <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.nfh} onChange={(e)=>setdata({...data,nfh:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.nfh) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.nfh}</p>
                        </div>

                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Other Allowance'}  />
                        </div>
                        <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.other_allowance} onChange={(e)=>setdata({...data,other_allowance:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.other_allowance) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.other_allowance}</p>
                        </div>

                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'New Take Home'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.new_take_home} onChange={(e)=>setdata({...data,new_take_home:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.new_take_home) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.new_take_home}</p>
                        </div>
                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Leave Encashment'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.leave_encashment} onChange={(e)=>setdata({...data,leave_encashment:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.leave_encashment) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.leave_encashment}</p>
                        </div>



                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.new_take_home) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.new_take_home}</p>
                        </div>
                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Uniform Cost'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.uniform_cost} onChange={(e)=>setdata({...data,uniform_cost:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.uniform_cost) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.uniform_cost}</p>
                        </div>


                      
                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Reliever Charges'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.reliever_charges} onChange={(e)=>setdata({...data,reliever_charges:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.reliever_charges) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.reliever_charges}</p>
                        </div>

                      
                     

                      
                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Health Insurance'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.health_insurance} onChange={(e)=>setdata({...data,health_insurance:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.health_insurance) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.health_insurance}</p>
                        </div>

                     
                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'Gratuity LE'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.gratuity_le} onChange={(e)=>setdata({...data,gratuity_le:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.gratuity_le) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.gratuity_le}</p>
                        </div>

                        <div style={{marginBottom:'10px'}}>
                        <div style={{width:'150px'}}>
                        <SmallText fontWeight={'600'} val={'MNG Fee'}  />
                        </div>
                        <Input type="text" style={{borderLeft:`4px solid ${theme_color}`}}  value={data.mng_fee} onChange={(e)=>setdata({...data,mng_fee:e.target.value})} />
                        </div>

                        <div style={{display:'flex',flexDirection:'row !important'}}>
                        {(error?.mng_fee) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                        <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.mng_fee}</p>
                        </div>

                        
                    </div>
                </div> */}


                {/* <BoldText1 val={'Basic Option'} />
                <div style={{width:'100%',marginTop:'20px',marginBottom:'20px',display:'flex',flexWrap:'wrap',borderBottom:'1px solid #eee'}}>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Basic'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  name="basic" value={data.basic} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.basic) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.basic}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'DA'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  name="da" value={data.da} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.da) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.da}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Services Weightage - (2% on Basic DA)'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" readOnly style={{borderLeft:`4px solid ${theme_color}`}} name="service_weightage_percent_da"  value={data.service_weightage_percent_da} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.service_weightage_percent_da) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.service_weightage_percent_da}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Washing Allowance (Fixed)'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="washing_allownace"  value={data?.washing_allownace} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.washing_allownace) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.washing_allownace}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'HRA - Basic DA * 40%'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" readOnly style={{borderLeft:`4px solid ${theme_color}`}} name="hra_is_basic_da_40_per" value={data.hra_is_basic_da_40_per} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.hra_is_basic_da_40_per) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.hra_is_basic_da_40_per}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Advance Bonus 8.33%  for Monthly - Basic DA * 8.33%'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} readOnly type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="advance_bonus_basic_da_833percent" value={data.advance_bonus_basic_da_833percent} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.advance_bonus_basic_da_833percent) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.advance_bonus_basic_da_833percent}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'CCA @5% Basic & DA '}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} readOnly type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="cca_5per_basic_da" value={data.cca_5per_basic_da} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.cca_5per_basic_da) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.cca_5per_basic_da}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Privileged Leaves - Basic DA for 18 days '}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} readOnly type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="privileged_leaves_basic_da_18days"  value={data.privileged_leaves_basic_da_18days} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.privileged_leaves_basic_da_18days) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.privileged_leaves_basic_da_18days}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Special Allowance'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  name="special_allowance"  value={data?.special_allowance} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.special_allowance) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.special_allowance}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Other Allowance'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  name="other_allowance"  value={data?.other_allowance} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.other_allowance) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.other_allowance}</p>
                    </div>
                 </div>

                 <div style={{marginRight:'10px'}}>
                  <div style={{marginBottom:'10px'}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Total Gross'}  />
                    </div>
                    <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="total_gross"  value={data.total_gross} onChange={handlechange} />
                    </div>

                    <div style={{display:'flex',flexDirection:'row !important'}}>
                    {(error?.total_gross) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                    <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.total_gross}</p>
                    </div>
                 </div>  
                
                </div>

                <BoldText1 val={'Employee Contribution'} />
                <div style={{width:'100%',marginTop:'20px',marginBottom:'20px',display:'flex',flexWrap:'wrap',borderBottom:'1px solid #eee'}}>
                 
                 
 
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'PF Employee 12 % - 15000/12% - (Restricted Mode) (Basic DA + Leave Encashment (If Provided Monthly) + Special Allowances + Conveynence)*0.12 - (Unrestricted Mode)'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} readOnly type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="pf_employee_12per" value={data.pf_employee_12per} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.pf_employee_12per) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.pf_employee_12per}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'PT'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="pt" value={data.pt} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.pt) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.pt}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'LWF Ee'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="lwf_ee" value={data.lwf_ee} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.lwf_ee) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.lwf_ee}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Total Deduction'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="total_deduction" value={data.total_deduction} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.total_deduction) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.total_deduction}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Reliever Charges'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} readOnly type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="reliever_charges"  value={data.reliever_charges} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.reliever_charges) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.reliever_charges}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'OT Wages'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} readOnly type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="ot_wages"  value={data.ot_wages} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.ot_wages) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.ot_wages}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Net Pay(Take Home Salary)'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="net_pay_take_home_salary"  value={data.net_pay_take_home_salary} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.net_pay_take_home_salary) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.net_pay_take_home_salary}</p>
                   </div>
                  </div>
 
                 
                </div>

                <BoldText1 val={'Employeer Contribution'} />
                <div style={{width:'100%',marginTop:'20px',display:'flex',flexWrap:'wrap',}}> 
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'PF Employer 13 % - 15000/13% - (Restricted Mode) '}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} readOnly type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="pf_employee_13per" value={data.pf_employee_13per} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.pf_employee_13per) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.pf_employee_13per}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'ESI Employer 3.25% - (Gross Wages - Washing Allowances)*3.25%'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} readOnly type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="esi_emplyee325per" value={data.esi_emplyee325per} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.esi_emplyee325per) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.esi_emplyee325per}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'LWF Er'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="lwf_er" value={data.lwf_er} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.lwf_er) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.lwf_er}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Health Insurance'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="health_insurance"  value={data.health_insurance} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.health_insurance) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.health_insurance}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'EC Policy'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="ex_policy" value={data.ex_policy} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.ex_policy) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.ex_policy}</p>
                     </div>
                  </div>
 
                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Outskirts Allowance'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="outskirts_allowance" value={data.outskirts_allowance} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.outskirts_allowance) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.outskirts_allowance}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Training Charges'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  name="training_charges" value={data.training_charges} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.training_charges) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.training_charges}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Accidental Coverage'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="accidental_coverage" value={data.accidental_coverage} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.accidental_coverage) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.accidental_coverage}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Uniform'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="uniform" value={data.uniform} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.uniform) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.uniform}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Gratuity - 4.81% on Basic Wages'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="gratuity_481per_basic_wages"  value={data.gratuity_481per_basic_wages} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.gratuity_481per_basic_wages) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.gratuity_481per_basic_wages}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Employer Contribution '}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="employer_contribution" value={data.employer_contribution} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.employer_contribution) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.employer_contribution}</p>
                     </div>
                  </div>


                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Total'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="total"  value={data.total} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.total) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.total}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Total'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="total"   value={data.total} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.total) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.total}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Management Fee @  12%'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  name="management_fee_12per"  value={data.management_fee_12per} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.management_fee_12per) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.management_fee_12per}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Grand Total (CTC)'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}} name="grand_total_ctc"  value={data.grand_total_ctc} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.grand_total_ctc) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.grand_total_ctc}</p>
                     </div>
                  </div>

                  <div style={{marginRight:'10px'}}>
                   <div style={{marginBottom:'10px'}}>
                     <div style={{width:'150px'}}>
                     <SmallText fontWeight={'600'} val={'Over Time Duty per hour'}  />
                     </div>
                     <Input  onMouseLeave={calculateFixedVal} type="text" style={{borderLeft:`4px solid ${theme_color}`}}  name="over_time_duty_per_hr" value={data.over_time_duty_per_hr} onChange={handlechange} />
                     </div>
 
                     <div style={{display:'flex',flexDirection:'row !important'}}>
                     {(error?.over_time_duty_per_hr) && <AiOutlineInfoCircle size={14} style={{color:'red'}}/>  }
                     <p style={{marginBottom:'0px',fontSize:'10px',color:'red',paddingLeft:'3px'}}>{error?.over_time_duty_per_hr}</p>
                     </div>
                  </div>
 
                 
                </div> */}



              <div style={{marginBottom:'10px',marginTop:'20px',width:'40%',}}>
                    <div style={{width:'150px'}}>
                    <SmallText fontWeight={'600'} val={'Designation Name'}  />
                    </div>
                    <Select allowClear={true} filterOption={false} options={fmsemployee_designation} showSearch={true} onSearch={(e)=>{searchdesignation(e)}}  style={{borderLeft:`4px solid ${theme_color}`,width:'100%',zIndex:100,fontSize:'13px',fontWeight:'600'}}  value={data?.designation}  onChange={(e)=>{setdata({...data,designation:fmsemployee_designation.find((c)=>c?.value === e)});seterror({...error,designation:''})}} />

                    </div>

                <div style={{width:'40%',borderLeft:'1px solid #ddd',borderRight:'1px solid #ddd',borderTop:'1px solid #ddd'}}>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Basic</h6>
                    <Input  style={{borderWidth:0,width:'30%'}} name={'basic'} value={data?.basic} onChange={handlechange} />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>DA</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'da'} value={data?.da} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Services weightage - 2% on Basic DA</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'service_weightage_percent_da'} value={data?.service_weightage_percent_da} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Washing Allowance(Fixed)</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'washing_allownace'} value={data?.washing_allownace} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>HRA - Basic DA Percentage</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'hra_is_basic_da_40_per'} value={data?.hra_is_basic_da_40_per} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>HRA - Basic DA Value</h6>
                    <Input style={{borderWidth:0,width:'30%'}}  name={'hra_is_basic_da_40_per_val'} value={data?.hra_is_basic_da_40_per_val} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Advance Bonus 8.33%  for Monthly - Basic DA * 8.33%</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'advance_bonus_basic_da_833percent'} value={data?.advance_bonus_basic_da_833percent} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>CCA @5% Basic & DA </h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'cca_5per_basic_da'} value={data?.cca_5per_basic_da} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Privileged Leaves - Basic DA for 18 days </h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'privileged_leaves_basic_da_18days'} value={data?.privileged_leaves_basic_da_18days} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Special Allowance</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'special_allowance'} value={data?.special_allowance} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Other Allowance</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'other_allowance'} value={data?.other_allowance} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd',}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>Total Gross</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'total_gross'} value={data?.total_gross} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>PF Employee 12 % - 15000/12% - (Restricted Mode) (Basic DA + Leave Encashment (If Provided Monthly) + Special Allowances + Conveynence)*0.12 - (Unrestricted Mode)</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'pf_employee_12per'} value={data?.pf_employee_12per} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>ESI Employee 0.75 % - (Gross Wages - Washing Allowances)*0.75%</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'esi_emplyee075per'} value={data?.esi_emplyee075per} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>PT</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'pt'} value={data?.pt} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>LWF Ee</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'lwf_ee'} value={data?.lwf_ee} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Total Deduction</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'total_deduction'} value={data?.total_deduction} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Reliever Charges</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'reliever_charges'} value={data?.reliever_charges} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>OT Wages </h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'ot_wages'} value={data?.ot_wages} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>Net Pay(Take Home Salary)</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'net_pay_take_home_salary'} value={data?.net_pay_take_home_salary} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>PF Employer 13 % - 15000/13% - (Restricted Mode) </h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'pf_employee_13per'} value={data?.pf_employee_13per} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>ESI Employer 3.25% - (Gross Wages - Washing Allowances)*3.25%</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'esi_emplyee325per'} value={data?.esi_emplyee325per} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>LWF Er</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'lwf_er'} value={data?.lwf_er} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Health Insurance</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'health_insurance'} value={data?.health_insurance} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>EC Policy</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'ex_policy'} value={data?.ex_policy} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Outskirts allowance</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'outskirts_allowance'} value={data?.outskirts_allowance} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Training Charges</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'training_charges'} value={data?.training_charges} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Accidental Coverage</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'accidental_coverage'} value={data?.accidental_coverage} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Uniform</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'uniform'} value={data?.uniform} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Gratuity - 4.81% on Basic Wages</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'gratuity_481per_basic_wages'} value={data?.gratuity_481per_basic_wages} onChange={handlechange}  />
                  </div>


                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Employer Contribution </h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'employer_contribution'} value={data?.employer_contribution} onChange={handlechange}  />
                  </div>


                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>One Time Payment PVC & BVG</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'one_time_payment_pvc_and_bvg'} value={data?.one_time_payment_pvc_and_bvg} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#5b698f'}}>Total</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'total'} value={data?.total} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Management Fee @  12%</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'management_fee_12per'} value={data?.management_fee_12per} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Grand Total (CTC)</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'grand_total_ctc'} value={data?.grand_total_ctc} onChange={handlechange}  />
                  </div>

                  <div style={{display:'flex',flexDirection:'row',borderBottom:'1px solid #ddd'}}>
                    <h6 style={{width:'70%',fontWeight:'bold',padding:'4px',fontSize:'13px',paddingTop:'6px',borderRight:'1px solid #ddd',marginBottom:'0px',background:'#e8eeff'}}>Over Time Duty per hour</h6>
                    <Input style={{borderWidth:0,width:'30%'}} name={'over_time_duty_per_hr'} value={data?.over_time_duty_per_hr} onChange={handlechange}  />
                  </div>


                


                </div>

                {['admin','fms_hr']?.filter((f)=>roles?.includes(f))?.length > 0 &&
                <div style={{marginTop:'10px'}}>
                    <div style={{display:'flex',flexDirection:'row'}}>
                    <p className={data?.is_approved  ? 'checked_here2': 'checked_here'} onClick={()=>setdata({...data,is_approved:!data.is_approved}) }>{data?.is_approved && <p className='checked_here1' style={{background:theme_color}}></p>}</p>
                    <p style={{marginLeft:'10px',marginTop:'-2px'}}>Is  Approved</p>
                    </div>
                  </div>}


                <div style={{display:'flex'}}>
                    <PrimaryButton btn_name={'Save'} onClick={submitform}/>
                    <SecondaryButton btn_name={'Close'} onClick={()=>navigator(-1)}/>
                </div>


            </div>

       
       
       

    </FmsDashboardMainRoot>
  )
}

export default FmsClientsSalaryStatementCE